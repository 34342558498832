<template>
    <div class="mainStyle">
        <scheduleDetailContent :schedule="invitation" :isInvitation="true" @updateSchedule="onUpdateSchedule" :tokenData="tokenDataDat"/>
    </div>
</template>
<script>
import scheduleDetailContent from "@/page/meeting/schedule/scheduleDetailContent.vue"
import { useInvitationStore } from "@/stores/invitation"
import { useRoute, useRouter } from 'vue-router'
import { computed, inject, onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import scheduleData from '@/data/scheduleData'
import util from '@/data/util'
export default {
    components : {
        scheduleDetailContent,
    },
    setup(props, ctx) {
        const route = useRoute()
        const router = useRouter()
        const invitationStore = useInvitationStore()
        const modalManager = inject("ModalManager")
        const {invitation} = storeToRefs(invitationStore)
        const tokenData = ref([])

        const tokenDataDat = computed(() => {
            if(tokenData.value.length > 1 && tokenData.value[1]?.dat) {
                return tokenData.value[1].dat
            }
            return {}
        })

        const onUpdateSchedule = async (type, param) => {
            const func = async () => {
                if(type == "accept") {
                    const res = await modalManager.showdialog({
                        title : "Do you want to accept this schedule?",
                        buttons : {
                            "yes" : "yes",
                            "no" : "no"
                        }
                    })
                    if(res.result == "yes") {
                        await invitationStore.updateInvitation({
                            status : scheduleData.P_STATUS.NEGOTIATION_AGREEMENT,
                            negotiation : param,
                        }, route.query.token)
                    }
                }
                else if(type == "reject") {
                    const res = await modalManager.showdialog({
                        title : "Do you want to reject this schedule?",
                        buttons : {
                            "yes" : "yes",
                            "no" : "no"
                        }
                    })
                    if(res.result == "yes") {
                        await invitationStore.updateInvitation({
                            status : scheduleData.P_STATUS.NEGOTIATION_REFUSAL,
                            negotiation : param,
                        }, route.query.token)
                    }
                }
                else if(type == "mediate") {
                    router.push({
                        path : "/invitation/mediate",
                        query : {
                            token : route.query.token
                        }
                    })
                }
                else if(type == "proceed") {
                    const res = await modalManager.showdialog({
                        title : "Do you want to proceed for all particpants?",
                        buttons : {
                            "yes" : "yes",
                            "no" : "no"
                        }
                    })
                    if(res.result == "yes") {
                        await invitationStore.proceedInvitation(route.query.token)
                    }
                }
                const res = await refresh()
                return res;
            }
            await modalManager.loading(func)
        }
        const refresh = async () => {
            return await invitationStore.fetchInvitation(route.query.token)
        }
        const onLink = () => {
            router.push("/login")
        }

        onMounted(async () => {
            if(route.query.token) {
                tokenData.value = [];
                const tokenDataList = route.query.token.split(".")
                tokenDataList.forEach((tokenItem, index) => {
                    if(index > 1) {
                        return;
                    }
                    const tokenItemJSON = util.base64TokenParse(tokenItem)

                    const tokenItemData = JSON.parse(tokenItemJSON)
                    tokenData.value.push(tokenItemData) 
                })
            }
            const res = await refresh()
            if(!res) {
                router.push({
                    path : "/invitation/error"
                })
            }
        })

        return {
            tokenDataDat,
            invitation,
            onUpdateSchedule,
            onLink,
        }
    }
}
</script>

<style scoped lang="scss">
.mainStyle {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.loginLink {
    cursor: pointer;
}
</style>