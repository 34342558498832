<template>
    <div class="containerStyle">
        <div class="tablediv">
            <table>
                <colgroup>
                    <col v-for="header in tableData?.header" :key="header"
                        :style="{maxWidth : header.fixed ? `calc(${tableWidth}px / ${tableData?.header.length})` : `auto`}"
                        :class="{nowrap : header.fixed}"/>
                </colgroup>
                <thead>
                    <th v-for="header in tableData?.header" :key="header" 
                        @click="header.order ? sorting(header.value, header.order, header.depdc) : ''"
                    >{{header?.name}}
                        <span 
                            class="arrow"
                            v-if="header?.value == sortColumn"
                            :class="[sortReverse ? 'asc' : 'desc']">
                        </span>
                    </th>
                </thead>
                <tbody>
                    <template v-for="colData in tableData?.content" :key="colData" >
                        <tr @click="colData?.callback(colData)" :style="colData?.style">
                            <template v-for="header in tableData?.header" :key="header">
                                <td v-if="!header.slot" 
                                    :style="{maxWidth : header.fixed ? `calc(1220px / ${tableData?.header.length})` : `auto`}"
                                    :class="{nowrap : header.fixed}"
                                >{{colData?.[header?.value] ?? '-'}}</td>
                                <td v-else> <slot :name="header?.value" :="colData"/></td>
                            </template>
                        </tr>
                        <slot name="trContent" :="colData"/>
                    </template>
                </tbody>
            </table>
        </div>
        <div class="tableFooter">
            <Footer :totalCount="totalCount" :init_page="init_page" :perPage="perPage"/>
        </div>
    </div>
</template>

<script>
import Footer from "@/component/dataTable/dataTableFooter.vue"
import { getCurrentInstance, ref, watch } from 'vue'
import { useRoute } from "vue-router"
export default {
    name : "dataTable",
    props : {
        tableData : Object,
        totalCount : Number,
        pageCallback : Function,
        init_page : Number,
        perPage : Number,
        tableWidth : {
            type : Number,
            default : 1220,
        }
    },
    components : {
        Footer,
    },
    setup(props, ctx) {
        const { emit } = getCurrentInstance()
        const route = useRoute()

        const sortColumn = ref('')
        const sortReverse = ref(false)

        const sorting = (header, order, depdc) => {
            if(depdc) { header = depdc }

            if(sortColumn.value == header) {
                sortReverse.value = !sortReverse.value
            } else {
                sortReverse.value = true
                sortColumn.value = header
            }

            if(sortReverse.value == false) {
                for(let i=0; i<order.length; i++) {
                    order[i] = `-${order[i]}`
                }
            }
            
            emit('sorting', order)
        }

        watch(() => props.tableData.column, () => {
            let columns = props.tableData.column
            if(typeof columns == "string") {
                columns = [props.tableData.column]
            }
            // sortReverse.value = props.tableData.reverse
            if(columns.some(item => item.includes('-'))) {
                columns = columns.map(item => item.replace('-', ''))
                sortReverse.value = false
            } else {
                sortReverse.value = true
            }
            
            props.tableData.header.forEach(item => {
                if(item?.order) {
                    if(item.order[0] == columns[0]) {
                        sortColumn.value = item.value
                    }
                }
            })
        })

        return {
            sortColumn,
            sortReverse,

            sorting,
        }
    },
}
</script>

<style scoped lang="scss">
.containerStyle {
    max-width: 1220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .tablediv {
        position: relative;
        box-shadow: none;
        background: #fff;
        border-radius: 12px;
        overflow: hidden;
        width: 100%;

        table {
            table-layout:auto;
            width: 100%;
            border-spacing:  0px;
            display: table;
            border-collapse: collapse;
            .nowrap {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            thead {
                th {
                    cursor: pointer;
                    margin-right: 1px;
                    height: 30.5px;
                    border-collapse: collapse;
                    background-color: #910457 ;
                    text-align: center;
                    color: #fff;
                    padding: 10px;
                    font: 800 16px / 18px NanumSquareOTF;

                    .desc {
                        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQEAQAAADlauupAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQfmCg4BODiY+VP/AAAAmklEQVQ4y9WRzQ2DMAxG7UGIxBZZjAWQIAeQwgCIyQApi+T10Kopv82V72j5Pcu2yPMD1sI0EY3JZqIxMI5grUAIALCuORKiMcRleTMhCNQ139xLtjCAc581miYVQ4CyPMJFQZzn1Of97hbXkr9wamzbvSQbTpK+397kd+dhANWM1zrHId5nweeS68m3RmJViaqKdJ0qZE9/Vl66mQDawt+EPAAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMi0xMC0xNFQwMTo1Njo1NiswMDowMJ7TLS4AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjItMTAtMTRUMDE6NTY6NTYrMDA6MDDvjpWSAAAAKHRFWHRkYXRlOnRpbWVzdGFtcAAyMDIyLTEwLTE0VDAxOjU2OjU2KzAwOjAwuJu0TQAAAABJRU5ErkJggg==')
                    }
                    .asc {
                        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQEAQAAADlauupAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQfmCg4BOAJe9YpNAAAAhUlEQVQ4y92SOwqAMBAFk1uIuYZeTHKIkEZS6NEEY5OTZCwENYifWImvXWbYt6wQ/w1ISdQamuYdjHOsaVuQ8iWcITnC4wje7yUZsPegFBQFDMPlJmfwNr+Q3MG3EqK1aeeyPK+pVHoTYwSE8AROJdO0MCEIYlVB1z2BU0nfE+s6+8m+lxkYNhIchRBHjwAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMi0xMC0xNFQwMTo1NjowMiswMDowMCJ8B1kAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjItMTAtMTRUMDE6NTY6MDIrMDA6MDBTIb/lAAAAKHRFWHRkYXRlOnRpbWVzdGFtcAAyMDIyLTEwLTE0VDAxOjU2OjAyKzAwOjAwBDSeOgAAAABJRU5ErkJggg==')
                    }
                    .arrow {
                        // width: 10px;
                        // height: 15px;
                        padding-left: 10px;
                        margin-left: 5px;
                        background-color: transparent;
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position-y: center;
                    }
                }
            }
            tbody {
                width: 100%;
                tr {
                    cursor: pointer;
                    width: 100%;
                    &:not(:last-of-type) {
                        border-bottom: 1px solid #9c9c9c;
                    }
                    td {
                        color: rgb(108, 108, 108);
                        font: 16px / 26px NanumSquareOTF;
                        border-top:1px solid #9c9c9c;
                        text-align: center;
                        padding: 6px 16px;
                    }
                    
                }
            }
        }
    }
    .tableFooter {
        margin-top: 20px;
        display: flex;
        align-self: center;
    }
}
</style>