import { defineStore } from "pinia";
import { ApiError } from "@/services/api.v2.service";
import AdminService from "@/services/admin.service";
import util from '@/data/util'
import storeData from "./storeData";

export const useAdminStore = defineStore('admin', {
    state: () => ({
        adminUsers: null,
        adminUserType: 9,
        adminUserCountry: "",
        adminUserName: "",
        adminUserField: '',
        userTotalCount : 0,
        userpage: 1,
        userperPage: storeData.default_per_page,

        adminPosts: null,
        postTotalCount : 0,
        postpage: 1,
        postperPage: storeData.default_per_page,

        adminEvents: null,
        eventTotalCount : 0,
        eventpage: 1,
        evemtperPage: storeData.default_per_page,
        
        adminSchedules: null,
        evnt: '',
        scheduleTotalCount : 0,
        schedulepage: 1,
        scheduleperPage: storeData.default_per_page,

        order: null,
        error: { code: 0, message: "" },
    }),

    actions: {
        async getUsers(params) {
            try {
                this.adminUserType = params?.type ?? 0
                this.adminUserCountry = params?.ctr
                this.adminUserName = params?.name
                this.adminUserField = params?.field
                this.order = params?.order !== undefined ? params.order : this.order
                this.userpage = params?.page ?? 1
                this.userperPage = storeData.default_per_page
                
                const response = await AdminService.getUsers(this.adminUserType, this.adminUserCountry, this.adminUserName, this.adminUserField, this.order, this.userpage, this.userperPage, params?.xlsx)
                if(response) {
                    if(params?.xlsx) {
                        return response.data
                    }
                    this.adminUsers = response.data
                    this.userTotalCount = Number(response.count)
                    return this.adminUsers
                }
                
                return false
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async getPosts(params) {
            try {
                this.order = params?.order !== undefined ? params.order : this.order
                this.postpage = params?.page ?? 1
                this.postperPage = storeData.default_per_page
                
                const response = await AdminService.getPosts(this.order, this.postpage, this.postperPage, params?.xlsx)
                if(response) {
                    if(params?.xlsx) {
                        return response.data
                    }
                    this.adminPosts = response.data
                    this.postTotalCount = Number(response.count)
                    return this.adminPosts
                }

                return false
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async getEvents(params) {
            try {
                this.order = params?.order !== undefined ? params.order : this.order
                this.eventpage = params?.page ?? 1
                this.evemtperPage = storeData.default_per_page

                const response = await AdminService.getEvents(this.order, this.eventpage, this.evemtperPage, params?.xlsx)
                if(response) {
                    if(params?.xlsx) {
                        return response.data
                    }
                    this.adminEvents = response.data
                    this.eventTotalCount = Number(response.count)
                    return this.adminEvents
                }
                return false
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async getSchedules(params) {
            try {
                this.order = params?.order !== undefined ? params.order : this.order
                this.schedulepage = params?.page ?? 1
                this.scheduleperPage = storeData.default_per_page

                const response = await AdminService.getSchedules(this.order, this.schedulepage, this.scheduleperPage, params?.xlsx, params?.evnt)
                if(response) {
                    if(params?.xlsx) {
                        return response.data
                    }
                    this.adminSchedules = response.data
                    this.scheduleTotalCount = Number(response.count)

                    return this.adminSchedules
                }

                return false
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        }
    }
})