<template>
    <div class="tableButtons">
        <div class="prev_button_div">
            <div class="clickable" @click="onMovePage(1)">
                <v-icon>
                    mdi-chevron-double-left
                </v-icon>
            </div>
            <div class="clickable" @click="onMovePage('prev')">
                <v-icon>
                    mdi-chevron-left
                </v-icon>
            </div>
        </div>
        <div class="page_button_div">
            <ul :class="{
                single_page : totalCount === 1,
                multi_page : totalCount > 1,
            }">
                <li class="clickable" v-for="idx in display_page_data" :key="idx" @click="onMovePage(idx)" 
                :class="{
                    now_page : currentPage === idx,
                }" >{{idx}}</li>
            </ul>
        </div>
        <div class="next_button_div">
            <div class="clickable" @click="onMovePage('next')">
                <v-icon>
                    mdi-chevron-right
                </v-icon>
            </div>
            <div class="clickable" @click="onMovePage(totalPage)">
                <v-icon>
                    mdi-chevron-double-right
                </v-icon>
            </div>
        </div>
    </div>
</template>
<script>
import { computed, getCurrentInstance, onMounted, ref, watch } from 'vue'
import EventManager from '@/services/eventmanager.service';
import util from '@/data/util';
import storeData from '@/stores/storeData';
export default {
    props : {
        totalCount : Number,
        init_page : Number,
        perPage : Number,
    },
    setup(props, ctx) {
        const defaultPerPage = ref(storeData.default_per_page);
        const defaultpageLength = ref(5);
        const totalPage = ref(1);
        const currentPage = ref(1);
        const display_page_data = computed(() => {
            const page_index = Math.floor( (currentPage.value - 1 )/ defaultpageLength.value) * defaultpageLength.value
            return util.range(
                page_index + 1,
                (page_index + defaultpageLength.value > totalPage.value ? totalPage.value : page_index + defaultpageLength.value) + 1)
        })
        const currentInstance = getCurrentInstance()

        const onMovePage = page => {
            if(page == "next") {
                if(currentPage.value != totalPage.value) {
                    currentPage.value +=1;
                }
            }
            else if (page == "prev") {
                if(currentPage.value != 1) {
                    currentPage.value -= 1
                }
            }
            else{
                currentPage.value = page
            }
            EventManager.EmitToParent(currentInstance, "onPageUpdate", currentPage.value)
        }
        onMounted(() => {
            currentPage.value = props.init_page ?? 1
            totalPage.value = Math.ceil((props.totalCount ?? 1) / (props.perPage ?? defaultPerPage.value))
        })

        watch(() => props.init_page, () => {
            currentPage.value = props.init_page ?? 1
        })

        watch(() => props.totalCount, () => {
            totalPage.value = Math.ceil((props.totalCount ?? 1) / (props.perPage ?? defaultPerPage.value))
        })

        return {
            display_page_data,
            currentPage,
            totalPage,

            onMovePage
        }
    }
}
</script>

<style scoped lang="scss">
.tableButtons {
    display: flex;
    background : #fff;
    border-radius: 12px;
    padding : 5px 10px;
    align-items: center;
    width: fit-content;
    .prev_button_div,
    .next_button_div {
        display: flex;
    }
    .page_button_div {
        ul {
            display: flex;
            margin: 10px 20px;
        }
        li {
            padding : 0 10px;
            margin : 0 10px;
            list-style-type: none;
        }
        
    }
    .now_page {
        color: #61c1be;
    }
    .clickable {
        font-size: 22px;
        border-radius: 5px;
        &:hover  {
            background: #CCCCCC;
        }
    }
}
</style>