<template style="z-index:1000;">
    <div>
        <DatePicker 
            is24hr 
            v-model="r_date.date" 
            :is-required="is_required" 
            :is-expanded="isExpanded"
            :model-config="modelConfig" 
            :locale="date_locale" mode="date" 
            :available-dates="availableDate" 
            :from-date="(useFromDate && date?.date) ? util.getDate(date?.date) : null" 
            :disabled-dates="disabledDates"
            @update:from-page="data => $emit('update:from-page',data)"
            @dayclick="handleDateTime"
            :min-date="min_date"
            />
    </div>
</template>
<script>
import {DatePicker} from "v-calendar"
import util from "@/data/util"
export default {
    components : {
        DatePicker,
    },
    props : {
        // date, dateTime
        date : Object,
        is_required : {
            type : Boolean,
            default : true,
        },
        availableDate : {
            type : Object,
            default : null,
        },
        disabledDates : {
            type : Array,
            default : null,
        },
        isExpanded : {
            type : Boolean,
            default : false,
        },
        useFromDate : {
            type : Boolean,
            default : true,
        },
        min_date : {
            type : [String, Number, Date],
            default : null,
        }
    },
    methods : {
        handleDateTime(){
            this.r_date.start = this.r_date.date
            this.r_date.end = this.r_date.date
        }
    },

    data() {
        return {
            r_date : this.date,
            modelConfig: {
                type: 'number',
                // masks: {
                //     title : "YYYY MMMM",
                // }
            },
            date_locale : {
                id : 'en', 
                firstDayOfWeek : 1, 
                masks : {
                    L : "WWW DD-MM-YYYY",
                }
            },
            time_locale : {
                id : 'en', 
                masks : {
                    L : "hh:mm",
                }
            },
            util : util,
            timezoneIndex: 0,
            timezones: [
                'Pacific/Niue', // -11
                'US/Hawaii', // -10
                'America/Anchorage', // -9
                'America/Los_Angeles', // -8
                'America/Boise', // -7
                'America/Chicago', // -6
                'America/New_York', // -5
                'America/Aruba', // -4
                'America/Buenos_Aires', // -3
                'Brazil/DeNoronha', // -2,
                'Atlantic/Azores', // -1
                'UTC', // 0
                'Europe/Amsterdam', // +1
                'Europe/Athens', // +2
                'Europe/Moscow', // +3
                'Indian/Mahe', // +4
                'Asia/Ashgabat', // +5
                'Asia/Dhaka', // +6
                'Asia/Bangkok', // +7
                'Asia/Hong_Kong', // +8
                'Asia/Pyongyang', // +9
                'Australia/Sydney', // +10
                'Asia/Magadan', // +11
            ],
        }
    },
    watch : {
        date : {
            handler() {
                this.r_date = this.date
            }
        },
    }
}
</script>

<style scoped lang="scss">

.calendar_popup {
    position: absolute;
    z-index: 1000;
    width : fit-content;
    height : fit-content;
}
.date_content {
    width: 150px;
    height: 26px;
    border: 1px solid #9c9c9c;
    border-radius: 50px;
    padding-left: 10px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
}
.date_content input {
    position: relative;
    width: 100px;
    height: 19px;
    border: none;
    color: #6c6c6c;
    font-size: 16px;
    outline: none;
    background : rgba(0, 0, 0, 0);
    
}
.date_content img {
    width: 16px;
    height: 16px;
    right: 13px;
    position: absolute;
    user-select: none;
    cursor:pointer;
}

</style>