import SignUp from "@/page/signup/signUpPage.vue";

export default [
    {
        path : "/signup",
        component : SignUp,
        meta: { requiresEmailAuth: true },
        props : true,
    },
    {
        path : "/password/reset",
        component : SignUp,
        meta: { requiresEmailAuth: true },
        props : true,
    }
]