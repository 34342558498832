import FeedMain from "@/page/feed/feedMainPage.vue"
import FeedWrite from "@/page/feed/feedWrite.vue"
import FeedDetail from "@/page/feed/feedDetail.vue"
import FeedLayout from "@/page/feed/feedLayout.vue"
export default [
    {
        path : "/feed",
        component : FeedLayout,
        redirect : "/feed/main",
        children : [
            {
                path : "/feed/main",
                component : FeedMain,
            },
            {
                path : "/feed/write",
                component : FeedWrite,
            },
            {
                path : "/feed/edit",
                component : FeedWrite,
            },
            {
                path : "/feed/detail",
                component : FeedDetail,
                props : true,
            }
        ]
    }
]