<template>
    <div class="searchBoxStyle">
        <div>
            <nationSelector 
                @updateCountry="onUpdateCountry"  
                :selectedCountries="selectedCountries" 
                variant="outlined"/>
        </div>
        <div class="typeSelector">
            <v-select 
                variant="outlined" v-model="searchType" hide-details 
                label="Search Field" :items="typeList" item-value="value" item-title="title"
            />
        </div>
        <div>
            <searchBox  
                @onSearch="search => onSearch(search)" 
                :useSpecialChar="false" 
                :input="searchText"
            />
        </div>
    </div>
</template>

<script>
import searchBox from "@/component/searchBox.vue"
import nationSelector from "@/component/nationSelector.vue"
import { usePostStore } from "@/stores/post"
import { onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from "vue-router"

export default {
    props : {
        qCountry : [String, Object],
        // qSearch : [String, Object],
        qName : [String, Object],
        qContents : [String, Object],
    },
    components : {
        searchBox,
        nationSelector,
    },
    setup(props, ctx) {
        const postStore = usePostStore()
        const router = useRouter()
        const route = useRoute()

        const searchText = ref("")
        const selectedCountries = ref([])
        const searchType = ref('name')
        const typeList = ref([
            {title: 'Name', value: 'name'},
            {title: 'Contents', value: 'cont'}
        ])

        const updateUrl = () => {
            var query = {}
            if(selectedCountries.value.length > 0 ) {
                query.ctr = selectedCountries.value.join(",") ;
            }
            if(searchText.value && searchText.value.trim() && searchType.value) {
                query[searchType.value] = searchText.value
            }
            router.push({
                query: query,
            });
        }

        const onUpdateCountry = (p_selectedCountries) => {
            selectedCountries.value = p_selectedCountries;
            updateUrl()
        }

        const onSearch = (text) => {
            searchText.value = text
            updateUrl()
        }

        const init = async () => {
            if(props.qCountry) {
                selectedCountries.value = props.qCountry.split(",")
            }
            else {
                selectedCountries.value = []
            }
            if(props.qName) {
                searchText.value = props.qName
                searchType.value = 'name'
            } else if(props.qContents) {
                searchText.value = props.qContents
                searchType.value = 'cont'
            } else {
                searchText.value = ""
            }
        }
        watch(() => [props.qCountry, props.qName, props.qContents], () => {
            init()
        })

        onMounted(() => {
            init()
        })

        return {
            searchText,
            selectedCountries,
            typeList,
            searchType,
            
            onUpdateCountry,
            onSearch,
        }
    },
}
</script>

<style scoped lang="scss">
.searchBoxStyle {
    display: flex;
    max-width: 54.375rem;
    flex: 1;
    > div {
        width: 100%;
        margin: 0 5px;
        height: fit-content;
        &.typeSelector {
            display: flex;
            width: 270px;
        }
    }
}
</style>
