<template>
    <div>
        <div class="searchContainer">
            <div>
                <nationSelector
                    @updateCountry="onUpdateCountry" 
                    :selectedCountries="selectedCountries" 
                    variant="outlined"/>
            </div>
            <div class="typeSelector">
                <v-select 
                    variant="outlined" v-model="searchType" hide-details 
                    label="Search Field" :items="typeList" item-value="value" item-title="title"
                />
            </div>
            <div v-if="searchType == 'meetcnt'" class="meetingCntType">
                <v-select 
                    variant="outlined" v-model="meetingCntType" hide-details 
                    :items="meetingCntTypeList" item-value="value" item-title="title"
                />
            </div>
            <div>
                <searchBox  
                    @onSearch="onSearch" 
                    :useSpecialChar="false" 
                    :input="searchText"
                    :searchType="searchBoxType"
                />
            </div>
        </div>
        <div>
            <dataTable :tableData="tableData" 
                :init_page="current_page"
                :totalCount="totalCount" 
                @onPageUpdate="onPageUpdate"
                @sorting="sorting"
                v-show="tableData?.content?.length > 0 ? true : false"
            >
                <template v-slot:title="data"> 
                    <div class="titleContainer">
                        <span class="title" 
                            :style="{maxWidth : `calc(1220px / ${tableData.header?.length})`}">
                            {{data.title}} 
                        </span>
                        <span class="code">
                            {{`(${data?.country_iso2})`}}
                        </span>
                    </div>
                </template>
                <template v-slot:statusText="data">
                    <div class="statusContainer">
                        <div :class="[data?.statusText?.class]" class="status">
                            {{data?.statusText?.text}}
                        </div>
                    </div>
                </template>
                <template v-slot:owner="data">
                    <div class="owner">
                        <div v-if="data.isMine">
                            <div class="mineIcon">
                                ME 
                            </div>
                            <v-icon>mdi-arrow-right</v-icon>
                            <div class="other">
                                {{data.others[0].name ?? data.others[0].email ?? `OTHER`}} {{data.others[0].data?.country_iso2 ? `(${data.others[0].data?.country_iso2})` : ``}}
                            </div>
                        </div>
                        <div v-else>
                            <div>
                                ME 
                            </div>
                            <v-icon>mdi-arrow-left</v-icon>
                            <div class="mineIcon other">
                                {{data.others[0].name ?? data.others[0].email ?? `OTHER` }} {{data.others[0].data?.country_iso2 ? `(${data.others[0].data?.country_iso2})` : ``}}
                            </div>
                        </div>
                    </div>
                </template>
                <template v-slot:trContent="data" >
                    <Transition mode="in-out" name="slide">
                        <tr @click="onDetail(data)" v-if="selectedData.id == data.id" class="trContentStyle">
                            <td colspan="100%">
                                <div>
                                    <div>
                                        <h3>
                                            Participants
                                        </h3>
                                        <div v-for="recipient in data?.recipient" :key="recipient" class="recipientContentStyle" >
                                            <div>
                                                <div v-if="isOwner(data, recipient?.id)" class="owner">Invitor</div>
                                                <div v-if="isMe(recipient?.id)" class="me">Me</div>
                                                <profileImage :src="recipient?.data?.profile_image" :size="40"/>
                                                <span>{{recipient?.name ?? "Non-member"}}</span> | <span>{{recipient?.email}}</span> 
                                            </div>
                                            <div>
                                                <img :src="require('@/assets/company.png')" class="imageStyle">
                                                {{recipient?.company ?? "-"}}
                                                <img :src="require('@/assets/country.png')" class="imageStyle">
                                                {{recipient.data?.country_name ?? "-"}}
                                            </div>
                                        </div>
                                        <div v-if="data?.recipient.length > maxRecipientCount" class="recipientContentStyle more">
                                            More...
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </Transition>
                </template>
            </dataTable>
            <template v-if="tableData?.content?.length < 1">
                <div class="noScheduleStyle" :style="{ maxWidth: '54.375rem' }" >
                    <h1>There is no schedule.</h1>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import nationSelector from "@/component/nationSelector.vue"
import searchBox from "@/component/searchBox.vue"
import dataTable from "@/component/dataTable"
import { computed, inject, onMounted, onUnmounted, ref, watch } from 'vue'
import util from '@/data/util'
import { useRoute, useRouter } from 'vue-router'
import { useScheduleStore } from '@/stores/schedule'
import { storeToRefs } from 'pinia'
import scheduleData from '@/data/scheduleData'
import { useUserStore } from '@/stores/user'
import profileImage from '@/component/profileImage.vue'
export default {
    components : {
        dataTable,
        searchBox,
        nationSelector,
        profileImage,
    },
    setup(props, ctx) {
        
        const scheduleStore = useScheduleStore()
        const route = useRoute()
        const router = useRouter()
        const userStore = useUserStore()
        const {schedules, totalCount} = storeToRefs(scheduleStore)
        const {user} = storeToRefs(userStore)
        const selectedData = ref({})
        const current_page = ref(1)
        const maxRecipientCount = ref(5)
        const reverse = ref(false)
        const isInit = ref(false)
        const orders = ref([])
        const modalManager = inject("ModalManager")
        
        const searchBoxType = ref("text")
        const selectedCountries = ref([])
        const searchType = ref("title")
        const searchText = ref("")
        const meetingCntType = ref("more")
        const typeList = ref([
            {title: 'Title', value: 'title', type : 'text'},
            {title: 'Event Title', value: 'evntTitle', type : 'text'},
            {title: 'Name', value: 'name', type : 'text'},
            {title: 'Company', value: 'company', type : 'text'},
            {title: 'Meeting Times', value : 'meetcnt', type : 'number'}
        ])
        const meetingCntTypeList = ref([
            {title : "More" , value : "more"},
            {title : "Less", value : "less"},
            {title : "Equal", value : "equal"}
        ])

        const onDetail = (data) => {
            router.push({
                path : "/meeting/schedule/detail",
                query : {
                    scheduleno : data.id
                }
            })
        }
        const onShowRecipient = (data) => {
            if(selectedData.value?.id != data?.id) {
                selectedData.value = data
            }
            else {
                onDetail(data)
            }
        }
        const tableData = computed(() => {
            return {
                header : [
                    {name : 'Title', value : 'title', order: ['title'], fixed : true, slot: true},
                    {name : 'Date', value : 'date', order: ['start_time', 'end_time'], depdc: 'time'},
                    {name : 'Time', value : 'time', order: ['start_time', 'end_time']},
                    {name : 'Meeting Place', value : 'location', order: ['location']},
                    // {name : 'Coudhksntry', value : 'country', order: ['country_name'], slot : true},
                    {name : 'Participants', value : 'owner', slot : true},
                    {name : 'Status', value : 'statusText', slot : true},
                ],
                content : schedules.value?.map(item => {
                    const recipient = JSON.parse(item.participants)
                    const date = item.date ? toFormatString(item.date, 'YYYY-MM-DD') : ""
                    const start_time = item.start_time ? toFormatString(item.start_time, 'hh:mm') : ""
                    const end_time = item.end_time ? toFormatString(item.end_time, 'hh:mm') : ""
                    const isMine = item.owner_id == user.value.id
                    // let timezone ={}
                    // try {
                    //     if(item.country_timezones) {
                    //         timezone = util.convertTimezoneJsonToData(item.country_timezones)
                    //     }
                    // }
                    // catch(error) {
                    //     console.log(error)
                    // }
                    return {
                        ...item,
                        isMine : isMine,
                        recipient : util.sortObj(recipient, false, reci => reci.id == item.owner_id ? 1 : -1, reci => reci.name),
                        others : recipient.filter(reci => reci.id != user.value.id),
                        location : item.location ?? scheduleData.noSpecified,
                        date: date ? date : scheduleData.noSpecified,
                        time : start_time && end_time ? `${start_time} ~ ${end_time}` : scheduleData.noSpecified,
                        country : item.country_name,
                        statusText : scheduleData.getMStatusText(item.status),
                        callback : onShowRecipient
                    }
                }),
                column : orders.value,
                reverse: reverse.value
            }
        })

        const sorting = (order) => {
            orders.value = order
            current_page.value = 1
            updateQuery()
        }

        const toFormatString = (timestamp, format) => {
            return util.date_format(util.convertTimeStmapToDateTime(timestamp), format)
        }

        const isOwner = (schedule, id) => {
            if(id && schedule?.owner_id && id == schedule?.owner_id) {
                return true
            }
            return false
        }
        const isMe = id => {
            if(id && user.value && user.value?.id == id) {
                return true;
            }
            return false
        }
        const onUpdateCountry = country => {
            selectedCountries.value = country
            current_page.value = 1
            updateQuery()
        }
        const onSearch = search => {
            searchText.value = search
            current_page.value = 1
            updateQuery()
        }
        const onPageUpdate = page => {
            current_page.value = page
            updateQuery()
        }
        const updateQuery = () => {
            selectedData.value = {}
            let query = {}
            if(orders.value && orders.value.length > 0) {
                query.order = orders.value
            }
            if(current_page.value) {
                query.page = current_page.value
            }
            if(selectedCountries.value && selectedCountries.value.length > 0) {
                query.ctr = selectedCountries.value.join(",")
            }
            if(searchText.value && ((typeof searchText.value == 'string' && searchText.value.trim()) || (typeof searchText.value == 'number'))&& searchType.value) {
                let text = searchText.value
                if(searchType.value == "meetcnt") {
                    if(meetingCntType.value == "more") {
                        text = `+:${searchText.value}`
                    }
                    else if(meetingCntType.value == "less") {
                        text = `-:${searchText.value}`
                    }
                    else {
                        text = `${searchText.value}`
                    }
                }
                query[searchType.value] = text
            }
            router.push({
                query : query
            })
        }

        const init = async() => {
            isInit.value = true
            selectedData.value = {}
            current_page.value = 1
            orders.value = []
            searchType.value = "title"
            searchText.value = ""
            selectedCountries.value = []
            if(route.query.page && !isNaN(parseInt(route.query.page)) ) {
                if(route.query.page < 1) {
                    modalManager.showdialog(`The page starts with 1. Not ${route.query.page}`).then(res => {
                        router.back()
                    })
                }
                current_page.value = Number(route.query.page)
            }
            if(route.query.order) {
                if(typeof route.query.order == 'string') {
                    orders.value = [route.query.order]
                } else {
                    orders.value = route.query.order
                }
            }
            typeList.value.forEach(type => {
                if(route.query[type.value]) {
                    searchType.value = type.value
                    let searchTextValue = route.query[type.value]
                    if(searchType.value == "meetcnt") {
                        if(searchTextValue.includes("+")) {
                            meetingCntType.value = "more"
                        }
                        else if(searchTextValue.includes("-")) {
                            meetingCntType.value = "less"
                        }
                        else {
                            meetingCntType.value = "equal"
                        }
                        searchTextValue = searchTextValue.replace(/[^0-9]/g, '')
                        searchTextValue = parseInt(searchTextValue)
                    }
                    searchText.value = searchTextValue
                }
            })
            if(route.query.ctr) {
                selectedCountries.value = route.query.ctr.split(',')
            }
            await scheduleStore.fetchSchedules(
                route.query
            )
            
            isInit.value = false
        }

        onMounted(async () => {
            await init()
        })

        onUnmounted(() => {
            scheduleStore.clearSchedules()
        })

        watch(() => route.query, async() => {
            if(route.path.includes("history")) {
                await init()
            }
        })
        watch(() => searchType.value, (newV, oldV) => {
            if(isInit.value) {
                return;
            }
            let res = false
            typeList.value.forEach(typeItem => {
                if(res) {
                    return;
                }
                if(typeItem.value == searchType.value) {
                    const type = typeItem.type
                    res = true;
                    if(type != searchBoxType.value) {
                        searchText.value = ""
                        searchBoxType.value = type;
                    }
                }
            })
        })

        return {
            tableData,
            totalCount,
            current_page,
            selectedData,
            maxRecipientCount,
            util,

            selectedCountries,
            typeList,
            searchType,
            searchText,
            searchBoxType,
            meetingCntTypeList,
            meetingCntType,

            isOwner,
            isMe,
            sorting,
            onPageUpdate,
            onDetail,
            onUpdateCountry,
            onSearch,
        }
    }
}
</script>

<style scoped lang="scss">
.searchContainer {
    margin-bottom: 30px;
    display: flex;
    max-width: 54.375rem;
    flex: 1;
    > div {
        width: 100%;
        margin: 0 5px;
        height: fit-content;
        &.typeSelector {
            display: flex;
            width: 530px;
        }
        &.meetingCntType {
            width: 270px;
        }
    }
}
.statusContainer {
    display: flex;
    justify-content: center;
    width : 100%;
    .status {
        border-radius: 15px;
        font-size: 14px;
        padding : 0 10px;
        width: fit-content;
    }
}
.trContentStyle {
    background: #EEEEEE;
    width: 100%;
    cursor: pointer;
    transform-origin: top;
    transition: transform .4s ease-in-out;
    td {
        h3 { 
            padding: 3px 20px;
        }
        width: 100%;
        >div {
            width: 100%;
            display: flex;
            flex-direction: column;
        }
        .recipientContentStyle {
            border-top: 1px solid #9c9c9c;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            color: #111;
            &.more {
                color: #910428EE;
            }
            span {
                margin: 0 10px;
            }
            > div {
                display: flex;
                align-items : center;
                .imageStyle {
                    margin: 0 5px;
                    width: 30px;
                }
            }
        }
        .owner {
            margin-right: 5px;
            border-radius: 10px;
            padding: 0 5px;
            background: #910457;
            color: #fff;
        }
        .me {
            margin-right: 5px;
            border-radius: 10px;
            padding: 0 5px;
            background: #484848;
            color: #fff;
        }
    }
}

.titleContainer {
    display: flex;
    justify-content: center;
    .title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .code {
        margin-left: 5px;
    }
}
.owner {
    display: flex;
    justify-content: center;
    div {
        display: flex;
        height: fit-content;
        
        .mineIcon {
            height: fit-content;
            padding : 0 5px;
            border-radius: 10px;
            background: #910457;
            color : #fff;
        }
        .other {
            display: block;
            max-width: 60px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
</style>
