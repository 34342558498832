<template>
    <div class="mainStyle">
        <invitationMediateContent :schedule="invitation" @onApply="onApply" :tokenData="tokenDataDat"
            @onBack="onBack" :event="event"/>
    </div>    
</template>
<script>
import invitationMediateContent from "@/page/invitation/invitationMediateContent.vue"
import { useInvitationStore } from '@/stores/invitation'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { computed, inject, onMounted, ref } from 'vue'
import util from '@/data/util'
export default {
    components : {
        invitationMediateContent,
    },
    setup(props, ctx) {
        const router = useRouter()
        const route = useRoute()
        const invitationStore = useInvitationStore()
        const modalManager = inject("ModalManager")
        const {invitation} = storeToRefs(invitationStore)
        const tokenData = ref([])

        const tokenDataDat = computed(() => {
            if(tokenData.value.length > 1 && tokenData.value[1]?.dat) {
                return tokenData.value[1].dat
            }
            return {}
        })

        const onApply = async (form) => {
            
            const func = async () => {
                const params = {
                    date : util.convertJTSToUTS(form.date.date),
                    start_time : util.convertJTSToUTS(form.date.start),
                    end_time : util.convertJTSToUTS(form.date.end),
                    location : form.location,
                    comment : form.comment,
                }
                const res = await invitationStore.mediateInvitation(params, route.query.token)
                if(res) {
                    modalManager.showdialog("Adjusted").then(res => {
                        onBack()
                    })
                }
                else {
                    modalManager.showdialog("Failed")
                }
            }
            modalManager.loading(func);
        }

        const event = computed(() => {
            let event = {}
            if(invitation.value) {
                Object.entries(invitation.value).forEach(data => {
                    const k = data[0]
                    const v = data[1]
                    let index = k.indexOf("event_")
                    if(index > -1 ) {
                        index += "event_".length
                        event[k.slice(index)] = v
                    }
                })
            }
            return event;
        })

        const onBack = () => {
            router.back({
                path : "/invitation",
                query : {
                    token : route.query.token,
                }
            })
        }

        onMounted(async () => {
            if(route.query.token) {
                tokenData.value = [];
                const tokenDataList = route.query.token.split(".")
                tokenDataList.forEach((tokenItem, index) => {
                    if(index > 1) {
                        return;
                    }
                    const tokenItemJSON = util.base64TokenParse(tokenItem)

                    const tokenItemData = JSON.parse(tokenItemJSON)
                    tokenData.value.push(tokenItemData) 
                })
            }
            const res = await invitationStore.fetchInvitation(route.query.token)
            if(!res) {
                router.push({
                    path : "/invitation/error"
                })
            }
        })
        
        return {
            invitation,
            event,
            tokenDataDat,
            
            onApply,
            onBack,
        }
    }
}
</script>

<style scoped lang="scss">

.mainStyle {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>