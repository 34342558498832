<template>
    <div id="modalContainer">
        <div class="header">
            <h2>Select Schedule</h2>
            <div class="buttons">
                <v-btn @click="onReset" class="reset" rounded>
                    RESET
                    <v-icon >
                        mdi-restart
                    </v-icon>
                </v-btn>
            </div>
        </div>
        <div class="calendar">
            <!-- <dateSelector v-model:date="p_form" :disabledDates="disabledDates" :isExpanded="true" :min_date="minDate"/> -->
        </div>
        <div v-if="(form.date.date || event)" class="timeSeletor">
            <timePeriodSelectorTable :dateData="selectedDate_dateData" :oneSchedule="true" :minStamp="minStamp" :clearCallback="resetSelectedTime" 
                @onMouseUp="eventSelect" :isVertical="true" :oneDate="true"/>
        </div>
        <v-btn @click="onClose" class="close" block>
            SELECT
        </v-btn>
    </div>
</template>
<script>
import dateSelector from "@/component/dateSelector"
import timePeriodSelectorTable, { dateDataClass } from "@/component/timePeriodSelectorTable.vue"
import { computed, getCurrentInstance, inject, onMounted, ref, watch } from 'vue'
import util from '@/data/util'
import scheduleData from '@/data/scheduleData'
export default {
    components : {
        timePeriodSelectorTable,
        // dateSelector,
    },
    props : {
        timezone : Object,
        selectedDateData : Object,
        event : Object,
        form : Object,
        disabledDates : Object,
        disabledMeetings : Array,
    },
    setup(props, ctx) {
        const minStamp = ref(scheduleData.minStamp)
        const currentInstance = getCurrentInstance()
        const modalManager = inject("ModalManager")
        const p_form = ref({})
        const dateClass = ref(null)
        const initDate = ref(null)
        const minDate = ref(null)
        const current_selected_data = ref({})

        const selectedDate_dateData = computed(() => {
            if(dateClass.value?.dateData && (p_form.value?.date || props.event)) {
                return dateClass.value.dateData
                // const selectedDate = util.date_format(p_form.value.date, "YYYY-MM-DD")
                // const data = dateClass.value.dateData.filter(date => date.date == selectedDate)
                // if(data.length > 0) {
                //     return data
                // }
            }
            return []
        })
        
        const resetSelectedTime = () => {
            dateClass.value.setSelectedDateData()
        }

        const onReset = () => {
            if(p_form.value?.date) {
                p_form.value.date = initDate.value
            }
            current_selected_data.value = props.selectedDateData
            dateClass.value.setSelectedDateData(current_selected_data.value)

        }

        const onClose = () => {
            modalManager.close(currentInstance, current_selected_data.value)
        }

        const setMinDate = () => {
            if(props.timezone) {
                const timezoneOffset = props.timezone.gmtOffset
                const currentDate = util.getCurrentDate(timezoneOffset)
                minDate.value = currentDate
            }
        }
        const eventSelect = () => {
            current_selected_data.value = dateClass.value.getDateDataText()
        }

        onMounted(() => {
            p_form.value = props.form.date
            if(props.form.date?.date) {
                initDate.value = props.form.date.date
            }
            current_selected_data.value = props.selectedDateData
            dateClass.value = new dateDataClass(props.event ?? initDate.value, current_selected_data.value, props.timezone, props.disabledMeetings)
            setMinDate()
        })

        watch(() => props.event, () => {
            current_selected_data.value = props.selectedDateData
            dateClass.value = new dateDataClass(props.event ?? initDate.value, current_selected_data.value, props.timezone, props.disabledMeetings)
        })

        watch(() => props.form, () => {
            if(props.form.date?.date) {
                initDate.value = props.form.date.date
                if(!props.event) {
                    dateClass.value.setDate(util.date_format(util.getDate(p_form.value.date), "YYYY-MM-DD"))
                    dateClass.value.setSelectedDateData(current_selected_data.value)
                    dateClass.value.setDisableMeeting(props.disabledMeetings, true)
                    dateClass.value.setDisableCurrentTimeData()
                }
            }
        }, {deep : true})
        return {
            p_form,
            selectedDate_dateData,
            minStamp,
            initDate,
            minDate,

            resetSelectedTime,
            eventSelect,
            onReset,
            onClose,
        }
    }
}
</script>

<style scoped lang="scss">
#modalContainer {       
    display: flex;
    flex-direction: column;
    background: #fff;
    align-items: center;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    max-width: 1200px;
    .header {
        display: flex;
        width: 100%;
        align-items: center;
        h2 {
            font: normal normal 800 20px/23px NanumSquareOTF;
            margin-right: 10px;
        }
    }
    .buttons { 
        margin-right: 0;
        margin-left: auto;
        display: flex;
        justify-content: flex-end;
        .reset {
            color : #fff;
            background: rgba(238, 92, 92); 
        }
    }
    .close {
        background: #910457;
        color : #fff;
    }
    .calendar {
        width: 80%;
    }
    .timeSeletor {
        max-width: 1000px;
        width: fit-content;
        margin: 10px 0;
    }
}
</style>