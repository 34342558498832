<template>
    <BaseModal/>
    <router-view/>
</template>

<script>
import { inject, watch } from "vue"
import { useRoute } from 'vue-router'
export default {
    name: 'App',
    setup(props, ctx) {
        const modalManager = inject("ModalManager")
        const route = useRoute()
        watch(() => route.path, () => {
            modalManager.all_close()
        })
    }
}
</script>
<style lang="scss">
@import "@/css/index.scss";
</style>
