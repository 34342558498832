<template>
    <div class="calendarContainer">
        <Calendar
        class="custom-calendar max-w-full"
        :masks="masks" :locale="locale" :model-config="modelConfig"
        :attributes="attributes"
        disable-page-swipe
        is-expanded ref="vCalendar" @update:from-page="onUpdateMonth"
        >
            <template v-slot:day-content="{ day, attributes }" >
                <div class="dayItem vc-day" v-show="isNoneDisplay(day)" 
                :class="{clickable : isClickDate || isCreateEventDrag, 
                    selected : isSelected(day),
                    today : isToday(day)
                }"
                :style="{height : `${31 * maxEventCount + 50}px`}"
                @mousedown="onMouseDownDate(day)"
                @mousemove="onMouseMoveDate(day)"
                @mouseup="onMouseUpDate(day, attributes)">
                    <div v-if="isToday(day) && showToday" class="todayContent">
                        TODAY
                    </div>
                    <v-tooltip location="top">
                        <template v-slot:activator="{ props }">
                            <span class="vc-day-content vc-focusable" v-bind="props">
                                {{ day.day }}
                            </span>
                        </template>
                        <span>{{day.id}}</span>
                    </v-tooltip>
                    <div v-if="Array.isArray(attributes) && attributes.length > 0" class="eventContainer">
                        <template v-for="(attr, index) in attributes" :key="attr.key">
                            <div
                                v-if="attr.order < maxEventCount"
                                class="eventItem"
                                :class="{startEvent : checkEqualDate(day, attr.customData.startDate), endEvent : checkEqualDate(day, attr.customData.endDate)}"
                                :style="{
                                    ...attr.customData.baseStyle, 
                                    width : (100 - (checkEqualDate(day, attr.customData.startDate) + checkEqualDate(day, attr.customData.endDate)) * 50 ) + '%',
                                    marginTop : index == 0 ?
                                        (attr.order * eventHeight + (attr.order + 1) * eventMargin) + (isToday(day) ? -1 : 0) + 'px' :
                                        ((attr.order - attributes[index - 1].order - 1) * eventHeight + (attr.order - attributes[index - 1].order ) * eventMargin) + 'px'
                                }"
                                @mouseup="onEventClick(attr)" 
                                :title="attr.customData.title"
                                :startDate="attr.customData.startDate"
                                :endDate="attr.customData.endDate"
                                :color="attr.customData.baseStyle.backgroundColor"
                                :no="attr.customData.eventId"
                            >
                            </div>
                            <div class="eventStartEndItemContainer"
                                @mouseup="onEventClick(attr)"
                                :title="attr.customData.title"
                                :startDate="attr.customData.startDate"
                                :endDate="attr.customData.endDate"
                                :color="attr.customData.baseStyle.backgroundColor"
                                :no="attr.customData.eventId"
                                v-if="attr.order < maxEventCount">
                                <template v-if="(checkEqualDate(day, attr.customData.startDate) || checkEqualDate(day, attr.customData.endDate))">
                                    <div class="eventStartEndItem" :style="{...attr.customData.startEndStyle}" />
                                    <template  v-if="checkEqualDate(day, attr.customData.startDate)">
                                        <div class="eventTitleItem">
                                            {{attr.customData.title}}
                                        </div>
                                        <div class="arrow"/> 
                                    </template>
                                </template>
                                <div class="eventInnerTitle"
                                v-else-if="isFirstDay(day) && !isOneDate(attr)"
                                    :style="{width : `${100 * getTitleWidthCount(day, attr)}%`}"
                                    :class="{startMiddle : checkEqualDate(day, attr.customData.startDate)}">
                                    {{attr.customData.title}}
                                </div>
                                <!-- :style="{width : getTitleWidth(day, attr)}" -->
                            </div>
                        </template>
                        <div v-if="Math.max(...attributes.map(attr => attr.order)) >= maxEventCount" class="more">
                            More...
                        </div>
                    </div>
                </div>
            </template>
        </Calendar>
        <mouseHoverItem :hoverRefs="hoverRefs" :overCallback="overCallback" :outCallback="outCallback">
            <template v-slot:content> 
                <div class="hoverItemContent" v-if="hoverItemData.title">
                    <div :style="{backgroundColor : hoverItemData?.color}" class="colorDiv"/>
                    <div class="content">
                        <div>
                            {{hoverItemData?.title}}
                        </div>
                        <div>
                            {{hoverItemData.startDate}} ~ {{hoverItemData.endDate}}
                        </div>
                        <div v-if="hoverItemData.country_name">
                            Coutry : 
                            <img :src="util.getCountryFlag(hoverItemData.country_iso2)">
                            {{hoverItemData.country_name}}
                        </div>
                        <div v-if="hoverItemData.participants" class="partiStyle">
                            Participants : 
                            <template v-for="parti in hoverItemData.participants" :key="parti">
                                <span class="parti" :class="{'parti_owner' : myData.id == parti.id}">
                                    {{parti.name ?? "someone"}}
                                </span>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
        </mouseHoverItem>
    </div>
</template>

<script>
import mouseHoverItem from "@/component/mouseHoverItem.vue"
import {Calendar} from "v-calendar"
import util from '@/data/util';
import EventManager from '@/services/eventmanager.service';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
export default {
    props : {
        eventList : Object,
        maxEventCount : {
            type : Number,
            default : 999,
        },
        isClickDate : {
            type : Boolean,
            default : true,
        },
        isCreateEventDrag : {
            type : Boolean,
            default : false,
        },
        showToday : {
            type : Boolean,
            default : true,
        }
    },
    components : {
        Calendar,
        mouseHoverItem,
    },
    computed : {
        myData() {
            const userStore = useUserStore()
            const {user} = storeToRefs(userStore)
            if(!user.value) {
                userStore.fetchMe()
            }
            return user.value;
        }
    },
    data() {
        return {
            masks: {
                weekdays: 'WWW',
            },
            modelConfig: {
                type: 'number',
                masks: {
                    title : "YYYY MMMM",
                }
            },
            locale : {
                id : 'en', 
                firstDayOfWeek : 1, 
                masks : {
                    L : "WWW DD-MM-YYYY",
                }
            },
            attributes: [],
            popover : true,
            eventHeight : 20,
            eventMargin : 10,
            hoverRefs : [],
            hoverItemData : {},
            isDrag : false,
            placeHoldDay : null,
            startDay : null,
            endDay : null,
            mouseDownTimeoutEvent : null,
            util : util,
        };
    },
    methods : {
        isNoneDisplay(day) {
            let res = true
            day.classes.forEach(c_item => {
                if(Object.keys(c_item).length > 0) {
                    Object.entries(c_item).forEach(c_o_item => {
                        if(c_o_item[0] == "in-month" && c_o_item[1] == false) {
                            res = false
                        }
                    })
                }
            })
            return res;
        },
        isSelected(day) {
            if(this.startDay && this.endDay) {
                if(util.getDate(this.startDay.id) <= util.getDate(day.id) &&
                (util.getDate(day.id) <= util.getDate(this.endDay.id))) {
                    return true;
                }
            }
            return false
        },
        isToday(day) {
            if(this.showToday) {
                if(util.date_format(util.getCurrentDate(), "YYYY-MM-DD") == day.id) {
                    return true
                }
            }
            return false
        },
        isFirstDay(day) {
            return this.locale.firstDayOfWeek == day.weekday || day.day == 1
        },
        isOneDate(attr) {
            return attr.targetDate.endTime - attr.targetDate.startTime < (3600 * 1000 * 24)
            // const startDate =  util.getDate(util.date_format(attr.targetDate.start, "YYYY-MM-DD"))
            // const endDate = util.getDate(util.date_format(attr.targetDate.end, "YYYY-MM-DD"))
            // return (endDate.getTime() - startDate.getTime()) == 0 ? true : false 
        },
        getTitleWidthCount(day, attr) {
            // 스케쥴 하나에 표출되는 제목의 길이
            // 해당 Tag가 날짜에 종속되어 길이가 길어지면 제목Tag를 클릭하게 될때, 제목이 시작하는 날짜가 선택되어 1보다 낮은 값으로 설정.
            
            // let end_offset = false;
            // let start_offset = this.checkEqualDate(day, attr.customData.startDate);

            // const date_range = Math.floor((attr.targetDate.endTime - day.range.start.getTime()) / (3600 * 1000 * 24)) + 1
            // let count = day.weekday >= this.locale.firstDayOfWeek ? (7 + this.locale.firstDayOfWeek - day.weekday) : (this.locale.firstDayOfWeek - day.weekday)
            // count = Math.min(day.dayFromEnd, count)

            // if(date_range <= count) {
            //     end_offset = true;
            //     count = date_range;
            // }
            
            // count = count - (0.75 * (end_offset + start_offset));
            // return count;
            return 0.9;
        },
        overCallback(e) {
            const no = e.target.getAttribute("no")
            const color = e.target.getAttribute("color")
            const event = this.eventList.filter(event => event.id == no) 
            if(typeof event[0].participants == 'string') {
                event[0].participants = JSON.parse(event[0].participants)
            }
            if(event.length > 0) {
                this.hoverItemData = {
                    ...event[0],
                    color,
                }
            }
            else {
                const title = e.target.getAttribute("title")
                const startDate = e.target.getAttribute("startDate")
                const endDate = e.target.getAttribute("endDate")
                this.hoverItemData= {
                    no,
                    title,
                    startDate,
                    endDate,
                    color,
                }
            }
        },
        onUpdateMonth(e) {
            this.$nextTick(() => {
                this.registerHoverItem()
                this.$emit("updateMonth", e)
            })
        },
        outCallback(e) {
        },
        checkEqualDate(day, date) {
            return util.getDate(util.date_format(day.id,"YYYY-MM-DD")).getTime() == util.getDate(util.date_format(date,"YYYY-MM-DD")).getTime() 
        },
        onEventClick (attr) {
            if(!this.isDrag) {
                attr.customData.callback()
            }
        },

        onMouseDownDate(day) {
            // 왼쪽 클릭만 통과
            if(event.button != 0) {
                return;
            }
            if(!this.isCreateEventDrag) {
                return;
            }
            if(!this.mouseDownTimeoutEvent) {
                this.mouseDownTimeoutEvent = setTimeout(() => {
                    this.isDrag = true;
                    this.placeHoldDay = day;
                    this.startDay = day;
                    this.endDay = day;
                }, 500)
            }
        },
        
        onMouseMoveDate(day) {
            if(this.isDrag) {
                if(util.getDate(day.id) < util.getDate(this.placeHoldDay.id)) {
                    this.startDay = day;
                    this.endDay = this.placeHoldDay;
                }
                else {
                    this.startDay = this.placeHoldDay;
                    this.endDay = day;
                }
            }
        },
        onMouseUpDate(day, attr) {
            if(event.button != 0) {
                return;
            }
            if(this.mouseDownTimeoutEvent) {
                clearTimeout(this.mouseDownTimeoutEvent)
                this.mouseDownTimeoutEvent = null;
            }
            if(this.isDrag) {
                const endEvent = () => {
                    this.placeHoldDay = null;
                    this.startDay = null;
                    this.endDay =null;
                }
                EventManager.EmitToParent(this, "onDrag", [this.startDay.id, this.endDay.id, endEvent])
            }
            else if(this.isClickDate && day && attr) {
                this.$emit("clickDate", day, attr)
            }
            this.isDrag = false;
        },
        init() {
            if(this.eventList && this.eventList.length > 0) {
                let colorArray = util.getRandomColor(this.eventList.length)
                this.attributes = this.eventList.map((item, index) => {
                    if(colorArray.length <= item.order) {
                        colorArray = util.getRandomColor(item.order + 1)
                    }
                    const color = item.color ?? colorArray[item.order].color;
                    return {
                        customData : {
                            title : item.title,
                            startDate : item.startDate,
                            endDate : item.endDate,
                            startEndStyle : {
                                backgroundColor : color ,
                            },
                            baseStyle : {
                                backgroundColor : color + "99",
                                position : "relative"
                            },
                            data : item,
                            eventId : item.id,
                            callback : item.callback,
                            timezone : item.timezone,
                        },
                        order : item.order,
                        key : index,
                        dates : [{
                            start : item.start_date_jts,
                            end : item.end_date_jts
                        }],
                        popover: true,
                    }
                })
                this.$nextTick(() => {
                    this.registerHoverItem()
                })
            }
            else {
                this.attributes = []
            }
        },
        registerHoverItem() {
            this.hoverRefs = [
                ...this.$refs.vCalendar.$el.querySelectorAll(".eventItem"),
                ...this.$refs.vCalendar.$el.querySelectorAll(".eventStartEndItemContainer")
            ]
        }
    },
    mounted() {
        this.init()
        window.addEventListener("mouseup", this.onMouseUpDate)
    },
    unmounted() {
        window.removeEventListener("mouseup", this.onMouseUpDate)
    },
    watch : {
        eventList : {
            handler() {
                this.init()
            },deep : true,
        },
    }
};
</script>

<style lang="scss" scoped>
.calendarContainer {
    height: 100%;
    width: 100%;
    
    // 타이틀 보기용 z-index 제거.
    // ::v-deep .vc-day {
    //     z-index: auto;
    // }
}
.dayItem {
    --height : 20px;
    border : 1px solid #ccc;
    user-select: none;
    position: relative;
    &.selected {
        background: #b0d7fcAA;
    }
    &.today {
        border : 2px solid #6c8501
    }
    .eventContainer {
        height: calc(100% - 28px);
        display: flex;
        flex-direction: column;
    }
    .todayContent {
        position: absolute;
        right: 0;
        background: #6c8501;
        padding: 0 5px;
        color: #fff;
    }
    .more {
        text-align: right;
        font : normal normal normal 13px/22px NanumSquareOTF;
        margin-right: 10px;
        margin-top : auto;
        margin-bottom: 0;
        color :#2700b3;
    }
    .eventItem {
        display: flex;
        cursor: pointer;
        height: var(--height);
    }
    .startEvent {
        left : 50%;
        right : 0;
    }
    .endEvent {
        right : 50%;
        left : 0;
    }
    .eventStartEndItemContainer {
        position: relative;
        user-select: none;
        cursor: pointer;
        z-index: 10;
        .eventStartEndItem {
            height: var(--height);
            width: var(--height);
            border-radius: 50%;
            position: absolute;
            left: 50%;
            margin-left: calc(var(--height) / -2);
            margin-top : calc(var(--height) * -1);
        }
        .eventTitleItem {
            position: absolute;
            font-size: 12px;
            justify-content: flex-end;
            background: #484848;
            color: white;
            border-radius : 5px;
            padding : 0 5px;
            max-width: max-content;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
            width :calc(100% - 25px);
            overflow: hidden;
            right : calc(50% + (var(--height) / 2) + 5px);
            top : calc(-1 * var(--height) + 1px);

        }
        .arrow {
            position: absolute;
            border-top: 5px solid transparent;
            border-left: 5px solid #484848;
            border-right: 0px solid transparent;
            border-bottom: 5px solid transparent;
            content: "";
            right : calc(50% + (var(--height) / 2));
            top : calc(-1 * var(--height) + 5px);
        }
        .eventInnerTitle {
            position: absolute;
            left : 5px; // offset
            top : calc(-1 * var(--height) + 2px);
            font: 12px NanumSquareOTF;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &.startMiddle {
                left : calc(50% + var(--height) / 2);
            }
        }
    }
}
.hoverItemContent {
    user-select: none;
    position: absolute;
    right: 1px;
    bottom: 1px;
    font-size: 14px;
    background: #484848;
    color: white;
    border-radius: 5px;
    padding : 5px;
    width:max-content;
    display: flex;
    align-items: center;
    max-width: 350px;
    z-index: 1;
    .colorDiv {
        min-width: 10px;
        min-height: 10px;
        border-radius: 2px;
        margin-right: 10px;
    }
    .content {
        word-break: break-all;
    }
    .partiStyle {
        display: flex;
        flex-flow: wrap;
        gap: 5px;
        .parti {
            border: 1px solid #fff;
            border-radius: 5px;
            padding: 0 5px;
        }
        .parti_owner {
            background: #a500006f;
        }
    }
}

</style>