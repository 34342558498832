<template>
    <div class="Auth">
        <div class='containerStyle'>
            <figure>
                <img :src='require("@/assets/login_logo.png")' alt='login-logo' />
            </figure>
            <div class='loginFormStyle'>
                <section>
                    <LoginForm />
                </section>
                <div class="divider_div">
                    <v-divider></v-divider>
                    <div>OR</div>
                    <v-divider></v-divider>
                </div>
                <section>
                    <div class='underBlockStyle'>
                        <h4 @click="this.$router.push('/email/check')">Sign Up</h4>
                        <h4 @click="this.$router.push('/email/forgot')">Forgot Password</h4>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import LoginForm from "@/page/login/loginForm.vue"
export default {
    components : {
        LoginForm,
    },
}
</script>

<style scoped lang="scss">
    
.Auth {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.containerStyle{
    
    @media all and (max-width : 768px) {
        width: 17.1875rem;
    }
    @media all and (min-width : 768px) and (max-width : 1023px) {
        width: 27.1875rem;
    }
    @media all and (min-width : 1023px) {
        width: 37.1875rem;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    padding :60px 30px;
    border-radius: 1rem;
    box-shadow: 0 3px 6px #00000029;
    background: rgba(255, 255, 255, 0.5);

    figure {
        margin: 3.375rem 0 2.125rem;
        width: 116px;
        height: 150px;

        img {
        max-width: 100%;
        max-height: 100%;
        height: 150px;
        }
    }

    .title {
        color: #333;
        margin: 0 0 1rem;
        font: normal normal 800 20px/23px NanumSquareOTF;
    }
}

.loginFormStyle {
    
  display: flex;
  flex-direction: column;
  max-width: 22.230625rem;

  section {
    flex: 1;
  }

  a {
    text-decoration: none;
  }

}
.underBlockStyle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;

  h4 {
    text-align: center;
    color: #6c6c6c;
    font: normal normal bold 15px/17px 'NanumSquareOTF';
    margin: 0;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
    &:first-of-type {
        @media all and (max-width : 768px) {
            margin-left : 0.5625rem;
        }
        @media all and (min-width : 768px) and (max-width : 1023px) {
            margin-left : 2.5625rem;
        }
        @media all and (min-width : 1023px) {
            margin-left : 3.0625rem;
        }
    }
    &:last-of-type {
        @media all and (max-width : 768px) {
            margin-right: 0rem;
        }
        @media all and (min-width : 768px) and (max-width : 1023px) {
            margin-right: 0.2rem;
        }
        @media all and (min-width : 1023px) {
            margin-right: 1rem;
        }
    }
  }
}
</style>