<template>
    <div id="containerStyle">
        <div class="contentStyle">
            <div class="titleStyle">
                Select Member
            </div>
            <div class="commentStyle">
                To schedule a meeting, please select a person from my network.
            </div>
            <div class="userContainerStyle">
                <template v-for="user in networks" :key="user">
                    <template v-if="user.user_id">
                        <div v-if="user.additional">
                            <v-icon>mdi-plus</v-icon>
                        </div>
                        <v-checkbox v-model="selectUserData" :value="user" hide-details class="checkboxStyle">
                            <template v-slot:label>
                                <div class="userDataStyle">
                                    <profile-image :src="user.profile_image" :size="40"/>
                                    <span>
                                        {{user.name}} {{user.email}} 
                                    </span>
                                </div>
                            </template>
                        </v-checkbox>
                        <v-divider/>
                    </template>
                </template>
            </div>
            <div class="invertUserContainerStyle">
                <div class="titleStyle">
                    Invite Non-member
                </div>
                <div class="commentStyle">
                    To schedule a meeting, please enter the e-mail address.
                </div>
                <div class="emailInputStyle">
                    Enter e-mail :
                    <input type="text" @keyup.enter="onAddEmail" v-model="addEmail">
                    <v-icon class="add-btn" @click="onAddEmail">mdi-check</v-icon>
                </div>
                <div>
                    <div>
                        Invited e-mail
                    </div>
                    <div class="invited">
                        <div>
                            <div v-for="emailUser in selectEmail" :key="emailUser"  @click="onEmailDel(emailUser.email)">
                                {{ emailUser.email }}
                                <v-icon>mdi-close</v-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="buttons-div">
                <v-btn class="v-button" @click="onSelect">SELECT</v-btn>
                <v-btn class="v-button close" @click="onClose">CLOSE</v-btn>
            </div>
        </div>
    </div>
</template>
<script>
import util from "@/data/util"
import { useNetworkStore } from '@/stores/network'
import { storeToRefs } from 'pinia'
import { getCurrentInstance, inject, onMounted, ref, watch } from 'vue'
import { useUserStore } from '@/stores/user'
export default {
    props : {
        additionalUserList : {
            type : Array,
            default : null,
        },
        network_query : {
            type : Object,
            default : null,
        },
        selectedUser : Object,
        isOnePerson : {
            type : Boolean,
            default : true,
        },
        isExistMine : {
            type : Boolean,
            default : false,
        }
    },
    setup(props, ctx) {
        const modalManager = inject("ModalManager")
        const networkStore = useNetworkStore()
        const userStore = useUserStore()
        const {networks} = storeToRefs(networkStore)
        const {user} = storeToRefs(userStore)
        const currentInstance = getCurrentInstance()

        const selectUserData = ref([])
        const selectEmail = ref([])
        const addEmail =  ref("")

        const getUsersInfo = async () => {
            if(!user.value) {
                await userStore.fetchMe()
            }
            await networkStore.fetchNetworks(props.network_query)
            if(!props.isExistMine) {
                networks.value.forEach((item, index) => {
                    if(item.user_id == user.value.id) {
                        // remove me from list
                        networks.value.splice(index, 1)
                    }
                })
            }
        }
        const onClose = () => {
            modalManager.close(currentInstance)
        }
        const onSelect = () => {
            if(selectEmail.value.length > 0) {
                selectEmail.value.forEach(item => {
                    selectUserData.value.push(item)
                })
            }
            if(selectUserData.value.length > 0) {
                modalManager.close(currentInstance, {userData : selectUserData.value})
            }
            else {
                const IsTypingaddEmail = addEmail.value && addEmail.value.trim()
                modalManager.showdialog({
                    title : `You don't select user. Are you sure?`,
                    msg : `${IsTypingaddEmail ? "you write email but, don't press enter or click the check button." : ''}`,
                    buttons : {
                        yes : 'yes',
                        no : 'no'
                    }
                }).then(res => {
                    if(res.result == 'yes') {
                        modalManager.close(currentInstance, {userData : []})
                    }
                })
            }
        }
        const onAddEmail = () => {
            if(!util.emailCheck(addEmail.value)) {
                modalManager.showdialog("Invalid e-mail form")
                return;
            }
            if(props.isOnePerson) {
                if(selectUserData.value.length > 0) {
                    selectUserData.value.splice(0,1);
                }
                if(selectEmail.value.length > 0) {
                    selectEmail.value.splice(0,1);
                }
            }
            const selectedUser = networks.value.filter(network => {
                return network.email == addEmail.value
            })
            if(selectedUser.length > 0) {
                if(!selectUserData.value.some(user => selectedUser[0].user_id == user.user_id)) {
                    selectUserData.value.push(selectedUser[0])
                }
            }
            else if(user.value.email == addEmail.value) {
                modalManager.showdialog("Don't add your e-mail")
            }
            else {
                if(!selectEmail.value.some(user => user.email == addEmail.value)) {
                    selectEmail.value.push({email : addEmail.value})
                }
            }
            addEmail.value = ""
        }
        const onEmailDel = (email) => {
            const index = selectEmail.value.findIndex(emailUser => emailUser.email == email)
            if(index > -1 )
            {
                selectEmail.value.splice(index ,1);
            }
        }
        watch(() => selectUserData, () => {
            if(props.isOnePerson) {
                if(selectUserData.value.length > 1) {
                    selectUserData.value.splice(0,1);
                }
                if(selectEmail.value.length > 0 && selectUserData.value.length > 0) {
                    selectEmail.value.splice(0,1);
                }
            }
        }, {deep : true})
        onMounted(async () => {
            await getUsersInfo()

            if(props.additionalUserList && props.additionalUserList.length > 0) {
                props.additionalUserList.forEach(item => {
                    if(!networks.value.some(net => net.user_id == item.id)) {
                        networks.value.push(item)
                    }
                })
            }

            if(props.selectedUser && props.selectedUser.length > 0) {
                props.selectedUser.forEach(selectUser => {
                    if(selectUser.user_id) {
                        selectUserData.value.push(networks.value.filter(user => selectUser.user_id == user.user_id)[0])
                    }
                    else {
                        selectEmail.value.push({...selectUser, additional : true})
                    }
                })
            }
        })
        return {
            networks,
            selectUserData,
            addEmail,
            selectEmail,

            onClose,
            onSelect,
            onAddEmail,
            onEmailDel,
        } 
    },
}

</script>

<style scoped lang="scss">
#containerStyle {
    width: 555px;
    border-radius: 1rem;
    background: #fff;
    .contentStyle {
        margin : 30px;
        .titleStyle {
            color : #910457;
            font : normal normal 800 18px/21px NanumSquareOTF;
        }
        .commentStyle {
            margin-bottom: 25px;
            color : #6c6c6c;
            font: normal normal normal 14px/22px NanumSquareOTF;
        }
        .userContainerStyle {
            height: 250px;
            overflow-y: auto;
            .checkboxStyle {
                background: #fff;
                transition : background-color 0.2s linear;
                &:hover {
                    background: #cccccc;
                }
            }
            .userDataStyle {
                width: 438.4px;
                height: 100%;
                display: flex;
                align-items: center;
                font : normal normal normal 15px/22px NanumSquareOTF;
                span {
                    margin-left: 10px;
                }
            }
        }
        .invertUserContainerStyle {
            margin-bottom: 10px;
            .emailInputStyle {
                padding: 3px 5px;
                border: 1px solid #666;
                border-radius: 8px;
                display: flex;
                white-space: nowrap;
                align-items: center;
                width: 100%;
                input {
                    margin-left: 5px;
                    padding : 3px;
                    width: 100%;
                }
                .add-btn {
                    border-radius: 50%;
                    margin-left: 5px;
                    cursor: pointer;
                    &:hover {
                        background: #cccccc;
                    }
                }
            }
            .invited {
                // width: 100%;
                width: 100%;
                height: 110px;
                // height: 100px;
                > div {
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    overflow-x: none;
                    display: flex;
                    flex-wrap: wrap;
                    align-content: flex-start;
                    > div {
                        word-break: break-all;
                        display: flex;
                        align-items: center;
                        margin-right: 0.5rem;
                        margin-top: 0.25rem;
                        color: #fff;
                        padding: 0.25rem 0.5rem;
                        border-radius: 50px;
                        font-size: 0.875rem;
                        background: #1E3560;
                        font: normal normal 400 16px/18px NanumSquareOTF;
                        cursor: pointer;

                        > i {
                            // border: 1px solid #666;
                            border: none;
                            padding-left: 3px;
                            border-radius: 10px;
                            cursor: pointer;
                        }
                    }

                    &::-webkit-scrollbar {
                        width: 10px;
                    }
                    &::-webkit-scrollbar-thumb {
                        background-color: #9c9c9c;
                    }
                    &::-webkit-scrollbar-track {
                        background-color: #d9d9d9;
                    }
                }
            }
        }
        .buttons-div {
            display: flex;
            .v-button {
                border: none;
                width: 48%;
                margin: 0 5px;
                height: 43px;
                font: 14px NanumSquareOTF;
                font-weight: 500;
                color: white;
                cursor: pointer;
                background: #910457;
                &.close {
                    background: #6c6c6c;
                }
            }
        }
    }
}
</style>