<template>
    <div v-show="isLoading" class="LoaingBlock" /> 
    <div class="ContainerBlock">
        <v-form class="sectionStyle" @submit.prevent="onSubmit" id="schedule-create-form">
            <h1>{{isAutoEventCreate ? "Create Schedule Without Event" : event?.title}}</h1>
            <div class="eventInfoBlock" v-if="!isAutoEventCreate">
                <img :src="require('@/assets/calendar.png')"/>
                <span>
                    {{date?.start_date}} ~ {{date?.end_date}}
                </span>
            </div>
            <div class="eventInfoBlock" v-if="!isAutoEventCreate">
                <img :src="require('@/assets/country_gray.png')" />
                <img :src="util.getCountryFlag(event?.country_iso2)">
                <span>
                    {{event?.country_name}}
                </span>
            </div>
            <section class="MeetingSection">
                <h2>Meeting Title</h2>
                <v-text-field
                    name="title"
                    type='text'
                    placeholder='Enter Meeting Title'
                    v-model="form.title"
                    hide-details
                />
            </section>
            <section class="MeetingSection" v-if="isAutoEventCreate">
                <h2>Country</h2>
                <nationSelector :oneSelect="true" @updateCountry="onUpdateCountry" :isShowNationImg="false"/>
            </section>
            <section class="MeetingSection">
                <h2>Recipients</h2>
                <div class="recipientStyle">
                    <div class="selectUser" :class="{nonSelectUser : form.recipient.length == 0}" ref="selectUsersRef">
                        <div>
                            <template v-for="user in form.recipient" :key="user">
                                <div @click="onClickUser(user)" :class="{nonUser : !user.id}"
                                    class="recipientSimpleDataStyle" :id="user.id" :name="user.name" :email="user.email">
                                    <div>
                                        {{user.name ? user.name + " |" : ""}} {{user.email}}
                                        
                                    </div>
                                    <div class="userText" v-if="user.id">
                                        User
                                    </div>
                                    <div class="nonUserClick" v-else>
                                        <div v-if="user.check == undefined">
                                            Click Here
                                        </div>
                                        <div class="nonUserIcon"  v-if="user.check == undefined || !(user.name && user.company)">
                                        <v-icon :class="{blink : user.check == undefined}">
                                            mdi-pencil
                                        </v-icon>
                                        </div>
                                    </div>
                                </div>
                                <Transition mode="in-out" name="slide">
                                    <div v-if="!user.id && user.check" class="nonUserInputStyle">
                                        <section class="MeetingSection">
                                            <v-text-field
                                                hide-details
                                                v-model="user.name"
                                                name="name"
                                                type='text'
                                                label='recipient name'
                                            />
                                        </section>
                                        <section class="MeetingSection">
                                            <v-text-field
                                                hide-details
                                                v-model="user.company"
                                                name="firm"
                                                type='text'
                                                label='recipient firm'
                                            />
                                        </section>
                                    </div>
                                </Transition>
                            </template>
                        </div>
                    </div>
                    <div class="searchImageStyle" @click="onUserSelectOpen">
                        <img :src="require('@/assets/icons_search.png')" />
                    </div>
                </div>
            </section>
            <section class="MeetingSection" title='Calendar'>
                <h2>Calendar</h2>
                <p class="caution" v-if="isDisabledScheduleTime">
                    <v-icon class="blink">mdi-alert-circle-outline</v-icon>
                    The selected period overlaps with the participant's schedule.
                </p>
                <p class="caution" v-else-if="form.recipient.length > 0">
                    You can't schedule the following periods for the selected recipient.
                </p>
                <div class="calendarContainerStyle">
                    <div>
                        <div class="showSelectedDate" v-if="form.date.start != 0 && form.date.end != 0">
                            {{`${scheduleDate.date} ${scheduleDate.start} ~ ${scheduleDate.end}`}} {{scheduleDate.timezone ? `(${scheduleDate.timezone})` : ""}}
                            <div>
                                <v-icon @click="onResetDate" class="clickable">
                                    mdi-close
                                </v-icon>
                            </div>
                        </div>
                        <v-btn @click="onScheduleDateModal" class="selectButton">
                            SELECT SCHEDULE DATE
                        </v-btn>
                    </div>
                </div>
            </section>
            <section class="MeetingSection" title='Location'>
                <h2>Meeting Place</h2>
                <v-combobox v-model="form.location" :items="locationList" placeholder="Enter or Select Preferred Meeting Place" hide-details/>
            </section>
            <section class="MeetingSection" title="comment">
                <div class="commentTitleStyle">
                    <h2>Comment</h2>
                    <v-checkbox v-model="isUserComment" v-if="isUseUserComment" hide-details> 
                        <template v-slot:label>
                            <div class="commentIsUserCommentStyle">
                                Use a user default comment
                            </div>
                        </template>
                    </v-checkbox>
                </div>

                <v-textarea
                    v-model="form.comment"
                    type='text'
                    placeholder="Enter Comment"
                    no-resize
                    hide-details
                />
                
                <v-checkbox v-model="form.sendEmail"> 
                    <template v-slot:label>
                        Confirm Schedule without sending E-mail 
                    </template>
                </v-checkbox>
            </section>
            <v-btn class="buttonStyle" type='submit' form="schedule-create-form">
                Propose Meeting
            </v-btn>
        </v-form>
    </div>                                
    <mouseHoverItem :hoverRefs="hoverRefs" :overCallback="overCallback">
        <template v-slot:content>
            <scheduleUserCard :recipient="hoverItemData" v-if="hoverItemData?.id" class="UserInfoStyle"/>
        </template>
    </mouseHoverItem>
</template>
<script>
import mouseHoverItem from "@/component/mouseHoverItem.vue"
import selectUserModal from "@/modal/userSelectModal.vue"
import {dateDataClass} from "@/component/timePeriodSelectorTable.vue"
import scheduleDateSeletorModal from "@/modal/scheduleDateSeletorModal.vue"
import scheduleUserCard from "@/page/meeting/schedule/scheduleUserCard.vue"
import nationSelector from "@/component/nationSelector.vue"
import util from "@/data/util"
import { computed, inject, nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useEventStore } from '@/stores/event'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import { useScheduleStore } from '@/stores/schedule'
import { useNetworkStore } from '@/stores/network'
import scheduleData from '@/data/scheduleData'
import { useCountryStore } from '@/stores/country'
export default {
    components : {
        // dateSelector,
        // timePeriodSelector,
        mouseHoverItem,
        scheduleUserCard,
        nationSelector,
    },
    setup(props, ctx) {
        const modalManager = inject("ModalManager")
        const router = useRouter()
        const route = useRoute()
        const eventStore = useEventStore()
        const userStore = useUserStore()
        const scheduleStore = useScheduleStore()
        const networkStore = useNetworkStore()
        const countryStore = useCountryStore()

        const {schedules} =storeToRefs(scheduleStore)

        const {user} = storeToRefs(userStore)
        const {event} = storeToRefs(eventStore)
        const {countries} = storeToRefs(countryStore)
        
        const form = ref({
            title : "",
            recipient : [],
            date : {
                start : 0,
                end : 0,
                date : 0,
            },
            country : "",
            location : "",
            comment : "",
            sendEmail : 0,
        })
        const availableDate = ref({})
        const locationList = ref([])
        const disabledDates = ref([])
        const myScheduleData = ref([])
        const dateData = ref([])
        const isUserComment = ref(false)
        const isUseUserComment = ref(false)
        const isAutoEventCreate = ref(false)
        const commentData = ref("")
        const hoverRefs = ref([])
        const hoverItemData = ref(null)
        const selectUsersRef = ref(null)
        const isLoading = ref(false)
        const minStamp = ref(scheduleData.minStamp)
        const selectedDateData = ref(null)
        const additionalUserList = ref([])

        const timezoneData = computed(() => {
            if(isAutoEventCreate.value && countries.value) {
                const countryData = countries.value.filter(country => country.id == form.value.country)
                if(countryData.length > 0) {
                    return util.convertTimezoneJsonToData(countryData[0].timezones)
                }
            }
            return util.convertTimezoneJsonToData(event.value?.country_timezones)
        })

        const date = computed(() => {
            var res = {
                start_date : util.date_format(util.convertTimeStmapToDateTime(event.value?.start_date), "YYYY-MM-DD"),
                end_date : util.date_format(util.convertTimeStmapToDateTime(event.value?.end_date), "YYYY-MM-DD"),
            }
            if(timezoneData.value?.gmtOffsetName) {
                res.timezone = timezoneData.value.gmtOffsetName
            }
            return res
        })

        const scheduleDate = computed(() => {
            var res = {
                date : util.date_format(util.getDate(form.value.date.date), "YYYY-MM-DD"),
                start : util.date_format(util.getDate(form.value.date.start), "HH:mm"),
                end : util.date_format(util.getDate(form.value.date.end), "HH:mm"),
            }
            if(res.end == "00:00") {
                res.end = util.date_format(util.getDate(form.value.date.end), "YYYY-MM-DD HH:mm");
            }
            // if(timezoneData.value?.gmtOffsetName) {
            //     res.timezone = timezoneData.value.gmtOffsetName
            // }
            return res;
        })

        const selectedMeetings = computed(() => {
            let result = myScheduleData.value ?? []
            result = JSON.parse(JSON.stringify(result))
            if(form.value.recipient.length > 0 ) {
                form.value.recipient.forEach(reci => {
                    if(!reci.user_id || !reci.meetings) {
                        return;
                    }
                    reci.meetings.forEach(meeting => {
                        result.push(meeting)
                    })
                })
            }
            if(result.length > 0) {
                result = result.map(meeting => {
                    // 다른 이벤트의 미팅 시간이 현 이벤트의 타임존에 맞도록 변경 동작.
                    const m_timezoneData = util.convertTimezoneJsonToData(meeting.country_timezones)
                    let start_time = Number(meeting.start_time)
                    let end_time = Number(meeting.end_time)
                    if(m_timezoneData?.gmtOffset && timezoneData.value?.gmtOffset) {
                        start_time = start_time - Number(m_timezoneData.gmtOffset) + Number(timezoneData.value?.gmtOffset)
                        end_time = end_time - Number(m_timezoneData.gmtOffset) + Number(timezoneData.value?.gmtOffset)
                        meeting.start_time = start_time
                        meeting.end_time = end_time
                    }
                    return meeting
                })
                return result
            }
            return null;
        })

        const isDisabledScheduleTime = computed(() => {
            let res = false
            if(selectedMeetings.value && form.value.date.date && form.value.date.start && form.value.date.end) {
                const schedule_start = Number(form.value.date.start)
                const schedule_end = Number(form.value.date.end)
                selectedMeetings.value.forEach(meeting => {
                    if(res) {
                        return;
                    }
                    const meeting_start = Number(util.convertUTSTOJTS(meeting.start_time))
                    const meeting_end = Number(util.convertUTSTOJTS(meeting.end_time))
                    if(!(schedule_start >= meeting_end || meeting_start >= schedule_end)) {
                        res = true;
                    }
                })
            }
            return res
        })
        
        const checkSubmitData = () => {
            var req = [
                {value : form.value.title, text : "title"},
                {value : form.value.recipient, check : form.value.recipient.length > 0, text : "recipient"},
                // {value : form.value.date.start && form.value.date.end
                //     , check : form.value.date.start < form.value.date.end
                //     , text : "end time is less than or equal to start time"},
                // {value : form.value.location , text : "location"},
                {value : form.value.comment , text : "comment"},
                {value : !isDisabledScheduleTime.value, text : "period overlaps with this participant's schedule"}
            ]
            if(isAutoEventCreate.value) {
                req.push({value : form.value.date.date , text : "date"})
                req.push({value : form.value.date.start , text : "start time"})
                req.push({value : form.value.date.end , text : "end time"})
                req.push({value : form.value.country, text : "country"})
            }
            // else {
            //     req.push({value : form.value.date.start && form.value.date.end, text : "calendar period"})
            // }
            const res = util.dataCheck(req)
            if(res.length > 0) {
                return res.join("<br>")
            }
            return false
        }
        
        const checkSubmitData2 = () => {
            var req = [
                {value : form.value.date.start && form.value.date.end, text : "period"},
                {value : form.value.date.start && form.value.date.end
                    , check : form.value.date.start < form.value.date.end
                    , text : "end time is less than or equal to start time"},
                {value : form.value.location , text : "location"},
            ]
            const res = util.dataCheck(req)
            if(res.length > 0) {
                return res.join("<br>")
            }
            return false
        }

        const onSubmit = async () => {
            let res = checkSubmitData()
            if(res) {
                modalManager.showdialog(`Please check your data:<br>${res}`)
                return;
            }
            res = checkSubmitData2()
            if(res) {
                res = await modalManager.showdialog({
                    title : `Please check your data:<br>${res}<br>Do you still want to schedule a meeting?<br>(Let the recipient specify the time or meeting place)`,
                    buttons : {
                        yes : "yes",
                        no : "no"
                    }
                })
                if(res.result == "no") {
                    return;
                }
            }
            let params = {}
            let event_id = event.value?.id
            if(isAutoEventCreate.value) {
                params = {
                    title : form.value.title,
                    start_date : util.convertJTSToUTS(form.value.date.start),
                    end_date : util.convertJTSToUTS(form.value.date.end),
                    country : form.value.country,
                }
                res = await eventStore.createEvent(params);
                if(res) {
                    event_id = res
                }
                else {
                    modalManager.showdialog("Failed")
                    return;
                }
            }
            const date = util.convertJTSToUTS(form.value.date.date) 
            const start_hour = util.convertJTSToUTS(form.value.date.start) 
            const end_hour = util.convertJTSToUTS(form.value.date.end)
            const location = form.value.location
            params = {
                event_id : event_id,
                title : form.value.title,
                participants : form.value.recipient.map(item => {
                    let user = {}
                    if(item.user_id) user.id = Number(item.user_id)
                    if(item.email) user.email = item.email
                    if(item.company) user.company = item.company
                    if(item.name) user.name = item.name
                    return user
                }),
                comment : form.value.comment,
                status : form.value.sendEmail ? scheduleData.M_STATUS.MEET_PROCEED : scheduleData.M_STATUS.MEET_INVITATION,
            }
            if(date && start_hour && end_hour) {
                params.date = date
                params.start_time = start_hour
                params.end_time = end_hour
            }
            if(location && location.trim()) {
                params.location = location
            }
            const func = async () => {
                res = await scheduleStore.createSchedule(params)
                isLoading.value = false
                if(res) {
                    modalManager.showdialog("Created").then(() => {
                        router.push(
                            {
                                path : `/meeting/event/detail`,
                                query : {
                                    eventno : event_id
                                }
                            }
                        )
                    })
                }
                else {
                    modalManager.showdialog("Failed")
                }
            }
            await modalManager.loading(func)
        }
        const networkQuery = computed(() => {
            let networkQuery = {}
            if(isAutoEventCreate.value) {
                const currentDate = util.getCurrentDate()
                networkQuery = {
                    etim : `+:${util.convertJTSToUTS(currentDate.getTime()) - (24 * 3600)}`
                }
            }
            else {
                networkQuery = {
                    stim : `-:${Number(event.value.end_date) + (24 * 3600)}`,
                    etim : `+:${Number(event.value.start_date) - (24 * 3600)}`
                }
            }
            return networkQuery;
        })

        const onUserSelectOpen = () => {
            // 국가별 timezone이 다르므로 24시간내외의 경우를 둔다. (DB내 데이터는 현지시간)
            modalManager.showEx(selectUserModal, {
                bind : {
                    additionalUserList : additionalUserList.value,
                    network_query : networkQuery.value,
                    selectedUser : form.value.recipient,
                    isOnePerson : false,
                },
            })
            .then(res => {
                if(res.userData) {
                    form.value.recipient = res.userData
                }
                setHoverRefs()
            })
        }

        const onClickUser = (user) => {
            if(!user.check) {
                user.check = true
            }
            else {
                user.check = false
            }
            return ;
        }
        const onUpdateCountry = country => {
            if(country && country.length > 0) {
                form.value.country = country[0]
            }
        }

        const overCallback = (e, ref) => {
            const id = e.target.getAttribute("id")
            const name = e.target.getAttribute("name")
            const email = e.target.getAttribute("email")
            if(id) {
                hoverItemData.value = form.value.recipient.filter(user => user.id == id)[0]
            }
            else {
                hoverItemData.value = {
                    id : id,
                    name : name,
                    email : email
                }
            }
        }
        const setHoverRefs = () => {
            nextTick(() => {
                hoverRefs.value = [
                    ...selectUsersRef.value.querySelectorAll(".recipientSimpleDataStyle")
                ]
            })
        }

        const setDisabledDatesTime = () => {
            const currentDate = util.getCurrentDate()
            let min_date = currentDate.setDate(currentDate.getDate() - 1)
            let max_date = null;
            if(event.value?.start_date && event.value?.end_date) {

                const start_date = util.convertTimeStmapToDateTime(event.value.start_date)
                const end_date = util.convertTimeStmapToDateTime(event.value.end_date)

                const event_min_date = start_date.setDate(start_date.getDate() - 1)
                const event_max_date = end_date.setDate(end_date.getDate() + 1)
                if(min_date < event_min_date) {
                    min_date = event_min_date
                }
                max_date = event_max_date
            }
            if(min_date) {
                disabledDates.value.push({
                    start : null,
                    end : min_date,
                })
            }
            if(max_date) {
                disabledDates.value.push({
                    start : max_date,
                    end : null,
                })
            }
        }
        const onResetDate = () => {
            form.value.date.date = 0
            form.value.date.start = 0
            form.value.date.end = 0
            selectedDateData.value = null
        }

        const onScheduleDateModal = () => {
            modalManager.showEx(scheduleDateSeletorModal, {
                bind : {
                    timezone : timezoneData.value,
                    selectedDateData : selectedDateData.value,
                    event : event.value,
                    form : form.value,
                    disabledDates : disabledDates.value,
                    disabledMeetings : selectedMeetings.value,
                }
            })
            .then(res => {
                if(res) {
                    selectedDateData.value = res
                    
                    if(selectedDateData.value.start) {
                        form.value.date.date = util.getDate(selectedDateData.value.start).getTime()
                    }
                    form.value.date.start = util.getDate(selectedDateData.value.start).getTime()
                    form.value.date.end = util.getDate(selectedDateData.value.end).getTime()
                }
                
            })
        }

        onUnmounted(() => {
            networkStore.setTempUser(null)
        })

        onMounted(async () => {
            if(!user.value) {
                await userStore.fetchMe()
            }
            if(route.query.eventno != "without") {
                const res = await eventStore.fetchEvent(route.query.eventno)
                if(!res) {
                    modalManager.showdialog("No Permission").then(() => {
                        router.back();
                    })
                }
                // 달력으로 변경시 필요.
                const firstTime = new dateDataClass(event.value).findFirstTime()
                form.value.title = event.value.title;
                form.value.date.date = firstTime;
                // form.value.date.start = firstTime;
                // form.value.date.end = firstTime;
            }
            else {
                isAutoEventCreate.value = true;
                form.value.date.date = util.getCurrentDate().getTime()
                // form.value.date.start = util.getCurrentDate().getTime()
                // form.value.date.end = util.getCurrentDate().getTime()
            }
            if(networkStore.tempUser) {
                const tempUser = {...networkStore.tempUser, user_id : networkStore.tempUser.id}
                additionalUserList.value.push(tempUser)
                form.value.recipient = [tempUser]
            }
            if (event.value?.location) {
                try {
                    const location = JSON.parse(event.value.location)
                    if (Array.isArray(location)) {
                        locationList.value = location
                    }
                } catch(e) {
                    locationList.value = []
                }
            }
            form.value.comment = event.value?.comment
            isUseUserComment.value = user.value?.comment ? true : false
            
            // 본인 미팅 정보를 가져오기 위한 선 작업.
            await scheduleStore.fetchSchedules(networkQuery.value)

            if(schedules.value && schedules.value.length > 0) {
                const myData = schedules.value.filter(schedule => 
                    schedule.status == scheduleData.M_STATUS.MEET_PROCEED ||
                    schedule.status == scheduleData.M_STATUS.MEET_AGREEMENT
                )
                if(myData.length > 0) {
                    myScheduleData.value = myData
                }
            }
            scheduleStore.clearSchedules()
            
            disabledDates.value = []
            setDisabledDatesTime()
            setHoverRefs();
        })
        watch(() => isUserComment.value, (newValue, oldValue) => {
            if(newValue) {
                commentData.value = form.value.comment
                form.value.comment = user.value.comment
            } else {
                form.value.comment = commentData.value
            }
        })
        return {
            util,
            form,
            availableDate, 
            locationList,
            isUserComment,
            isUseUserComment,
            event,
            date,
            scheduleDate,
            user,
            isAutoEventCreate,
            hoverRefs,
            hoverItemData,
            selectUsersRef,
            isLoading,
            disabledDates,
            dateData,
            minStamp,
            isDisabledScheduleTime,

            onResetDate,
            onUpdateCountry,
            onUserSelectOpen,
            onClickUser,
            onSubmit,
            overCallback,
            onScheduleDateModal,

        }
    },
}
</script>

<style scoped lang="scss">
.LoaingBlock {

}
.ContainerBlock {
    
    position: relative;
    display: flex;
    flex-direction: column;
    width: 34.0625rem;
    min-width: 32.0625rem;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 2px 5px 11px #00000029;
    border-radius: 1rem;
    padding: 1.875rem;
    margin-bottom: 1rem;

    h1 {
        color: #333;
        margin: 0 0 1.875rem;
        font: normal normal 800 20px/23px NanumSquareOTF;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .sectionStyle {
        display: flex;
        flex-direction: column;
        & > h1 {
            color: #333;
            font: normal normal 800 20px NanumSquareOTF;
            margin-bottom: 0.75rem;
        }
        .eventInfoBlock {
            display: flex;
            align-items: center;
            margin-bottom: 0.8125rem;

            img {
                margin-right: 0.5rem;
                width: 1rem;
            }

            span {
                font: normal normal normal 1rem/26px NanumSquareOTF;
                color: #6c6c6c;
            }
        }
        .MeetingSection {
            display: flex;
            flex-direction: column;
            margin-bottom: 1.3125rem;
            color: #333333;
            width: 100%;
            .commentTitleStyle {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .commentIsUserCommentStyle {
                    font-size: 14px;
                    font-weight: 800;
                    color: #910457;
                    font-family: NanumSquareOTF;
                }
            }
            &:last-of-type {
                margin-bottom: 0;
            }

            .multiline {
                white-space: pre-wrap;
                max-width: 100%;
                overflow-wrap: break-word;
                color: #6c6c6c;
                font: normal normal normal 16px/26px NanumSquareOTF;
            }

            .email {
                font: normal normal normal 16px/18px NanumSquareOTF;
                color: #9c9c9c;
            }

            .state-text {
                font: normal normal normal 16px/26px NanumSquareOTF;
                color: #6c6c6c;
                margin-left: 1.25rem;
            }

            h2 {
                font: normal normal 800 16px/36px NanumSquareOTF;
                line-height: 2.25;
                margin: 0 0 0.25rem;
                border-radius: 0.5rem;
                user-select: none;
            }

            p {
                margin: 0;
                font: normal normal normal 16px/18px NanumSquareOTF;
                &.caution {
                    font : 14px NanumSquareOTF;
                    margin-bottom: 5px;
                    i {
                        border-radius: 50%;
                        background: rgb(145, 0, 0);
                        color : #fff;
                    }
                }
            }

            .divider {
                background: #d9d9d9 0% 0% no-repeat padding-box;
                height: 18px;
                width: 1px;
            }
            .recipientStyle {
                display: flex;
                align-content: center;
                width: 100%;
            }
            .selectUser {
                &.nonSelectUser {
                    border: 1px solid #9c9c9c;
                }
                box-sizing: border-box;
                padding: 5px;
                width: 90%;
                border-radius: 10px;
                > div {
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-content: flex-start;
                    .recipientSimpleDataStyle {
                        display: flex;
                        border-bottom: 1px solid #9c9c9c;
                        width: 100%;
                        height: 40px;
                        user-select: none;
                        align-items: center;
                        .userText{
                            margin-right: 0;
                            margin-left: auto;
                            border-radius: 10px;
                            padding: 5px;
                            background: #910457;
                            color: #fff;
                        }
                        .nonUserClick {
                            display: flex;
                            align-items: center;
                            margin-right: 0;
                            margin-left: auto;
                            >div {
                                margin: 0 5px;
                            }
                            .nonUserIcon {
                                padding: 5px;
                                border-radius: 50%;
                                background: #F6B838;
                                color : #fff;
                            }
                        }
                        &.nonUser {
                            cursor: pointer;
                        }
                    }
                    .nonUserInputStyle {
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        border: 1px solid #9c9c9c;
                        transform-origin: top;
                        transition: transform .4s ease-in-out;
                    }
                    // > div {
                    //     display: flex;
                    //     height: fit-content;
                    //     flex-direction: column;
                    //     margin-right: 0.5rem;
                    //     margin-top: 0.25rem;
                    //     color: #fff;
                    //     padding: 15px;
                    //     border-radius: 15px;
                    //     font-size: 0.875rem;
                    //     background: #1E3560;
                    //     font: normal normal 400 16px/18px NanumSquareOTF;
                    //     cursor: pointer;
                    //     > i {
                    //         // border: 1px solid #666;
                    //         border: none;
                    //         padding-left: 3px;
                    //         border-radius: 10px;
                    //     }
                    // }
                    &::-webkit-scrollbar {
                        width: 10px;
                    }
                    &::-webkit-scrollbar-thumb {
                        background-color: #9c9c9c;
                    }
                    &::-webkit-scrollbar-track {
                        background-color: #d9d9d9;
                    }
                }
            }
            .searchImageStyle {
                cursor: pointer;
                margin-left: 0.5rem;
                width: 3rem;
                height: 3rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
                &:hover {
                    background: #cccccc;
                }
                >img {
                    width: 2rem;
                }
            }
            .calendarContainerStyle {
                display: flex;
                flex-direction: column;
                .selectButton {
                    background: #910457;
                    font: 14px NanumSquareOTF;
                    color: #fff;
                }
                .creatWithoutEvent {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    >div {
                        margin-bottom: 10px;
                    }
                }
                .showSelectedDate {
                    display: flex;
                    margin-bottom: 10px;
                }
            }
        }
    }
    .buttonStyle {
        background: #910457;
        color: #fff;
        font: 14px NanumSquareOTF;
    }
}
</style>