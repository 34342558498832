<template>
    <div id="imageContainer" ref="imageContainer">
        <div class="buttons">
            <v-icon @click="onClose" class="cancelIcon" size="50">mdi-close</v-icon>
            <v-icon v-if="index > 0" @click="onPrevNext(0)" class="icon first" size="50">mdi-arrow-left-drop-circle-outline</v-icon>
            <v-icon v-if="index < imgs.length - 1" @click="onPrevNext(1)" class="icon last" size="50">mdi-arrow-right-drop-circle-outline</v-icon>
        </div>
        <div ref="slideRef" class="slide"
            @wheel="imgResize"  
            @mousedown="startDrag" 
            @mousemove="moveDrag"
            @mouseup="endDrag"
        >
            <img :src="imgs[index].src" ref="imageRefs">
        </div>
    </div>
</template>
<script>
import { getCurrentInstance, inject, nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
export default {
    props : {
        src : String,
        imgs: Object,
        idx: Number,
    },
    setup(props, ctx) {
        const currentInstance = getCurrentInstance()
        const modalManager = inject("ModalManager")
        const slideRef = ref(null)
        const imageRefs = ref(null)
        const imageContainer = ref(null)
        const onClose = () => {
            modalManager.close(currentInstance)
        }
        const testImage = () => { 
            if(props.imgs[index.value].width > props.imgs[index.value].height) {
                imageRefs.value.style.width = '100%'
                imageRefs.value.style.height = 'auto'
            } else {
                imageRefs.value.style.width = 'auto'
                imageRefs.value.style.height = '100%'
            }
        }

        const index = ref(props.idx)
        const onPrevNext = (n) => {
            n ? index.value++ : index.value--
            testImage()
            slideRef.value.style.transform = 'none'
            scale.value = 1
            pos.value = {x: 0,y: 0}
        }

        const scale = ref(1)
        const factor = ref(0.1)
        const max_scale = ref(5)
        const size = ref({w: 0, h: 0})
        const pos = ref({x: 0, y: 0})
        const zoom_target = ref({x: 0, y: 0})
        const zoom_point = ref({x: 0, y: 0})
        const last_ms_pos = ref({x: 0, y: 0})

        const update = () => {          
            if(pos.value.x > 0) {
                pos.value.x = 0
            }
            if(pos.value.x + size.value.w * scale.value < size.value.w) {
                pos.value.x = -size.value.w*(scale.value-1)
            }
            if(pos.value.y > 0) {
                pos.value.y = 0
            }
            if(pos.value.y + size.value.h * scale.value < size.value.h) {
                pos.value.y = -size.value.h*(scale.value-1)
            }
            slideRef.value.style.transform = `translate(${pos.value.x}px, ${pos.value.y}px) scale(${scale.value}, ${scale.value})`
        }

        const imgResize = (e) => {
            e.preventDefault()
            const img = slideRef.value
            const container = imageContainer.value
            const offset = {x: container.offsetLeft, y: container.offsetTop}
            let center_zoom = {x: 0, y: 0}
            let delta = e.wheelDelta

            zoom_point.value.x = e.pageX - offset.x
            zoom_point.value.y = e.pageY - offset.y

            delta = Math.max(-1, Math.min(1, delta))

            zoom_target.value.x = (zoom_point.value.x - pos.value.x)/scale.value
            zoom_target.value.y = (zoom_point.value.y - pos.value.y)/scale.value
            center_zoom.x = ((img.clientWidth/2) - pos.value.x)/scale.value
            center_zoom.y = ((img.clientHeight/2) - pos.value.y)/scale.value

            scale.value += delta * factor.value * scale.value
            scale.value = Math.max(1, Math.min(max_scale.value, scale.value))

            pos.value.x = -zoom_target.value.x * scale.value + zoom_point.value.x
            pos.value.y = -zoom_target.value.y * scale.value + zoom_point.value.y

            if(imageRefs.value.getBoundingClientRect().width < container.clientWidth) {
                pos.value.x = -center_zoom.x * scale.value + (img.clientWidth/2)
            } else if(imageRefs.value.getBoundingClientRect().height < container.clientHeight) {
                pos.value.y = -center_zoom.y * scale.value + (img.clientHeight/2)
            }

            update()
        }

        const isXDrag = ref(false)
        const isYDrag = ref(false)

        // 이미지 움직이기
        const moveDrag = (e) => {
            const container = imageContainer.value
            const img = imageRefs.value

            if(isXDrag.value || isYDrag.value) {
                e.preventDefault()
                const disL = img.getBoundingClientRect().left - container.getBoundingClientRect().left
                const disR = container.getBoundingClientRect().right - img.getBoundingClientRect().right
                const disT = img.getBoundingClientRect().top - container.getBoundingClientRect().top
                const disB = container.getBoundingClientRect().bottom - img.getBoundingClientRect().bottom
                
                const current_ms_pos = {x: e.pageX, y: e.pageY}
                const chX = current_ms_pos.x - last_ms_pos.value.x
                const chY = current_ms_pos.y - last_ms_pos.value.y
                
                last_ms_pos.value = current_ms_pos

                const changePosX = pos.value.x + chX
                const changePosY = pos.value.y + chY
                
                if(isXDrag.value) {
                    pos.value.x = Math.min(changePosX - disL, Math.max(changePosX + disR, changePosX))
                }
                if(isYDrag.value) {
                    pos.value.y = Math.min(changePosY - disT, Math.max(changePosY + disB, changePosY))
                }
                
                update()
            }
        }

        // 드래그 종료
        const endDrag = (e) => {
            isXDrag.value = false
            isYDrag.value = false;
        }

        // 드래그 시작
        const startDrag = (e) =>{
            e.preventDefault();
            const container = imageContainer.value
            const img = imageRefs.value.getBoundingClientRect()

            if(img.width >= container.clientWidth && img.height >= container.clientHeight) {
                isXDrag.value = true
                isYDrag.value = true
            } else if(img.height >= container.clientHeight) {
                isYDrag.value = true
            } else if(img.width >= container.clientWidth) {
                isXDrag.value = true
            }

            last_ms_pos.value = {x: e.pageX, y: e.pageY}
        }

        const onResize = () => {
            if(imageContainer.value.clientWidth < imageContainer.value.clientHeight) {
                imageRefs.value.style.width = '100%'
                imageRefs.value.style.height = 'auto'
            } else {
                testImage()
            }
        }

        onMounted(() => {
            nextTick(() => {
                testImage()
                size.value.w = slideRef.value.clientWidth
                size.value.h = slideRef.value.clientHeight
            })
            window.addEventListener("mouseup", endDrag)
            window.addEventListener('resize', onResize)
        })

        onUnmounted(() => {
            window.removeEventListener("mouseup", endDrag)
            window.removeEventListener("resize", onResize)
        })

        return {
            slideRef, 
            imageRefs, 
            imageContainer,
            index,

            imgResize,
            onClose,
            startDrag,
            moveDrag,
            endDrag,
            onPrevNext,
        }
    }
}
</script>

<style scoped lang="scss">
#imageContainer {
    user-select: none;
    border: 1px solid #999;
    width : 60%;
    height: 80%;
    overflow: hidden;
    background: #eee;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;

    .cancelIcon {
        position: absolute;
        top: calc(10% - 50px);
        left : calc(50% - 50px);
        background: #fff;
        border-radius: 50%;
        color: #910457;
        z-index: 1;
    }
    .buttons {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .icon {
            position: absolute;
            top: calc(50% - 50px);
            color: #910457;
            background: #fff;
            border-radius: 50%;
            z-index: 1;
            &.first {
                left: calc(15% - 50px);
            }
            &.last {
                right: calc(15% - 50px);
            }
        }
    }
    .slide {
        z-index: 1;
        width:100%;
        height:100%;
        transform-origin: 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
}
</style>