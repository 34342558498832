<template>
    <v-avatar :size="size" :style="{ border: is_border ? '1px solid lightgray' : '', background : '#fff'}">
        <imageShow :src="src" :style="{width : size + 'px'}" :default_image="require('@/assets/default_user.png')"/>
    </v-avatar>
</template>

<script>
import imageShow from "@/component/imageShow.vue"

export default {
    props : {
        src : String,
        size : {
            type : Number,
            default : 70,
        },
        is_border : {
            type : Boolean,
            default : true,
        }
    },
    components : {
        imageShow,
    },
    setup(props, ctx) {
    },
}
</script>

<style scoped>
</style>