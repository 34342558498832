<template>
    <div>
        <div class="circleMenuContainerStyle">
            <circleMenu :menuList="eventTab" class="circleMenuStyle" :activeValue="component"/>
        </div>
        <keep-alive>
            <component :ref="el => {eventComponent = el}" :is="component" :component="component" 
                @onDrag="onDrag" :isActivte="isActivte"/>
        </keep-alive>
    </div>
    <a class="linkStyle" @click="onCreateEvent">
        <v-btn icon class="addStyle">
            <img :src="require('@/assets/plus.png')"/>
        </v-btn>
    </a>
</template>
<script>
import circleMenu from "@/component/circleMenu.vue"
import emptyComponent from "@/component/emptyComponent.vue"
import eventCardList from "@/page/meeting/event/eventCardList.vue"
import eventListTable from "@/page/meeting/event/eventListTable.vue"
import eventCalendar from "@/page/meeting/event/eventCalendar.vue"
import eventCreateModal from "@/modal/eventCreateModal.vue"
import { inject, nextTick, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import util from '@/data/util'
export default {
    components : {
        eventCardList,
        eventListTable,
        eventCalendar,
        emptyComponent,
        circleMenu,
    },
    setup(props, ctx) {
        const route = useRoute()
        const router = useRouter()
        const component = ref("emptyComponent")
        const eventComponent = ref({})
        const query = ref({})
        const isActivte = ref(false)

        const eventTabCallback = (componentName) => {
            return () => {
                isActivte.value = false
                if(route.query) {
                    query.value[component.value] = route.query
                }
                var query_params = null
                if(query.value[componentName]) {
                    query_params = query.value[componentName]
                }
                
                router.push({query : query_params}).then(res => {
                    component.value = componentName
                    isActivte.value = true;
                })
            }
        }

        const eventTab = ref([
            {image : "mdi-calendar-text", value : "eventCalendar", callback : eventTabCallback("eventCalendar")},
            {image : "mdi-view-grid", value : "eventCardList", callback : eventTabCallback("eventCardList")},
            {image : "mdi-view-list", value : "eventListTable", callback : eventTabCallback("eventListTable")},
        ])


        const modalManager = inject("ModalManager")

        const onDrag = async (data) => {
            const startDay = util.getDate(data[0], 0).getTime()
            const endDay = util.getDate(data[1], 0).getTime()
            const endCallback = data[2]
            await onCreateEvent(null, startDay, endDay)
            endCallback()
        }

        const onCreateEvent = async (event, startDay, endDay) => {
            const res = await modalManager.showEx(eventCreateModal, {
                bind : {
                    startDate : startDay,
                    endDate : endDay,
                }
            })
            if(res?.success) {
                await refresh()
            }
        }

        const refresh = async () => {
            await eventComponent.value.refresh()
        }

        onMounted(() => {
            isActivte.value = false;
            if(route.query.page || route.query.order) {
                component.value = "eventListTable"
            }
            else {
                component.value = "eventCalendar"
            }
            isActivte.value = true;
        })

        return {
            component,
            eventTab,
            isActivte,

            onCreateEvent,
            onDrag,
            refresh,

            eventComponent,
        }
    },
}
</script>

<style scoped lang="scss">

.addStyle {
    position: fixed;
    bottom: 103px;
    right: 2rem;
    z-index: 10;
    background: rgb(145, 4, 87);
    width: 56px;
    height: 56px;
}
.circleMenuContainerStyle { 
    position: absolute;
    transform: translate3d(0,0,0);
    right : 0px;
    left : 600px;
    top : -10px;
    min-width: 300px;
    z-index: 10;
    height: 52px;
    overflow: hidden;
    .circleMenuStyle {
        right: 2rem;
        overflow: visible;
        position: absolute;
    }
}
</style>