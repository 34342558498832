import { TokenService } from "@/services/storage.service";
import { defineStore } from "pinia";
import { ApiError } from "@/services/api.v2.service";
import AuthV2Service from "@/services/auth.v2.service";
import { useUserStore } from "./user";

export const useAuthStore = defineStore("auth", {
    state: () => ({
        email: null,
        verifiedEmailToken: null,

        accessToken: TokenService.getAccessToken(),

        authenticationEmailCheck: false,
        authenticationEmailSend: false,
        authentication : false,
        authenticationError: { code: 0, message: "" }
    }),

    getters: {
        loggedIn: (state) => {
            return state.accessToken ? true : false
        },
        emailChecked : (state) => {
            return state.verifiedEmailToken ? true : false
        }
    },

    actions: {
        async checkEmail(email, use = 0) {
            this.authenticationEmailSend = true
            this.clearAuthenticationError()

            try {
                return await AuthV2Service.checkEamil(email, use)
            } catch (error) {
                if (error instanceof ApiError) {
                    this.authenticationError = { code: error.code, message: error.message }
                }

                return false
            } finally {
                this.authenticationEmailSend = false
            }
        },

        async verifyEmail(email, code) {
            this.authenticationEmailCheck = true
            this.clearAuthenticationError()

            try {
                const token = await AuthV2Service.verifyEmail(email, code)
                if (token !== false) {
                    this.email = email
                    this.verifiedEmailToken = token
                    return true
                }

                return false
            } catch (error) {
                if (error instanceof ApiError) {
                    this.authenticationError = { code: error.code, message: error.message }
                }
                return false
            } finally {
                this.authenticationEmailCheck = false
            }
        },

        async existAccount(account) {
            try {
                return await AuthV2Service.existAccount(account)
            } catch (error) {
                if (error instanceof ApiError) {
                    this.authenticationError = { code: error.code, message: error.message }
                }
                return false
            }
        },

        async join(email, account, password, name) {
            if (!this.isVerifiedEmailExist() || this.email != email) {
                return false
            }

            this.authentication = true
            this.clearAuthenticationError()

            try {
                const result = await AuthV2Service.join(this.verifiedEmailToken, email, account, password, name)
                if (result !== false) {
                    this.email = null
                    this.verifiedEmailToken = null
                    return true
                }

                return false
            } catch (error) {
                if (error instanceof ApiError) {
                    this.authenticationError = { code: error.code, message: error.message }
                }
                return false
            } finally {
                this.authentication = false
            }
        },

        async resetPassword(email, password) {
            if (!this.isVerifiedEmailExist() || this.email != email) {
                return false
            }

            this.authentication = true
            this.clearAuthenticationError()

            try {
                const result = await AuthV2Service.resetPassword(this.verifiedEmailToken, email, password)
                if (result !== false) {
                    this.destoryVerifiedEmail()
                    return true
                }

                return false
            } catch (error) {
                if (error instanceof ApiError) {
                    this.authenticationError = { code: error.code, message: error.message }
                }
                return false
            } finally {
                this.authentication = false
            }
        },

        isVerifiedEmailExist() {
            return this.email && this.verifiedEmailToken
        },

        destoryVerifiedEmail() {
            this.email = null
            this.verifiedEmailToken = null
        },

        async login(id, password) {
            this.authentication = true
            this.clearAuthenticationError()

            try {
                const accesToken = await AuthV2Service.login(id, password)
                if (accesToken !== false) {
                    this.accessToken = accesToken

                    const userStore = useUserStore()
                    await userStore.fetchMe();
    
                    // TODO: check
                    // this.router.push(this.router.history.current.query.redirect || "/feed");
                    this.router.push("/feed");
    
                    return true    
                }

                return false
            } catch (error) {
                if (error instanceof ApiError) {
                    this.authenticationError = { code: error.code, message: error.message }
                }
                return false
            } finally {
                this.authentication = false
            }
        },

        async refreshToken() {
            try {
                const accesToken = await AuthV2Service.refreshToken()
                if (accesToken !== false) {
                    this.accessToken = accesToken
                    return true
                }

                return true
            } catch (error) {
                if (error instanceof ApiError) {
                    this.authenticationError = { code: error.code, message: error.message }
                }
                return false
            }
        },

        logout() {
            AuthV2Service.logout()
            this.accessToken = null

            this.router.push("/login")
        },
        clearAuthenticationError() {
            this.authenticationError = { code: 0, message: '' }
        }
    },
})