import { ApiV2Service, ApiError } from "./api.v2.service";
import { TokenService } from "./storage.service";

// TODO: 추후 제거, 기존 코드 호환을 위해서 
// import JwtService from "@/services/jwt.service";

const AuthV2Service = {
    checkEamil: async function(email, use = 0) {
        const response = await ApiV2Service.post('/email/check', { email: email, use: use })

        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    verifyEmail: async function(email, code) {
        const response = await ApiV2Service.post('/email/verify', { email: email, code: code })

        if (response.data.success) {
            return response.data.data
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    existAccount: async function(account) {
        const response = await ApiV2Service.post('/account/exist', { account: account })

        if (response.data.success) {
            return response.data.data
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    join: async function(token, email, account, password, name) {
        const data = {
            method: 'post',
            url: '/signup',
            data: {
                email: email,
                account: account,
                password: password,
                name: name
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        } 

        const response = await ApiV2Service.customRequest(data)

        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    resetPassword: async function(token, email, password) {
        const data = {
            method: 'post',
            url: '/password/reset',
            data: {
                email: email,
                password: password,
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        } 

        const response = await ApiV2Service.customRequest(data)

        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    login: async function(id, password) {
        const response = await ApiV2Service.post('/signin', { id: id, password: password })

        if (response.data.success) {
            TokenService.saveAccessToken(response.data.data.access_token)
            TokenService.saveRefreshToken(response.data.data.refresh_token)
            ApiV2Service.setHeader()

            ApiV2Service.mount401Interceptor()

            // TODO: 추후 제거
            // JwtService.deleteAllToken()
            // JwtService.setAccessToken(response.data.data.access_token)
            // JwtService.setRefreshToken(response.data.data.refresh_token)

            return response.data.data.access_token
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    refreshToken: async function() {
        const data = {
            method: 'post',
            url: '/refresh',
            data: {
                access_token: TokenService.getAccessToken()
            },
            headers: {
                Authorization: `Bearer ${TokenService.getRefreshToken()}`
            }
        }

        const response = await ApiV2Service.customRequest(data)

        if (response.data.success) {
            TokenService.saveAccessToken(response.data.data.access_token)
            TokenService.saveRefreshToken(response.data.data.refresh_token)
            ApiV2Service.setHeader()

            // TODO: 추후 제거
            // JwtService.deleteAllToken()
            // JwtService.setAccessToken(response.data.data.access_token)
            // JwtService.setRefreshToken(response.data.data.refresh_token)

            return response.data.data.access_token
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    logout() {
        TokenService.removeAccessToken()
        TokenService.removeRefreshToken()
        ApiV2Service.removeHeader()

        ApiV2Service.unmount401Interceptor()
    }
}

export default AuthV2Service
