<template>
    <template v-if="conferences && conferences.length === 0">
        <div class="noScheduleStyle" :style="{ maxWidth: '54.375rem' }" >
            <h1>There is no conference.</h1>
        </div>
    </template>
    <template v-else>
        <div class="cardMain">
            <div v-for="conference in conferences" :key="conference">
                <ConferenceCard :conferenceData="conference" :isLoading="isLoading"/>
            </div>
        </div>
    </template>
    <a class="linkStyle" @click="onMoveToWrite" v-if="isAdmin">
        <v-btn icon class="addStyle">
            <img :src="require('@/assets/plus.png')"/>
        </v-btn>
    </a>
</template>

<script>
import ConferenceCard from "@/page/conference/conferenceCard.vue"
import { computed, onMounted, ref } from "vue"
import { useConferenceStore } from "@/stores/conference"
import { storeToRefs } from "pinia"
import { useRouter } from "vue-router"
import { useUserStore } from '@/stores/user'

export default {
    components: {
      ConferenceCard
    },
    setup(props, ctx) {
        const conferenceStore = useConferenceStore()
        const router = useRouter()
        const userStore = useUserStore()

        const isLoading = ref(true)

        const { conferences } = storeToRefs(conferenceStore)

        const onMoveToWrite = () => {
            router.push( {path: '/conference/write'} )
        }

        const isAdmin = computed(() => {
            return userStore.user?.type == 9
        })

        onMounted(async () => {
            isLoading.value = true;
            await conferenceStore.fetchConferences()
            isLoading.value = false;
        })

        return {
            conferences,

            onMoveToWrite,
            isLoading,
            isAdmin,
        }
    },
}
</script>

<style lang="scss" scoped>
.cardMain {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  div {
    margin : 0 5px;
  }
}
.addStyle {
    position: fixed;
    bottom: 103px;
    right: 2rem;
    z-index: 10;
    background: rgb(145, 4, 87);
    width: 56px;
    height: 56px;
}
</style>