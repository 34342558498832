<template style="z-index:1000;">
    <div>
        <!-- :masks="{title:'YYYY MMMM'}" -->
        <DatePicker v-model="r_date.datestring" :is-required="is_required" is24hr
            :model-config="modelConfig" :locale="locale" :mode="mode" :available-dates="available_date"
            :min-date="min_date" :minute-increment="minute_increment" :timezone="timezone" :valid-hours="isHourValid">
            <template v-slot="{inputValue, togglePopover }">
                <div ref="date_content" class="date_content" @click="togglePopover">
                    <input :value="inputValue ? inputValue : 'None'" @keypress.enter="date_input($event)"/>
                    <img :src="require('@/assets/calendar.png')" >
                </div>
            </template>
        </DatePicker>
    </div>
</template>

<script>
import util from "@/data/util"
import { DatePicker } from 'v-calendar';
import 'v-calendar/dist/style.css';

export default {
    components : {
        DatePicker, 
    },

    props : {
        // date, dateTime
        mode : {
            type : String,
            default : "date"
        },
        date : Object,
        min_date : {
            type : [String, Number, Date],
            default : null,
        },
        hour_valid : Object,
        is_required : {
            type : Boolean,
            default : true,
        },
        minute_increment : {
            type : Number,
            default : 1,
        },
        timezoneData : Object,
        available_date : Object,
    },

    mounted() {
        this.r_date = this.date
        if(this.r_date.datestring == undefined || this.r_date.datestring == "")
        {
            this.r_date.datestring = util.getDate(util.date_format(new Date(), "YYYY-MM-DD"));
        }

        // this.cal_input();
    },

    watch : {
        "date" : {
            handler : function(value) {
                this.r_date = this.date
                this.date_raw = this.date.datestring
            }, deep : true
        },
        timezoneData : {
            handler() {
                this.setTimezone()
            }, deep : true
        },
    },
    data() {
        return {
            r_date : this.date,
            date_raw : "",
            modelConfig: {
                type: 'number',
                // masks: {
                //     title : "YYYY MMMM",
                // }
            },
            locale : {
                id : 'en', 
                firstDayOfWeek : 1, 
                masks : {
                    L : "YYYY-MM-DD WWW",
                }
            },
            util : util,
            timezoneIndex: 0,
            timezones: [
                'Pacific/Niue', // -11
                'US/Hawaii', // -10
                'America/Anchorage', // -9
                'America/Los_Angeles', // -8
                'America/Boise', // -7
                'America/Chicago', // -6
                'America/New_York', // -5
                'America/Aruba', // -4
                'America/Buenos_Aires', // -3
                'Brazil/DeNoronha', // -2,
                'Atlantic/Azores', // -1
                'UTC', // 0
                'Europe/Amsterdam', // +1
                'Europe/Athens', // +2
                'Europe/Moscow', // +3
                'Indian/Mahe', // +4
                'Asia/Ashgabat', // +5
                'Asia/Dhaka', // +6
                'Asia/Bangkok', // +7
                'Asia/Hong_Kong', // +8
                'Asia/Pyongyang', // +9
                'Australia/Sydney', // +10
                'Asia/Magadan', // +11
            ],
            timezone : ""
        }
    },
    methods : {
        num_format(val)
        {
            var vs = String(val);
            if(vs.length == 1)
                vs = "0" + vs;
            return vs;
        },

        date_input(e)
        {
            this.r_date.datestring = util.getDate(e.target.value).getTime();
            // this.date_raw = new Date(e.target.value).getTime();
            // this.cal_input();
        },

        cal_input(e)
        {
            var datevalue = util.getDate(this.date_raw);
            console.log(this.date_raw)
            var datestring = datevalue.getFullYear() + "-" + this.num_format(datevalue.getMonth() + 1) + "-" + this.num_format(datevalue.getDate());
            if(datestring === "1970-01-01" || datestring === "NaN-NaN-NaN")
            {
                this.date_raw = this.r_date.datestring;
                this.cal_input();
                return;
            }
            this.r_date.datestring = this.date_raw;
        },

        setTimezone() {
            if(this.timezoneData) {
                var utc = Number(this.timezoneData.gmtOffset)
                utc = parseInt(utc / 3600)
                this.timezone = this.timezones[utc + 11]
            }
            else {
                this.timezone = null;
            }
        },
        isHourValid(hour, hourData) {
            if(this.min_date && this.hour_valid) {
                const minDate = util.getDate(this.min_date);
                if(minDate.getFullYear() == hourData.year &&
                    minDate.getMonth() + 1 == hourData.month &&
                    minDate.getDate() == hourData.day) {
                    const hourVaildData = this.hour_valid
                    
                    if(hour <= hourVaildData.max) {
                        return false;
                    }
                }
            }
            return true;
        }
    },
}
</script>

<style scoped>
.calendar_popup {
    position: absolute;
    z-index: 1000;
    width : fit-content;
    height : fit-content;
}
.date_content {
    width: 150px;
    height: 26px;
    border: 1px solid #9c9c9c;
    border-radius: 50px;
    padding-left: 10px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
}
.date_content input {
    position: relative;
    width: 100px;
    height: 19px;
    border: none;
    color: #6c6c6c;
    font-size: 16px;
    outline: none;
    background : rgba(0, 0, 0, 0);
    
}
.date_content img {
    width: 16px;
    height: 16px;
    right: 13px;
    position: absolute;
    user-select: none;
    cursor:pointer;
}

</style>