<template>
    <div class="post-div">
        <PostItem :postId="postId" :isDetail="true" :refreshPost="refresh"/>
    </div>
</template>

<script>
import { computed, inject, onMounted, onUnmounted } from "vue"
import PostItem from "@/page/feed/post"
import { useRoute, useRouter } from "vue-router"
import { usePostStore } from '@/stores/post'

export default {
    components : {
        PostItem,
    },
    setup(props, ctx) {
        const route = useRoute()
        const router = useRouter()
        const postStore = usePostStore()
        
        const modalManager = inject('ModalManager')

        
        const postId = computed(() => {
            return route.query.postno ?? ""
        })
        
        const refresh = async () => {
            return await postStore.fetchPost(postId.value)
        }
        
        onMounted(async () => {
            const fetch_res = await refresh()
            if(!fetch_res) {
                modalManager.showdialog("No Permission")
                .then(res => {
                    router.back()
                })
            }
        })
        onUnmounted(() => {
            postStore.clearPost();
        })

        return {
            postId,
            refresh,
        }
    },
}
</script>

<style scoped>
.post-div{
    max-width: 54.375rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
</style>
