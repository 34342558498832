<template>
    <div class="layout" ref="layout">
        <router-view/>
    </div>
</template>
<script>
import { provide, ref} from "vue"
export default {
    setup(props, ctx) {
        const layout = ref(null)
        provide("layout", layout)
        return {
            layout,
        }
    }
}
</script>

<style scoped lang="scss">
.layout {
    overflow: auto;    
    min-height: 100vh;
    height: 100vh;
    background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(@/assets/background.jpg);
    background-size: cover;
}
</style>