<template>
    <div>
        <div class="searchBoxStyle">
            <div>
                <nationSelector
                    @updateCountry="onUpdateCountry" 
                    :selectedCountries="selectedCountries" 
                    variant="outlined"/>
            </div>
            <div class="typeSelector">
                <v-select v-model="searchType"
                    variant="outlined" hide-details @update:modelValue="onUpdateSearchType"
                    label="Search Field" :items="typeList" item-text="title"
                />
            </div>
            <div>
                <searchBox 
                    ref="searchBox"
                    @onSearch="onSearch" 
                    :useSpecialChar="false" 
                    :input="searchText"
                    :searchType="selectedValue.boxtype"
                    :list="selectedValue.list"
                />
            </div>
        </div>

        <div class="userCheckBox">
            <v-checkbox v-model="userCheck.user" label="Member" hide-details>
            </v-checkbox>
            <v-checkbox v-model="userCheck.notUser" label="Non-Member" hide-details>
            </v-checkbox>
        </div>
        <div v-if="selectedNetworks && selectedNetworks.length > 0">
            <template v-for="user in selectedNetworks" :key="user">
                <div class="networkCartStyle">
                    <networkUserCard :user="user" />
                </div> 
            </template>
            <bottomRefresh :callBack="onScrolling" v-if="isBottomRefresh" class="bottomRefresh"/>
        </div>
        <div v-else class="noScheduleStyle" :style="{ maxWidth: '54.375rem' }" >
            <h1>There is no network.</h1>
        </div>
    </div>
</template>

<script>
import networkUserCard from "@/page/network/networkUserCard.vue"
import bottomRefresh from "@/component/bottomRefresh.vue"
import nationSelector from "@/component/nationSelector.vue"
import searchBox from "@/component/searchBox.vue"
import { useNetworkStore } from "@/stores/network"
import { storeToRefs } from "pinia"
import { computed, onMounted, onUnmounted, ref, watch } from "vue"
import { useUserStore } from '@/stores/user'
import { useRoute, useRouter } from 'vue-router'
import userData from '@/data/userData'
import profileData from '@/data/profileData'

export default {
    components : {
        networkUserCard,
        bottomRefresh,
        nationSelector,
        searchBox,
    },
    setup(props, ctx) {
        const userStore = useUserStore()
        const router = useRouter()
        const route = useRoute()
        const networkStore = useNetworkStore()

        const searchBox = ref(null)
        const fieldList = ref(profileData.fieldData)
        const { user } = storeToRefs(userStore)
        const { networks, totalNetworks } = storeToRefs(networkStore)
        const searchText = ref("")
        const searchType = ref("name")
        const selectedCountries = ref([])
        const typeList = ref([
            {title : "Name", value : "name", boxtype : "text"},
            {title : "Field", value : "field", boxtype : "select", list : fieldList.value},
            // {title : "Email", value : "email"}
        ])
        const selectedValue = ref(typeList.value[0])
        
        const queryKey = ref([
            "name", 
            "field", 
            // "email", 
            "ctr"
        ])

        const userCheck = ref({
            user : true,
            notUser : true,
        })
        const isBottomRefresh = computed(() => {
            if(totalNetworks.value > networks.value.length) {
                return true;
            }
            return false;
        })

        const selectedNetworks = computed(() => {
            if(networks.value) {
                const networkList = networks.value.filter(nw => nw.user_id != user.value.id)
                if(networkList && networkList.length > 0) {
                    return networkList.filter(nw => {
                        if(!userCheck.value.user && nw.user_id) {
                            return false
                        }
                        if(!userCheck.value.notUser && !nw.user_id) {
                            return false
                        }
                        return true;
                    })
                }
            }
            return []
        })

        const onScrolling = async () => {
            await networkStore.moreNetworks()
        }
        const isMe = (network) => {
            return user.value?.id == network?.user_id
        }

        const getParams = () => {
            // [a, b, c] => {a : "",  b : "",  c : ""}
            let params = queryKey.value.reduce((acc, cur) => {
                acc[cur] = ""
                return acc;
            }, {})
            
            // 비트 연산으로 변경.
            params.type = [userData.NETWORK_TYPE.BUDDY ,userData.NETWORK_TYPE.MET].join(",")

            if(searchText.value && searchText.value.trim() && searchType.value) {
                params[searchType.value] = searchText.value
            }
            
            if(selectedCountries.value.length > 0) {
                params.ctr = selectedCountries.value.join(",")
            }
            return params
        }

        const updateUrl = () => {
            let params = getParams()
            delete params.type
            queryKey.value.forEach(key => {
                if(!params?.[key]) {
                    delete params[key]
                }
            })
            router.push({
                query : params
            })
        }

        const onUpdateSearchType = type => {
            searchText.value = ""
            searchBox.value.setInput("")
            selectedValue.value = typeList.value.filter(item => item.value == type)[0]
        }

        const onUpdateCountry = countries => {
            selectedCountries.value = countries;
            updateUrl()
        }

        const onSearch = search => {
            searchText.value = search;
            updateUrl()
        }

        const refresh = async () => {
            if(route.query.name) {
                searchText.value = route.query.name;
                selectedValue.value = typeList.value.filter(item => item.title == "Name")[0]
                searchType.value = "name"
            } else if(route.query.field) {
                searchText.value = route.query.field;
                selectedValue.value = typeList.value.filter(item => item.title == "Field")[0]
                searchType.value = "field"
            }
            else {
                searchText.value = "";
            }
            
            if(route.query.ctr) {
                const countries = route.query.ctr.split(",")
                selectedCountries.value = countries;
            }
            else{
                selectedCountries.value = [];
            }
            await networkStore.fetchNetworks(getParams())
        }

        watch(() => route.query, async () => {
            if(route.path.includes("network/my")) {
                await refresh()
            }
        })

        onMounted(async () => {
            await refresh()
        })

        onUnmounted(async () => {
            networkStore.clearNetworks()
        })

        return {
            selectedNetworks,
            isBottomRefresh,
            userCheck,
            typeList,
            selectedValue,
            searchType,
            selectedCountries,
            searchText,
            searchBox,

            onUpdateSearchType,
            onScrolling,
            onUpdateCountry,
            onSearch,
            isMe,
        }
    },
}
</script>

<style scoped lang="scss">

.searchBoxStyle {
    display: flex;
    max-width: 54.375rem;
    > div {
        width: 100%;
        margin: 0 5px;
        height: fit-content;
        &.typeSelector {
            display: flex;
            width: 230px;
        }
    }
}
.userCheckBox {
    width: 250px;
    display: flex;
}
.networkCartStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    width: 54.375rem;
    margin-bottom: 20px;
}
</style>