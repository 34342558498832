<template>
    <div>
        <dataTable 
            :tableData="tableData"
            :init_page="init_page"
            :totalCount="totalCount" 
            v-show="tableData?.content?.length > 0 ? true : false"
            @onPageUpdate="onPageUpdate"
            @sorting="sorting"
        >
            <template v-slot:country="data">
                <img :src="util.getCountryFlag(data.country_iso2)"
                    style="marginRight:10px;">
                {{data.country_name}}
            </template>
        </dataTable>
        <template v-if="tableData?.content?.length < 1">
            <div class="noScheduleStyle" :style="{ maxWidth: '54.375rem' }" >
                <h1>There is no events.</h1>
            </div>
        </template>
    </div>
</template>
<script>
import dataTable from "@/component/dataTable"
import { computed, onMounted, onActivated, onDeactivated, ref, inject, watch, compile, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useEventStore } from '@/stores/event'
import { storeToRefs } from 'pinia'
import util from '@/data/util'
export default {
    props : {
        eventList : Object,
        component : String,
        isActivte : Boolean,
    },
    components : {
        dataTable
    },
    setup(props, ctx) {
        const router = useRouter()
        const route = useRoute()
        const modalManager = inject("ModalManager")
        const eventStore = useEventStore()
        const {events, totalCount} = storeToRefs(eventStore)
        const init_page = ref(1)
        const reverse = ref(false)
        const orders = ref([])
        
        const convertDate = (date) => {
            return util.date_format(util.convertTimeStmapToDateTime(date), "YYYY-MM-DD")
        }

        const tableData = computed(() => {
            return {
                header : [
                    {name : "Title", value : "title", order: ['title'], fixed : true},
                    {name : "Country" , value : "country", order: ['country_name'], slot : true},
                    {name : "Start", value : "start", order: ['start_date']},
                    {name : "End", value : "end", order: ['end_date']},
                    // {name : "TimeZone", value : "timezone"},
                    {name : "Schedule" , value : "meeting_count", order: ['meeting_count']},
                ],
                content : events.value?.map(
                    item => {
                        const timezoneData = util.convertTimezoneJsonToData(item.country_timezones)
                        const period = `${convertDate(item.start_date)} ~ ${convertDate(item.end_date)}
                        ${timezoneData.gmtOffsetName ? `(${timezoneData.gmtOffsetName})` : ""}`
                        
                        const event_end_date = util.getDate(convertDate(item.end_date))
                        const current_date = util.getDate(util.date_format(util.getCurrentDate(), 'YYYY-MM-DD'))
                        const expire_check = current_date > event_end_date
                        return {
                            ...item,
                            title : item.title,
                            // period : period,
                            start : convertDate(item.start_date),
                            end : convertDate(item.end_date),
                            timezone : timezoneData.gmtOffsetName ? `(${timezoneData.gmtOffsetName})` : "",
                            meeting_count : item.meeting_count,
                            callback : onDetail(item.id),
                            style : {background : expire_check ? "#EEE" : "#FFF"}
                        }
                    }
                ),
                column : orders.value,
                reverse: reverse.value
            }
        })
        const onDetail = (id) => {
            return () => {
                router.push({
                    path : "/meeting/event/detail",
                    query : {
                        eventno : id
                    }
                })
            }
        }

        const sorting = (order) => {
            orders.value = order
            // eventStore.fetchEvents({ page: route.query.value, order: orders })
            router.push({
                path : route.path,
                query : {
                    ...route.query,
                    order : order
                }
            })
        }

        const onPageUpdate = (page) => {
            // eventStore.fetchEvents({
            //     page : page,
            //     order: orders.value
            // })
            router.push({
                path : route.path,
                query : {
                    ...route.query,
                    page : page
                }
            })
        }

        const init = async() => {
            init_page.value = 1
            orders.value = []
            if(route.query.page && !isNaN(parseInt(route.query.page))) {
                if(route.query.page < 1) {
                    modalManager.showdialog(`The page starts with 1. Not ${route.query.page}`).then(res => {
                        router.back()
                    })
                }
                init_page.value = Number(route.query.page)
            }
            if(route.query.order) {
                if(typeof route.query.order == 'string') {
                    orders.value = [route.query.order]
                } else {
                    orders.value = route.query.order
                }
            }
            else {
                orders.value = ["-end_date"]
            }
            await eventStore.fetchEvents({ page: route.query.page, order: orders.value })
        }

        watch(() => route.query, async() => {
            if(!props.isActivte) {
                return;
            }
            await init()
        })

        onActivated(async () => {
            await init()
        })

        onDeactivated(() => {
            eventStore.clearEvents()
        })

        return {
            tableData,
            totalCount,
            init_page,
            util,

            onDetail,
            onPageUpdate,
            sorting,
        }
    },
}
</script>

<style scoped lang="scss">
</style>