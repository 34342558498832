import storeData from "@/stores/storeData";
import { ApiV2Service, ApiError } from "./api.v2.service";

const PostService = {
    createPost: async function(contents) {
        const response = await ApiV2Service.post('/post', { contents: contents })

        if (response.data.success) {
            return response.data.data
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    fetchPosts: async function(name = '', content = '', country = '', page = 1, perPage = storeData.default_feed_per_page) {
        const response = await ApiV2Service.get('/posts', { writ: name, cont: content, ctr: country, page: page, per_page: perPage })

        if (response.data.success) {
            return {
                data : response.data.data,
                count : response.headers["x-total-count"],
                link : response.headers.link,
            }
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    fetchPost: async function(postId) {
        const response = await ApiV2Service.get(`/post/${postId}`)

        if (response.data.success) {
            return response.data.data
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    updatePost: async function(postId, contents) {
        const response = await ApiV2Service.put(`/post/${postId}`, { contents: contents })

        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    deletePost: async function(postId) {
        const response = await ApiV2Service.delete(`/post/${postId}`)

        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    createComment: async function(postId, contents, parentCommentId = null) {
        const data = {
            contents: contents
        }

        if (parentCommentId) {
            data.parent_comment_id = parentCommentId
        }

        const response = await ApiV2Service.post(`/post/${postId}/comment`, data)

        if (response.data.success) {
            return response.data.data
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    fetchComments: async function(postId) {
        const response = await ApiV2Service.get(`/post/${postId}/comments`)

        if (response.data.success) {
            return response.data.data
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    updateComment: async function(postId, commentId, contents) {
        const response = await ApiV2Service.put(`/post/${postId}/comment/${commentId}`, { contents: contents })

        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    deleteComment: async function(postId, commentId) {
        const response = await ApiV2Service.delete(`/post/${postId}/comment/${commentId}`)

        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    setLike: async function(postId, isLike) {
        let response

        if (isLike) {
            response = await ApiV2Service.post(`/post/${postId}/like`)
        }
        else {
            response = await ApiV2Service.delete(`/post/${postId}/like`)
        }

        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },
}

export default PostService