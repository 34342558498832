<template>
    <div class="empty">
    </div>    
</template>
<script>
export default { 
    name : "emptyComponent"
}
</script>

<style scoped>
.empty { 
    display: flex;
}
</style>