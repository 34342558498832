import NetworkMainPage from "@/page/network/networkMainPage.vue"
import NetworkLayout from "@/page/network/networkLayout.vue"
import NetworkBrowse from "@/page/network/networkBrowse.vue"
import NetworkFollow from "@/page/network/networkFollow.vue"

export default [
    {
        path : "/network",
        redirect : "/network/my",
        component : NetworkLayout,
        children : [
            {
                path : "/network/my",
                component : NetworkMainPage,
            },
            {
                path : "/network/browse",
                component : NetworkBrowse
            },
            {
                path : "/network/follow",
                component : NetworkFollow
            },
        ]
    },
]