<template>
    <div class="boxStyle" >
        <div class="innerBoxStyle">
            <v-form id="event-create-form" @submit.prevent="onSubmit">
                <div class="eventSeletor-div">
                    <h3>
                        Event Title
                    </h3>
                    <v-text-field v-model="form.title" hide-details label="Event Title"/>
                </div>
                <div class="eventSeletor-div">
                    <h3>
                        Country
                    </h3>
                    <nationSelector @updateCountry="onUpdateCountry" :isShowNationImg="false" :oneSelect="true" :selectedCountries="selectedCountry"/>
                </div>
                <div class="eventSeletor-div">
                    <h3>
                        Start Date
                    </h3>
                    <dayPicker v-model:date="form.start_date" class="day-picker" :available_date="availableDate"
                        :mode="pickerMode" :minute_increment="scheduleData.minStamp" :min_date="minDate" :hour_valid="timeValidData" /> 
                </div>
                <div class="eventSeletor-div">
                    <h3>
                        End Date
                    </h3>
                    <dayPicker v-model:date="form.end_date" class="day-picker" :available_date="availableDate"
                        :mode="pickerMode" :minute_increment="scheduleData.minStamp" :min_date="minDate" :hour_valid="timeValidData" />
                </div>
                <div class="eventSeletor-div">
                    <h3>
                        Preferred Meeting Place
                    </h3>
                    <v-text-field v-model="inputLocation" @keydown.enter.prevent="onAddLocation" hide-details append-icon="mdi-plus-circle" @click:append="onAddLocation" label="Preferred Meeting Place" />
                    <div class="location-list">
                        <div>
                            <div v-for="location in form.location" :key="location" class="location-item" @click="onDeleteLocation(location)">
                                {{ location }}
                                <v-icon>mdi-close</v-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="eventSeletor-div">
                    <h3>
                        Default Comment
                    </h3>
                    <v-text-field v-model="form.comment" hide-details label="Default Comment"/>
                </div>
                <div class="buttons-div">
                    <v-btn form="event-create-form" class="buttonStyle" type="submit">
                        {{isEdit ? "EDIT" : "CREATE"}}
                    </v-btn>
                    <v-btn class="buttonStyle close" @click="onClose">
                        CLOSE
                    </v-btn>
                </div>
            </v-form>
        </div>
    </div>
</template>
<script>
import dayPicker from "@/component/dayPicker.vue"
import util from "@/data/util"
import nationSelector from "@/component/nationSelector.vue"
import scheduleData from "@/data/scheduleData"
import { computed, getCurrentInstance, inject, onMounted, ref } from 'vue'
import { useEventStore } from '@/stores/event'
import { useCountryStore } from '@/stores/country'
import { storeToRefs } from 'pinia'
export default {
    components : {
        dayPicker,
        nationSelector,
    },
    props: {
        conference : Object,
        isEdit : {
            type : Boolean,
            default : false,
        },
        eventData : {
            type : Object,
            default : null,
        },
        startDate : [String, Number, Date],
        endDate : [String, Number, Date]
    },
    setup(props, ctx) {

        const form = ref({
            title : "",
            start_date : {
                datestring : ""
            },
            end_date : {
                datestring : ""
            },
            location : [],
            comment : "",
            country : "",
        })

        const countryStore = useCountryStore()
        const {countries} = storeToRefs(countryStore)
        const inputLocation = ref("")
        const pickerMode = ref("date")
        const modalManager = inject("ModalManager")
        const currentInstance = getCurrentInstance()
        const eventStore = useEventStore()
        const selectedCountry = ref([])
        const selectedCountryData = ref({})
        const minDate = ref(null)
        const availableDate = ref(null)
        const timeValidData = ref(null)

        const timezoneData = computed(() => {
            return util.convertTimezoneJsonToData(selectedCountryData.value?.timezones)
        })

        const onUpdateCountry = async (country) => {
            if(!countries.value) {
                await countryStore.fetchCountries()
            }
            if(country && country.length > 0) {
                form.value.country = country[0]
                selectedCountryData.value = countries.value.filter(c => c.id == form.value.country)[0]
                setDisableDateandTime()
            }
            else {
                form.value.country = ""
            }
        }

        const onAddLocation = () => {
            const location = inputLocation.value.trim()
            if (location && form.value.location.indexOf(location) === -1) {
                form.value.location.push(inputLocation.value.trim())
                inputLocation.value = ""
            }
        }

        const onDeleteLocation = (location) => {
            const index = form.value.location.indexOf(location)
            if (index !== -1) {
                form.value.location.splice(index, 1)
            }
        }

        const setDisableDateandTime = () => {
            if(selectedCountryData.value) {
                const currentDateData = util.getCurrentDate(timezoneData.value.gmtOffset)
                minDate.value = currentDateData;
                timeValidData.value = {
                    min : 0, max : currentDateData.getHours(),
                }
                if(props.conference) {
                    const conference_start_date = util.convertTimeStmapToDateTime(props.conference.start_date)
                    const conference_end_date = util.convertTimeStmapToDateTime(props.conference.end_date)
                    const current_date = util.getDate(util.date_format(currentDateData, "YYYY-MM-DD"))
                    availableDate.value = {
                        start : Math.max(conference_start_date.getTime(), current_date.getTime()),
                        end : conference_end_date.getTime(),
                    }
                    minDate.value = null;
                }
            }
        }

        const checkContent = () => {
            const currentDate = util.getCurrentDate(timezoneData.value.gmtOffset)
            let req = [
                {value : form.value.title, text : "title"},
                {value : form.value.title, check : form.value.title.trim(), text : "title"},
                {value : form.value.start_date.datestring , text : "start date and time"},
                {value : form.value.end_date.datestring , text : "end date and time"},
                {value : form.value.country , text : "country"},
                {value : form.value.start_date.datestring && form.value.end_date.datestring,
                    check : getOnlyDate(form.value.start_date.datestring).getTime() <= getOnlyDate(form.value.end_date.datestring).getTime(),
                    text : "end time is less than or equal to start time"},
                {value : form.value.start_date.datestring && form.value.end_date.datestring,
                    check : getOnlyDate(form.value.start_date.datestring).getTime() >= getOnlyDate(currentDate).getTime(),
                    text : "start date is earlier than the current time."}
            ]
            if(props.isEdit) {
                req.push({
                    value : form.value.start_date.datestring && 
                        form.value.end_date.datestring &&
                        form.value.title,
                    check : 
                        !(getOnlyDate(form.value.start_date.datestring).getTime() == getOnlyDate(util.convertUTSTOJTS(props.eventData.start_date)).getTime() && 
                        getOnlyDate(form.value.end_date.datestring).getTime() == getOnlyDate(util.convertUTSTOJTS(props.eventData.end_date)).getTime() && 
                        form.value.title == props.eventData.title && form.value.comment == props.eventData.comment &&
                        JSON.stringify(form.value.location) == props.eventData.location),
                    text : "This is same data"
                })
            }
            
            const res = util.dataCheck(req)
            if(res.length > 0) {
                return res.join("<br>")
            }
            return false
        }
        const onSubmit = async () => {
            let res = checkContent()
            if(res) {
                modalManager.showdialog(`Please check your data: <br>${res}`)
                return;
            }
            if(inputLocation.value) {
                modalManager.showdialog(``)
            }
            let params = {
                title : form.value.title,
                start_date : util.convertJTSToUTS(getOnlyDate(form.value.start_date.datestring), 0, false),
                end_date : util.convertJTSToUTS(getOnlyDate(form.value.end_date.datestring), 0, false) + (24 * 3600 - 1),
                location : JSON.stringify(form.value.location),
                comment : form.value.comment,
                country : form.value.country,
            }
            // const title = form.value.title
            // const start_date = util.convertJTSToUTS(form.value.start_date.datestring)
            // const end_date = util.convertJTSToUTS(form.value.end_date.datestring)
            
            let successText = "Created"
            if(props.isEdit) {
                res = await eventStore.updateEvent(props.eventData.id, params)
                successText = "Editted"
            } else {
                if (props.conference) {
                    Object.assign(params, { conference_id: props.conference.id })
                }
                res = await eventStore.createEvent(params)
            }
            
            if(res) {
                modalManager.close(currentInstance,{success : true})
                modalManager.showdialog(`${successText}`)
            }
            else {
                modalManager.showdialog(`Failed`)
            }
        }

        const onClose = () => {
            modalManager.close(currentInstance)
        }

        const getOnlyDate = date => {
            return util.getDate(util.date_format(date, "YYYY-MM-DD"))
        }

        const init = async () => {
            setDisableDateandTime()
            form.value.start_date.datestring = getOnlyDate(util.getCurrentDate()).getTime()
            form.value.end_date.datestring = getOnlyDate(util.getCurrentDate()).getTime()
            if(props.startDate && props.endDate) {
                form.value.start_date.datestring = Math.max(form.value.start_date.datestring, props.startDate)
                form.value.end_date.datestring = Math.max(form.value.end_date.datestring, props.endDate)
                let errorText = ""
                if(minDate.value.getTime() - (24 * 3600 * 1000) > props.startDate) {
                    errorText = "The selected period is earlier than today.<br>The date changes to today's date."
                }
                if(errorText) {
                    modalManager.showdialog(errorText)
                }
            }
            else if(props.isEdit) {
                form.value.title = props.eventData.title
                if (props.eventData.location) {
                    try {
                        const location = JSON.parse(props.eventData.location)
                        if (Array.isArray(location)) {
                            form.value.location = location
                        }
                    } catch(e) {
                        form.value.location = []
                    }
                }
                form.value.comment = props.eventData.comment
                if(props.eventData.country_id) {
                    await onUpdateCountry(props.eventData.country_id)
                    selectedCountry.value = [props.eventData.country_id]
                }
                const current_timestamp = util.getDate(getOnlyDate(util.getCurrentDate(timezoneData.value?.gmtOffset)), 0).getTime()
                form.value.start_date.datestring = Math.max(current_timestamp, util.convertUTSTOJTS(props.eventData.start_date))
                form.value.end_date.datestring = Math.max(current_timestamp, util.convertUTSTOJTS(props.eventData.end_date))
            } else if (props.conference) {
                form.value.title = props.conference.title
                if(props.conference.country_id) {
                    await onUpdateCountry(props.conference.country_id)
                    selectedCountry.value = [props.conference.country_id]
                }
                if(props.conference.location) {
                    form.value.location.push(props.conference.location)
                }
                const current_timestamp = util.getDate(getOnlyDate(util.getCurrentDate(timezoneData.value?.gmtOffset)), 0).getTime()
                form.value.start_date.datestring = Math.max(current_timestamp, util.convertUTSTOJTS(props.conference.start_date))
                form.value.end_date.datestring = Math.max(current_timestamp, util.convertUTSTOJTS(props.conference.end_date))

                // 컨퍼런스가 이미 시작되어 있는 경우,
                if((form.value.start_date.datestring != util.convertUTSTOJTS(props.conference.start_date))) {
                    modalManager.showdialog("The conference has started.<br>The start time changes to the current time.")
                }
            }
        }

        onMounted(async () => {
            init()  
        })

        return {
            form,
            inputLocation,
            pickerMode,
            util,
            selectedCountry,
            timezoneData,
            minDate,
            timeValidData,
            scheduleData,
            availableDate,

            onUpdateCountry,
            onAddLocation,
            onDeleteLocation,
            onSubmit,
            onClose,
        }
    }
}
</script>

<style scoped lang="scss">
.boxStyle {
    display : flex;
    align-items : center;
    justify-content : center;
    background: #fff;
    border-radius: 1rem;
    .innerBoxStyle {
        padding : 50px;
        width : 393px;
        .eventSeletor-div {
            width : 100%;
            margin-bottom: 1.3125rem;
            .day-picker:deep(.date_content) {
                width: 293px;
                height: 43px;
            }
            .day-picker:deep(.date_content input) {
                width: 293px;
                height: 43px;
            }
            .location-list {
                width: 100%;
                margin-top: 5px;
                > div {
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    overflow-x: none;
                    display: flex;
                    flex-wrap: wrap;
                    align-content: flex-start;
                    .location-item {
                        word-break: break-all;
                        display: flex;
                        align-items: center;
                        margin-right: 0.5rem;
                        margin-top: 0.25rem;
                        color: #fff;
                        padding: 0.25rem 0.5rem;
                        border-radius: 50px;
                        font-size: 0.875rem;
                        background: #1E3560;
                        font: normal normal 400 16px/18px NanumSquareOTF;
                        cursor: pointer;

                        > i {
                            // border: 1px solid #666;
                            border: none;
                            padding-left: 3px;
                            border-radius: 10px;
                        }
                    }

                    &::-webkit-scrollbar {
                        width: 10px;
                    }
                    &::-webkit-scrollbar-thumb {
                        background-color: #9c9c9c;
                    }
                    &::-webkit-scrollbar-track {
                        background-color: #d9d9d9;
                    }
                }
            }
        }
        .buttons-div {
            display: flex;
            .buttonStyle {
                border: none;
                width: 48%;
                margin: 0 5px;
                height: 43px;
                font: 14px NanumSquareOTF;
                font-weight: 500;
                color: white;
                cursor: pointer;
                background: #910457;
                &.close {
                    background: #6c6c6c;
                }
            }
        }
        h3 {
            margin: 0;
            margin-bottom: 5px;
            font: normal normal 800 16px/18px NanumSquareOTF;
            color: #6c6c6c;
        }
    }
}
</style>