<template>
    <div class="headerStyle">
        <v-btn class="downloadStyle" @click="downloadExcel" :disabled="tableDatas?.content?.length == 0">
            Download<v-icon>mdi-file-excel</v-icon>
        </v-btn>
    </div>
    <div>
        <dataTable 
            :tableData="tableDatas"
            :init_page="init_page"
            :totalCount="postTotalCount"
            @onPageUpdate="onPageUpdate"
            @sorting="sorting"
        >
            <template v-slot:contents="data">
                <div class="contentStyle">
                    {{util.removeTag(data.contents) ? util.removeTag(data.contents) : "-"}}
                </div>
            </template>
        </dataTable>
    </div>
</template>

<script>
import adminTableModal from "@/modal/adminTableModal.vue"
import dataTable from "@/component/dataTable"
import { useAdminStore } from '@/stores/admin'
import { usePostStore } from "@/stores/post"
import { storeToRefs } from "pinia"
import { computed, onMounted, inject, ref, onActivated, watch } from "vue"
import { useRoute, useRouter } from "vue-router"
import util from '@/data/util'
import * as XLSX from 'xlsx'

export default {
    components : {
        dataTable,
    },
    setup(props, ctx) {
        const modalManager = inject("ModalManager")

        const adminStore = useAdminStore()
        const postStore = usePostStore()
        const route = useRoute()
        const router = useRouter()

        const { adminPosts, postTotalCount } = storeToRefs(adminStore)

        const init_page = ref(1)
        const reverse = ref(false)
        const orders = ref([])

        const downloadExcel = async() => {    
            const data = tableData(await adminStore.getPosts({xlsx: true, order: ['id']}))
            let jsonData = []
            data?.content.forEach(elem => {
                let a = {}
                data?.header.forEach(item => {
                    elem.contents = util.removeTag(elem.contents) ? util.removeTag(elem.contents) : "-"
                    a[item.name] = elem?.[item.value] ?? '-'
                })
                jsonData.push(a)
            })

            const workBook = XLSX.utils.book_new()
            const tbData = XLSX.utils.json_to_sheet(jsonData)
            XLSX.utils.book_append_sheet(workBook, tbData, 'PostData')
            XLSX.writeFile(workBook, 'PostData.xlsx')
        }

        const onDetail = (item) => {
            router.push({ path : "/feed/detail", query : { postno : item.id } })
        }
        const onDelete = (item) => {
            postStore.deletePost(item.id)
        }
        const onCreate = () => {

        }
        const onUpdate = (item) => {
            router.push({ path: '/feed/edit', query: { postno: item.id } })
            modalManager.showdialog("Not Working....")
            console.log(item)
        }
        const onCallback = (item) => {
            return () => {
                modalManager.showEx(adminTableModal,
                {
                    bind : {
                        title : `Management Feed<br> No : ${item.id}`,
                        data : item,
                        value : "feed"
                    },
                    on : {
                        read : onDetail,
                        delete : onDelete,
                        update : onUpdate,
                    }
                }, true)
            }
        }

        const tableData = (data) => {
            return {
                header : [
                    {name : "No", value : "id", order: ['id']},
                    {name : "Writer Name", value : "writer_name", order: ['writer_name']},
                    {name : "Writer Email", value : "writer_email", order: ['writer_email']},
                    {name : "Comment Count", value : "comment_count", order: ['comment_count']},
                    {name : "Like Count", value : "like_count", order: ['like_count']},
                    {name : "Post", value : "contents", slot : true},
                    {name : "Created", value : "created_at", order: ['created_at']},
                ],
                content : 
                    data?.map(item => {
                        return {
                            ...item,
                            callback : onCallback(item),
                            created_at: toFormatString(item.created_at)
                        }
                    }),
                column : orders.value,
                reverse: reverse.value
            }
        }

        const tableDatas = computed(() => {
            return tableData(adminPosts.value)
        })

        const sorting = (order) => {
            orders.value = order
            adminStore.getPosts({ page: route.query.page, order: order })
            router.push({
                path : route.path,
                query : {
                    ...route.query,
                    order : order
                }
            })
        }

        const toFormatString = (timestamp) => {
            return util.date_format(util.convertTimeStmapToDateTime(timestamp), 'YYYY-MM-DD hh:mm')
        }
        
        const onPageUpdate = (page) => {
            adminStore.getPosts({
                page : page,
                order: orders.value
            })
            router.push({
                path : route.path,
                query : {
                    ...route.query,
                    page : page
                }
            })
        }

        const init = async() => {
            init_page.value = 1
            orders.value = []

            if(route.query.page) {
                init_page.value = Number(route.query.page)
            }
            if(route.query.order) {
                if(typeof route.query.order == 'string') {
                    orders.value = [route.query.order]
                } else {
                    orders.value = route.query.order
                }
            }
            
            await adminStore.getPosts({ page: route.query.page, order: orders.value })
        }

        onMounted(async () => {
            await init()
        })

        watch(() => route.query, async() => {
            if(route.path.includes("feed")) {
                await init()
            }
        })

        return {
            tableData,
            util : util,
            init_page,
            postTotalCount,
            tableDatas,

            sorting,
            onPageUpdate,
            downloadExcel
        }
    },
}
</script>

<style scoped lang="scss">
.contentStyle {
    max-width: 300px;
    height: 40px;
    text-align:center;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    &:deep(*) {
        text-overflow:ellipsis;
        overflow: hidden;
        height: 100%;
    }
}
.headerStyle {
    max-width: 76.25rem;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
    
    .downloadStyle {
        background-color: #9C9C9C;
        color: white;
        border-radius: 50px;
        &:hover {
            background-color: #1E3560;
        }
        i {
            padding-left: 10px;
        }
    }
}
</style>