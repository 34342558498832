<template>
    <dataTable 
        v-model:tableData="tableData"
        :init_page="init_page"
        :totalCount="totalCount" 
        v-if="tableData?.content?.length > 0" 
        @onPageUpdate="onPageUpdate"
        @sorting="sorting"
    >   
        <template v-slot:title="data"> 
            <div class="titleContainer">
                <span class="title" 
                    :style="{maxWidth : `calc(810px / ${tableData.header?.length})`}">
                    {{data.title}} 
                </span>
                <span class="code">
                    {{`(${data?.country_iso2})`}}
                </span>
            </div>
        </template>
        <template v-slot:country="data"> 
            <div>
                <img :src="util.getCountryFlag(data.country_iso2)">
                {{data.country_name}}
            </div>
        </template>
        <template v-slot:statusText="data">
            <div class="statusContainer">
                <div :class="[data?.statusText?.class]" class="status">
                    {{data?.statusText?.text}}
                </div>
            </div>
        </template>
        <template v-slot:owner="data">
            <div class="owner">
                <div v-if="data.isMine">
                    <div class="mineIcon">
                        ME
                    </div>
                    <v-icon>mdi-arrow-right</v-icon>
                    <div class="other">
                        {{data.others[0].name ?? data.others[0].email ?? `OTHER`}} {{data.others[0].data?.country_iso2 ? `(${data.others[0].data?.country_iso2})` : ``}}
                    </div>
                </div>
                <div v-else>
                    <div>
                        ME 
                    </div>
                    <v-icon>mdi-arrow-left</v-icon>
                    <div class="mineIcon other">
                        {{data.others[0].name ?? data.others[0].email ?? `OTHER`}} {{data.others[0].data?.country_iso2 ? `(${data.others[0].data?.country_iso2})` : ``}}
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:trContent="data" >
            <Transition mode="in-out" name="slide">
                <tr @click="onDetail(data)" v-if="selectedData.id == data.id" class="trContentStyle">
                    <td colspan="100%">
                        <div>
                            <div>
                                <h3>
                                    Participants
                                </h3>
                                <div v-for="recipient in data?.recipient" :key="recipient" class="recipientContentStyle" >
                                    <div>
                                        <div v-if="isOwner(data, recipient?.id)" class="owner">Invitor</div>
                                        <div v-if="isMe(recipient?.id)" class="me">Me</div>
                                        <profileImage :src="recipient?.data?.profile_image" :size="40"/>
                                        <!-- 현재 참가자 profile image는 없음. 그냥 디폴트 이미지 보여주는 용도 -->
                                        <span>{{recipient?.name ?? "Non-member"}}</span> | <span>{{recipient?.email}}</span> 
                                    </div>
                                    <div>
                                        <img :src="require('@/assets/company.png')" class="imageStyle">
                                        {{recipient?.company ?? "-"}}
                                        <img :src="require('@/assets/country.png')" class="imageStyle">
                                        {{recipient?.data?.country_name ?? "-"}}
                                    </div>
                                </div>
                                <div v-if="data?.recipient.length > maxRecipientCount" class="recipientContentStyle more">
                                    More...
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </Transition>
        </template>
    </dataTable>
    <template v-else>
        <div class="noScheduleStyle" :style="{ maxWidth: '54.375rem' }" >
            <h1>We have not met yet.</h1>
        </div>
    </template>
</template>
<script>
import dataTable from "@/component/dataTable"
import util from '@/data/util'
import { useRoute, useRouter } from 'vue-router'
import { computed, onMounted, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import scheduleData from '@/data/scheduleData'
import { useUserStore } from '@/stores/user'
import profileImage from '@/component/profileImage.vue'
export default {
    components : {
        dataTable,
        profileImage,
    },
    props : {
        scheduleTableData : Object,

    },
    setup(props, ctx) {
        const router = useRouter()
        const route = useRoute()
        const userStore = useUserStore()
        const totalCount = ref(0)
        const schedules = ref([])
        const {user} = storeToRefs(userStore)
        const search = ref("")
        const selectedData = ref({})
        const init_page = ref(1)
        const maxRecipientCount = ref(5)
        const pageing = ref(1)
        const orders = ref([])
        const reverse = ref(false)

        const tableData = computed(() => {
            return {
                header : [
                    {name : 'Title', value : 'title', order: ['title'], slot : true},
                    {name : 'Date', value : 'date', order: ['start_time', 'end_time'], depdc: 'time'},
                    {name : 'Time', value : 'time', order: ['start_time', 'end_time']},
                    // {name : 'Country', value : 'country', order: ['country_name'], slot : true},
                    {name : 'Meeting Place', value : 'location', order: ['location']},
                    {name : 'Participants', value : 'owner', slot : true},
                    {name : 'Status', value : 'statusText', slot : true},
                ],
                content : schedules.value?.map(
                    item => {
                        const recipient = JSON.parse(item.participants)
                        const date = item.date ? util.date_format(util.convertTimeStmapToDateTime(item.date), "YYYY-MM-DD") : ""
                        const start_time = item.start_time ? util.date_format(util.convertTimeStmapToDateTime(item.start_time), "HH:mm") : ""
                        const end_time = item.end_time ? util.date_format(util.convertTimeStmapToDateTime(item.end_time), "HH:mm") : ""
                        const isMine = item.owner_id == user?.value.id
                        let timezone ={}
                        try {
                            if(item.country_timezones) {
                                timezone = util.convertTimezoneJsonToData(item.country_timezones)
                            }
                        }
                        catch(error) {
                            console.log(error)
                        }
                        return {
                            ...item,
                            isMine : isMine,
                            recipient : util.sortObj(recipient, false, reci => reci.id == item.owner_id ? 1 : -1, reci => reci.name),
                            others : recipient.filter(reci => reci.id != user.value.id),
                            title : item.title,
                            owner_name : item.owner_name,
                            owner_email : item.owner_email,
                            date : date ? date : scheduleData.noSpecified,
                            time : start_time && end_time ? `${start_time} ~ ${end_time}` : scheduleData.noSpecified,
                            country : item.country_name,
                            location : item.location ? item.location : scheduleData.noSpecified,
                            statusText : scheduleData.getMStatusText(item.status),
                            callback : onShowRecipient,
                        }
                    }
                ),
                // column : orders.value,
                reverse: reverse.value
            }
        })
        const onShowRecipient = (data) => {
            if(selectedData.value?.id != data?.id) {
                selectedData.value = data
            }
            else {
                onDetail(data)
            }
        }
        const onDetail = (data) => {
            // console.log(data)
            router.push({
                path : "/meeting/schedule/detail",
                query : {
                    scheduleno : data.id
                }
            })
        }

        const isOwner = (schedule, id) => {
            if(id && schedule?.owner_id && id == schedule?.owner_id) {
                return true
            }
            return false
        }
        const isMe = (id) => {
            if(id && user.value && user.value?.id == id) {
                return true
            }
            return false
        }

        const onPageUpdate = (page) => {
            selectedData.value = {}
            schedules.value = props.scheduleTableData.slice((page - 1)* 15, page * 15 )
            pageing.value = page;
        }

        const sorting = (header) => {
            selectedData.value = {}
            if(!header[0].includes('-')) {
                reverse.value = true
            } else {
                reverse.value = false
                header.forEach((item, idx) => {
                    header[idx] = item.replace('-', '')
                });
            }
            // orders.value = header
            const page = pageing.value ?? 1
            util.sortObj(props.scheduleTableData, reverse.value, ...header.map(h => item => item?.[h]))
            schedules.value = props.scheduleTableData.slice((page - 1) * 15, page * 15 )
        }

        onMounted(async() => {
            if(!user.value) {
                await userStore.fetchMe()
            }
            selectedData.value = {}
            if(props.scheduleTableData) {
                schedules.value = props.scheduleTableData.slice(0, 15)
                totalCount.value = props.scheduleTableData.length
            }
        })

        watch(() => props.scheduleTableData, () => {
            if(props.scheduleTableData) {
                schedules.value = props.scheduleTableData.slice(0, 15)
                totalCount.value = props.scheduleTableData.length
            }
        })

        return {
            tableData,
            totalCount,
            init_page,
            selectedData,
            maxRecipientCount,
            util,

            onDetail,
            onPageUpdate,
            sorting,
            isOwner,
            isMe,
        }
    },
}
</script>

<style scoped lang="scss">
.statusContainer {
    display: flex;
    justify-content: center;
    width : 100%;
    .status {
        border-radius: 15px;
        font-size: 14px;
        padding : 0 10px;
        width: fit-content;
    }
}
.trContentStyle {
    background: #EEEEEE;
    width: 100%;
    cursor: pointer;
    transform-origin: top;
    transition: transform .4s ease-in-out;
    td {
        h3 { 
            padding: 3px 20px;
        }
        width: 100%;
        >div {
            width: 100%;
            display: flex;
            flex-direction: column;
        }
        .recipientContentStyle {
            border-top: 1px solid #9c9c9c;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            color: #111;
            &.more {
                color: #910428EE;
            }
            span {
                margin: 0 10px;
            }
            > div {
                display: flex;
                align-items : center;
                .imageStyle {
                    margin: 0 5px;
                    width: 30px;
                }
            }
            .owner {
                margin-right: 5px;
                border-radius: 10px;
                padding: 0 5px;
                background: #910457;
                color: #fff;
            }
            .me {
                margin-right: 5px;
                border-radius: 10px;
                padding: 0 5px;
                background: #484848;
                color: #fff;
            }
        }
    }
}
.titleContainer {
    display: flex;
    justify-content: center;
    .title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .code {
        margin-left: 5px;
    }
}
.owner {
    display: flex;
    justify-content: center;
    div {
        display: flex;
        .mineIcon {
            height: fit-content;
            padding : 0 5px;
            border-radius: 10px;
            background: #910457;
            color : #fff;
        }
        .other {
            display: block;
            max-width: 60px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

</style>