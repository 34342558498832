<template>
    <div class="comment-div">
        <div class="profile-div">
            <profile-image :src="profileImage" :size="40"/>
        </div>
        <div>
            <div class="input-div">
                <div>
                    <div class="userInfo-div" v-if="commentData?.writer_name">
                        <div class="username">
                            {{ commentData?.writer_name }}
                        </div>
                        <div class="timeStyle" v-if="!isInputMode">
                            {{dateDiff}} 
                        </div>
                    </div>
                    <div v-if="!isInputMode" v-html="html" class="commentContent" @click="imgClick"/>
                </div>
                <div v-if="isInputMode" class="textarea-div">
                    <input 
                        type="file" accept="image/*" name="image" id="img"
                        ref="inputForm" style="display: none;"
                    >
                    <QuillEditor @ready="init" v-model:content="comment" contentType="html" :options="options" 
                        ref="quill_edit" @update:content="updateContent">
                        <template v-slot:toolbar>
                            <div class="size" :class="{over : isOverSize}">
                                <!-- {{quillSizeText}} -->
                                <sizeGauge :max="quillSize.max" :current="quillSize.current" :maxText="quillSizeText?.max" :currentText="quillSizeText?.current"/>
                            </div>
                        </template>
                    </QuillEditor>
                    <div v-if="inputImage" class="preview">
                        <div v-for="(img, idx) in inputImage" :key="idx" class="inputImg">
                            <img :src="img"/>
                            <v-icon size="25" @click="resetImage(img, idx)">mdi-close</v-icon>
                        </div>
                    </div>
                    <div class="textarea-buttons-div">
                        <v-btn @click="onWrite" class="button write" v-if="checkComment || inputImage.length > 0">
                            <v-icon>mdi-check</v-icon>
                        </v-btn>
                        <v-btn @click="onToggleEditMode" v-if="mode == 'edit'" class="button cancel">
                            <v-icon>mdi-cancel</v-icon>
                        </v-btn>
                    </div>
                </div>
            </div>
            <div class="buttons-div" v-if="!isInputMode && (isMe || isAdmin)">
                <div @click="onToggleEditMode" class="v-button" v-if="isMe">
                    <img :src="require('@/assets/edit.png')">
                </div>
                <div @click="onDelete" class="v-button">
                    <img :src="require('@/assets/delete.png')">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, inject, nextTick, onMounted, ref } from "vue"
import { useUserStore } from '@/stores/user'
import util from "@/data/util"
import { usePostStore } from '@/stores/post'
import { QuillEditor, Quill } from "@vueup/vue-quill";
import sizeGauge from "@/component/sizeGauge.vue"

export default {
    name : "postComment",
    components : {
        QuillEditor,
        sizeGauge,
    },
    props : {
        // view mode(need commentData)
        // input mode(don't need commentData)
        // edit mode(need commentData)
        inputMode : {
            type : String,
            default : "view"
        },
        isDetail : {
            type : Boolean,
            default : false,
        },
        commentData : Object,
    },
    setup(props, ctx) {
        const modalManager = inject("ModalManager")

        const userStore = useUserStore()
        const postStore = usePostStore()

        const mode = ref("")
        const comment = ref("")
        const inputImage = ref([])
        const inputForm = ref('')
        const quill_edit = ref(null)

        const postData = inject('postData')
        const refreshComments = inject("refreshComments")
        const options = ref({
            modules: {
                toolbar: [
                    ["link", "image"],
                ]
            },
            placeholder: "Enter Comment",
        })

        const quillSize = ref({
            current : 0,
            max : (2 * (1024 ** 2))
        })

        const isInputMode = computed(() => {
            return mode.value == "input" || mode.value == "edit"
        })

        const isMe = computed(() => {
            return userStore.user?.id == props.commentData?.writer_id
        })

        const { isAdmin } = userStore

        const profileImage = computed(() => {
            if(isInputMode.value) {
                return userStore.user?.profile_image
            }
            return props.commentData?.writer_profile_image
        })

        const init = (editor) => {
            const quill = editor.getModule('toolbar')
            quill.addHandler('image', function() {
                const input = document.querySelector('#img')
                input.click()
                input.onchange = async e => {
                    const selectImg = await util.fileToBase64(input.files[0])
                    inputImage.value.push(selectImg)
                    checkSize()
                    e.target.value = ''
                }
            })
            const formatLink = Quill.import('formats/link')
            formatLink.sanitize = function(url) {
                if(!url.startsWith('http://') && !url.startsWith('https://')) {
                    return `https://${url}`
                }
                return url
            }
        }
        const resetImage = (src, idx) => {
            if(inputImage.value[idx] == src) {
                inputImage.value.splice(idx, 1)
                checkSize()
            }
        }
        const imgClick = (e) => {
            const defaultImageSSize = 100
            const defaultImageBSize = 660
            if(e.target.id == 'img') {
                if(e.target.clientWidth != defaultImageSSize) {
                    e.target.style.width = `${defaultImageSSize}px`
                    e.target.style.height = `${defaultImageSSize}px`
                }
                else {
                    // e.target.style.width = e.target.naturalWidth + 'px'
                    // if(e.target.naturalWidth > defaultImageBSize) {
                    //     e.target.style.width = `${defaultImageBSize}px`
                    // }
                    e.target.style.width = '100%'
                    e.target.style.height = 'auto'
                }
            }
        }

        const dateDiff = computed(() => {
            const res = util.getTimeDiifForCurrent(util.convertUTSTOJTS(props.commentData?.created_at))
            if(res) {
                const type = Number(res.data) > 1 ? `${res.type}s` : res.type;
                return `${res.data} ${type}`
            }
            return false
        })
        
        const date = computed(() => {
            return util.date_format(util.convertTimeStmapToDateTime(props.commentData?.created_at), "YYYY-MM-DD hh:mm")
        })

        const timezone = computed(() => {
            return util.getTimeZoneText()
        })

        const html = computed(() => {
            return props.commentData?.contents ? util.convertTextToHTML(props.commentData?.contents) : ""
        })

        const checkComment = computed(() => {
            let filteredContents = comment.value
            filteredContents = filteredContents.replaceAll("<br>" , '');
            filteredContents = filteredContents.replaceAll("<p>" , '');
            filteredContents = filteredContents.replaceAll("</p>" , '');
            filteredContents = filteredContents.replaceAll(" " , '');
            return filteredContents && filteredContents.trim() && filteredContents.trim().length > 0
        })

        const quillSizeText = computed(() => {
            const current = util.convertByteToSizeWithUnit(quillSize.value.current)
            const max = util.convertByteToSizeWithUnit(quillSize.value.max)
            return {
                current :`${current.size} ${current.unit}` ,
                max : `${max.size} ${max.unit}`
            }
        })

        const isOverSize = computed(() => {
            return quillSize.value.current > quillSize.value.max
        })

        const checkSize = () => {
            let size = 0;
            const quill = quill_edit.value;
            const content = quill.getHTML()

            const tagReg = /(<([^>]+)>)/ig
            const contentWithoutTag = content.replace(tagReg, "")
            size += util.getUnicodeTextSize(contentWithoutTag)

            // 이미지
            if(inputImage.value?.length > 0) {
                inputImage.value.forEach(base64 => {
                    const imgSize = util.getUnicodeTextSize(base64)
                    size += imgSize
                })
            }

            quillSize.value.current = size;
            if(quillSize.value.max) {
                if(size >= quillSize.value.max) {
                    return false;
                }
            }
            return true;
        }

        const updateContent = () => {
            checkSize()
        }

        const focusInput = () => {
            nextTick(() => {
                const editor = quill_edit.value.getEditor()
                editor.__quill.focus()
            })
        }

        const onWrite = async () => {
            if(isOverSize.value) {
                modalManager.showdialog("Size is over")
                return;
            }
            if(mode.value == "edit" && comment.value == props.commentData.contents) {
                mode.value = props.inputMode
                return;
            }
            if(!checkComment.value) {
                modalManager.showdialog("There is no comment. Please check your comment")
                return;
            }

            let link = /((https?:\/\/)[^\s<"]+)+(?![^<]*>)+(?![^<]*?<\/span>)+(?![^&]*;)/g
            comment.value = comment.value.replace(link, '<a href="$1" target="_blank">$1</a>')

            if(inputImage.value.length > 0) {
                inputImage.value.forEach(item => {
                    comment.value += `<img id="img" src="${item}"/>`
                })
                inputImage.value = []
            }

            let result = false
            if (mode.value == "edit") {
                result = await postStore.updateComment(postData.value.id, props.commentData.id, comment.value)
            } else {
                result = await postStore.createComment(postData.value.id, comment.value)
            }
            if (result !== false) {
                await refreshComments()
                comment.value = ""
                quill_edit.value.setText("")
                if(mode.value == "edit") {
                    onToggleEditMode()
                }
            }
            else {
                modalManager.showdialog("Failed")
            }
        }

        const onToggleEditMode = () => {
            if(mode.value == "edit") {
                mode.value = props.inputMode
            } else {
                inputImage.value = []
                comment.value = props.commentData?.contents

                const imgreg = /<img[^>]+src="?([^"\s]+)"?\s*\/>/g
                const test = /src\s*=\s*"?(.+?)["|\s]/g
                if(imgreg.test(props.commentData?.contents)) {
                    const arr = [...props.commentData?.contents.matchAll(test)]
                    arr.forEach(item => {
                        inputImage.value.push(item[1])
                    })
                    comment.value = props.commentData?.contents.replaceAll(imgreg, "")
                }

                mode.value = "edit"
                focusInput()
            }
        }

        const onDelete = async () => {
            modalManager.showdialog({
                title : "Do you want to delete this comment?",
                buttons : {
                    "yes" : "yes",
                    "no": "no"
                }
            })
            .then(res => {
                if(res.result == "yes") {
                    postStore.deleteComment(postData.value.id, props.commentData.id)
                    .then(async (result) => {
                        if (result) {
                            modalManager.showdialog("Deleted")
                            await refreshComments()
                        } else {
                            modalManager.showdialog("Failed")
                        }
                    })
                }
            })
        }

        onMounted(() => {
            mode.value = props.inputMode
        })

        return {
            mode,
            comment,
            postData,
            inputForm,
            inputImage,
            quill_edit,
            options,
            checkComment,
            quillSizeText,
            quillSize,
            isOverSize,

            isInputMode,
            isMe,
            isAdmin,
            profileImage,
            date,
            dateDiff,
            timezone,
            html,
            
            updateContent,
            onWrite,
            onToggleEditMode,
            onDelete,
            focusInput,
            init,
            resetImage,
            imgClick
        }
    },
}
</script>

<style scoped lang="scss">
.comment-div {
    display : flex;
    min-height: 4.0625rem;
    font-size: 0.5rem;
    letter-spacing: 0.00938em;
    position: relative;
    text-align: center;
    font: normal normal bold 14px/16px NanumBarunGothic;
    font-weight: 400;
    background: #fff;
    width : 100%;
    margin-bottom: 20px;
    .input-div {
        padding: 10px 5px;
        background: #eee;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        >div {
            width: 100%;
        }
        .userInfo-div {
            display: flex;
            width: 100%;
            margin-bottom: 10px;
            .username {
                font-weight: bold;
                margin-left: 0;
                margin-right: auto;
            }
            .timeStyle {
                margin-left: auto;
                margin-right: 0;
                margin-top: 4px;
                font-family: NanumSquareOTF;
                font-size: 12px;
                line-height: 1.142857143;
                color: #9c9c9c;
            }
        }
        .commentContent {
            text-align: left;
            ::v-deep img {
                width: 100px; height: 100px;
                object-fit: cover;
                cursor: pointer;
                margin-top: 10px;
                margin-right: 10px;
            }
        }
        .preview {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            margin-top: 10px;
            .inputImg {
                position: relative;
                width: 150px; height: 150px;
                display: flex;
                img {
                    width: 100%; height: 100%;
                    object-fit: cover;
                }
                i {
                    border-radius: 50%;
                    position: absolute;
                    top: 0; right: 0;
                    z-index: 1;
                    &:hover {
                        background-color: #fff;
                    }
                }
            }
        }
        .textarea-div {
            width: 100%;
            position: relative;

            .size {
                position: absolute;
                height: 25px;
                display: flex;
                align-items: center;
                top : 5px;
                right: 20px;
                &.over {
                    color : #850000;
                }
            }
            ::v-deep .ql-snow {
                border: 0px;
                .ql-formats {
                    display: block;
                    > button {
                        height: 25px;
                    }
                }
                .ql-editor {
                    border-bottom: 1px solid #9c9c9c;
                    &:hover {
                        border-color: #000;
                    }
                    &::after {
                        display: block;
                        border-bottom: 2px solid #000;
                        transition: transform 250ms ease-in-out;
                        transform: scaleX(0);
                        content: '';
                        position: absolute; bottom: 0; left: 0;
                        width: 100%;
                    }
                    &:focus::after {
                        transform: scale(1);
                    }
                }
                .ql-tooltip {
                    z-index: 9;
                }
            }
            
            .textarea-buttons-div {
                margin-top: 5px;
                margin-left: 5px;
                display: flex;
                justify-content: end;
                .button {
                    margin: 0 5px;
                    width: 20px;
                    &.write {
                        background: #910457;
                        color: #fff;
                    }
                    &.cancel {
                        background: #cccccc;
                    }
                }
            }
        }
    }
    > div {
        &.profile-div {
            margin-right: 5px;
        }
        &:not(.profile-div) {
            width : 100%;
            height: 100%;
        }
    }
}
.buttons-div {
    margin-top: 5px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    .v-button {
        margin: 0 5px;
        cursor: pointer;
        width: 70px;
        height: 30px;
        justify-content: center;
        align-items: center;
        display: flex;
        transition: 0.2s all linear;
        &:hover {
            background: #ccc;
        }
        img {
            width: 20px;
            height: 20px;
        }
    }
}
</style>
