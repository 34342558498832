import interceptor from "@/stores/interceptor";
import storeData from "@/stores/storeData";
import { ApiV2Service, ApiError } from "./api.v2.service";

const ConferenceService = {
    createConference: async function(data) {
        const param = interceptor.timezoneEncode(data)
        const response = await ApiV2Service.post('/conference', param)

        if (response.data.success) {
            return response.data.data
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    fetchConferences: async function(order = null, page = 1, perPage = storeData.default_per_page, xlsx) {
        let params =  { 
            page : page, 
            per_page : perPage,
        }
        if(xlsx) {
            params.per_page = 99999
        }
        if(order) {
            params["order"] = order
        }
        
        const response = await ApiV2Service.get('/conferences', params)
        response.data.data.map(con => {
            return interceptor.timezoneDecode(con)
        })

        if (response.data.success) {
            return {
                data : response.data.data,
                count : response.headers["x-total-count"],
                link : response.headers.link,
            }
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    fetchConference: async function(conferenceId) {
        const response = await ApiV2Service.get(`/conference/${conferenceId}`)

        if (response.data.success) {
            return interceptor.timezoneDecode(response.data.data)
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    fetchConferenceAttendees : async function(conferenceId) {
        const response = await ApiV2Service.get(`/conference/${conferenceId}/attendees`)

        if (response.data.success) {
            return interceptor.timezoneDecode(response.data.data)
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    updateConference: async function(conferenceId, data) {
        const param = interceptor.timezoneEncode(data)
        const response = await ApiV2Service.post(`/conference/${conferenceId}`, param)

        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    deleteConference: async function(conferenceId) {
        const response = await ApiV2Service.delete(`/conference/${conferenceId}`)

        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    attendConference: async function(conferenceId) {
        const response = await ApiV2Service.post(`/conference/${conferenceId}/attendee`)

        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },


    absentConference: async function(conferenceId) {
        const response = await ApiV2Service.delete(`/conference/${conferenceId}/attendee`)

        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

}

export default ConferenceService