<template>
    <nav class="Header">
        <div class="inner">
            <div class='logo'>
                <img :src='require("@/assets/wemet_logo_mini.png")' alt='logo' />
            </div>
            <ul>
                <li>
                    <button @click="() => this.$router.push('/login')" class='btn-login'>
                        Login
                    </button>
                </li>
                <li class='skip'>
                    <button @click="() => this.$router.push('/email/check')">Get started</button>
                </li>
            </ul>
        </div>
    </nav>
    <section :style="{ width: '100%', height: '100px' }"></section>
    <section class="Section1">
        <img :src='require("@/assets/wemet_logo.png")' alt='logo' class='main-logo' />
        <h1>
            <span>We Met for IP</span> and we want to <span>Meet for IP</span>.
        </h1>
        <h2>
            We will provide the tools. All you need to do is <span>We Met for IP!</span>
        </h2>
        <section class='MainSection'>
            <div class='large-block'></div>
                <img :src='require("@/assets/landing-meeting.png")' />
            <div class='text-block'>
                <p class='contents'>
                    <b>We Met for IP</b> will make your meeting scheduling task much easier for everybody in the IP industry.
                    All you need to do is provide the name and e-mail of the person you want to schedule a meeting.' '
                    <b>We Met for IP</b> will send the meeting invite, track date, time and place you will meet during an IP
                    event. <b>We Met for IP</b> will keep the history of the meeting so you know exactly where and when you
                    met each other. <b>We Met for IP</b> also provide a playground for all the people in the IP industry so
                    you can share precious memories and bring the IP community closer to each other.
                </p>
            </div>
            <div class='small-block'></div>
        </section>
        <section class='CardSection'>
            <div class='CardItem'>
                All you need for meeting is We&nbsp;<span>Met for IP</span>
            </div>
        </section>
        <footer class="sponsor_footer">
            <span>sponsor</span>
            <img :src='require("@/assets/logo-hanyang.png")' class='hanyang' />
            <img :src='require("@/assets/logo-sungam.png")' class='sungam' />
            <img :src='require("@/assets/logo-cisun.png")' class='cisun' />
            <img :src='require("@/assets/logo-hanyang.png")' class='hanyang' />
            <img :src='require("@/assets/logo-sungam.png")' class='sungam' />
            <img :src='require("@/assets/logo-cisun.png")' class='cisun' />
            <img :src='require("@/assets/logo-hanyang.png")' class='hanyang' />
        </footer>
    </section>

</template>

<script>
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {

}
</script>

<style scoped lang="scss">
html * {
    box-sizing: inherit;
    margin: 0px;
    padding: 0px;
}
.sponsor_footer{
 position: relative;
  display: flex;
  justify-content: space-between;
  width: 59.5625rem;
  margin: 5rem auto 0;

  span {
    font: normal normal 800 15px/17px NanumSquareOTF;
    color: #9c9c9c;
  }
  .hanyang,
  .sungam {
    width: 73px;
    height: 17px;
  }

  .cisun {
    width: 51px;
    height: 17px;
  }
}

.CardSection {
  margin-top: 4.375rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.CardItem{
  width: 24.5rem;
  height: 3.125rem;
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  & + & {
    margin-top: 1.875rem;
  }
}
.MainSection {
    
  margin: 3.125rem auto 0;
  min-width: 1220px;
  position: relative;

  img {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .large-block {
    position: relative;
    width: 1.875rem;
    height: 45rem;
    background: #910457;
  }

  .small-block {
    position: absolute;
    width: 1.875rem;
    height: 31rem;
    top: 113px;
    left: 1191px;
    background: #910457;
  }

  .text-block {
    position: absolute;
    top: 123px;
    left: 50.625rem;
    width: 25rem;
    height: 29.75rem;
    background: #fff;
    z-index: 5;
    padding: 1.875rem;
  }

  p.contents {
    width: 21.25rem;
    height: 416px;
    position: relative;
    text-align: left;
    color: #6c6c6c;
    font: normal normal normal 18px NanumSquareOTF;
    line-height: 1.55;
    max-width: 37rem;
  }
}
.Section1{
    
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 10.625rem 0 3.125rem;
  margin-top: 8.75rem;
  width: 100%;

  .main-logo {
    position: absolute;
    top: -58px;
    width: 116px;
    height: 150px;
  }

  span {
    font-weight: 800;
  }

  h1 {
    text-align: center;
    color: #333333;
    font: normal normal 300 51px/60px NanumSquareOTF;
    line-height: 1.176470588;
    width: 1010px;
  }

  h2 {
    text-align: center;
    color: #333333;
    font: normal normal 300 36px/60px NanumSquareOTF;
    line-height: 1.666666667;
    max-width: 66rem;
    width: 1000px;
  }
}

.Header {
    
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  height: 6.25rem;
  backdrop-filter: blur(50px);
  z-index: 50;

  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 75rem;
    margin: 0 auto;
    height: 6.25rem;
  }


  ul {
    display: flex;
    align-items: center;
    list-style: none;
  }

  li {
    display: flex;
    align-items: center;
    transition: all 0.2s linear 0s;
    position: relative;
  }

  li:not(.skip) {
    margin: 0 20px;

    a {
      font-size: 1rem;
      line-height: 1;
      color: black;
      font-weight: 700;
      transition: all 0.2s linear 0s;
      white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 2px solid rgb(255, 255, 255);
      text-decoration: none;
    }
  }

  button {
    border: none;
    outline: none;
    background: none;
    padding: 0;
    cursor: pointer;
    overflow: visible;

    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s linear 0s;
    color: rgb(255, 255, 255);
    background-color: #A1045A;
    height: 1.75rem;
    width: 9.375rem;
    border-radius: 1rem;
    font: normal normal normal 14px/26px NanumSquareOTF;
    line-height: 1;
  }

  .btn-login {
    color: #A1045A;
    background-color: transparent;
    border: 1px solid #A1045A;
  }
}
.scroll-container {
  overflow: auto;
  background-color: beige;
}

</style>