import { defineStore } from "pinia";
import { ApiError } from "@/services/api.v2.service";
import EventService from "@/services/event.service";
import storeData from "./storeData";

export const useEventStore = defineStore("event", {
    state: () => ({
        events: null,
        event: null,
        totalCount : 0,
        sdat : "",
        edat : "",
        order: null,
        page: 1,
        perPage: storeData.default_per_page,
        error: { code: 0, message: "" },
    }),

    actions: {
        async createEvent(data) {
            try {
                return await EventService.createEvent(data)
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async fetchEvents(params) {
            try {
                this.sdat = params?.sdat
                this.edat = params?.edat
                this.order = params?.order !== undefined ? params.order : this.order
                this.page = params?.page !== undefined ? params.page : this.page
                this.perPage = (params?.sdat || params?.edat) ? 999 : storeData.default_per_page

                const response = await EventService.fetchEvents(this.sdat, this.edat, this.order, this.page, this.perPage)
                if(response) {
                    this.events = response.data
                    this.totalCount = Number(response.count)
                    return this.events
                }
                return false
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async moreEvents() {
            try {
                this.page += 1
                const res = await EventService.fetchEvents(this.sdat, this.edat, this.order, this.page, this.perPage)

                if(res) {
                    this.events = this.events.concat(res.data)
                    return this.events
                }
                else {
                    return res
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async fetchEvent(eventId) {
            try {
                this.event = await EventService.fetchEvent(eventId)

                return this.event
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async updateEvent(eventId, data) {
            try {
                return await EventService.updateEvent(eventId, data)
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async deleteEvent(eventId) {
            try {
                return await EventService.deleteEvent(eventId)
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        getEvent(eventId) {
            const res = this.events.filter(event => event.id == eventId)
            if(res.length > 0) {
                return res[0]
            }
            return false
        },

        clearEvent () {
            this.event = null;
        },

        clearEvents() {
            this.events = null;
        }

    },
})