<template>
    <div class="sidebarStyle">
        <div class="logoStyle">
            <img :src="require('@/assets/wemet_logo.png')" alt='main-logo'  />
        </div>
        <ul class="menuStyle">
            <li v-for="menu in menus" :key="menu" @click="onMoveTo(menu.value)" 
                class="linkStyle" :class="{ active: activeMenu == menu.value }">
                <img :src="activeMenu == menu.value ? menu.active_image : menu.image" class="imgStyle"/>
                <span>{{ menu.title }}</span>
            </li>
        </ul>
        <div class="dividerStyle"></div>
        <div class="userStyle">
            <div  @click="onMoveTo('profile/update/me')">
                <profile-image :src="user?.profile_image" />
                <span>{{user?.name}}</span>
            </div>
            <div class="logout-div">
                <img :src="require('@/assets/logout.png')" @click="onLogout" />
            </div>
        </div>
    </div>
</template>

<script>
import userData from '@/data/userData'
import { useUserStore } from "@/stores/user"
import { storeToRefs } from "pinia"
import { computed, onMounted, ref, watch } from "vue"
import { useRoute, useRouter } from "vue-router"
import { useAuthStore } from '@/stores/auth'

export default {
    setup(props, ctx) {
        const authStore = useAuthStore()
        const userStore = useUserStore()

        const { user } = storeToRefs(userStore)
        const { fetchMe } = userStore

        const router = useRouter()
        const route = useRoute()
        const menus = [
            {title : "Feed", value : "feed", image : require("@/assets/feed.png"), active_image : require("@/assets/feed_active.png")},
            {title : "Meeting", value : "meeting", image : require("@/assets/meetingMenu.png"), active_image : require("@/assets/meetingMenu_active.png")},
            {title : "My Network", value : "network", image : require("@/assets/network.png"), active_image : require("@/assets/network_active.png")},
            {title : "Conference", value : "conference", image : require("@/assets/conference.png"), active_image : require("@/assets/conference_active.png")},
        ]
        const activeMenu = ref("")

        const updateActiveMenu = (path) => {
            const isFound = menus.some((menu) => {
                if (path.startsWith(`/${menu.value}`)) {
                    activeMenu.value = menu.value
                    return true
                }
                return false
            })
            if (!isFound) {
                activeMenu.value = ""
            }
        }
        const onMoveTo = (path) => {
            router.push({ path: `/${path}`})
        }
        const onLogout = () => {
            authStore.logout()
        }

        onMounted(async () => {
            if (!user.value) {
                await fetchMe()
            }
            if(user.value.type == userData.USER_TYPE.ADMIN) {
                menus.push({title : "Admin", value : "admin", image : require("@/assets/admin.png"), active_image : require("@/assets/admin_active.png")})
            }
            updateActiveMenu(route.path)
        })
        watch(() => route.path, (newValue, oldValue) => {
            updateActiveMenu(newValue)
        })

        return {
            user,

            menus,
            activeMenu,

            onMoveTo,
            onLogout,
        }
    },
}
</script>

<style scoped lang="scss">
    .sidebarStyle {
        position: sticky;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        max-width: 5.9375rem;
        user-select: none;
        .logoStyle {
            width: 90px;
            height: 90px;
            display: flex;
            justify-content: flex-end;
            user-select: none;

            img {
                width: 4.375rem;
                height: 100%;
                margin-right: 0.78125rem;
            }
        }
        .menuStyle {
            
            list-style: none;
            padding: 0;
            margin: 2.1875rem 0 1.875rem;
        
            .linkStyle {
                    
                display: flex;
                align-items: center;
                color: #6c6c6c;
                text-decoration: none;
                width: 105px;
                margin-bottom: 15px;
                // justify-content: center;
                font: normal normal normal 15px/19px NanumSquareOTF;
                cursor: pointer;

                span {
                    text-align: center;
                    padding: 1.25rem 0 1.25rem;
                }
                .imgStyle {
                    margin-right: 10px;
                    width: 30px;
                    height: 30px;
                }

                &:hover {
                    font-weight: bold;
                    .imgStyle{
                        width: 35px;
                        height: 35px;
                    }
                }
                &.active {
                    color: #910457;
                    font-weight: 800;
                    .imgStyle {
                        width : 35px;
                        height: 35px;
                    }
                }
            }
        }
        .dividerStyle {
            border-top: 1px solid #9c9c9c;
            width: 6.8125rem;
            margin-right: -0.4375rem;
        }
        .userStyle {
            margin-top: 3.125rem;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            >div {
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
            }
            span {
                font: normal normal 800 18px NanumSquareOTF;
                line-height: 1.166666667;
                color: #333333;
                white-space: nowrap;
                margin-top: 1.25rem;
                margin-bottom: 0.9375rem;
            }

            .logout-div img {
                width: 1.75rem;
                height: 1.75rem;

                &:hover {
                    fill: #A1045A;
                }
            }
        }
    }
</style>
