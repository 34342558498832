<template>
    <div class="boxStyle">
        <div class="header">
            <div v-html="title"/>
        </div>
        <div class="content">
            <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                    <v-btn @click="onDelete" class="delete btn" v-bind="props">
                        <v-icon size="40">mdi-delete</v-icon> 
                    </v-btn>
                </template>
                <span>DELETE</span>
            </v-tooltip>        
            <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                    <v-btn @click="onUpdate" class="update btn" v-bind="props"> <v-icon size="40">mdi-pencil</v-icon> </v-btn>
                </template>
                <span>UPDATE</span>
            </v-tooltip>   
            <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                    <v-btn @click="onRead" class="read btn" v-bind="props"> <v-icon size="40">mdi-eye</v-icon> </v-btn>
                </template>
                <span>READ</span>
            </v-tooltip>
        </div>
        <div class="footer">
            <v-btn @click="onClose" block> CLOSE </v-btn>
        </div>
    </div>
</template>
<script>
export default {
    props : {
        title : String,
        data : Object,
        value : String
    },
    methods : {
        onClose() {
            this.$ModalManager.close(this);
        },
        onDelete() {
            this.$ModalManager.showdialog({
                title : `Do you want to delete ${this.value}?`,
                buttons : {
                    yes : "yes",
                    no : "no",
                }
            }).then(res => {
                if(res.result == "yes") {
                    this.$ModalManager.close(this);
                    this.$emit("delete", this.data)
                }
            })
        },
        onUpdate() {
            // this.$ModalManager.showdialog({
            //     title : `Do you want to update ${this.value}?`,
            //     buttons : {
            //         yes : "yes",
            //         no : "no",
            //     }
            // }).then(res => {
                // if(res.result == "yes") {
                    this.$ModalManager.close(this);
                    this.$emit("update", this.data)
                // }
            // })
        },
        onRead() {
            // this.$ModalManager.showdialog({
            //     title : `Do you want to read ${this.value}?`,
            //     buttons : {
            //         yes : "yes",
            //         no : "no",
            //     }
            // }).then(res => {
            //     if(res.result == "yes") {
            //         this.$ModalManager.close(this);
                    this.$emit("read", this.data)
            //     }
            // })
        },
    }
}
</script>

<style scoped lang="scss">
.boxStyle {
    background: #fff;
    border-radius: 15px;
    .header {
        background: #910457;
        color:#fff;
        border-radius: 15px;
        font-weight: bold;
        margin : 20px;
        display: flex;
        font-size: 18px;
        justify-content: center;
        text-align: center;
        padding: 10px 0;
    }
    .content {
        justify-content: space-between;
        display: flex;
        .btn {
            color : #fff;
            width: 80px;
            height: 60px;
            margin: 20px;
            &.delete {
                background: #B91A4D;
            }
            &.update {
                background: #1E90FF;
            }
            &.read {
                background: #3CB371;
            }
        }
    }
    .footer {
        display: flex;
        justify-content: center;
        margin : 20px;
        >* {
            background: #9c9c9c;
            color: #fff;
        }
    }
}
</style>