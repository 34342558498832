<template>
    <div class="boxStyle">
        <div class="contentContainerStyle">
            <div v-html="view" class="content"/>
            <v-btn @click="onClose" class="btn close" block>CLOSE</v-btn>
        </div>
    </div>
</template>
<script>
import policyData from "@/data/policyData";
import util from '@/data/util';
import { getCurrentInstance, inject, onMounted, ref } from 'vue';
export default {
    props : {
        text : String,
    },
    setup(props, ctx) {
        const modalManager = inject("ModalManager")
        const view = ref("")
        const currentInstance = getCurrentInstance()
        
        const getView = (value) => {
            const markdownText = policyData[value];
            view.value = util.convertMarkDownToHTML(markdownText)
        }
        const onClose = () => {
            console.log(currentInstance)
            modalManager.close(currentInstance)
        }
        onMounted(() => {
            if(props.text) {
                getView(props.text)
            }
            else {
                getView("privacy")
            }
        })

        return {
            view,

            getView,
            onClose,
        }
    },
}
</script>

<style scoped lang="scss">
.boxStyle {
    width: 1000px;
    height: 500px;
    background: #fff;
    border-radius: 15px;
    padding : 15px;
    .contentContainerStyle {
        width:100%;
        height: 100%;
        overflow: auto;
        padding: 15px;
    }
    .btn {
        &.close {
            background: #9c9c9c;
            color: #fff;
        }
    }
}
</style>