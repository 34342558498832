import AdminLayout from "@/page/admin/adminLayout.vue"

import AdminUser from "@/page/admin/adminUser.vue"
import AdminEvent from "@/page/admin/adminEvent.vue"
import AdminFeed from "@/page/admin/adminFeed.vue"
import AdminSchedule from "@/page/admin/adminSchedule.vue"
import AdminNetwork from "@/page/admin/adminNetwork.vue"
import AdminConference from "@/page/admin/adminConference.vue"

export default [
    {
        path : "/admin",
        component :AdminLayout,
        redirect : "/admin/user",
        meta: { requiresAdmin: true },
        children : [
            {
                path : "/admin/user",
                component : AdminUser,
            },
            {
                path : "/admin/feed",
                component : AdminFeed,
            },
            {
                path : "/admin/schedule",
                component : AdminSchedule,
            },
            {
                path : "/admin/event",
                component : AdminEvent,
            },
            {
                path : "/admin/network",
                component : AdminNetwork,
            },
            {
                path : "/admin/conference",
                component : AdminConference,
            },
        ]
    }
]