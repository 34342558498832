<template>
    <div class="itemStyle">
        <div class="inner">
            <div class="title">
                <label>{{title}}</label>
            </div>
            <div class="textStyle">
                <template v-if="p_isEdit">
                    <template v-if="type == 'text'">
                        <div class="text">
                            <v-text-field :label="title" :name="profile_type" v-model="p_profile[profile_type]"
                                hide-details @keyup.enter="toggle" :variant="variant"/>
                        </div>
                    </template>
                    <template v-else-if="type == 'custom'">
                        <slot name="editor"></slot>
                    </template>
                    <button class='btn' @click="toggle">
                        <img :src="require('@/assets/write.png')" alt='edit-btn' :style="{ width: '17px', height: '17px' }" />
                    </button>
                </template>
                <template v-else>
                    <div class="text">
                        <template v-if="type == 'custom' && !useOrgDisplay">
                            <slot name="display"></slot>
                        </template>
                        <template v-else>
                            <div>{{profile?.[profile_type]}}</div>
                        </template>
                        <button class='btn' @click="toggle">
                            <img :src="require('@/assets/write.png')" alt='edit-btn' :style="{ width: '17px', height: '17px' }" />
                        </button>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>

import {ref, onMounted, watch} from "vue"

export default {
    props : {
        title : String,
        isEdit : {
            type : Boolean,
            default : false,
        },
        profile : Object,
        profile_type : String,
        useOrgDisplay : {
            type : Boolean,
            default : true,
        },
        type : {
            type : String,
            default : "text"
        },
        variant : {
            type : String,
            default : "filled"
        }
    },
    setup(props, ctx) {
        const p_isEdit = ref(false)
        // const content = ref("")
        let p_profile = ref(props.profile);
        
        const toggle = () => {
            p_isEdit.value = !p_isEdit.value
        }
        // const onEnter = () => {
        //     p_profile.value[props.profile_type] = content.value
            
        //     toggle()
        // }
        // const init = () => {
        //     content.value = p_profile.value[props.profile_type]
        // }
        onMounted(() => {
            p_isEdit.value = props.isEdit
            // init()
        })
        
        // watch(() => props.profile, (newValue, oldValue) => {
        //     init()
        // }, {deep : true })
        return {
            p_isEdit,
            p_profile,
            // content,

            toggle,
            // onEnter,
        }
    },
    
}
</script>

<style scoped lang="scss">

.itemStyle {
    
    padding: 0.9375rem 1.875rem;

    & + & {
        margin-top: 0.625rem;
    }

    background: #fff;
    border-radius: 1rem;
    min-height: 4.3125rem;
    display: flex;
    flex: 1;

    .inner {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        width: 100%;
    }

    .title {
        width: 7.5rem;
        flex-shrink: 0;
        user-select: none;

        label {
        color: #6c6c6c;
        font: normal normal normal 16px/26px NanumSquareOTF;
        }
    }
    .textStyle {

        flex: 1;
        display: flex;
        // flex-direction: column;

        div {
            flex-grow: 1;
        }

        button.btn {
            border: none;
            outline: none;
            background: none;
            padding: 0;
            margin-left : 10px;
            cursor: pointer;
        }

        .text {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            color: #333333;
            font: normal normal 800 16px/18px NanumSquareOTF;
            width: 100%;

            img {
                user-select: none;
            }
            .iconButton {
                margin-left: 10px;
                border-radius: 50%;
                &:hover {
                    background: #cccccc;
                }
            }
        }

        .plus {
            border: none;
            outline: none;
            background: none;
            padding: 0;
            cursor: pointer;
            border: 1px solid #9c9c9c;
            padding: 0;
            margin: 0;
            border-radius: 50px;

            &:disabled {
            color: rgba(0, 0, 0, 0.3);
            background-color: rgba(0, 0, 0, 0.05);
            user-select: none;
            }

            &:hover {
            background-color: #eeeeee;
            }
        }
    }
}
</style>