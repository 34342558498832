<template>
    <div class="AttendeesListContainer">
        <div>
            <div class="titleContainer">
                <div class="title">
                    Conference Attndees List
                </div>
                <div class="download">
                    <v-btn class="downloadStyle" @click="downloadExcel" :disabled="tableData?.content?.length == 0">
                        Download<v-icon>mdi-file-excel</v-icon>
                    </v-btn>
                </div>
            </div>
            <div class="table">
                <dataTable :tableData="tableData" @sorting="onSort" @onPageUpdate="onPageUpdate" :totalCount="totalCount" :perPage="per_page"/>
            </div>
        </div>
        <div>
            <v-btn @click="onClose" block class="closeButton">
                CLOSE
            </v-btn>
        </div>
    </div>
</template>

<script>
import { computed, getCurrentInstance, inject, onMounted, ref } from 'vue-demi'
import dataTable from "@/component/dataTable"
import util from '@/data/util'
import { useRouter } from 'vue-router'
import { useConferenceStore } from '@/stores/conference'
import { storeToRefs } from 'pinia'
import * as XLSX from 'xlsx'
export default {
    components : {
        dataTable,
    },
    props : {
        conference : Object,
        attendees : Object,
    },
    setup(props, ctx) {
        const conferenceStore = useConferenceStore()
        const { conference_attendees } = storeToRefs(conferenceStore)

        const modalManager = inject("ModalManager")
        const currentInstance = getCurrentInstance()

        const router = useRouter()
        const orders = ref([])
        const reverse = ref(false)
        const page = ref(1)
        const per_page = ref(5)
        const totalCount = ref(1)

        const toFormatString = (timestamp) => {
            return util.date_format(util.convertTimeStmapToDateTime(timestamp), 'YYYY-MM-DD')
        }
        const onDetail = (item) => {
            return () => router.push({
                path : "/profile/user",
                query : {
                    userno : item.id
                }
            })
        }

        const downloadExcel = async() => {
            const data = JSON.parse(JSON.stringify(props.attendees)).map(item => {
                return {
                    ...item,
                    phone : item.phone_code && item.phone_number 
                        ?  `${item.phone_code} ${item.phone_number}`
                        : '-',
                }
            })
            let jsonData = []
            data.forEach(elem => {
                let a = {}
                tableData.value.header.forEach(item => {
                    a[item.name] = elem?.[item.value] ?? '-'
                })
                jsonData.push(a)
            })

            const workBook = XLSX.utils.book_new()
            const tbData = XLSX.utils.json_to_sheet(jsonData)
            XLSX.utils.book_append_sheet(workBook, tbData, `${props.conference.title}`)
            XLSX.writeFile(workBook, `${props.conference.title}_attendees.xlsx`)
        }

        const selected_attendees = computed(() => {
            let attendeesList = JSON.parse(JSON.stringify(props.attendees))
            let orderList = orders.value.map(item => item.replaceAll("-",""))
            if(orderList.length > 0) {
                attendeesList = util.sortObj(attendeesList, reverse.value, ...orderList.map(order => item => item?.[order] ?? ""), item => Number(item.id))
            }
            attendeesList = attendeesList.splice(((page.value - 1) * per_page.value), per_page.value)
            return attendeesList
        })
        const tableData = computed(() => {
            return {
                header : [
                    {name : "Name", value : "name", order: ['name']},
                    {name : "E-mail", value : "email", order: ['email']},
                    {name : "Phone", value : "phone", order: ['phone_code', 'phone_number']},
                    {name : "Company", value : "company", order: ['company']},
                    {name : "Country", value : "country_name", order: ['country_name']},
                    {name : "Department", value : "department", order: ['department']},
                    {name : "Position", value : "position", order: ['position']},
                    // {name : "Signature", value : "signature", order: ['signature']},
                ],
                content : 
                    selected_attendees.value?.map(item => {
                        return {
                            ...item,
                            phone : item.phone_code && item.phone_number 
                                ?  `${item.phone_code} ${item.phone_number}`
                                : '-',
                            callback : onDetail(item),
                            created_at: toFormatString(item.created_at)
                        }
                    }),
                column : orders.value,
                reverse: reverse.value
            }                
        })
        const onSort = order => {
            reverse.value = !order.some(item => item.includes("-"))
            orders.value = order
        }
        const onPageUpdate = p => {
            page.value = p
        }
        const onClose = () => {
            modalManager.close(currentInstance)
        }
        onMounted(() => {
            totalCount.value = props.attendees.length
        })
        return {
            tableData,
            totalCount,
            per_page,

            downloadExcel,
            onClose,
            onSort,
            onPageUpdate,
        }
    }
}
</script>

<style scoped lang="scss">
.AttendeesListContainer {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 10px;
    padding : 10px;
    .titleContainer {
        display: flex;
        margin-bottom: 10px;

        .title {
            user-select: none;
            display: flex;
            text-align: center;
            width: 100%;
            font-size : 20px;
        }
        .download {
            margin-right: 0;
            margin-left: auto;
            .downloadStyle {
                background-color: #9C9C9C;
                color: white;
                border-radius: 50px;
                &:hover {
                    background-color: #1E3560;
                }
                i {
                    padding-left: 10px;
                }
            }
        }
    }
    .table {
        max-width: 1100px;
        max-height: 600px;
        overflow: auto;
    }
    .closeButton {
        background: #6c6c6c;
        color : #fff;
    }
}
</style>