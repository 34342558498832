<template>
    <div class="boxStyle">
        <v-form
        class="searchBoxStyle" id="search-form"
        @submit.prevent="onSearch"
        >
            <v-text-field
                v-if="searchType == 'text'"
                v-model="p_input"
                variant="outlined"
                :type="searchType"
                :label="placeholder" 
                hide-details
                :style="{ background: 'transparent', border: 'none' }" @input="onChange"
            />
            <v-combobox
                v-if="searchType == 'select'"
                v-model="p_input"
                variant="outlined"
                :label="placeholder"
                hide-details
                :items="list"
            />
            <button type="submit" aria-label="search" form="search-form" class="search-btn">
                <img :src="require('@/assets/icons_search.png')" />
            </button>
        </v-form>
        <div class="errorTextStyle" :class="{visible : is_error_count}">
            {{errorText}}
        </div>
    </div>
</template>
<script>

export default {
    props : {
        input : String,
        useSpecialChar : {
            type : Boolean,
            default : true,
        },
        placeholder : {
            type : String,
            default : "Search"
        },
        searchType : {
            type : String,
            default : "text"
        },
        list : Array,
    },
    data() {
        return {
            p_input : "",
            errorText : "Don't Use special characters",
            is_error_count : 0,
        }
    },
    methods : {
        onSearch() {
            this.$emit("onSearch", this.p_input)
        },
        onChange(e) {
            if(!this.useSpecialChar) {
                let value = e.target.value
                const reg = /[{}[\]/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]/gi
                if(reg.test(value)) {
                    value = value.replace(reg, "")
                    this.is_error_count++;
                    setTimeout(() => {
                        if(this.is_error_count) {
                            this.is_error_count--;
                        }
                    }, 3000)
                }
                e.target.value = value;
                this.p_input = value;
            }
            else {
                this.p_input = e.target.value
            }
        },
        setInput(input) {
            this.p_input = input;
        }
    },
    mounted() {
        this.p_input = this.input
    },
    watch : {
        input : {
            handler() {
                this.p_input = this.input
            }
        }
    }
}
</script>

<style scoped lang="scss">
.boxStyle {
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;
    .searchBoxStyle {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        input::placeholder {
            color: #d9d9d9;
        }

        input:focus {
            outline: none;
        }
        .search-btn {
            width: 1.75rem;
            height: 1.75rem;
            border-radius: 50%;
            margin-left: 10px;
            padding: 4px;
            &:hover {
                background: #DADADA;
            }
            img {
                width: 1.25rem;
            }
        }
    }
    .errorTextStyle {
        visibility: hidden;
        background: #910457CC;
        border-radius: 5px;
        padding : 0 5px;
        position: absolute;
        font-size: 12px;
        color : #fff;
        user-select: none;
        height: 20px;
        opacity: 0;
        bottom : -25px;
        transition : all 0.2s linear;
        &.visible {
            visibility: visible;
            opacity: 1;
        }
    }
}
</style>