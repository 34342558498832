<template>
    <div class="UserBody">
        <div class="Middle">
            <div class="Summary">
                <h3>Summary</h3>
                <v-tooltip  location="start">
                    <template v-slot:activator="{props}">
                        <span v-bind="props" class="tooltip">
                            <img :src="require('@/assets/company.png')"/>
                            {{profile?.company ?? '-'}}
                        </span>
                    </template>
                    <span>Company</span>
                </v-tooltip>
                <v-tooltip  location="start">
                    <template v-slot:activator="{props}">
                        <span v-bind="props" class="tooltip">
                            <img :src="require('@/assets/position.png')"/>
                            {{profile?.position ?? '-'}}
                        </span>
                    </template>
                    <span>Position</span>
                </v-tooltip>
                <v-tooltip  location="start">
                    <template v-slot:activator="{props}">
                        <span v-bind="props" class="tooltip">
                            <img :src="require('@/assets/department.png')"/>
                            {{profile?.department ?? '-'}}
                        </span>
                    </template>
                    <span>Department</span>
                </v-tooltip>
                <v-tooltip  location="start">
                    <template v-slot:activator="{props}">
                        <span v-bind="props" class="tooltip">
                            <img :src="require('@/assets/country.png')"/>
                            {{profile?.country_name ?? '-'}}
                        </span>
                    </template>
                    <span>Country</span>
                </v-tooltip>
                <v-tooltip  location="start" v-if="!isMe">
                    <template v-slot:activator="{props}">
                        <span v-bind="props" class="tooltip">
                            <img :src="require('@/assets/meeting.png')"/>
                            {{profile?.related_meetings?.length ?? '0'}}
                        </span>
                    </template>
                    <span>Wemet</span>
                </v-tooltip>
            </div>
            <div class="Field">
                <h3>Field</h3>
                <div class="Grid" >
                    <template v-for="field in field_list" :key="field">
                        <div class="FieldItem">
                            {{field}}
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="Signature">
            <h3>Signature</h3>
            <div>{{profile?.signature}}</div>
        </div>
        <div class="History" v-if="!isMe">
            <div class="historyTitle">
                <h3>Relative Meeting</h3>
            </div>
            <div class="scheduleTableStyle">
                <userProfileScheduleTable :scheduleTableData="profile?.related_meetings" />
            </div>
        </div>
    </div>
</template>

<script>
import userProfileScheduleTable from "@/page/profile/userProfileScheduleTable.vue"
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
export default {
    components : {
        userProfileScheduleTable,
    },
    setup(props, ctx) {

        const userStore = useUserStore()
        const {userFromId, user} = storeToRefs(userStore)
        const profile = ref(userFromId)
        const isMe = computed(() => {
            return user.value?.id == profile.value?.id
        })
        const field_list = computed(() => {
            if(profile.value?.field) {
                return JSON.parse(profile.value?.field)
            }
            return []
        })
        
        return {
            isMe,
            profile,
            field_list,
        }
    }
}
</script>

<style scoped lang="scss">

    .UserBody {

        display: flex;
        flex-direction: column;
        padding: 0 1.875rem 2rem;
        width: 100%;

        h3 {
            margin: 0 0 0.625rem;
            font: normal normal 800 16px/18px NanumSquareOTF;
            color: #6c6c6c;
        }
        .Middle {
            width: 100%;
            display: flex;
            padding-right: 1rem;
            .Summary {
            
                display: flex;
                flex-direction: column;
                flex: 1;
                color: #6c6c6c;
                margin-bottom: 1.875rem;

                img {
                    margin-right: 0.375rem;
                    width: 1.375rem;
                    height: 1.375rem;
                    color: #A1045A;
                }

                span {
                    display: flex;
                    align-items: center;
                    margin-bottom: 0.5rem;
                    font: normal normal normal 16px/26px NanumSquareOTF;
                } 
            }
        }
        .Field {
            flex: 1;
            max-width: 50%;
            .Grid {
                display: flex;
                flex-wrap: wrap;
                .FieldItem {
                    display: flex;
                    align-items: center;
                    margin-right: 0.5rem;
                    margin-top: 0.25rem;
                    color: #fff;
                    padding: 0.25rem 0.5rem;
                    border-radius: 50px;
                    font-size: 0.875rem;
                    background: #1E3560;
                }
            }
        }
        .Signature {
            margin-bottom: 1.875rem;
        }
        .historyTitle {
            display: flex;
            align-items: center;
            .historyCount {
                margin-right: 0;
                margin-left: auto;
                user-select: none;
            }
        }
    }
    .scheduleTableStyle {
        padding: 10px 5px;
        background: #EEEEEEEE;
        border-radius: 15px;
    }
    .tooltip {
        width: fit-content;
    }
</style>