<template>
    <router-view>
    </router-view>

</template>
<script>
</script>

<style scoped>

.addStyle {
    position: fixed;
    bottom: 103px;
    right: 2rem;
    z-index: 10;
    background: rgb(145, 4, 87);
    width: 56px;
    height: 56px;
}
</style>