<template>
    <div>
        <div class="timeSelectorContainer" :class="{vertical : isVertical}" ref="timeSelectorContainer">
            <div class="HourTimeTableHeader" :class="{vertical : isVertical}">
                <div class="blankDiv" :class="{vertical : isVertical}">
                </div>
                <div v-for="hour in 24" :key="hour - 1" class="HeaderData" :class="{vertical : isVertical}">
                    {{util.num_format(hour - 1)}}:00
                </div>
            </div>
            <div class="timeSelectorPicker" v-for="date in dateData" :key="date" :class="{vertical : isVertical}">
                <div class="timelineDate" :class="{sun : util.getDate(date?.date).getDay() == 0 , sat : util.getDate(date?.date).getDay() == 6 }">
                    {{util.date_format(date.date,"YYYY")}}
                    <br>
                    {{util.date_format(date.date,"MM-DD")}}
                </div>
                <div v-for="hour in date.hours" :key="hour"
                    class="timeContainer"
                    :class="[
                        hour.hour % 2 == 0 ? 'even' : 'odd',
                        isVertical ? 'vertical' : '' 
                    ]">
                    <div class="hourData" :class="{vertical : isVertical}">
                        <template v-if="hour.mins">
                            <div v-for="min in hour.mins" :key="min" class="minData"
                                @mousedown="onMousedown(date.date, hour.hour, min.min)"
                                @mouseUp="onMouseUp"
                                @mousemove="onMouseMove(date.date, hour.hour, min.min)"
                                :class="[min.disabled ? 'noneSelectable' : min.selected == true ? 'selected' : 'selectable',
                                isVertical ? 'vertical' : '' 
                            ]">
                                <!-- {{min.min}} -->
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isDrag" class="selectedDateInfo" ref="showSelectedData">
            {{selectedPeriodData}}
        </div>
    </div>
</template>
<script>
import { computed, nextTick, onMounted, onUnmounted, ref } from 'vue'
import util from '@/data/util'
import scheduleData from '@/data/scheduleData';

export class dateDataClass {
    dateData = [];
    minStamp = ref(scheduleData.minStamp);
    timezoneData = ref({})
    isNotUnavailableTime = ref(false);
    disabledMeetings = ref([]);
    selectedData = ref({});
    eventData = ref({})

    oneSchedule = ref(false);

    constructor(event, selectedData, timezoneData, disabledMeetings, isNotUnavailableTime = false, minStamp = scheduleData.minStamp) {
        this.minStamp.value = minStamp;
        this.eventData = event;
        this.setIsUnavailableTime(isNotUnavailableTime, true)

        this.initDateData(event)
        if(disabledMeetings) {
            this.setDisableMeeting(disabledMeetings)
        }

        if(selectedData) {
            this.setSelectedDateData(selectedData)
        }
        // timezone의 현재 시간까지 데이터를 disable 처리.
        if(timezoneData) {
            this.setTimezoneData(timezoneData)
        }
    }
    setUseSpace(value) {
        this.useSpace = value;
    }
    setIsUnavailableTime(value, init = false) {
        this.isNotUnavailableTime.value = value;
        if(!init) {
            this.initDateData(this.eventData)
        }
    }
    setTimezoneData(timezoneData, reset = false) {
        if(timezoneData) {
            this.timezoneData = timezoneData
        }
        this.setDisableCurrentTimeData(reset)
    }

    setEventData(event) {
        this.eventData = event;
        this.initDateData(event)
    }

    setDate(date) {
        if(this.oneSchedule) {
            this.dateData[0].date = util.date_format(util.getDate(date), 'YYYY-MM-DD')
        }
    }

    setDisableCurrentTimeData(reset = false) {
        if(this.timezoneData?.gmtOffset) {
            const timezone_uts = this.timezoneData.gmtOffset
            const currentDate = util.getCurrentDate(timezone_uts)
            const c_date = util.date_format(currentDate, "YYYY-MM-DD HH:mm")
            this.dateData.forEach(date => {
                date.hours.forEach(hour => {
                    hour.mins.forEach(min => {
                        if(util.getDate(`${date.date} ${hour.hour}:${min.min}`) <= util.getDate(c_date)) {
                            min.disabled =  true;
                        }
                        else if(reset){
                            min.disabled = false;
                        }
                    })
                })
            })
        }
    }
    initDateData(event) {
        this.dateData = []
        this.oneSchedule = false
        if(typeof event == "object") {
            var UAT = {}
            if(event?.unavailable_time && !this.isNotUnavailableTime.value) {
                UAT = JSON.parse(event?.unavailable_time)
            }
            const start_date_time = util.convertTimeStmapToDateTime(event.start_date)
            const start_hour = start_date_time.getHours()
            const start_min = start_date_time.getMinutes()
            const start_date = util.date_format(start_date_time, "YYYY-MM-DD")
            const end_date_time = util.convertTimeStmapToDateTime(event.end_date)
            const end_hour = end_date_time.getHours()
            const end_min = end_date_time.getMinutes()
            const end_date = util.date_format(end_date_time, "YYYY-MM-DD")
            const period = (util.getDate(end_date).getTime() - util.getDate(start_date).getTime()) / (1000 * 3600 * 24)

            var date = util.getDate(start_date)

            util.range(0, period + 1).forEach(day => {
                const date_text = util.date_format(date, "YYYY-MM-DD")
                this.dateData.push({
                    date : date_text,
                    hours : util.range(0, 24).map(hour => {
                        return {
                            hour : hour,
                            mins : util.range(0, Math.floor(60 / this.minStamp.value)).map(min => {
                                min = min * this.minStamp.value
                                let disabled = false;
                                if((date_text == start_date && hour < start_hour) || 
                                (date_text == end_date && hour > end_hour )) {
                                    disabled = true;
                                }
                                if(!disabled && (date_text == start_date && hour == start_hour && min < start_min) ||
                                (date_text == end_date && hour == end_hour && min >= end_min )) {
                                    disabled = true;
                                }
                                if(UAT?.[date_text]?.[hour] && UAT[date_text][hour].includes(min)) {
                                    disabled = true;
                                }

                                return {
                                    min : min,
                                    disabled : disabled,
                                    selected : false,
                                    saved_selected : false,
                                    draging : false,
                                }
                            }),
                        }
                    }),
                })
                date.setDate(date.getDate() + 1)
            })
        }
        else {
            this.oneSchedule = true;
            const curr_date = util.date_format(event, "YYYY-MM-DD") ?? util.date_format(util.getCurrentDate(), "YYYY-MM-DD")
            this.dateData.push({
                date : curr_date,
                hours : util.range(0, 24).map(hour => {
                    return {
                        hour : hour,
                        mins : util.range(0, Math.floor(60 / this.minStamp.value)).map(min => {
                            return {
                                min : min * this.minStamp.value,
                                disabled : false,
                                selected : false,
                                saved_selected : false,
                                draging : false,
                            }
                        }),
                    }
                })
            })
        }
    }
    setSelectedDateData(data, reset = true) {
        this.selectedData.value = data

        const start_time = util.getDate(data?.start) ?? null
        const start_date = util.date_format(start_time, "YYYY-MM-DD") ?? null
        const start_hour = parseInt(util.date_format(start_time, "HH")) ?? null
        const start_min = parseInt(util.date_format(start_time, "mm")) ?? null
        const end_time = util.getDate(data?.end) ?? null
        const end_date = util.date_format(end_time, "YYYY-MM-DD") ?? null
        const end_hour = parseInt(util.date_format(end_time, "HH")) ?? null
        const end_min = parseInt(util.date_format(end_time, "mm")) ?? null
        
        var checking = false;
        var res = false;
        this.dateData.forEach(date => {
            if(res) {
                return;
            }
            if(date.hours) {
                date.hours.forEach(hour => {
                    if(res) {
                        return;
                    }
                    if(hour.mins) {
                        hour.mins.forEach(min => {
                            if(res) {
                                return;
                            }
                            if(date.date == start_date && hour.hour == start_hour && min.min == start_min) {
                                checking = true;
                            }
                            else if (date.date == end_date && hour.hour == end_hour && min.min == end_min) {
                                checking = false;
                                if(!reset) {
                                    res = true;
                                }
                            }
                            if(checking) {
                                min.saved_selected = true;
                                min.selected = true;
                            }
                            else {
                                if(reset) {
                                    min.saved_selected = false;
                                    min.selected = false;
                                }
                            }
                        })
                    }
                })
            }
        })
    }
    setDisableMeeting(meetings, reset = false) {
        this.disabledMeetings.value = meetings

        if(this.disabledMeetings.value && this.disabledMeetings.value.length > 0) {
            this.disabledMeetings.value.forEach(item => {
                let start_dateTime = util.convertTimeStmapToDateTime(item.start_time)
                let start_min = parseInt(util.date_format(start_dateTime, "mm"))
                if(start_min % this.minStamp.value != 0) {
                    start_dateTime = start_dateTime.setMinutes(start_dateTime.getMinutes() - (start_min % this.minStamp.value) + this.minStamp.value)
                }
                let start_date = util.date_format(start_dateTime, "YYYY-MM-DD")
                let start_hour = parseInt(util.date_format(start_dateTime, "HH"))
                start_min = parseInt(util.date_format(start_dateTime, "mm"))
                
                let end_dateTime = util.convertTimeStmapToDateTime(item.end_time)
                let end_min = parseInt(util.date_format(end_dateTime, "mm"))
                if(end_min % this.minStamp.value != 0) {
                    end_dateTime = end_dateTime.setMinutes(end_dateTime.getMinutes() - (end_min % this.minStamp.value) + this.minStamp.value)
                }
                let end_date = util.date_format(end_dateTime, "YYYY-MM-DD")
                let end_hour = parseInt(util.date_format(end_dateTime, "HH"))
                end_min = parseInt(util.date_format(end_dateTime, "mm"))

                // 미팅 시작 및 끝 시점에 flag값 설정.
                let d_data = this.dateData.filter(date => date.date == start_date)
                if(d_data.length > 0) {
                    const sh_data = d_data[0].hours.filter(hour => hour.hour == start_hour)
                    if(sh_data.length > 0) {
                        const sm_data = sh_data[0].mins.filter(min => min.min == start_min)
                        if(sm_data.length > 0) {
                            if(sm_data[0].start_flag) {
                                sm_data[0].start_flag++;
                            }
                            else {
                                sm_data[0].start_flag = 1;
                            }
                        }
                    }
                }
                d_data = this.dateData.filter(date => date.date == end_date)
                if(d_data.length > 0) {
                    const eh_data = d_data[0].hours.filter(hour => hour.hour == end_hour)
                    if(eh_data.length > 0) {
                        const em_data = eh_data[0].mins.filter(min => min.min == end_min)
                        if(em_data.length > 0) {
                            if(em_data[0].end_flag) {
                                em_data[0].end_flag++;
                            }
                            else {
                                em_data[0].end_flag = 1;
                            }
                        }
                    }
                }
            })
        }
        var checking = 0;
        this.dateData.forEach(date => {
            if(date.hours) {
                date.hours.forEach(hour => {
                    if(hour.mins) {
                        hour.mins.forEach(min => {
                            if(min.start_flag) {
                                checking += min.start_flag;
                                delete min.start_flag
                            }
                            if (min.end_flag) {
                                checking -= min.end_flag;
                                delete min.end_flag
                            }
                            if(checking) {
                                min.disabled = true;
                            }
                            else {
                                if(reset) {
                                    min.disabled = false;
                                }
                            }
                        })
                    }
                })
            }
        })
    }
    getDateDataText() {
        let start_date = null;
        let start_hour = null;
        let start_min = null;
        let end_date = null;
        let end_hour = null;
        let end_min = null;
        let res = false

        this.dateData.forEach(date => {
            if(res) {
                return;
            }
            if(date.hours) {
                date.hours.forEach(hour => {
                    if(res) {
                        return;
                    }
                    hour.mins.forEach(min => {
                        if(res) {
                            return;
                        }
                        if((!min.disabled && min.selected) && 
                            start_date == null && start_hour == null && start_min == null) {
                            start_date = date.date;
                            start_hour = hour.hour;
                            start_min = min.min;
                        }
                        if((!min.selected || min.disabled) && 
                            start_date != null && start_hour != null &&  start_min != null &&
                            end_date == null && end_hour == null && end_min == null) {
                            end_date = date.date;
                            end_hour = hour.hour;
                            end_min = min.min;
                            res = true;
                        }
                    })
                })
            }
        })
        // 시간이 24:00을설정 할 경우 그 다음날 비활성화로 나오지 않게되면 해당 동작 필요.
        // 현재 가지고 있는 데이터 보다 더 뒤의 날짜를 가져와 처리.
        if(!res) {
            end_date = util.date_format(Math.max(this.dateData.map(item => util.getDate(item.date).getTime())) + (24 * 3600 * 1000), "YYYY-MM-DD")
            end_hour = 0;
            end_min = 0;
        }

        if(!(start_date && start_hour != null && start_min != null && end_date && end_hour != null && end_min != null)) {
            return {
                start : 0,
                end : 0,
            }
        }
        return {
            start : `${start_date} ${start_hour}:${start_min}`,
            end : `${end_date} ${end_hour}:${end_min}`
        }
    }
    findFirstTime() {
        let sDate = -1;
        let sTime = -1;
        let sMin = -1;
        let res = false
        this.dateData.forEach(date => {
            if(res) {
                return;
            }
            if(date.hours) {
                date.hours.forEach(hour => {
                    if(res) {
                        return;
                    }
                    hour.mins.forEach(min => {
                        if(res) {
                            return;
                        }
                        if(!min.disabled) {
                            sDate = date.date
                            sTime = hour.hour
                            sMin = min.min
                            res = true
                        }
                    })
                })
            }
        })
        return util.getDate(`${sDate} ${sTime}:${sMin}`).getTime()
    }
}

export default {
    props : {
        dateData : Object,
        oneSchedule : {
            type : Boolean,
            default : false,
        },
        minStamp : Number,
        clearCallback : Function,
        isVertical :{
            type : Boolean,
            default : true,
        },
        oneDate : {
            type :Boolean,
            default : false,
        }
    },
    setup(props, ctx) {
        const isDrag = ref(false)
        const isScrolling = ref(false)

        const showSelectedData = ref(null)

        const placehold_date = ref(null);
        const placehold_hour = ref(null);
        const placehold_min = ref(null);
        const placehold_select = ref(false);

        const select_start_date = ref(null);
        const select_start_hour = ref(null);
        const select_start_min = ref(null);

        const select_end_date = ref(null);
        const select_end_hour = ref(null);
        const select_end_min = ref(null);

        const mouseMoveDelay = ref(true);
        const reverseSelect = ref(false);
        const isShowMinSelector = ref(false);
        const timeSelectorContainer = ref(null);

        const selectedPeriodData = computed(() => {
            if(isDrag.value) {
                const startDateTime = util.getDate(`${select_start_date.value} ${select_start_hour.value}:${select_start_min.value}`)
                const endDateTime = util.getDate(`${select_end_date.value} ${select_end_hour.value}:${select_end_min.value}`)

                endDateTime.setMinutes(endDateTime.getMinutes() + 30)
                const startText = util.date_format(startDateTime, "YYYY-MM-DD HH:mm")
                const endText = util.date_format(endDateTime, "YYYY-MM-DD HH:mm")
                return `${startText} ~ ${endText}`
            }
            return ""
        })
        
        const moveShowSelectedData = (e) => {
            if(showSelectedData.value && isDrag.value) {
                nextTick(() => {
                    showSelectedData.value.style.top = 1 + e.clientY + "px";
                    showSelectedData.value.style.left = 1 + e.clientX + "px";
                })
            }
        }

        const dateFormat = (date, format = "YYYY") => {
            util.date_format(date, format)
        }
        
        const selectedTime = () => {
            let date = select_start_date.value
            let hour = select_start_hour.value
            let min = select_start_min.value
            if(reverseSelect.value) {
                date = select_end_date.value
                hour = select_end_hour.value
                min = select_end_min.value
            }
            const unit = props.minStamp ? (1000 * 60 * props.minStamp) : (1000 * 3600)
            const period = (util.getDate(`${select_end_date.value} ${select_end_hour.value}:${select_end_min.value ?? "00"}`) - 
                util.getDate(`${select_start_date.value} ${select_start_hour.value}:${select_start_min.value ?? "00"}`)) 
                / unit
            
            props.dateData.forEach(date => {
                date.hours.forEach(hour => {
                    if(hour.mins) {
                        hour.mins.forEach(min => {
                            if(!min.disabled && min.draging) {
                                if(props.oneSchedule) {
                                    min.selected = false;
                                }
                                else {
                                    min.selected = min.saved_selected;
                                }
                            }
                        })
                    }
                    // else {
                    //     if(!hour.disabled && hour.draging) {
                    //         if(props.oneSchedule) {
                    //             hour.selected = false;
                    //         }
                    //         // else {
                    //         //     hour.selected = hour.saved_selected;
                    //         // }
                    //     }
                    // }
                })
            })
            let res = true
            let last_date = date;
            let last_hour = hour;
            let last_min = min;
            util.range(0, period + 1).forEach(i => {
                if(!res) {
                    return;
                }
                const timeData = getDateDataIndex(date, hour, min)
                if(props.oneSchedule) {
                    if(timeData.disabled == true) {
                        res = false;
                        if(reverseSelect.value) {
                            select_start_date.value = last_date;
                            select_start_hour.value = last_hour;
                            select_start_min.value = last_min;
                        }
                        else {
                            select_end_date.value = last_date;
                            select_end_hour.value = last_hour;
                            select_end_min.value = last_min;
                        }
                        return;
                    }
                    else {
                        last_date = date;
                        last_hour = hour;
                        last_min = min;
                    }
                    timeData.selected = true;
                }
                else {
                    // timeData.selected = !timeData.saved_selected
                    timeData.selected = placehold_select.value
                }
                if(!timeData.draging && !timeData.disabled) {
                    timeData.draging = true;
                }
                const minStamp = props.minStamp ?? 60;
                if(min == undefined && !props.minStamp) min = 0;

                let currentDateTime = util.getDate(`${date} ${hour}:${min}`)
                if(reverseSelect.value) {
                    currentDateTime.setMinutes(currentDateTime.getMinutes() - minStamp)
                }
                else {
                    currentDateTime.setMinutes(currentDateTime.getMinutes() + minStamp)
                }
                date = util.date_format(currentDateTime, "YYYY-MM-DD")
                hour = parseInt(util.date_format(currentDateTime, "HH"))
                min = parseInt(util.date_format(currentDateTime, "mm"))
            })
        }
        const savedSelectedTime = () => {
            props.dateData.forEach(date => {
                date.hours.forEach(hour => {
                    if(hour.mins) {
                        hour.mins.forEach(min => {
                            if(!min.disabled) {
                                min.saved_selected = min.selected;
                                min.draging = false;
                            }
                        })
                    }
                    else if(!hour.disabled) {
                        hour.saved_selected = hour.selected;
                        hour.draging = false;
                    } 
                })
            })
        }
        const resetSelectedTime = () => {
            props.dateData.forEach(date => {
                date.hours.forEach(hour => {
                    if(hour.mins) {
                        hour.mins.forEach(min => {
                            min.selected = false;
                        })
                    }
                    else {
                        hour.selected = false;
                    }
                })
            })
        }

        const onMousedown = (date, hour, min) => {
            // 왼쪽 클릭만 통과
            if(event.button != 0) {
                return;
            }
            if(getDateDataIndex(date, hour, min).disabled) {
                return;
            }
            placehold_date.value = date;
            placehold_hour.value = hour;
            placehold_min.value = min;

            select_start_date.value = date;
            select_start_hour.value = hour;
            select_start_min.value = min;

            select_end_date.value = date;
            select_end_hour.value = hour;
            select_end_min.value = min;

            if(props.oneSchedule) {
                if(props.clearCallback) {
                    props.clearCallback()
                }
                resetSelectedTime()
            }
            placehold_select.value = !getDateDataIndex(date, hour, min)?.selected
            selectedTime();
            
            isDrag.value = true;
        }

        const onMouseUp = () => {

            if(isDrag.value) {
                savedSelectedTime();
                ctx.emit("onMouseUp")
            }
            placehold_date.value = null;
            placehold_hour.value = null;
            placehold_min.value = null;

            select_start_date.value = null;
            select_start_hour.value = null;
            select_start_min.value = null;

            select_end_date.value = null;
            select_end_hour.value = null;
            select_end_min.value = null;

            isDrag.value = false;
            isScrolling.value = false;
        }
        const onMouseMove = (date, hour, min ) => {
            if(mouseMoveDelay.value) {
                mouseMoveDelay.value = false;
                setTimeout(() => {
                    mouseMoveDelay.value = true;
                }, 10)
            }
            else {
                return;
            }
            if(props.oneDate && placehold_date.value != date ) {
                date = placehold_date.value
            }
            if(isDrag.value && placehold_date.value && 
                (placehold_hour.value || placehold_hour.value == 0 ) && 
                date && 
                (hour || hour == 0)) {
                
                if(util.getDate(`${date} ${hour}:${min ?? "00"}`) < util.getDate(`${placehold_date.value} ${placehold_hour.value}:${placehold_min.value ?? "00"}`)) {
                    select_start_date.value = date;
                    select_start_hour.value = hour;
                    select_start_min.value = min;
                    select_end_date.value = placehold_date.value;
                    select_end_hour.value = placehold_hour.value;
                    select_end_min.value = placehold_min.value;
                    reverseSelect.value = true;
                } else {
                    select_start_date.value = placehold_date.value;
                    select_start_hour.value = placehold_hour.value;
                    select_start_min.value = placehold_min.value
                    select_end_date.value = date;
                    select_end_hour.value = hour;
                    select_end_min.value = min;
                    reverseSelect.value = false;
                }
                selectedTime();
            }
        }
        const autoScrolling = (e) => {
            if(!isDrag.value || !timeSelectorContainer.value) {
                return;
            }
            const maxWidth = window.innerWidth
            const maxHeight = window.innerHeight
            const rect = timeSelectorContainer.value.getBoundingClientRect()
            const x1 = rect.left
            const y1 = rect.top
            const x2 = x1 + rect.width
            const y2 = y1 + rect.height

            let xyData ={
                x : 0,
                y : 0
            }
            isScrolling.value = false;
            if(x1 > e.x || 0 > e.x) {
                isScrolling.value = true;
                xyData.x = - 5;
            }
            else if(e.x > x2 || e.x > maxWidth){
                isScrolling.value = true;
                xyData.x = 5;
            }
            if(y1 > e.y || 0 > e.y) {
                isScrolling.value = true;
                xyData.y = - 5;
            }
            else if(e.y > y2 || e.y > maxHeight){
                isScrolling.value = true;
                xyData.y = 5;
            }
            if(isScrolling.value) {
                scrollingAction(xyData)
            }
        }
        const scrollingAction = (xyData) => {
            setTimeout(() => {
                if(isScrolling.value && timeSelectorContainer.value) {
                    let scrollData = {
                        top : timeSelectorContainer.value.scrollTop + xyData.y,
                        left : timeSelectorContainer.value.scrollLeft + xyData.x,
                    }
                    timeSelectorContainer.value.scrollTo(scrollData)
                    scrollingAction(xyData)
                }
                else {
                    clearTimeout(this)
                }
            }, 40)
        }
        const initScrolling = () => {
            nextTick(() => {
                const selectedDiv = document.querySelector('div.selected.minData')
                if(selectedDiv && timeSelectorContainer.value) {
                    timeSelectorContainer.value.scrollTo({
                        top : selectedDiv.offsetTop,
                        left : selectedDiv.offsetLeft - 160
                    })
                }
            })
        }
        const getDateDataIndex = (date, hour, min) => {
            let res = {}
            props.dateData.forEach(date_data => {
                if(res.disabled != undefined) {
                    return;
                }
                if(date_data.date == date) {
                    date_data.hours.forEach(hour_data => {
                        if(res.disabled != undefined) {
                            return;
                        }
                        if(hour_data.hour == hour) {
                            if(hour_data.mins) {
                                hour_data.mins.forEach(min_data => {
                                    if(res.disabled != undefined) {
                                        return;
                                    }
                                    if(min_data.min == min) {
                                        res = min_data
                                    }
                                })
                            }
                            else {
                                res = hour_data
                            }
                        }
                    })
                }
            })
            return res;
        }
        onMounted(() => {
            initScrolling()
            window.addEventListener("mouseup", onMouseUp)
            window.addEventListener("mousemove", autoScrolling)
            window.addEventListener("mousemove", moveShowSelectedData)
            window.addEventListener("mousedown", moveShowSelectedData)
        })
        onUnmounted(() => {
            window.removeEventListener("mouseup", onMouseUp)
            window.removeEventListener("mousemove", autoScrolling)
            window.removeEventListener("mousemove", moveShowSelectedData)
            window.removeEventListener("mousedown", moveShowSelectedData)
        })
        return {
            showSelectedData,
            resetSelectedTime,
            isShowMinSelector,
            onMouseUp,
            onMousedown,
            onMouseMove,
            util,
            dateFormat,
            timeSelectorContainer,
            selectedPeriodData,
            isDrag,
        }
    }
}
</script>

<style scoped lang="scss">
.timeSelectorContainer {
    user-select: none;
    overflow: auto;
    height: 100%;
    &.vertical {
        display: flex;
    }
    .timeSelectorPicker {
        display: flex;
        &.vertical {
            flex-direction: column;
            &:not(:last-of-type) {
                border-right: 1px solid #999;
            }
        }
        &:not(.vertical) {
            &:not(:last-of-type){
                border-bottom: 1px solid #999;
            }
        }
        height: fit-content;
        width: fit-content;
    }
    .timelineDate {
        &.sun {
            color : #e76464;
        }
        &.sat {
            color : rgb(94, 96, 207)
        }
        position: sticky;
        top: 0;
        left: 0;
        background: #fff;
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 12px;
        line-height: 12px;
        min-height: 25px;
        min-width: 40px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.5);
        align-items: center;
    }
    .timeContainer {
        &.vertical {
            border-top: 1px solid #999;
        }
        &:not(.vertical) {
            border-left: 1px solid #999;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 14px;
        &.noneSelectable {
            background: #CCC;
        }
        &.selectable, &.selected {
            cursor: pointer;
        }
        // &.even {
        //     background: #91045723;
        //     color: #000;
        // }
        // &.odd {
        //     background: #eee;
        //     color : #000;
        // }
        &.selected {
            background: #910457;
            color: #fff;
        }
        .hourData {
            display: flex;
            &.vertical {
                flex-direction: column;
                width: 100%;
                height: 25px;
            }
            &:not(.vertical) {
                width: 40px;
                height: 100%;
            }
            transition: 0.2s all linear;
            font-size: 14px;
            .hourTitle {
                display: flex;
                width: 100%;
                justify-content: center;
                background: #8b0000;
                color: #fff;
            }
            .minData {
                &.vertical {
                    border-top: 1px solid #999;
                }
                &:not(.vertical) {
                    border-left: 1px solid #999;
                }
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                font-size: 9px;
                
                &.noneSelectable {
                    background: #CCC;
                }
                &.selectable, &.selected {
                    cursor: pointer;
                }
                // &.selectable {
                //     &.even {
                //         background: #91045723;
                //         color: #000;
                //     }
                //     &.odd {
                //         background: #eee;
                //         color : #000;
                //     }
                // }
                &.selected {
                    background: #910457;
                    color: #fff;
                }
            }
        }
    }
    .HourTimeTableHeader {
        display: flex;
        &.vertical {
            flex-direction: column;
            .HeaderData {
                width: 40px;
                height: 26px;
                border-top: 2px solid #999;
            }
        }
        &:not(.vertical) {
            .HeaderData {
                width: 41px;
                height: 25px;
                border-left: 2px solid #999;
            }
        }
        position : sticky;
        background : #fff;
        left : 0;
        top : 0;
        z-index : 1;
        width : fit-content;
        height : fit-content;
        .blankDiv {
            top:0;
            left: 0;
            background: #fff;
            position: sticky;
            z-index: 2;
            width: 40px;
            height: 25px;
        }
        .HeaderData {
            align-items: center;
            display: flex;
            justify-content: center;
            font-size: 13px;
        }
    }
}
.selectedDateInfo {
    top : 1px;
    left : 1px;
    position: fixed;
    z-index : 99;
    background: #555;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>