import interceptor from "@/stores/interceptor";
import { ApiV2Service, ApiError } from "./api.v2.service";

const InvitationService = {

    fetchInvitation: async function(token) {
        const data = {
            method: 'get',
            url: `/meeting/token`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        } 

        const response = await ApiV2Service.customRequest(data)


        if (response.data.success) {
            return interceptor.timezoneDecode(response.data.data)
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    updateInvitation: async function(token, params) {
        const data = {
            method: 'put',
            url: `/meeting/token/negot`,
            data : params,
            headers: {
                Authorization: `Bearer ${token}`
            }
        } 

        const response = await ApiV2Service.customRequest(data)

        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    proceedInvitation: async function(token) {
        const data = {
            method: 'put',
            url: `/meeting/token/proceed`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        } 
        const response = await ApiV2Service.customRequest(data)
        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    mediateInvitation: async function(token, params) {
        const param = interceptor.timezoneEncode(params)
        const data = {
            method: 'post',
            url: `/meeting/token/negot`,
            data : param,
            headers: {
                Authorization: `Bearer ${token}`
            }
        } 
        const response = await ApiV2Service.customRequest(data)
        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },
}

export default InvitationService