<template>
    <div>
        <div class="titleStyle">
            <img :src="require('@/assets/profile.png')"/>
            <h2>Profile {{userType != 'me' ? `(No : ${userType})` : ""}}</h2>
            <div class="scheduleCountStyle" v-if="userType == 'me'">
                <a @click="onMoveToSchedule">
                    Meeting: {{ userData?.meeting_count }}
                </a>
            </div>
            <div class="withdrawStyle" v-if="userType == 'me'">
                <v-btn @click="onWithdarw">Cancel Membership</v-btn>
            </div>
        </div>
        <div class="InfoStyleDiv">
            <div class="headerStyle" title="Account">
                <h3>Account</h3>
                <div class="sub-comment">
                    <p>Where we send important messages about your account </p>
                </div>
            </div>

            <div class="wrapper">
                <div class="itemStyle">
                    <div class="inner">
                        <div class="title">
                            <label>Email</label>
                        </div>
                        <div class="emailStyle">
                            <div class="email-block">{{ userData?.email }}</div>
                            <p>
                                <strong>Verified.</strong> Thank you for verifying your email.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="itemStyle">
                    <div class="inner">
                        <div class="title">
                            <label>Account</label>
                        </div>
                        <div class="emailStyle">
                            <div class="email-block">{{ userData?.account }}</div>
                        </div>
                    </div>
                </div>
                <div class="itemStyle">
                    <div class="inner">
                        <div class="title">
                            <label>Name</label>
                        </div>
                        <div class="emailStyle">
                            <div class="email-block">{{ userData?.name }}</div>
                        </div>
                    </div>
                </div>

                <div class="itemStyle">
                    <div class="inner">
                        <div class="title">
                            <label>Password</label>
                        </div>
                        <div class="inner">
                            <v-text-field
                                variant="outlined"
                                clearable
                                label="Password"
                                v-model="form.password"
                                class="login_input"
                                hide-details
                                :type="isShowPassword ? 'text' : 'password'"
                                :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="isShowPassword = !isShowPassword"
                            />
                        </div>
                    </div>
                </div>

                <div class="itemStyle">
                    <div class="inner">
                        <div class="title">
                            <label>Confirm Passwrod</label>
                        </div>
                        <div class="inner">
                            <v-text-field
                                variant="outlined"
                                clearable
                                label="Confirm Password"
                                v-model="form.confirmed_password"
                                class="login_input"
                                hide-details
                                :type="isShowConfirmedPassword ? 'text' : 'password'"
                                :append-icon="isShowConfirmedPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="isShowConfirmedPassword = !isShowConfirmedPassword"
                            />
                        </div>
                    </div>
                </div>

                <div class="itemStyle">
                    <div class="inner">
                        <div class="title">
                            <label>Photo</label>
                        </div>
                        <div class="photoStyle">
                            <div class='img'>
                                <profile-image :src="userData?.profile_image"/>
                                <template v-if="inputProfileImage"> 
                                    <div>
                                        <v-icon>mdi-arrow-right</v-icon>
                                    </div>
                                    <profile-image :src="inputProfileImage"/>
                                </template>
                            </div>
                            <input
                                type="file"
                                accept="image/*"
                                ref="profile_img"
                                v-show="false"
                                @change="onSelectProfileImage"
                            />
                            <div class='upload-btn'>
                                <v-btn icon @click="$refs.profile_img.click()">
                                    <img :src="require('@/assets/upload.png')" :style="{ width: '20px', height: '20px' }" />
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="itemStyle">
                    <div class="inner">
                        <div class="title">
                            <label>Phone Number</label>
                        </div>
                        <v-menu offset-y>
                            <template v-slot:activator="{ props: menu }">
                                <p class="countryListImg" v-bind="menu" v-if="!form.phone_code">
                                    <v-icon :style="{'margin-right': '-10px'}">mdi-phone</v-icon>
                                    <v-icon>mdi-earth</v-icon>
                                </p>
                                <p class="countryListImg" v-bind="menu" v-else>
                                    {{ form.phone_code }}
                                </p>
                            </template>
                            <v-list max-height="400">
                                <v-list-item v-for="(item, index) in countries" :key="index" class="clickable" @click="onSelectPhoneCode(item)">
                                    <v-list-item-title >
                                        <img :src="util.getCountryFlag(item?.iso2)"/>
                                        {{ `${item.name} (${item.native})` }} {{ item.phonecode }}
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-text-field v-model="form.phone_number" label="Phone Number"
                            @input="onInputPhoneNumber" 
                            variant="outlined"
                            hide-details
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="InfoStyleDiv">
            <div class="headerStyle" title="Account">
                <h3>Belonging</h3>
                <div class="sub-comment">
                    <p>Change identifying details for your connecting </p>
                </div>
            </div>
            <div class="wrapper">
                <myProfileInputContent title="Firm" :profile="form" profile_type="company" variant="outlined"/>
                <myProfileInputContent title="Department" :profile="form" profile_type="department" type="custom">
                    <template v-slot:editor>
                        <div class="fieldStyle">
                            <div>
                                <v-combobox label="Department" v-model="form.department" :items="departmentList" variant="outlined" hide-details/>
                            </div>
                        </div>
                    </template>
                </myProfileInputContent>
                <myProfileInputContent title="Position" :profile="form" profile_type="position" type="custom">
                    <template v-slot:editor>
                        <div class="fieldStyle">
                            <div>
                                <v-combobox label="Position" v-model="form.position" :items="positionList" variant="outlined" hide-details/>
                            </div>
                        </div>
                    </template>
                </myProfileInputContent>
                <myProfileInputContent title="Field" :profile="form" profile_type="fields" type="custom" :useOrgDisplay="false">
                    <template v-slot:editor> 
                        <div class="fieldStyle">
                            <div>
                                <v-autocomplete 
                                    :items="fieldList"
                                    clearable
                                    chips
                                    closable-chips
                                    filled
                                    multiple
                                    item-title="name"
                                    item-value="id"
                                    :menu-props="{ maxHeight: 400 }"
                                v-model="form.fields" type="text" hide-details @keyup.enter="onAddField" label="Field" variant="outlined"/>
                                <!-- <v-icon class="iconButton" @click="onAddField">mdi-check
                                </v-icon> -->
                            </div>
                            <!-- <div class="fieldList">
                                <div>
                                    <div v-for="field in form.fields" :key="field" class="fieldItem" @click="onDeleteField(field)">
                                        {{ field }}
                                        <v-icon>mdi-close</v-icon>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </template>
                    <template v-slot:display> 
                        <div class="fieldList">
                            <div>
                                <div v-for="field in form.fields" :key="field" class="fieldItem">
                                {{ field }}
                                </div>
                            </div>
                        </div>
                    </template>
                </myProfileInputContent>
                <myProfileInputContent title="Country" :profile="form" profile_type="country_name" type="custom">
                    <template v-slot:editor>
                        <div class="countrySelector">
                            <nationSelector :oneSelect="true" :isShowNationImg="false" @updateCountry="onSelectCountry"
                                :selectedCountries="[form.country_id]" variant="outlined"/>
                        </div>
                    </template>
                    <template v-slot:display>
                        <div>
                            {{form.country_name}}
                        </div>
                    </template>
                </myProfileInputContent>
                <myProfileInputContent title="Signature" :profile="form" profile_type="signature" variant="outlined"/>
                <myProfileInputContent title="Default Comment" :profile="form" profile_type="comment" variant="outlined" />
            </div>
            <div class="buttons">
                <v-btn class="buttonStyle" @click="onSave" >
                    SAVE
                </v-btn>
                <v-btn class="buttonStyle cancel" @click="onCancel" >
                    CANCEL
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import myProfileInputContent from "@/page/profile/myProfileContent.vue"
import util from '@/data/util'
import nationSelector from "@/component/nationSelector.vue"
import { useCountryStore } from "@/stores/country"
import { useUserStore } from "@/stores/user"
import { inject, onMounted, ref } from "vue"
import { storeToRefs } from "pinia"
import { useRoute, useRouter } from "vue-router"
import { useAuthStore } from "@/stores/auth"
import ImageResizeModalVue from '@/modal/ImageResizeModal.vue'
import profileData from '@/data/profileData'
import profile from '@/router/profile'

export default {
    components : {
        myProfileInputContent,
        nationSelector,
    },
    setup(props, ctx) {
        const modalManager = inject("ModalManager")

        const countryStore = useCountryStore()
        const userStore = useUserStore()
        const authStore = useAuthStore()
        const router = useRouter()
        const route = useRoute()

        const fieldList = ref(profileData.fieldData)
        const positionList = ref(profileData.positionData)
        const departmentList = ref(profileData.departmentData)

        const { countries } = storeToRefs(countryStore)
        const { user, isAdmin, userFromId, authenticationError } = storeToRefs(userStore)
        const form = ref({
            password : "",
            confirmed_password : "",
            profile_image: null,
            phone_code: "",
            phone_number: "",
            country_id: "",
            country_name: "",
            company: "",
            department: "",
            position: "",
            fields: [],
            signature: "",
            comment: "",
        })
        const userData = ref(null)
        const userType = ref("me")
        const inputProfileImage = ref("")
        const inputField = ref("")
        const isShowPassword = ref(false)
        const isShowConfirmedPassword = ref(false)

        const onMoveToSchedule = () => {
            router.push({ path: '/meeting/schedule' })
        }
        const onWithdarw = () => {
            modalManager.showdialog(
            {
                title : "Are you sure you want to cancel membership?",
                buttons : {
                    yes : "yes",
                    no : "no",
                }
            })
            .then(res => {
                if(res.result == "yes") {
                    userStore.withdraw()
                    .then((result) => {
                        if (result) {
                            authStore.logout()
                        }
                    })
                }
            })
        }
        const onSelectCountry = (selectedCountries) => {
            if(selectedCountries.length >= 1) {
                const country_id = selectedCountries[0]
                const country = countries.value?.filter(country => country.id == country_id)
                if(country.length > 0) {
                    form.value.country_name = country[0].name
                    form.value.country_id = country[0].id
                }
            }
            else if(selectedCountries.length == 0){
                form.value.country_name = ""
                form.value.country_id = ""
            }
        }
        const onInputPhoneNumber = (e) => {
            const target = e.currentTarget;
            target.value = target.value
                .replace(/[^0-9]/g, '')
                // .replace(/^(\d{2,3})(\d{3,4})(\d{4})$(\d)/, `$1-$2-$3$4`);

            // v-model 처리 후 해당 input 이벤트가 처리되는 것으로 보임.
            // input 처리후 남아있으면 안되는 마지막 입력값이 phone_number 값에 남아있음.
            // 따라서 해당 값 input 처리 시점에도 다시 업데이트 추가.
            form.value.phone_number = target.value
        }
        const onSelectProfileImage = async (e) => {
            if(!e.target.files[0]) {
                return;
            }
            modalManager.showEx(ImageResizeModalVue, {
                bind : {
                    image : e.target.files[0],
                    isCircle : true,
                }
            }).then(async res => {
                if(res?.image) {
                    form.value.profile_image = res.image
                    inputProfileImage.value = await util.fileToBase64(form.value.profile_image)
                }
            })
            e.target.value = "";
        }
        const onSelectPhoneCode = (country) => {
            form.value.phone_code = country.phonecode
        }
        const onAddField = () => {
            if (inputField.value.trim()) {
                form.value.fields.push(inputField.value.trim())
                inputField.value = ""
            }
        }
        const onDeleteField = (field) => {
            const index = form.value.fields.indexOf(field)
            if (index !== -1) {
                form.value.fields.splice(index, 1)
            }
        }

        const checkForm = () => {
            const req = [
                {
                    value : form.value.password || form.value.confirmed_password,
                    check : form.value.confirmed_password && form.value.password && 
                        form.value.password.trim() == form.value.confirmed_password.trim(),
                    text : "passwords don't match each other"
                },
                {
                    value : !(!(form.value.password || form.value.confirmed_password)
                        && !form.value.profile_image
                        && userData.value.phone_code == form.value.phone_code 
                        && userData.value.phone_number == form.value.phone_number?.replace(/[^0-9]/g, '')
                        && userData.value.country_id == form.value.country_id 
                        && userData.value.company == form.value.company 
                        && userData.value.department == form.value.department 
                        && userData.value.position == form.value.position
                        && userData.value.field == JSON.stringify(form.value.fields)
                        && userData.value.signature == form.value.signature
                        && userData.value.comment == form.value.comment), 
                    text : "This is same profile"
                }
            ]
            const res = util.dataCheck(req)
            if(res.length > 0) {
                return res.join("<br>")
            }
            return false
        }
        const onSave = async () => {
            let res = checkForm()
            if(res) {
                modalManager.showdialog(`Please check your data:<br> ${res}`)
                return;
            }
            const data = new FormData()
            const dataFieldList = [
                {type : "profile_image", value : form.value.profile_image},
                {type : "password", value : form.value.password},
                {type : "phone_code", value : form.value.phone_code},
                {type : "phone_number", value : form.value.phone_number?.replace(/[^0-9]/g, '')},
                {type : "country", value : form.value.country_id},
                {type : "company", value : form.value.company},
                {type : "department", value : form.value.department},
                {type : "position", value : form.value.position},
                {type : "field", value : JSON.stringify(form.value.fields)},
                {type : "signature", value : form.value.signature},
                {type : "comment", value : form.value.comment},
            ]
            dataFieldList.forEach(item => {
                if(item.value) {
                    data.append(item.type, item.value)
                }
            })
            
            res = false
            if(userType.value == "me") {
                res = await userStore.updateMe(data)
            }
            else{
                res= await userStore.updateUser(userType.value, data)
            }
            if(res) {
                modalManager.showdialog("Saved")
                .then(() => {
                    router.back()
                })
            }
            else {
                modalManager.showdialog(`Failed<br>${authenticationError?.message} `)
            }
        }
        const onCancel= () => {
            router.back()
        }

        onMounted(async () => {
            if(route.path.includes("update/user")) {
                userType.value = route.query.userno
            }
            else if(route.path.includes("update/me")){
                userType.value = "me"
            }

            if(userType.value != "me" && !isAdmin) {
                modalManager.showdialog("No permission").then(res => {
                    router.back()
                })
                return;
            }

            if (!countries.value) {
                await countryStore.fetchCountries()
                // phone code가 2개 이상인 경우 처리.
                countries.value.forEach((item, index) => {
                    if(item.phonecode.includes("and")) {
                        const phonecodes = item.phonecode.split(" and ")
                        phonecodes.forEach(code => {
                            countries.value.splice(index +1 , 0, {
                                ...item,
                                phonecode : code,
                            })
                        })
                        countries.value.splice(index, 1)
                    }
                })
            }

            if(userType.value != "me") {
                await userStore.fetchUser(userType.value)
                userData.value = userFromId.value
            }
            else {
                if(!user.value) {
                    await userStore.fetchMe()
                }
                userData.value = user.value
            }
            if(userData.value) {
                form.value.phone_code = userData.value.phone_code
                form.value.phone_number = userData.value.phone_number
                form.value.country_id = userData.value.country_id
                form.value.country_name = userData.value.country_name
                form.value.company = userData.value.company
                form.value.department = userData.value.department
                form.value.position = userData.value.position
                if (userData.value.field) {
                    try {
                        const fields = JSON.parse(userData.value.field)
                        if (Array.isArray(fields)) {
                            form.value.fields = fields
                        }
                    } catch(e) {
                        form.value.fields = []
                    }
                }
                form.value.signature = userData.value.signature
                form.value.comment = userData.value.comment
            }
            
        })

        return {
            countries,
            userData,
            userType,
            form,
            inputProfileImage,
            inputField,
            isShowPassword,
            isShowConfirmedPassword,
            util,
            fieldList,
            positionList,
            departmentList,

            onMoveToSchedule,
            onWithdarw,
            onInputPhoneNumber,
            onSelectProfileImage,
            onSelectPhoneCode,
            onSelectCountry,
            onAddField,
            onDeleteField,
            onSave,
            onCancel,
        }
    },
}

</script>

<style scoped lang="scss">
.titleStyle {
    display: flex;
    align-items: center;
    max-width: 54.375rem;
    img {
        width: 0.8125rem;
        height: 0.875rem;
        margin-right: 0.3125rem;
    }
    h2 {
        color: #910457;
        font: normal normal 800 18px/21px NanumSquareOTF;
    }
    padding-bottom: 1.5625rem;
    border-bottom: 1px solid #9c9c9c;
    margin-bottom: 1.5625rem;
    .scheduleCountStyle {
        display: flex;
        width: 120px;
        margin-left: 1.5rem;
        color: #6c6c6c;
        font: normal normal 800 14px/16px NanumSquareOTF;
        cursor: pointer;
    }
    .withdrawStyle {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        button {
            width: 180px;
            font: 14px NanumSquareOTF;
            font-weight: 500;
            background: #B9062F;
            color:#fff;
        }
    }
}
.InfoStyleDiv {    
    display: flex;
    flex-flow: column;
    max-width: 54.375rem;
    .headerStyle {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        h3 {
            line-height: 1.125;
            color: #6c6c6c;
            font: normal normal 800 16px/18px NanumSquareOTF;
        }

        .sub-comment {
            p {
            font: normal normal normal 14px/26px NanumSquareOTF;
            color: #6c6c6c;
            line-height: 1.857142857;
            }
        }
    }
    .wrapper {
        margin-bottom: 1.875rem;
        border-radius: 1rem;
    }
    .itemStyle {
        
        padding: 0.9375rem 1.875rem;
        margin-top: 0.625rem;

        background: #fff;
        border-radius: 1rem;
        min-height: 4.3125rem;
        display: flex;
        flex: 1;

        .inner {
            display: flex;
            align-items: center;
            width: 100%;
            input {
                border: 1px solid #333;
                padding: 5px 10px;
                border-radius: 10px;
                outline: none;
                width: 100%;
            }
        }

        .title {
            width: 7.5rem;
            flex-shrink: 0;
            user-select: none;

            label {
                color: #6c6c6c;
                font: normal normal normal 16px/26px NanumSquareOTF;
            }
        }
        .emailStyle {
            flex-grow: 1;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;

            .email-block {
                color: #333333;
                font: normal normal 800 16px/18px NanumSquareOTF;
            }

            p {
                margin-top: 0.5rem;
                color: #9c9c9c;
                font: normal normal normal 12px/13px NanumSquareOTF;
            }
        }
        .photoStyle {
            display: flex;
            flex: 1;
            width: 100%;

            > div.img {
                flex-grow: 1;
                display: flex;
                align-items: center;
                width: 100%;
                div {
                    margin: 0 10px;
                }
                img {
                    width: 4rem;
                }
            }

            > div.upload-btn {
                align-self: center;
            }
        }
        .countryListImg {
            width : 100px;
            &:hover {
                cursor: pointer;
                color: #A1045A;
            }

            color: #333;
            font: normal normal 800 16px/18px NanumSquareOTF;
        }
        .countrySelector {
            width: 100%;
        }
        .fieldStyle {
            width: 100%;
            >div {
                display: flex;
                margin-right: 10px;
                align-items: center;
                .iconButton {
                    margin-left: 10px;
                    border-radius: 50%;
                    &:hover {
                        background: #cccccc;
                    }
                }
                &::v-deep .v-chip {
                    color : #fff;
                    background: #1E3560;
                }
                &::v-deep .v-chip__underlay {
                    opacity: 0;
                }
            }
        }
        .fieldList {
            
            // width: 100%;
            width: 100%;
            margin-top: 5px;
            // height: 100px;
            > div {
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                overflow-x: none;
                display: flex;
                flex-wrap: wrap;
                align-content: flex-start;
                .fieldItem {
                    display: flex;
                    align-items: center;
                    margin-right: 0.5rem;
                    margin-top: 0.25rem;
                    color: #fff;
                    padding: 0.25rem 0.5rem;
                    border-radius: 50px;
                    font-size: 0.875rem;
                    background: #1E3560;
                    font: normal normal 400 16px/18px NanumSquareOTF;
                    cursor: default;

                    > i {
                        // border: 1px solid #666;
                        border: none;
                        padding-left: 3px;
                        border-radius: 10px;
                    }
                }

                &::-webkit-scrollbar {
                    width: 10px;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #9c9c9c;
                }
                &::-webkit-scrollbar-track {
                    background-color: #d9d9d9;
                }
            }
        }
    }
    .buttons {
        display: flex;
        .buttonStyle {
            border: none;
            width: 48%;
            margin: 0 5px;
            height: 43px;
            font: 14px NanumSquareOTF;
            font-weight: 500;
            color: white;
            cursor: pointer;
            background: #910457;
            &.cancel {
                background: #6c6c6c;
            }
        }
    }
}
</style>
