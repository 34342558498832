<template>
    <div class="layoutContainer">
        <Header :menuList="menuList"> </Header>
        <router-view></router-view>
    </div>
</template>
<script>
import Header from "@/component/subMenuBar.vue"
import { useRouter } from 'vue-router'
import { ref } from 'vue'
export default {
    components : {
        Header,
    },
    setup(props, ctx) {
        const router = useRouter()

        const onLink = (path) => {
            return () => {router.push(path)};
        }

        const menuList = ref( [
                {title : "EVENT", value : "event", callback : onLink("/meeting/event")},
                {title : "SCHEDULE", value : "schedule", callback : onLink("/meeting/schedule")},
                {title : "HISTORY", value : "history", callback : onLink("/meeting/history")},
        ])
        
        return  {
            menuList,

            onLink
        }
    },
}
</script>

<style scoped lang="scss">
.layoutContainer {
    position: relative;
}
</style>