<template>
    <div class="Auth">
        <div class="containerStyle" >
            <figure>
                <img :src="require('@/assets/login_logo.png')" alt='login-logo' />
            </figure>
            <div class="loginForm">
                <section>
                    <emailCheckForm :sendMail="sendMail" @onSendMail="onSendMail"
                        @onEmailChecked="onEmailChecked" :emailCheckType="emailCheckType"/>
                </section>
            </div>
            <div class="link" @click="this.$router.back()">
                <span>Back</span>
            </div>
        </div>
    </div>
</template>

<script>
import emailCheckForm from "@/page/email/emailCheckForm.vue"
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
export default {
    components : {
        emailCheckForm,
    },
    setup(props, ctx) {
        
        const router = useRouter()
        const route = useRoute()
        const sendMail = ref(false)
        const pageType = ref("check")
        const emailCheckType = ref(0)

        
        const onSendMail = () => {
            sendMail.value = true;
        }

        const onEmailChecked = () => {
            let path = ""
            if(pageType.value == "check") {
                path = "/signup"
            }
            else if(pageType.value  == "forgot") {
                path = "/password/reset"
            }
            router.push({
                path : path,
            })
        }
        onMounted(() => {
            const routePath = route.path;
            if(routePath.includes("check")) {
                pageType.value = "check"
                emailCheckType.value = 0;
            }
            else if(routePath.includes("forgot")) {
                pageType.value = "forgot"
                emailCheckType.value = 1;
            }
        })

        return {
            sendMail,
            pageType,
            emailCheckType,

            onSendMail,
            onEmailChecked,
        }
    },
}
</script>

<style scoped lang="scss">
.Auth {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.link {
    position: relative;
    font-size: 1.2rem;
    align-self: center;
    text-decoration: none;
    font: normal normal bold 15px/17px NanumSquareOTF;
    color: #6c6c6c;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
        color: #757575;
     } 
}
.loginForm {
    
    display: flex;
    flex-direction: column;
    max-width: 22.230625rem;

    section {
        flex: 1;
    }

    a {
        text-decoration: none;
    }
}
.containerStyle {
    padding: 60px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    @media all and (max-width : 768px) {
        width: 17.1875rem;
    }
    @media all and (min-width : 768px) and (max-width : 1023px) {
        width: 27.1875rem;
    }
    @media all and (min-width : 1023px) {
        width: 37.1875rem;
    }
    border-radius: 1rem;
    box-shadow: 0 3px 6px #00000029;
    background: rgba(255, 255, 255, 0.5);

    figure {
        margin: 3.375rem 0 2.125rem;
        width: 116px;
        height: 150px;

        img {
        max-width: 100%;
        max-height: 100%;
        height: 150px;
        }
    }

    .title {
        color: #333;
        margin: 0 0 1rem;
        font: normal normal 800 20px/23px NanumSquareOTF;
    }
}
</style>