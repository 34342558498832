import { defineStore } from "pinia";
import { ApiError } from "@/services/api.v2.service";
import InvitationService from "@/services/invitation.service";

export const useInvitationStore = defineStore("invitation", {
    state: () => ({
        token : null,
        invitation: null,
        error: { code: 0, message: "" },
    }),

    actions: {
        async fetchInvitation(token) {
            try {
                this.token = token
                this.invitation = await InvitationService.fetchInvitation(token)

                return this.invitation
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },
        async proceedInvitation(token) {
            try {
                if(!token) {
                    token = this.token
                }
                return await InvitationService.proceedInvitation(token)
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async updateInvitation(data, token) {
            try {
                if(!token) {
                    token = this.token
                }
                return await InvitationService.updateInvitation(token, data)
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },
        async mediateInvitation(data, token) {
            try {
                if(!token) {
                    token = this.token
                }
                return await InvitationService.mediateInvitation(token, data)
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },
    },
})