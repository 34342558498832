<template>
    <div class="postWriteStyle">
        <v-form @submit.prevent="onSubmit" :style="{ height: '100%', marginBottom: '1.875rem' }" id="post-write-form">
            <div class="headStyle">
                <img :src="require('@/assets/write.png')" class="iconStyle">
                <p class="title"> 
                    {{ title }} Feed
                </p>
                <div class="size">
                    <sizeGauge :current="quillSize.current" :max="quillSize.max" :currentText="currentSizeText.current" :maxText="currentSizeText.max"/>
                </div>
            </div>
            <div class="quillWrapperStyle">
                <quillEditor class="editorStyle" v-model:content="contents" ref="quillEditor" v-model:isLoading="isLoading"
                    :size="quillSize"/>
            </div>
        </v-form>
        <div class="buttonWrapStyle">
            <v-btn class="buttonStyle" @click="onCancel" :style="{ background: '#9C9C9C'}">
                Cancel
            </v-btn>
            <v-btn class="buttonStyle" type="submit" form="post-write-form" :style="{ background: '#910457'}">
                {{ submitButtonText }}
            </v-btn>
        </div>
    </div>
</template>

<script>
import quillEditor from "@/component/quillEditor.vue"
import util from '@/data/util'
import { usePostStore } from "@/stores/post"
import { computed, inject, onMounted, ref } from "vue"
import { useRoute, useRouter } from "vue-router"
import sizeGauge from "@/component/sizeGauge.vue"

export default {
    components : {
        quillEditor,
        sizeGauge,
    },
    setup(props, ctx) {
        const quillSize = ref({
            current : 0,
            max : 8 * (1024 ** 2),
        })
        const modalManager = inject("ModalManager")

        const postStore = usePostStore()
        const router = useRouter()
        const route = useRoute()

        const editMode = ref("create")
        const isLoading = ref({
            image : 0,
        })

        let post = null
        let contents = { value: "" }

        const quillEditor = ref(null)

        const title = computed(() => {
            return editMode.value == "create" ? "New" : "Edit"
        })
        const submitButtonText = computed(() => {
            return editMode.value == "create" ? "Write" : "Edit"
        })
        const currentSizeText = computed(() => {
            const current = util.convertByteToSizeWithUnit(quillSize.value.current)
            const max = util.convertByteToSizeWithUnit(quillSize.value.max)
            return {
                current : `${current.size} ${current.unit}`,
                max : `${max.size} ${max.unit}`
            }
        })
        const isOverSize = computed(() => {
            return quillSize.value.current > quillSize.value.max
        })

        const checkContents = () => {
            let filteredContents = contents.value
            filteredContents = filteredContents.replaceAll("<br>" , '');
            filteredContents = filteredContents.replaceAll("<p>" , '');
            filteredContents = filteredContents.replaceAll("</p>" , '');
            filteredContents = filteredContents.replaceAll(" " , '');

            let req = [
                { value: filteredContents, text: "Please check your Feed"}
            ]
            if(editMode.value == "update") {
                req.push({ value: filteredContents && post.contents , check: contents.value != post.contents, text: "This is same content"})
            }
            const res = util.dataCheck(req)
            if(res.length > 0) {
                return res.join("<br>")
            }
            return false
        }

        const onSubmit = async () => {
            if(isOverSize.value) {
                modalManager.showdialog("Size is over")
                return;
            }
            if(isLoading.value.image > 0) {
                modalManager.showdialog("Image Loading...")
                return;
            }
            const res = checkContents() 
            if(res) {
                modalManager.showdialog(`${res}`)
                return;
            }
            
            let link = /((https?:\/\/)[^\s<"]+)+(?![^<]*>)+(?![^<]*?<\/span>)+(?![^&]*;)/g
            contents.value = contents.value.replace(link, '<a href="$1" target="_blank">$1</a>')

            let result = false
            if (post) {
                result = await postStore.updatePost(post.id, contents.value)
            } else {
                result = await postStore.createPost(contents.value)
            }

            if (result !== false) {
                router.back()
            } else {
                modalManager.showdialog(`Failed. Please try again.`)
            }
        }

        const onCancel = () => {
            router.back();
        }

        onMounted(async () => {
            if (route.path.includes("edit")) {
                editMode.value = "update"
                post = await postStore.fetchPost(route.query.postno)
                contents.value = post.contents
                quillEditor.value.setHTML(contents.value)
            }
        })

        return {
            contents,
            quillEditor,

            title,
            submitButtonText,
            isLoading,
            quillSize,
            currentSizeText,
            isOverSize,

            onSubmit,
            onCancel,
            
        }
    },
}
</script>

<style scoped lang="scss">
.postWriteStyle {
    width: 54.375rem;
    height: 37.5rem;
    margin-bottom: 1.6rem;
    align-items: flex-start;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 0.7rem;
    opacity: 0.8;
    position: relative;
    background: #ffffff;
    .headStyle {
        display: flex;
        padding: 1.875rem 0 1.875rem 1.875rem;
        align-items: center;
        .title {
            font: 800 18px NanumSquareOTF;
            color: #333333;
        }
        .iconStyle {
            padding: 0 0.375rem 0 0;
            widows: 1.5rem;
            height: 1.5rem;
        }
        .size {
            margin-left: auto;
            margin-right : 30px;
            &.over {
                color : #a00000;
            }
        }
    }
    .quillWrapperStyle {
        position: relative;
        height: 32.5rem;
        padding: 0 30px 30px 30px;
        .editorStyle {
            height: 100%;
            background: #ffffff !important;
        }
    }
    .buttonWrapStyle {
        display: flex;
        justify-content: center;

        button + button {
            margin-left: 1.25rem;
        }
        .buttonStyle {
            border: none;
            border-radius: 999px;
            width: 9.375rem;
            height: 1.75rem;
            font: 14px NanumSquareOTF;
            font-weight: 100;
            color: white;
            cursor: pointer;
        }
    }
}
</style>