<template>
    <div class="headerStyle">
        <div class="iconStyle" @click.stop="onMoveToUserInfo">
            <profile-image :src="postData?.writer_profile_image"/>
        </div>
        <div class="titleStyle">
            <div class="titleContent">
                <h4>
                    <span>{{postData?.writer_name}}</span>
                </h4>
                <div v-if="checkNetwork(networkTypes.BUDDY) && !isMe" class="networkTypeText">
                    {{getNetworkText(networkTypes.BUDDY)}}
                </div>
                <div v-if="checkNetwork(networkTypes.MET) && !isMe" class="networkTypeText">
                    {{getNetworkText(networkTypes.MET)}}
                </div>
            </div>
            <div class='time-date'>
                {{dateDiff}}
                <!-- {{ date }} ({{ timezone }}) -->
                </div>
        </div>
        <div class="moreStyle">
            <template v-for="(item, index) in menus" :key="index">
                <div v-if="item.external">
                    <template v-if="item.image == 'mdi-account' && !isMe">
                        <div class="moreDiv clickable" @click.stop="item.callback">
                            <v-icon>
                                {{item.image}}
                            </v-icon>
                            <v-icon :size="15">
                                {{checkNetwork(networkTypes.FOLLOW) ? `mdi-star` : `mdi-star-outline`}}
                            </v-icon>
                        </div>
                    </template>
                </div>
                <div v-else-if="isMe || (item.useAdmin && isAdmin)" class="moreDiv clickable" @click.stop="item.callback">
                    <v-icon>
                        {{item.image}}
                    </v-icon>
                </div>
            </template>
        </div>
    </div>    
</template>

<script>
import { computed, inject, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { usePostStore } from '@/stores/post'
import { useUserStore } from '@/stores/user'
import util from '@/data/util'
import { useNetworkStore } from '@/stores/network'
import userData from '@/data/userData'

export default {
    props : {
        isDetail : {
            tyle : Boolean,
            default : false,
        }
    },
    setup(props, ctx) {
        const modalManager = inject("ModalManager")

        const userStore = useUserStore()
        const postStore = usePostStore()
        const networkStore = useNetworkStore()
        const router = useRouter()

        const menus = ref([])
        const postData = inject('postData')
        const refreshPost = inject('refreshPost')
        const refreshNetwork = inject('refreshNetwork')
        const isMe = computed(() => {
            return userStore.user?.id == postData.value?.writer_id
        })
        const networkTypes = ref(userData.NETWORK_TYPE)

        const checkNetwork = type => {
            return (postData.value?.network_type & type) == type
        }
        
        const getNetworkText = type => {
            if(!checkNetwork(type) || isMe.value) {
                return ""
            }
            return userData.getNetworkText(type)
        }

        const { isAdmin } = userStore

        const date = computed(() => {
            return postData.value?.updated_at 
                ? util.date_format(util.convertTimeStmapToDateTime(postData.value?.updated_at), "YYYY-MM-DD HH:mm")
                : util.date_format(util.convertTimeStmapToDateTime(postData.value?.created_at), "YYYY-MM-DD HH:mm")
        })

        const dateDiff = computed(() => {
            const time = postData.value?.updated_at ?? postData.value?.created_at
            const res = util.getTimeDiifForCurrent(util.convertUTSTOJTS(time))
            if(res) {
                const type = Number(res.data) > 1 ? `${res.type}s` : res.type;
                return `${res.data} ${type}`
            }
            return false
        })
        
        const timezone = computed(() => {
            return util.getTimeZoneText()
        })
        const onFollowUser = () => {
            let isCreate = true
            let text = `follow`
            let successText = `Followed`
            if(checkNetwork(userData.NETWORK_TYPE.FOLLOW)) {
                text = `unfollow`
                successText = `Unfollowed`
                isCreate = false
            }
            modalManager.showdialog({
                title : `Do you want to ${text} this user?`,
                buttons : {
                    "yes" : "yes",
                    "no" : "no"
                }
            }).then(async res => {
                if(res.result == "yes") {
                    let res = false
                    if(isCreate) {
                        const params = {
                            type : userData.NETWORK_TYPE.FOLLOW,
                            data : {
                                id : Number(postData.value?.writer_id),
                                name : postData.value?.writer_name,
                                email : postData.value?.writer_email,
                                company : postData.value?.writer_company
                            }
                        }
                        res = await networkStore.createNetwork(params)
                    }
                    else {
                        res = await networkStore.deleteNetwork(postData.value?.network_id, {type : userData.NETWORK_TYPE.FOLLOW})
                    }
                    if(res) {
                        await refreshNetwork()
                        modalManager.showdialog(`${successText}`)
                    }
                    else {
                        modalManager.showdialog(`Failed`)
                    }
                }
            })
        }

        const onMoveToUserInfo = () => {
            router.push({
                path : "/profile/user",
                query : {
                    userno : postData.value.writer_id
                }
            })
        }

        onMounted(() => {
            if(!props.isDetail) {
                menus.value.push({ image: "mdi-account", callback: onFollowUser, external : true})
            }
            else {
                menus.value.push({ image: "mdi-account", callback: onFollowUser, external : true})
                menus.value.push({ image: "mdi-pencil", useAdmin : false, callback: () => (router.push({ path: "/feed/edit", query: { postno : postData.value.id } })) })
                menus.value.push({ image: "mdi-delete", useAdmin : true, callback: () => {
                        modalManager.showdialog({
                            title : "Do you want to delete this feed?",
                            buttons : {
                                "yes" : "yes",
                                "no" : "no"
                            }
                        })
                        .then((res) => {
                            if(res.result == "yes") {
                                postStore.deletePost(postData.value.id)
                                    .then((result) => {
                                        if (result) {
                                                router.push("/feed")
                                        } else {
                                            modalManager.showdialog("Failed")
                                        }
                                    })
                            }
                        })
                    }})
            }
        })

        return {
            menus,
            postData,
            isMe,
            isAdmin,
            date,
            dateDiff,
            timezone,
            networkTypes,
            checkNetwork,
            getNetworkText,

            onMoveToUserInfo,
        }
    },
}
</script>

<style scoped lang="scss">
.headerStyle {
    display: flex;
    padding: 1.5625rem 1.875rem 1.25rem;
    align-items: flex-start;
}
.iconStyle {
    cursor: pointer;
    border-radius: 50%;
    margin-right: 1.25rem;
    background: #fff;
    padding: 5px;
    transition: 0.2s all linear;
    &:hover {
        background: #910457;
    }
}
.titleStyle {
    
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-top: -5px;
    margin-bottom: -5px;
    .titleContent {
        align-items: center;
        display: flex;
        text-decoration: none;
    }

    h4 {
        margin: 0.5625rem 0 0.3125rem;
        outline: none;
        text-align: left;
    }

    span {
        color: #333333;
        font-family: NanumSquareOTF;
        font-weight: 800;
        font-size: 18px;
        line-height: 1.166666667;
    }

    .time-date {
        font-family: NanumSquareOTF;
        font-size: 14px;
        line-height: 1.142857143;
        color: #9c9c9c;
    }
}
.moreStyle {
    display: flex;
    .moreDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3rem;
        height: 3rem;
        padding: 0.5rem;
        border-radius: 999px;
        color: #000;
        &:hover {
            background: #f3e5f5;
            cursor: pointer;
        }
    }
}
.clickable {
    &:hover {
        font-weight: 430;
    }
}
.networkTypeText {
    user-select: none;
    background: #910457;
    padding: 2px 10px;
    border-radius: 5px;
    color: #fff;
    margin-left: 10px;
}
</style>
