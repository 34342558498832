<template>
    <div class="headerStyle">
        <v-btn class="downloadStyle" @click="downloadExcel" :disabled="tableDatas?.content?.length == 0">
            Download<v-icon>mdi-file-excel</v-icon>
        </v-btn>
    </div>
    <div>
        <dataTable :tableData="tableDatas" 
            :init_page="init_page"
            :totalCount="scheduleTotalCount"
            @onPageUpdate="onPageUpdate"
            @sorting="sorting">
        </dataTable>
    </div>
</template>
<script>
import dataTable from "@/component/dataTable"
import {scheduleClass} from "@/class/scheduleClass"
import adminTableModal from "@/modal/adminTableModal.vue"
import { useAdminStore } from '@/stores/admin'
import { useScheduleStore } from '@/stores/schedule'
import { storeToRefs } from "pinia"
import { computed, onMounted, inject, ref, onActivated, watch } from "vue"
import { useRouter, useRoute } from "vue-router"
import util from "@/data/util"
import scheduleData from '@/data/scheduleData'
import * as XLSX from 'xlsx'

export default {
    components: {
        dataTable,
    },
    setup(props, ctx) {
        const modalManager = inject('ModalManager')

        const adminStore = useAdminStore()
        const scheduleStore = useScheduleStore()
        const router = useRouter()
        const route = useRoute()

        const { adminSchedules, scheduleTotalCount } = storeToRefs(adminStore)

        const init_page = ref(1)
        const reverse = ref(false)
        const orders = ref([])

        const downloadExcel = async() => {
            const data = tableData(await adminStore.getSchedules({xlsx: true, order: ['id']}))
            let jsonData = []
            data?.content.forEach(elem => {
                let a = {}
                data?.header.forEach(item => {
                    a[item.name] = elem?.[item.value] ?? '-'
                })
                jsonData.push(a)
            })

            const workBook = XLSX.utils.book_new()
            const tbData = XLSX.utils.json_to_sheet(jsonData)
            XLSX.utils.book_append_sheet(workBook, tbData, 'ScheduleData')
            XLSX.writeFile(workBook, 'ScheduleData.xlsx')
        }

        const onDetail = (item) => {
            return () => {
                router.push({ path : "/meeting/schedule/detail", query : { scheduleno : item.id } })
            }
        }

        const tableData = (data) => {
            return {
                header : [
                    {name : "No", value : "id", order: ['id']},
                    {name : "Title", value : "title", order: ['title'], fixed : true},
                    {name : "Invitor Name", value : "owner_name", order: ['owner_name']},
                    {name : "Invitor Email", value : "owner_email", order: ['owner_email']},
                    {name : "Date", value : "date", order: ['start_time', 'end_time'], depdc: 'time'},
                    {name : "Time", value : "time", order: ['start_time', 'end_time']},
                    {name : "Status", value : "statusText", order: ['status']},
                    {name : "Created", value : "created_at", order: ['created_at']},
                ],
                content : data?.map(item => {
                    return {
                        ...item,
                        callback : onDetail(item),
                        date: toFormatString(item.date, 'YYYY-MM-DD'),
                        time : `${toFormatString(item.start_time, 'hh:mm')} ~ ${toFormatString(item.end_time, 'hh:mm')}`,
                        statusText : scheduleData.getMStatusText(item.status)?.text,
                        created_at: toFormatString(item.created_at, 'YYYY-MM-DD hh:mm'),
                    }
                }),
                column : orders.value,
                reverse: reverse.value
            }
        }

        const tableDatas = computed(() => {
            return tableData(adminSchedules.value)
        })

        const sorting = (order) => {
            orders.value = order

            adminStore.getSchedules({ page: route.query.page, order: order })
            router.push({
                path: route.path,
                query: {
                    ...route.query,
                    order: order
                }
            })
        }

        const toFormatString = (timestamp, format) => {
            return util.date_format(util.convertTimeStmapToDateTime(timestamp), format)
        }
        
        const onPageUpdate = (page) => {
            adminStore.getSchedules({ 
                page: page,
                order: orders.value
            })
            router.push({
                path: route.path,
                query: {
                    ...route.query,
                    page: page,
                }
            })
        }

        const init = async() => {
            init_page.value = 1
            orders.value = []

            if(route.query.page) {
                init_page.value = Number(route.query.page)
            }
            if(route.query.order) {
                if(typeof route.query.order == 'string') {
                    orders.value = [route.query.order]
                } else {
                    orders.value = route.query.order
                }
            }
            await adminStore.getSchedules({ page: route.query.page, order: orders.value })
        }

        onMounted(async () => {
            await init()
        })

        watch(() => route.query, async() => {
            if(route.path.includes("schedule")) {
                await init()
            }
        })

        return {
            adminSchedules,
            tableDatas,
            util : util,
            init_page,
            scheduleTotalCount,
            reverse,

            sorting,
            onPageUpdate,
            downloadExcel,
        }
    },
}
</script>

<style scoped lang="scss">
.contentStyle {
    max-width: 300px;
    max-height: 80px;
    text-align:center;
    overflow: hidden;
    &:deep(*) {
        width: 100%;
        height: 100%;
    }
}
.headerStyle {
    max-width: 76.25rem;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
    
    .downloadStyle {
        background-color: #9C9C9C;
        color: white;
        border-radius: 50px;
        &:hover {
            background-color: #910457;
        }
        i {
            padding-left: 10px;
        }
    }
}
</style>