<template>
    <div class="headerStyle">
        <v-btn class="downloadStyle" @click="downloadExcel" :disabled="tableDatas?.content?.length == 0">
            Download<v-icon>mdi-file-excel</v-icon>
        </v-btn>
    </div>
    <div>
        <dataTable :tableData="tableDatas"
            :totalCount="totalCount" 
            @onPageUpdate="onPageUpdate"
            :init_page="init_page"
            @sorting="sorting"
        >
            <!-- <template v-slot:note="data">
                <div class="noteStyle">
                    {{data.note}} 
                </div>
            </template> -->
        </dataTable>
    </div>
</template>

<script>
import adminTableModal from "@/modal/adminTableModal.vue"
import dataTable from "@/component/dataTable"
import { useConferenceStore } from '@/stores/conference'
import { storeToRefs } from "pinia"
import { computed, onMounted, inject, ref, onActivated, watch } from "vue"
import { useRouter, useRoute } from "vue-router"
import util from '@/data/util'
import * as XLSX from 'xlsx'

export default {
    components : {
        dataTable,
    },
    setup(props, ctx) {
        const modalManager = inject("ModalManager")

        const conferenceStore = useConferenceStore()
        const router = useRouter()
        const route = useRoute()

        const { conferences, totalCount } = storeToRefs(conferenceStore)

        const init_page = ref(1)
        const reverse = ref(false)
        const orders = ref([])

        const downloadExcel = async() => {
            const data = tableData(await conferenceStore.fetchConferences({xlsx: true, order: ['id']}))
            let jsonData = []
            data?.content.forEach(elem => {
                let a = {}
                data?.header.forEach(item => {
                    a[item.name] = elem?.[item.value] ?? '-'
                })
                jsonData.push(a)
            })

            const workBook = XLSX.utils.book_new()
            const tbData = XLSX.utils.json_to_sheet(jsonData)
            XLSX.utils.book_append_sheet(workBook, tbData, 'ConferenceData')
            XLSX.writeFile(workBook, 'ConferenceData.xlsx')
        }

        const onDetail = (item) => {
            router.push({ path : "/conference/detail", query : { conferenceno : item.id } })
        }
        const onDelete = (item) => {
            conferenceStore.deleteConference(item.id)
        }
        const onCreate = () => {

        }
        const onUpdate = (item) => {
            router.push({ path: '/conference/edit', query: { conferenceno: item.id } })
            // modalManager.showdialog("Not Working....")
            console.log(item)
        }
        const onCallback = (item) => {
            return () => {
                modalManager.showEx(adminTableModal,
                {
                    bind : {
                        title : `Management Conference<br> No : ${item.id}`,
                        data : item,
                        value : "conference"
                    },
                    on : {
                        read : onDetail,
                        delete : onDelete,
                        update : onUpdate,
                    }
                }, true)
            }
        }

        const tableData = (data) => {
            return {
                header : [
                    {name : "No", value : "id", order: ['id']},
                    {name : "Title", value : "title", order: ['title'], fixed : true},
                    {name : "Invitor Name", value : "owner_name", order: ['owner_name']},
                    {name : "Invitor Email", value : "owner_email", order: ['owner_email']},
                    {name : "Location", value : "location", order: ['location'], fixed : true},
                    {name : "Note", value : "note", fixed : true},
                    {name : "Date", value : "datetime", order: ['start_date', 'end_date']},
                    {name : "Created", value : "created_at", order: ['created_at']},
                ],
                content :
                    data?.map(item => {
                        return {
                            ...item,
                            callback : onCallback(item),
                            datetime : `${toFormatString(item.start_date)} ~ ${toFormatString(item.end_date)}`,
                            created_at: toFormatString(item.created_at)
                        }
                    }),
                column : orders.value,
                reverse: reverse.value
            }
        }

        const tableDatas = computed(() => {
            return tableData(conferences.value)
        })

        const sorting = (order) => {
            orders.value = order
            conferenceStore.fetchConferences({ page: route.query.page, order: order })
            router.push({
                path : route.path,
                query : {
                    ...route.query,
                    order : order
                }
            })
        }

        const toFormatString = (timestamp) => {
            return util.date_format(util.convertTimeStmapToDateTime(timestamp), 'YYYY-MM-DD')
        }
        
        const onPageUpdate = (page) => {
            conferenceStore.fetchConferences({
                page : page,
                order: orders.value
            })
            router.push({
                path : route.path,
                query : {
                    ...route.query,
                    page : page
                }
            })
        }

        const init = async() => {
            init_page.value = 1
            orders.value = []

            if(route.query.page) {
                init_page.value = Number(route.query.page)
            }
            if(route.query.order) {
                if(typeof route.query.order == 'string') {
                    orders.value = [route.query.order]
                } else {
                    orders.value = route.query.order
                }
            }
            await conferenceStore.fetchConferences({ page: route.query.page, order: orders.value })
        }

        onMounted(async () => {
            await init()
        })

        watch(() => route.query, async() => {
            if(route.path.includes("conference")) {
                await init()
            }
        })

        return {
            conferences,
            tableDatas,
            util : util,
            totalCount,
            init_page,

            onPageUpdate,
            sorting,
            downloadExcel,
        }
    },
}
</script>

<style scoped lang="scss">
.noteStyle {
    max-width: 200px;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}
.headerStyle {
    max-width: 76.25rem;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
    
    .downloadStyle {
        background-color: #9C9C9C;
        color: white;
        border-radius: 50px;
        &:hover {
            background-color: #1E3560;
        }
        i {
            padding-left: 10px;
        }
    }
}
</style>