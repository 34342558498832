export default {
    USER_TYPE : {
        NORMAL : 0,
        ADMIN : 9,
    },
    NETWORK_TYPE : {
        BUDDY : 1 << 0,
        MET : 1 << 1,
        FOLLOW : 1 << 2,
    },
    getNetworkText(type) {
        switch(Number(type)) {
            // 친구
            case this.NETWORK_TYPE.BUDDY : return "Buddy"
            // 이미 만난
            case this.NETWORK_TYPE.MET : return "We Met"
            // 찜
            case this.NETWORK_TYPE.FOLLOW : return "Follow"
            default : return ""
        }
    }
}