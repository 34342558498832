<template>
  <main class="main">
    <a @click="onConferenceDetail">
      <div class="cardMain">
        <div class="cardItem">
          <v-card class="cardStyle" elevation="5">
            <div class="imgBox">
                <imageShow :src="conferenceData.image" :isUrl="true" :isLoading="isLoading" alt="conference_img"/>
            </div>
            <div class="titleStyle">
              <div>
                {{ conferenceData.title }}
              </div>
            </div>
            <div class="contentStyle">
              <p>{{ toFormatString(conferenceData.start_date, 'YYYY-MM-DD') }} ~ {{ toFormatString(conferenceData.end_date, 'YYYY-MM-DD') }}</p>
              <p><img :src="util.getCountryFlag(conferenceData.country_iso2)"> {{ conferenceData.country_name }}</p>
              <p>{{ conferenceData.note }}</p>
            </div>
          </v-card>
        </div>
      </div>
    </a>
  </main>
</template>

<script>
import util from '@/data/util'
import imageShow from "@/component/imageShow.vue"
export default {
    components : {
        imageShow,
    },
    props: {
        conferenceData: Object,
        isLoading : Boolean,
    },
    data() {
      return {
        util: util
      }
    },
    methods: {
      onConferenceDetail() {
        this.$router.push({ path: '/conference/detail', query: { conferenceno: this.conferenceData.id } })
      },

      toFormatString(timestamp, format) {
            return util.date_format(util.convertTimeStmapToDateTime(timestamp), format)
      }
    }
}
</script>

<style lang="scss" scoped>
a {
    cursor: pointer;
}
.cardStyle {
  width: 18rem; height: 300px;
  min-height: 270px;
  background-color: #fff;
  position: relative;
  margin: 40px 10px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  overflow: visible;
  transition: all 0.5s ease;

  &:hover {
    height: 350px;

    .contentStyle {
      visibility: visible;
      opacity: 1;
      transition-delay: 0.3s;
    }
  }

  .imgBox {
    position: relative;
    // top: -60px;
    width: 240px; height: 220px;
    min-height: 220px;
    margin: 0 auto;
  }
}
.imgBox > img {
    width: 100%; height: 100%;
    border: none;
    outline: none;

//   border-radius: 1rem;
}
.titleStyle {
  position: relative;
//   margin-top: -3rem;
  color: #333333;

  div {
    // font-weight: bold;

    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap;
    font: bold 16px / 1.36364 NanumBarunGothic;
  }
}
.contentStyle {
    user-select: none;
  position: relative;
  visibility: hidden;
  opacity: 0;
  color: #6c6c6c;
  text-align: center;
  padding: 10px 15px;
  transition: all 0.5s ease;
  p {
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap;
    img {
        margin : 0 5px;
    }
  }
}
</style>