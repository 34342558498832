import { createApp, markRaw } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import EventManager from "@/services/eventmanager.service";
// import ApiService from "@/services/api.service"
// import JwtService from "@/services/jwt.service";
// import { ApiReq } from '@/class/apiCallClass'
import ModalManager from "@/services/modal.service"
import axios from "axios";
import VueAxios from "vue-axios";

import ProfileImg from "@/component/profileImage.vue"
import AwaitVBtn from "@/component/awaitVBtn.vue"

import ApiV2Service from "@/services/api.v2.service"
import { TokenService } from "@/services/storage.service"
import { createPinia } from "pinia"

loadFonts()

ApiV2Service.init(process.env.VUE_APP_BASE_API)
if (TokenService.getAccessToken()) {
  ApiV2Service.setHeader()
  ApiV2Service.mount401Interceptor()
}

const pinia = createPinia()
pinia.use(({store}) => {
  store.router = markRaw(router)
})

// ApiService.init();
const app = createApp(App);

// app.config.globalProperties.$ApiService = ApiService;
// app.config.globalProperties.$JwtService = JwtService;
// app.config.globalProperties.$ApiReq = ApiReq;

app.component("profile-image", ProfileImg)
app.component("await-v-btn", AwaitVBtn)

app
  .use(pinia)
  .use(VueAxios, axios)
  .use(EventManager)
  .use(router)
  .use(vuetify)
  .use(ModalManager)
  .mount('#app')
