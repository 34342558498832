import { defineStore } from "pinia";
import { ApiError } from "@/services/api.v2.service";
import ScheduleService from "@/services/schedule.service";
import storeData from "./storeData";

export const useScheduleStore = defineStore("schedule", {
    state: () => ({
        title : "",
        evnt : "",
        company : "",
        ctr : [],
        evntTitle : "",
        name : "",
        schedules: null,
        schedule: null,
        stim : null,
        etim : null,
        totalCount : 0,
        order : null,
        page: 1,
        perPage: storeData.default_per_page,
        error: { code: 0, message: "" },
    }),

    actions: {
        async createSchedule(data) {
            try {
                return await ScheduleService.createSchedule(data)
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }
                return false
            }
        },

        async fetchSchedules(params) {
            try {
                this.title = params?.title
                this.company = params?.company
                this.evntTtile = params?.evntTtile
                this.ctr = params?.ctr
                this.name = params?.name
                
                this.evnt = params?.evnt
                this.stim = params?.stim
                this.etim = params?.etim
                this.order = params?.order !== undefined ? params.order : this.order
                params.order = this.order
                this.page = params?.page != undefined ? params.page : this.page
                this.perPage = params?.evnt || (params?.stim && params?.etim) ? 9999 : storeData.default_per_page

                const response = await ScheduleService.fetchSchedules(params, this.page, this.perPage)
                if(response) {
                    this.schedules = response.data
                    this.totalCount = Number(response.count)
                    return this.schedules
                }
                return false
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async moreSchedules() {
            try {
                this.page += 1
                const params = {
                    title : this.title,
                    evnt : this.evnt,
                    order : this.order,
                    company : this.company,
                    ctr : this.ctr,
                    evntTitle : this.evntTitle,
                    name : this.name
                }
                const res = await ScheduleService.fetchSchedules(params, this.page, this.perPage)

                if(res) {
                    this.schedules = this.schedules.concat(res.data)
                    return this.schedules
                }
                else {
                    return res;
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async fetchSchedule(scheduleId) {
            try {
                this.schedule = await ScheduleService.fetchSchedule(scheduleId)
                // json string to json object
                if (this.schedule && this.schedule.adjustments) {
                    this.schedule.adjustments.forEach(adjustment => {
                        adjustment.recipient = JSON.parse(adjustment.recipient)
                    })
                }

                return this.schedule
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async proceedSchedule(scheduleId, data) {
            try {
                return await ScheduleService.proceedSchedule(scheduleId, data)
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async cancelSchedule(scheduleId) {
            try {
                return await ScheduleService.cancelSchedule(scheduleId)
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async deleteSchedule(scheduleId) {
            try {
                return await ScheduleService.deleteSchedule(scheduleId)
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        getSchedule(scheduleId) {
            const res = this.schedules.filter(schedule => schedule.id == scheduleId)
            if(res.length > 0) {
                return res[0]
            }
            return false
        },

        clearSchedule () {
            this.schedule = null;
        },

        clearSchedules () {
            this.schedules = null;
        },

        async createScheduleResult(scheduleId, data) {
            try {
                return await ScheduleService.createScheduleResult(scheduleId, data)
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }
                return false
            }
        },

        async mediateSchedule(scheduleId, data) {
            try {
                return await ScheduleService.mediateSchedule(scheduleId, data)
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }
                return false
            }
        },

        async mediateUpdateSchedule(scheduleId, data) {
            try {
                return await ScheduleService.mediateUpdateSchedule(scheduleId, data)
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }
                return false
            }
        },
    },
})