<template>
    <div>
        <template v-if="events?.length > 0" >
            <div v-for="event in events" :key="event">
                <EventCard :eventData="event" />
            </div>
            <bottomRefresh :callBack="onScrolling" v-if="isBottomRefresh" class="bottomRefresh"/>
        </template>
        <template v-else>
            <div class="noScheduleStyle" :style="{ maxWidth: '54.375rem' }" >
                <h1>There is no events.</h1>
            </div>
        </template>
    </div>
</template>
<script>
import EventCard from "@/page/meeting/event/eventCard.vue"
import { useEventStore } from '@/stores/event'
import { computed, nextTick, onActivated, onDeactivated } from 'vue'
import { storeToRefs } from 'pinia'
import bottomRefresh from "@/component/bottomRefresh.vue"
export default {
    components : {
        EventCard,
        bottomRefresh,
    },
    props : {
        component : String,
    },
    setup(props, ctx) {
        const eventStore = useEventStore()
        const {events, totalCount} = storeToRefs(eventStore)

        const refresh = () => {
            eventStore.fetchEvents({
                page : 1,
                order : null,
            });
        }

        const isBottomRefresh = computed(() => {
            let res = false
            nextTick(() => {
                if(props.component == "eventCardList" && events.value && totalCount.value && events.value.length) {
                    res = events.value.length < totalCount.value
                }
            })
            return res;
        })
        const onScrolling = async () => {
            await eventStore.moreEvents()
        }

        onActivated(() => {
            refresh()
        })
        
        onDeactivated(() => {
            eventStore.clearEvents()
        })

        return {
            events,
            isBottomRefresh,

            refresh,
            onScrolling,
        }
    }
}
</script>

<style scoped lang="scss">
// .bottomRefresh {
    
//     width: 37.5rem;
// }
</style>