import { ApiV2Service, ApiError } from "./api.v2.service";

const UserService = {
    fetchMe: async function() {
        const response = await ApiV2Service.get('/user/me')

        if (response.data.success) {
            return response.data.data
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    updateMe: async function(data) {
        const response = await ApiV2Service.post('/user/me', data)

        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    withdraw: async function() {
        const response = await ApiV2Service.post('/withdraw')

        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    fetchUser : async function(userId) {
        const response = await ApiV2Service.get(`/user/${userId}`)

        if (response.data.success) {
            return response.data.data
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },
    deleteUser : async function(userId) {
        const response = await ApiV2Service.delete(`/user/${userId}`)

        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },
    updateUser: async function(userId, data) {
        const response = await ApiV2Service.post(`/user/${userId}`, data)

        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },
}

export default UserService