import MeetingLayout from "@/page/meeting/meetingLayout.vue"

import EventLayout from "@/page/meeting/event/eventLayout.vue"
import EventDetail from "@/page/meeting/event/eventDetail.vue"
import EventPage from "@/page/meeting/event/eventMainPage.vue"

import ScheduleLayout from "@/page/meeting/schedule/scheduleLayout.vue"
import SchedulePage from "@/page/meeting/schedule/scheduleMainPage.vue"
import ScheduleCreate from "@/page/meeting/schedule/scheduleCreate.vue"
import ScheduleDetail from "@/page/meeting/schedule/scheduleDetail.vue"

import HistoryLayout from "@/page/meeting/history/historyLayout.vue"
import HistoryMainPage from "@/page/meeting/history/historyMainPage.vue"

export default [
    {
        path : "/meeting",
        redirect : "/meeting/event",
        component : MeetingLayout,
        children : [
            {
                path : "/meeting/event",
                component : EventLayout,
                children : [
                    {
                        path : "/meeting/event",
                        component : EventPage
                    },
                    {
                        path : "/meeting/event/detail",
                        component : EventDetail,
                    }
                ]
            },
            {
                path : "/meeting/schedule",
                component : ScheduleLayout,
                children : [
                    {
                        path : "/meeting/schedule",
                        component : SchedulePage
                    },
                    {
                        // for network user
                        name : "scheduleCreate",
                        path : "/meeting/schedule/create",
                        component : ScheduleCreate,
                        props : true,
                    },
                    {
                        path : "/meeting/schedule/detail",
                        component : ScheduleDetail
                    },
                ]
            },
            {
                path : "/meeting/history",
                component : HistoryLayout,
                children : [
                    {
                        path : "/meeting/history",
                        component : HistoryMainPage,
                    }
                ]
            }
        ]
    },
]