export default {
    departmentData : [
        "Managerial",
        "International",
        "Patent",
        "Trademark /  Design",
    ],
    positionData : [
        "Managing Partner / Director",
        "Partner",
        "Associate"
    ],
    fieldData : [
        "Patent",
        "Trademark",
        "Design",
        "Other IP",
        "All IP"
    ]
}