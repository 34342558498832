import storeData from "@/stores/storeData";
import interceptor from "@/stores/interceptor";
import { ApiV2Service, ApiError } from "./api.v2.service";

const NetworkService = {
    createNetwork: async function(data) {
        const response = await ApiV2Service.post('/network', data)

        if (response.data.success) {
            return response.data.data
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    fetchNetworks: async function(page = 1, perPage = storeData.default_per_page, params = {}) {
        params =  {
            ...params,
            page: page, 
            per_page: perPage
        }
        const response = await ApiV2Service.get('/networks', params)

        if (response.data.success) {
            return {
                data : response.data.data.map(item => {return interceptor.timezoneDecode(item)}),
                count : response.headers["x-total-count"],
                link : response.headers.link,
            }
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    updateNetwork: async function(networkId, data) {
        const response = await ApiV2Service.put(`/network/${networkId}`, data)

        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    deleteNetwork: async function(networkId, params = {}) {
        if(!params.type) {
            params.type = ""
        }
        const response = await ApiV2Service.delete(`/network/${networkId}`, params)

        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    fetchUsers: async function(type = 0, country = '', name = "", field = "", page = 1, perPage = storeData.default_per_page) {
        const response = await ApiV2Service.get('/users', { type: type, ctr: country, name: name, field: field, page: page, per_page: perPage })

        if (response.data.success) {
            return {
                data : response.data.data.map(item => {return interceptor.timezoneDecode(item)}),
                count : response.headers["x-total-count"],
                link : response.headers.link,
            }
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    }
}

export default NetworkService