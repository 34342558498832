import { defineStore } from "pinia";
import { ApiError } from "@/services/api.v2.service";
import PostService from "@/services/post.service";
import { computed } from "vue";
import storeData from "./storeData";

export const usePostStore = defineStore("post", {
    state: () => ({
        post: null,
        posts: null,
        totalCount : 0,
        isLoading : false,
        name: "",
        content: "",
        country: "",
        page: 1,
        perPage: storeData.default_feed_per_page,
        error: { code: 0, message: "" },
    }),
    getters : {
        getPost(state) { 
            return (postId) => {
                const res = state.posts.findIndex(post => post.id == postId)
                if(res != -1) {
                    return computed(() =>  {return state.posts[res]})
                }
                return false
            }
        }
    },

    actions: {
        async createPost(contents) {
            try {
                return await PostService.createPost(contents)
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async fetchPosts(params) {
            try {
                this.isLoading = true;
                let res = false;
                if(params.noSave) {
                    res = await PostService.fetchPosts(params.name ?? '', params.content ?? '', params.country ?? '', params.page ?? 1, params.per_page ?? storeData.default_feed_per_page)
                }
                else {
                    this.name = params?.name !== undefined ? params.name : this.name
                    this.content = params?.content !== undefined ? params.content : this.content
                    this.country = params?.country !== undefined ? params.country : this.country
                    this.page = params?.page !== undefined ? params.page : this.page
                    this.perPage = params?.per_page != undefined ? params.per_page : storeData.default_feed_per_page
                    res = await PostService.fetchPosts(this.name, this.content, this.country, this.page, this.perPage)
                }

                this.isLoading = false;
                if(res) {
                    this.posts = res.data
                    this.totalCount = res.count
                    return this.posts
                }
                return false
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }
                this.isLoading = false;
                return false
            }
        },

        async morePosts() {
            try {
                this.page += 1
                const res = await PostService.fetchPosts(this.name, this.content, this.country, this.page, this.perPage)

                if(res) {
                    this.posts = this.posts.concat(res.data)
                    return this.posts
                }
                else {
                    return res;
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async fetchPost(postId) {
            try {
                this.post = await PostService.fetchPost(postId)

                return this.post
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async updatePost(postId, contents) {
            try {
                return await PostService.updatePost(postId, contents)
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async deletePost(postId) {
            try {
                return await PostService.deletePost(postId)
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        clearPost () {
            this.post = null;
        },

        async createComment(postId, contents, parentCommentId = null) {
            try {
                return await PostService.createComment(postId, contents, parentCommentId)
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async fetchComments(postId) {
            try {
                const comments = await PostService.fetchComments(postId)
                if (this.post && this.post.id == postId) {
                    this.post.comments = comments
                }

                return comments
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async updateComment(postId, commentId, contents) {
            try {
                return await PostService.updateComment(postId, commentId, contents)
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async deleteComment(postId, commentId) {
            try {
                return await PostService.deleteComment(postId, commentId)
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async setLike(postId, isLike) {
            try {
                return await PostService.setLike(postId, isLike)
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },
    },
})