<template>
    <div class="containerStyle">
        <Header :isLoading="isLoading"/>
        <Content :isLoading="isLoading"/>
    </div>
</template>
<script>
import Content from "@/page/profile/userProfileContent.vue"
import Header from "@/page/profile/userProfileHeader.vue"
import { useUserStore } from '@/stores/user'
import { inject, onMounted, onUnmounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
export default {
    components : {
        Header,
        Content
    },
    setup(props, ctx) {
        const network = ref([])
        const profile = ref({})
        const route = useRoute()
        const isLoading = ref(false)

        const userStore = useUserStore()
        const { userFromId } = storeToRefs(userStore)
        const router = useRouter()

        const modalManager = inject('ModalManager')

        onMounted(() => {
            isLoading.value = false
            userStore.fetchUser(route.query.userno)
            .then(fetch_res => {
                if(!fetch_res) {
                    modalManager.showdialog("No Permission")
                    .then(res => {
                        router.back()
                    })
                }
                isLoading.value = true;
            })
        })
        onUnmounted(() => {
            userStore.clearUser();
        })

        return {
            network,
            profile,

            userFromId,
        }
    }
}
</script>

<style scoped lang="scss">
.containerStyle {

    width: 54.375rem;
    background: rgba(255, 255, 255, 0.8);
    height: 100%;
    border-radius: 1rem;
    margin-right: 1rem;
    box-shadow: 2px 5px 11px #00000029;
}
</style>