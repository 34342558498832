<template>
    <div class="Auth">
        <div class="containerStyle">
            <figure>
            <img :src="require('@/assets/login_logo.png')" alt="'login-logo'" />
            </figure>
            <div class="loginFormStyle">
                <v-form id="sign-up-form" @submit.prevent="onSubmit">
                    <section>
                        <v-text-field
                            v-if="isSignupPage"
                            clearable
                            label="Email"
                            type="text"
                            v-model="email"
                            :disabled="true"
                        ></v-text-field>
                        <v-text-field
                            v-if="isSignupPage"
                            clearable
                            label="Account"
                            type="text"
                            v-model="account"
                            append-icon="mdi-check"
                            @click:append="onExistAccount"
                            :class="{ 'icon-green': isNotExistAccount }"
                            :error-messages="accountErrorMessage"
                            @input="isNotExistAccount = false; accountErrorMessage = ''"
                        ></v-text-field>
                        <v-text-field
                            v-if="isSignupPage"
                            clearable
                            label="Name"
                            type="text"
                            v-model="name"
                        ></v-text-field>
                        <v-text-field
                            clearable
                            :label="isSignupPage ? 'Password' : 'New Password'"
                            v-model="password"
                            class="login_input"
                            :type="isShowPassword ? 'text' : 'password'"
                            :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="isShowPassword = !isShowPassword"
                        />
                        <v-text-field
                            clearable
                            :label="isSignupPage ? 'Confirm Password' : 'Confirm New Password'"
                            v-model="confirm"
                            class="login_input"
                            :type="isShowConfirm ? 'text' : 'password'"
                            :append-icon="isShowConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="isShowConfirm = !isShowConfirm"
                        />
                    </section>
                    <section>
                        <div class="privacyStyle" v-if="isSignupPage">
                            <p>
                            By clicking Sign Up, you are indicating that you have read and
                            <br />
                            acknowledge the <a @click="onTerms" class="clickable" >Terms of Service</a> and
                            <a @click="onPrivacy" class="clickable">Privacy Notice</a>.
                            </p>
                        </div>
                        <div class="button-div">
                            <v-btn type="submit" form="sign-up-form" >
                                {{ isSignupPage ? "SIGN UP" : "CHANGE" }}
                            </v-btn>
                        </div>
                    </section>
                </v-form>
            </div>
        </div>
    </div>
</template>

<script>
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'
import { computed, inject, onMounted, ref, watch, onUnmounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import policyViewModal from './policyViewModal.vue'
import util from '@/data/util'

export default {
    setup(props, ctx) {
        const modalManager = inject("ModalManager")

        const authStore = useAuthStore()
        const router = useRouter()
        const route = useRoute()

        const email = ref("")
        const account = ref("")
        const name = ref("")
        const password = ref("")
        const confirm = ref("")
        const isNotExistAccount = ref(false)
        const accountErrorMessage = ref("")
        const isShowPassword = ref(false)
        const isShowConfirm = ref(false)

        const pageType = ref("up")

        const { authentication, authenticationError } = storeToRefs(authStore)

        const isSignupPage = computed(() => {
            return pageType.value == "up"
        })

        const onExistAccount = async () => {
            if (!account.value || account.value.length < 4) {
                accountErrorMessage.value = "Account length must be at least 4 characters."
                return
            }

            const result = await authStore.existAccount(account.value)
            if (result) {
                isNotExistAccount.value = false
                accountErrorMessage.value = "This account is already in use."
            } else {
                isNotExistAccount.value = true
                accountErrorMessage.value = ""
            }
        }
        const checkData = () => {
            let req = [
                {value : password.value && confirm.value , text : "Password"},
                {value : password.value && confirm.value , check : password.value.trim() == confirm.value.trim(), text : "The passwords are different."},
            ]
            if(isSignupPage.value) {
                req.push({value : account.value, text : "Account"})
                req.push({value : name.value, text : "Name"})
            }
            const res = util.dataCheck(req)
            if(res.length > 0) {
                return res.join("<br>")
            }
            return false
        }
        const onSubmit = async () => {
            if (isSignupPage.value && !isNotExistAccount.value) {
                modalManager.showdialog(`Please do a duplicate account check.`)
                return
            }
            const res = checkData()
            if(res) {
                modalManager.showdialog(`Please check your data: <br>${res}`)
                return;
            }

            let result = false
            let successText = "Reset"
            if (isSignupPage.value) {
                successText = "Registered"
                result = await authStore.join(email.value, account.value, password.value, name.value)
            } else {
                result = await authStore.resetPassword(email.value, password.value)
            }

            if (result !== false) {
                modalManager.showdialog(`${successText}`)
                .then(() => {
                    router.push("/login")
                })
            }
            else {
                modalManager.showdialog(`Failed`)
            }
        }
        const onTerms = () => {
            modalManager.showEx(policyViewModal,{
                bind : {
                    text : "terms"
                }
            }, true)
        }
        const onPrivacy = () => {
            modalManager.showEx(policyViewModal,{
                bind : {
                    text : "privacy"
                }
            }, true)
        }

        onMounted(() => {
            if (!authStore.isVerifiedEmailExist()) {
                router.push("/email/check")
                return
            }

            email.value = authStore.email

            if (route.path.includes("up")) {
                pageType.value = "up"
            } else if (route.path.includes("reset")) {
                pageType.value = "forget"
            }
        })
        onUnmounted(() => {
            authStore.clearAuthenticationError()
        })

        watch(() => route.path, () => {
            authStore.destoryVerifiedEmail()
        })

        return {
            email,
            account, 
            name, 
            password, 
            confirm,
            isNotExistAccount,
            accountErrorMessage,
            isShowPassword,
            isShowConfirm,

            pageType,
            
            authentication, 
            authenticationError,
            
            isSignupPage,

            onExistAccount,
            onSubmit,
            onTerms,
            onPrivacy,
        }
    },
}
</script>

<style scoped lang="scss">

.Auth {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.privacyStyle {
    margin: 0 0 1.5625rem;
    text-align: center;
    padding: 0;
    font: normal normal normal 12px/20px NanumSquareOTF;
    color: #6c6c6c;

    a {
        text-decoration: none;
        color: #910457;
    }

    a:hover {
        text-decoration: underline;
    }
}
.containerStyle { 
    
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 37.1875rem;
    border-radius: 1rem;
    box-shadow: 0 3px 6px #00000029;
    background: rgba(255, 255, 255, 0.5);

    figure {
        margin: 4.375rem 0 3.125rem;
        width: 116px;
        height: 150px;

        img {
            max-width: 100%;
            max-height: 100%;
            height: 150px;
        }
    }

    .title {
        color: #333;
        margin: 0 0 1rem;
        font: normal normal 800 20px/23px NanumSquareOTF;
    }
}
.loginFormStyle {
    
    display: flex;
    flex-direction: column;
    max-width: 355.69px;
    width: 100%;
    justify-content: center;

    section {
        width : 100%;
        flex: 1;
        margin-bottom : 40px;
        margin-left : auto;
        margin-right: auto;

        .icon-green {
            &:deep(.v-input__append .v-icon) {
                color: green;
            }
        }
    }

    a {
        text-decoration: none;
    }

}
</style>
