<template>
    <div>
        <div class="headerStyle">
            <div class="searchBoxStyle">
                <div>
                    <nationSelector
                        @updateCountry="onUpdateCountry" 
                        :selectedCountries="selectedCountries" 
                        variant="outlined"/>
                </div>
                <div class="typeSelector">
                    <v-select 
                        variant="outlined" v-model="searchType" hide-details 
                        label="Search Field" :items="typeList" item-value="value" item-title="title"
                    />
                </div>
                <div>
                    <searchBox  
                        @onSearch="onSearch" 
                        :useSpecialChar="false" 
                        :input="searchText"
                    />
                </div>
            </div>
            <v-btn class="downloadStyle" @click="downloadExcel" :disabled="tableDatas?.content?.length == 0">
                Download<v-icon>mdi-file-excel</v-icon>
            </v-btn>
        </div>
        <!-- <userSearch @onSearchUser="onSearchUser" ref="url" class="headerStyle" /> -->
        <div>
            <dataTable 
                :tableData="tableDatas" 
                :init_page="init_page"
                :totalCount="userTotalCount"
                @onPageUpdate="onPageUpdate"
                @sorting="sorting" />
        </div>
    </div>
</template>

<script>
import adminTableModal from "@/modal/adminTableModal.vue"
import dataTable from "@/component/dataTable"
import userSearch from '@/component/userSearch.vue'
import nationSelector from "@/component/nationSelector.vue"
import searchBox from "@/component/searchBox.vue"
import { useAdminStore } from '@/stores/admin'
import { useUserStore } from "@/stores/user"
import { storeToRefs } from "pinia"
import { computed, onMounted, inject, ref, watch } from "vue"
import { useRoute, useRouter } from "vue-router"
import util from '@/data/util'
import * as XLSX from 'xlsx'

export default {
    components : {
        dataTable,
        // userSearch,
        nationSelector,
        searchBox,
    },
    setup(props, ctx) {
        const modalManager = inject("ModalManager")

        const adminStore = useAdminStore()
        const userStore = useUserStore()
        const route = useRoute()
        const router = useRouter()

        const { adminUsers, adminUserCountry, userTotalCount } = storeToRefs(adminStore)

        const init_page = ref(1)
        const orders = ref([])
        const reverse = ref(false)

        const searchType = ref("name")
        const searchText = ref("")
        const selectedCountries = ref([])
        const typeList = ref([
            {title : "Name", value : "name"},
            {title : "Field", value : "field"}
        ])

        const downloadExcel = async() => {
            const data = tableData(await adminStore.getUsers({xlsx: true, order: ['id']}))
            let jsonData = []
            data?.content.forEach(elem => {
                let a = {}
                data?.header.forEach(item => {
                    a[item.name] = elem?.[item.value] ?? '-'
                })
                jsonData.push(a)
            })

            const workBook = XLSX.utils.book_new()
            const tbData = XLSX.utils.json_to_sheet(jsonData)
            XLSX.utils.book_append_sheet(workBook, tbData, 'UserData')
            XLSX.writeFile(workBook, 'UserData.xlsx')
        }

        const onDetail = (item) => {
            router.push({ path : "/profile/user", query : { userno : item.id } })
        }
        const onDelete = (item) => {
            userStore.deleteUser(item.id)
        }
        const onUpdate = (item) => {
            router.push({ path : "/profile/update/user", query : { userno : item.id } })
            modalManager.showdialog("Not Working....")
            console.log(item)
        }

        const onCallback = (item) => {
            return () => {
                modalManager.showEx(adminTableModal,
                {
                    bind : {
                        title : `Management User<br> No : ${item.id} | Name : ${item.name}`,
                        data : item,
                        value : "user"
                    },
                    on : {
                        read : onDetail,
                        delete : onDelete,
                        update : onUpdate,
                    }
                }, true)
            }
        }
        const onSearch = searchText => {
            let params = {};
            if(searchType.value) {
                params[searchType.value] = searchText
            }
            router.push({
                query : {
                    ...route.query,
                    ...params,
                },
            })
        }

        const onUpdateCountry = country => {
            router.push({
                query : {
                    ...route.query,
                    ctr : country.join(","),
                    page : 1,
                    order : "",
                }
            })
        }

        // const onSearchUser = (name, field) => {
        //     adminStore.getUsers({ 
        //         ctr: adminUserCountry, 
        //         name: name, 
        //         field: field,
        //         page: route.query.page,
        //         order: orders.value.length != 0 ? orders.value : ['-id']
        //     })

        //     router.push({
        //         path: route.path,
        //         query: { ...route.query }
        //     })
        // }

        const tableData = (data) => {
            return {
                header : [
                    {name : "No", value : "id", order: ['id']},
                    {name : "Name", value : "name", order: ['name']},
                    {name : "Email", value : "email", order: ['email']},
                    {name : "Phone", value : "phone", order: ['phone_code', 'phone_number']},
                    {name : "Company", value : "company", order: ['company']},
                    {name : "Country", value : "country_name", order: ['country_name']},
                    {name : "Department", value : "department", order: ['department']},
                    {name : "Position", value : "position", order: ['position']},
                    // {name : "Signature", value : "signature", order: ['signature']},
                    {name : "Created", value : "created_at", order: ['created_at']},
                ],
                content : 
                    data?.map(item => {
                        return {
                            ...item,
                            phone : item.phone_code && item.phone_number 
                                ?  `${item.phone_code} ${item.phone_number}`
                                : '-',
                            callback : onCallback(item),
                            created_at: toFormatString(item.created_at)
                        }
                    }),
                column : orders.value,
                reverse: reverse.value
            }                
        }

        const tableDatas = computed(() => {
            return tableData(adminUsers.value)
        })
        
        const onPageUpdate = (page) => {
            // adminStore.getUsers({
            //     ctr: route.query.ctr,
            //     name: route.query.name,
            //     field: route.query.field,
            //     page : page,
            //     order: orders.value.length != 0 ? orders.value : ['-id']
            // })
            router.push({
                query : {
                    ...route.query,
                    page : page
                }
            })
        }
        
        const sorting = (order) => {            
            orders.value = order

            // adminStore.getUsers({
            //     ctr: route.query.ctr,
            //     name: route.query.name,
            //     field: route.query.field,
            //     page: route.query.page,
            //     order: order,
            // })
            router.push({
                query: {
                    ...route.query,
                    order: order
                }
            })
        }

        const toFormatString = (timestamp) => {
            return util.date_format(util.convertTimeStmapToDateTime(timestamp), 'YYYY-MM-DD hh:mm')
        }

        const init = async() => {
            const params = {}
            init_page.value = 1

            if(route.query.ctr) {
                selectedCountries.value = route.query.ctr.split(",")
                params.ctr = route.query.ctr
            }
            else {
                selectedCountries.value = []
            }
            if(route.query.name || route.query.field) {
                if(route.query.name) {
                    searchType.value = "name"
                    searchText.value = route.query.name
                    params.name = route.query.name
                }
                if(route.query.field) {
                    searchType.value = "field"
                    searchText.value = route.query.field
                    params.field = route.query.field
                }
            }
            else {
                searchText.value = ""
            }
            
            if(route.query.page) {
                init_page.value = Number(route.query.page)
                params.page = route.query.page
            }
            if(route.query.order) {
                orders.value = route.query.order
                if(typeof route.query.order == 'string') {
                    orders.value = [route.query.order]
                }
                params.order = orders.value
            } else {
                params.order = ['-id']
                orders.value = []
            }
            await adminStore.getUsers(params)
        }

        onMounted(async () => {
            await init()
        })

        watch(() => route.query, async() => {
            if(route.path.includes("user")) {
                await init()
            }
        })
        
        return {
            adminUsers,
            tableData,
            util,
            init_page,
            userTotalCount,
            typeList,
            searchType,
            searchText,
            selectedCountries,

            onUpdateCountry,
            onSearch,
            // onSearchUser,
            sorting,
            onPageUpdate,
            downloadExcel,
            tableDatas
        }
    }
}
</script>

<style scoped lang="scss">
.headerStyle {
    max-width: 76.25rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    .searchBoxStyle {
        display: flex;
        max-width: 54.375rem;
        flex: 1;
        > div {
            width: 100%;
            margin: 0 5px;
            height: fit-content;
            &.typeSelector {
                display: flex;
                width: 230px;
            }
        }
    }
    .downloadStyle {
        align-self: center;
        background-color: #9C9C9C;
        color: white;
        border-radius: 50px;
        &:hover {
            background-color: #1E3560;
        }
        i {
            padding-left: 10px;
        }
    }
}


</style>