import MyProfile from "@/page/profile/myProfile.vue"
import UserProfile from "@/page/profile/userProfile.vue"

export default [
    {
        path : "/profile/update/me",
        component : MyProfile,
    },
    {
        path : "/profile/update/user",
        component : MyProfile,
    },
    {
        path : "/profile/user",
        component : UserProfile,
    }

]