<template>
    <div id="eventContainer">
        <div class="title">
            {{day.id}}
        </div>
        <div class="eventContentContainer">
            <template v-for="attr in attribues" :key="attr">
                <div class="eventContent" @click="onEventClick(attr.customData.data)">
                    <div class="contentDiv">
                        <div class="contentHeader">
                            Title :
                        </div> 
                        <div :style="{background : attr.customData.startEndStyle.backgroundColor}" class="colorDiv" />
                        <div class="contentData">
                            {{attr.customData.data.title}}
                        </div>
                    </div>
                    <div class="contentDiv">
                        <div calss="contentHeader">
                            Period :
                        </div>
                         <div class="contentData">
                            {{getTimeText(attr.customData.data)}}
                         </div>
                    </div>
                    <div class="contentDiv" v-if="attr.customData.data.country_name">
                        <div class="contentHeader">
                            Country : 
                        </div>
                        <img :src="util.getCountryFlag(attr.customData.data.country_iso2)">
                         <div class="contentData">
                            {{attr.customData.data.country_name}}
                         </div>
                    </div>
                </div>
            </template>
        </div>
        <div class="buttons">
            <v-btn block @click="onClose">
                CLOSE
            </v-btn>
        </div>
    </div>
</template>
<script>
import { computed, getCurrentInstance, inject, onMounted, ref } from 'vue-demi'
import util from '@/data/util'
export default {
    props : {
        day : Object,
        attribues : Object,
    },
    setup(props, ctx) {
        const modalManager = inject("ModalManager")
        const currentInstance = getCurrentInstance()
        const getTimeText = data => {
            const timezoneData = util.convertTimezoneJsonToData(data.country_timezones)
            const start = util.date_format(util.convertTimeStmapToDateTime(data.start_date), "YYYY-MM-DD")
            const end = util.date_format(util.convertTimeStmapToDateTime(data.end_date), "YYYY-MM-DD")
            return `${start} ~ ${end}` + (data.country_timezones ? ` (${timezoneData.gmtOffsetName})` : "")
        }
        const onClose = () => {
            modalManager.close(currentInstance)
        }
        const onEventClick = data => {
            modalManager.close(currentInstance, {
                event : data
            })
        }
        onMounted(() => {
            console.log(props.attribues)
        })
        return {
            util,
            
            getTimeText,
            onClose,
            onEventClick,
        }
    }
}
</script>

<style scoped lang="scss">
#eventContainer {
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    max-height: 600px;
    background: #fff;
    border-radius: 10px;
    padding : 10px;
    .title {
        display: flex;
        width: 100%;
        text-align: center;
        justify-content: center;

    }
    .eventContentContainer {
        display: flex;
        flex-direction: column;
        max-height: 700px;
        overflow-y : auto;
        .eventContent {

            background-color: rgba(255, 255, 255);
            box-shadow: 2px 5px 11px #00000029;
            border-radius: 1rem;
            padding: 8px;
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            margin-right: 10px;
            opacity: 0.7;
            &:hover{
                opacity: 1;
            }
            cursor: pointer;
            .contentDiv {
                font: 15px NanumSquareOTF;
                align-items: center;
                display: flex;
                word-break: break-all;
                img {
                    margin : 0 5px;
                }
                .colorDiv {
                    margin: 0 5px;
                    width: 15px;
                    height: 15px;
                }
                .contentHeader {
                    width: fit-content;
                    white-space: nowrap;
                }
                .contentData {
                    margin-left: 5px;
                    max-width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        .eventContentButton {
            margin-right: 0;
            margin-left: auto;
        }
    }
    .buttons {
        i {
            background: #6c6c6c;
            color : #fff;
        }
    }
}
</style>