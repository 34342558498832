<template>

    <div class="containerBlockStyle">
        <h1>{{isInvitation ? "WEMET - Invitation" : ""}}</h1>
        <div class="titleStyle">
            <h3>{{schedule?.title}}</h3>
            <div class="closeIcon" v-if="isMe(schedule?.owner_email) && isApply" @click="onCancel">
                <v-icon size="35">mdi-close</v-icon>
            </div>
        </div>
        <section class="meetingSectionStyle" ref="userTable">
            <h2>Participant</h2>
            <template v-for="(parti, index) in participants" :key="index">
                <v-divider v-if="index > 0" style="margin-top: 8px; margin-bottom: 8px"></v-divider>
                <div class="title-div" :id="parti.id" :class="{myData : isMe(parti?.email)}">
                    <p :class="{owner : isOwner(parti?.email)}">{{isOwner(parti?.email) ? "Invitor" : ""}}</p>
                    <div>
                        <profile-image :src="parti.data?.profile_image" :size="40"/>
                    </div>
                    <p>{{parti?.name ?? 'Non-member'}}</p>
                    <div class='divider'></div>
                    <p class='email'>{{parti?.email}}</p>
                    <template v-if="parti?.id && !isInvitation">
                        <div class="InfoLink" @click="onUserInfoLink(parti?.id)">
                            <v-icon :style="{ marginRight: '3px' }" >mdi-account</v-icon>
                            info
                        </div>
                    </template>
                    <div class="myDataContent" v-if="isMe(parti.email)">
                        Me
                    </div>
                </div>
            </template>
        </section>
        
        <section class="meetingSectionStyle">
            <h2>Country</h2>
            <div class="title-div">
                <img :src="util.getCountryFlag(schedule?.country_iso2)" > <p>{{schedule?.country_name}}</p>
            </div>
        </section>

        <section class="meetingSectionStyle" ref="negoTable">
            <div class="negoContainerHeader">
                <h2>Schedule Information</h2>
                <v-btn class="button" @click="onShowAllNego" v-if="isOneNegoShow && selectedNegoNo != null && negotiations.length > 1">
                    SHOW ALL
                </v-btn>
            </div>
            <template v-for="nego in negotiations" :key="nego">
                <div v-if="!isOneNegoShow || (isOneNegoShow && (selectedNegoNo == null || selectedNegoNo == nego.no))"
                    :class="{selectedNego : (selectedNegoNo != null && selectedNegoNo == nego.no)}">
                    <div v-if="(selectedNegoNo != null && selectedNegoNo == nego.no)" class="selectedNegoFlag">
                        Selected
                    </div>
                    <div class="negoDetailContainer" :class="{nonDetailShow : (!nego.show && !(isOneNegoShow && selectedNegoNo != null))}" @click="negoShowDetail(nego)">
                        <scheduleNegotiationCard :negotiation="nego" :detailShow="nego.show || (isOneNegoShow && selectedNegoNo != null)" :myData="myData" 
                            :disable="(selectedNegoNo != null && selectedNegoNo != nego.no) || (!nego.date || !nego.location)"/>
                    </div>
                    <div class="buttons" v-if="(schedule?.status == scheduleData.M_STATUS.MEET_NEGOTIATION || 
                        schedule?.status == scheduleData.M_STATUS.MEET_INVITATION) && isDecide(nego)">
                        <template v-if="!isNegoResultSelected(nego)">
                            <v-btn @click="onAccept(nego.no)" class="buttonStlye" id="accept">
                                ACCEPT
                            </v-btn>
                            <v-btn @click="onReject(nego.no)" class="buttonStlye" id="reject">
                                REJECT
                            </v-btn>
                        </template>
                        <template v-if="isOwner(myData?.email)">
                            <v-btn @click="onProceed(nego.no)" class="buttonStlye" id="proceed">
                                PROCEED
                            </v-btn>
                        </template>
                    </div>
                </div>
            </template>
            <div class="caution" v-if="negotiations?.length == 1 && (!negotiations?.[0].date || !negotiations?.[0].location)">
                <v-icon class="blink">mdi-alert-circle-outline</v-icon>
                There is no time or meeting place for this schedule.<br>
                Time and meeting place must be specified to proceed with the schedule.
            </div>

        </section>
        <section class="meetingSectionStyle">
            <h2>Status</h2>
            <div class='multiline'>
                <div class="status" :class="getMStatusText(schedule?.status)?.class">
                    {{getMStatusText(schedule?.status)?.text}}
                </div> 
            </div>
        </section>
        <template v-if="(schedule?.status != scheduleData.M_STATUS.MEET_COMPLETION || schedule?.status == scheduleData.M_STATUS.MEET_DELETION)">
            <div class="buttons">
                <v-btn @click="onMediate" class="buttonStlye mediate" block :class="{disabled : !isMediateAble}">
                    Adjust Schedule
                </v-btn>
            </div>
        </template>
        <mouseHoverItem :overCallback="overCallback" :hoverRefs="hoverRefs">
            <template v-slot:content>
                <scheduleUserCard :recipient="hoverItemData" v-if="hoverItemData?.id != undefined"
                     :isOwner="isOwner(hoverItemData.email)" class="UserInfoStyle"/>
            </template>
        </mouseHoverItem>
    </div>
</template>
<script>
import scheduleNegotiationCard from "@/page/meeting/schedule/scheduleNegotiationCard.vue"
import scheduleUserCard from "@/page/meeting/schedule/scheduleUserCard.vue"
import mouseHoverItem from "@/component/mouseHoverItem.vue"
import {dateDataClass} from "@/component/timePeriodSelectorTable.vue"
import { computed, inject, nextTick, onMounted, ref, watch } from 'vue'
import util from '@/data/util'
import { useRouter } from 'vue-router'
import scheduleData from "@/data/scheduleData"
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
export default {
    props : {
        schedule : Object,
        isInvitation : {
            type :Boolean,
            default : false,
        },
        tokenData : Object,
    },
    components : {
        mouseHoverItem,
        scheduleUserCard,
        scheduleNegotiationCard,
    },
    setup(props, ctx) {
        const router = useRouter()
        const userStore = useUserStore()
        const {user} = storeToRefs(userStore)
        const mediationComment = ref("")
        const userTable = ref(null)
        const negoTable = ref(null)
        const hoverItemData = ref(null)
        const hoverRefs = ref([])
        const negotiations = ref([])
        const isOneNegoShow = ref(true)
        const modalManager = inject("ModalManager")

        const isDecide = nego => {
            if(nego.start_time && nego.end_time && nego.date && nego.location) {
                return true;
            }
            return false;
        }

        const isMediateAble = computed(() => {
            let res = true;
            let event = {}
            if(!props.schedule) {
                return res;
            }
            Object.entries(props.schedule).forEach(data => {
                const k = data[0]
                const v = data[1]
                let index = k.indexOf("event_")
                if(index > -1 ) {
                    index += "event_".length
                    event[k.slice(index)] = v
                }
            })
            // 선택 가능 date가 없는 경우 false
            const dateData = new dateDataClass(event, null, timezoneData.value, selectedMeetings.value)
            res = dateData.dateData.some(date => !date.hours.some(hour => !hour.mins.some(min => !min.disabled)))
            return res;
        })

        const participants = computed(() => {
            if(props.schedule?.participants) {
                const participants = JSON.parse(props.schedule?.participants)
                // owner를 위로 올리기 위함. (owner는 유저이므로 무조건 id가 존재.)
                return util.sortObj(participants, false, item => item.id == props.schedule?.owner_id ? 1 : 0)
            }
            else {
                return []
            }
        })

        const timezoneData = computed(() => {
            return util.convertTimezoneJsonToData(props.schedule?.country_timezones)
        })

        const date = computed(() => {
            var res ={
                start_time : util.date_format(util.convertTimeStmapToDateTime(props.schedule?.start_time), "HH:mm"),
                end_time : util.date_format(util.convertTimeStmapToDateTime(props.schedule?.end_time), "HH:mm"),
            }
            const start_date = util.date_format(util.convertTimeStmapToDateTime(props.schedule?.start_time), "YYYY-MM-DD")
            const end_date = util.date_format(util.convertTimeStmapToDateTime(props.schedule?.end_time), "YYYY-MM-DD")
            if(start_date == end_date) {
                res.date = start_date
            }
            else {
                res.start_date = start_date
                res.end_date = end_date
            }
            return res
        })

        const selectedMeetings = computed(() => {
            if(participants.value.length > 0 ) {
                let result = []
                participants.value.forEach(reci => {
                    if(!reci.user_id && !reci.meetings) {
                        return;
                    }
                    reci.meetings.forEach(meeting => {
                        const m_timezoneData = util.convertTimezoneJsonToData(meeting.country_timezones)
                        let start_time = Number(meeting.start_time)
                        let end_time = Number(meeting.end_time)
                        if(m_timezoneData?.gmtOffset && timezoneData.value?.gmtOffset) {
                            start_time = start_time - Number(m_timezoneData.gmtOffset) + Number(timezoneData.value?.gmtOffset)
                            end_time = end_time - Number(m_timezoneData.gmtOffset) + Number(timezoneData.value?.gmtOffset)
                            meeting.start_time = start_time
                            meeting.end_time = end_time
                        }
                        result.push(meeting)
                    })
                })
                return result
            }
            return null
        })

        const selectedNegoNo = computed(() => {
            if(props.schedule?.selected_negotiation_no && 
            (props.schedule?.status == scheduleData.M_STATUS.MEET_AGREEMENT || 
            props.schedule?.status == scheduleData.M_STATUS.MEET_PROCEED ||
            props.schedule?.status == scheduleData.M_STATUS.MEET_COMPLETION)) {
                return props.schedule?.selected_negotiation_no
            }
            return null
        })

        const currentNegoNo = computed(() => {
            if(props.tokenData?.negotiation) {
                return props.tokenData?.negotiation
            }
            return null
        })

        const myData = computed(() => {
            if(props.isInvitation) {
                if(props.tokenData?.email && participants.value) {
                    const myParticipantData = participants.value.filter(parti => parti.email == props.tokenData.email) 
                    if(myParticipantData.length > 0) {
                        return myParticipantData[0]
                    }
                }
            }
            else if(user.value) {
                return user.value
            }
            return {}
        })

        const isParticipants = computed(()=>{
            if(!props.isInvitation && participants.value) {
                return participants.value?.some(parti => parti?.email == myData.value?.email) && props.schedule?.owner_email != myData.value?.email
            }
            return true
        })
        const isMe = (email) => {
            if(myData.value?.email) {
                return email == myData.value?.email
            }
            return false;
        }
        const isMediate = computed(() => {
            return false // TODO: UI변경 필요
            // return props.schedule?.adjustments && props.schedule?.status == scheduleData.STATUS.MEDIATE
        })
        const isApply = computed(() => {
            return props.schedule?.status == scheduleData.M_STATUS.MEET_INVITATION
        })

        const getParticipant = no => {
            if(participants.value) {
                const res = participants.value.filter(parti => parti.no == no)
                if(res.length > 0) {
                    return res[0]
                }
            }
            return {}
        }
        const isNegoResultSelected = nego => {
            if(nego.participants) {
                const mySelectData = nego.participants.filter(parti => parti.data.email == myData.value?.email)
                if(mySelectData.length > 0) {
                    const status = mySelectData[0].status
                    return status != scheduleData.P_STATUS.NEGOTIATION_NON
                }
            }
            return null
        }

        const isOwner = email => {
            return props.schedule?.owner_email == email;
        }

        const overCallback = (e, ref) => {
            const id = e.target.getAttribute("id")
            if(id) {
                const users = participants.value.filter(user => user.id == id)
                if(users.length > 0) {
                    hoverItemData.value = users[0]?.data;
                }
                else {
                    hoverItemData.value = null
                }
            }
            else {
                hoverItemData.value = null
            }
        }

        const initNegotiations = () => {
            var negos = []
            if(props.schedule?.negotiations && participants.value) {
                negos = JSON.parse(props.schedule.negotiations).map(item => {
                    return {
                        ...item,
                        date_text : util.date_format(util.convertTimeStmapToDateTime(item.date), "YYYY-MM-DD"),
                        start_time_text : util.date_format(util.convertTimeStmapToDateTime(item.start_time), "HH:mm"),
                        end_time_text : util.date_format(util.convertTimeStmapToDateTime(item.end_time), "HH:mm"),
                        timezone : timezoneData.value?.gmtOffsetName,
                        participants : item.participants.map(parti => {
                            return {
                                ...parti,
                                data : participants.value.filter(scheduleParti => scheduleParti.no == parti.no)[0]
                            }
                        }),
                        proposer : {
                            ...item.proposer,
                            data : getParticipant(item.proposer.no).data,
                        },
                        show : false,
                    }
                })
            }
            negotiations.value = negos;
        }

        const setHoverRefs = () => {
            nextTick(() => {
                if(userTable.value) {
                    hoverRefs.value =  [
                        ...userTable.value.querySelectorAll(".title-div"),
                    ]
                }
                else {
                    hoverRefs.value = []
                }
            })
        }

        const getMStatusText = (status) => {
            return scheduleData.getMStatusText(status)
        }

        const getPStatusText = (status) => {
            return scheduleData.getPStatusText(status)
        }

        const negoShowDetail = nego => {
            if(negotiations.value) {
                negotiations.value.forEach(nego => {
                    nego.show = false;
                })
            }
            nego.show = true;
        }

        const onShowAllNego = () => {
            modalManager.showdialog({
                title : "Do you want to show all schedule infomation?",
                buttons : {
                    yes : "yes",
                    no : "no",
                }
            })
            .then(res => {
                if(res.result == "yes") {
                    isOneNegoShow.value = false;
                }
            })
        }
        
        const onUserInfoLink = (user_id) => {
            router.push({
                path : "/profile/user",
                query : {
                    userno : user_id,
                }
            })
        }

        const onCancel = () => {
            ctx.emit("updateSchedule","cancel")
        }
        const onAccept = no => {
            ctx.emit("updateSchedule","accept", no)
        }
        const onReject = no => {
            ctx.emit("updateSchedule","reject", no)
        }
        const onProceed = no => {
            ctx.emit("updateSchedule", "proceed", no)
        }
        const onMediate = () => {
            if(isMediateAble.value) {
                ctx.emit("updateSchedule","mediate")
            }
            else {
                modalManager.showdialog("You don't have any available dates to change this schedule.")
            }
        }
        watch(() => props.schedule, () => {
            setHoverRefs()
            initNegotiations()
        })
        onMounted(() => {
            setHoverRefs()
            initNegotiations()
        })

        return {
            date,
            user,
            util,
            participants,
            selectedNegoNo,
            currentNegoNo,
            isParticipants,
            isMediate,
            isMediateAble,
            isApply,
            isOneNegoShow,
            scheduleData,
            mediationComment,
            negotiations,
            userTable,
            negoTable,
            hoverRefs,
            hoverItemData,
            overCallback,
            myData,

            isDecide,
            isMe,
            isOwner,
            isNegoResultSelected,
            negoShowDetail,
            onUserInfoLink,
            getParticipant,
            getMStatusText,
            getPStatusText,
            onShowAllNego,
            onAccept,
            onReject,
            onMediate,
            onProceed,
            onCancel,
        }
    }
}
</script>

<style scoped lang="scss">

.containerBlockStyle {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 32.0625rem;
    min-width: 32.0625rem;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 2px 5px 11px #00000029;
    border-radius: 1rem;
    padding: 1.875rem;
    margin-bottom: 1rem;
    margin-right : 16px;
    height: fit-content;
    .titleStyle {
        display: flex;
        .closeIcon {
            cursor: pointer;
            margin-right: 0;
            margin-left: auto;
        }
    }
    h1,h3 {
        color: #333;
        margin: 0 0 1.875rem;
        font: normal normal 800 20px/23px NanumSquareOTF;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // user-select: none;
        word-break: break-all;
    }
    h3 {
        user-select: none;
    }
    h1 {
        color : #910457;
    }
    .meetingSectionStyle {
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;
        color: #333333;
        width: 100%;
        .negoContainerHeader {
            display: flex;
            .button {
                background: #910457;
                color: #fff;
                font: 14px NanumSquareOTF;
                margin-right: 0;
                margin-left: auto;
            }
        }

        .multiline {
            white-space: pre-wrap;
            overflow-wrap: break-word;
            max-width: 100%;
            font: normal normal normal 16px/26px NanumSquareOTF;
            .status {
                width: fit-content;
                border-radius: 10px;
                padding: 0 10px;
            }
        }

        .email {
            font: normal normal normal 16px/18px NanumSquareOTF;
            color: #9c9c9c;
        }

        .state-text {
            font: normal normal normal 16px/26px NanumSquareOTF;
            color: #6c6c6c;
            margin-left: 1.25rem;
        }

        h2 {
            font: normal normal 800 16px/36px NanumSquareOTF;
            line-height: 2.25;
            margin: 0 0 0.25rem;
            border-radius: 0.5rem;
            user-select: none;
        }

        p {
            margin: 0 5px;
            font: normal normal normal 16px/18px NanumSquareOTF;
            &.owner {
                width: fit-content;
                height: fit-content;
                padding: 4px 5px;
                border-radius: 10px;
                background: #910457;
                color: #fff;
            }
        }

        .divider {
            background: #d9d9d9 0% 0% no-repeat padding-box;
            height: 18px;
            width: 1px;
        }
        .title-div {
            display: flex;
            align-items: center;
            width: fit-content;
            padding: 0 5px;
            &.myData {
                border: 1px solid #999;
                border-radius: 10px;
                position: relative;
                background: #EEE;
                .myDataContent {
                    position: absolute;
                    right : -35px;
                    font-size: 12px;
                    justify-content: flex-end;
                    background: #484848;
                    color: white;
                    border-radius: 5px;
                    padding : 0 5px;
                    width: fit-content;

                    &:after {
                        border-top: 5px solid transparent;
                        border-left: 0px solid transparent;
                        border-right: 5px solid #484848;
                        border-bottom: 5px solid transparent;
                        content: "";
                        position: absolute;
                        left : -5px;
                        top : 4px;
                    }
                }
            }
        }
        .ownerImage {
            border: 4px solid #910457;
            border-radius: 50%;
            padding: 2px;
            display: flex;
            justify-content: center;
            margin-right: 2px;
        }
        .selectedNego {
            margin-top: 10px;
            position : relative;
            border-radius: 25px;
            padding-left : 15px;
            background: #91045799;

            .selectedNegoFlag {
                position: absolute;
                top : 0px;
                left : 40px;
                font-size: 12px;
                justify-content: flex-end;
                background: #910457;
                color: white;
                padding : 0 5px;
                width: fit-content;

                // &:after {
                //     border-top: 5px solid #484848;
                //     border-left: 5px solid transparent;
                //     border-right: 5px solid transparent;
                //     border-bottom: 0 solid transparent;
                //     content: "";
                //     position: absolute;
                //     left : calc(50% - 2.5px);
                //     bottom : -5px;
                // }
            }
        }
        .negoDetailContainer {
            margin-top : 15px;
            transform-origin: top;
            transition: transform .4s ease-in-out;
            &.nonDetailShow {
                cursor: pointer;
            }
        }
        .ScheduleInfoBlock {
            display: flex;
            align-items: center;
            position: relative;
            background-color: rgba(255, 255, 255, 0.8);
            box-shadow: rgb(0 0 0 / 16%) 2px 5px 11px;
            border-radius: 1rem;
            padding: 10px;
            margin-top: 5px;
            color: #6c6c6c;
            user-select: none;
            cursor: pointer;
            & {
                color: #6c6c6c;
                font: normal normal normal 16px/26px NanumSquareOTF;
            }

            & + & {
                margin-top: 4px;
            }

            i,img {
                color: #910457;
                margin-right: 6px;
            }
            .imageStyle {
                width: 1.5rem;
            }
        }
        .InfoLink {
            cursor: pointer;
            border: 1px solid #9c9c9c;
            text-decoration: none;
            border-radius: 0.75rem;
            padding: 3px 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            font: normal normal normal 12px/18px NanumSquareOTF;
            color: #6c6c6c;
        }
        .mediateTextStyle {
            margin: 20px 0;
        }
        .mediateDataStyle {
            display: flex;
            align-items: center;
            i {
                color: black;
            }
            > div {
                border-radius: 15px;
                padding: 0 10px;
                border: 1px solid #910457;
            }
            .location {
                width: 100%;
            }
        }
        .caution {
            font : 14px NanumSquareOTF;
            margin-bottom: 5px;
            i {
                border-radius: 50%;
                background: rgb(145, 0, 0);
                color : #fff;
            }
        }
    }
    .buttons {
        display: flex;
        margin-bottom: 10px;
        .buttonStlye {
            border: none;
            margin: 0 5px;
            font: 14px NanumSquareOTF;
            font-weight: 500;
            
            &.mediate {
                background: #910457;
                color: #fff;
            }
            &.disabled {
                background: #6c6c6c;
            }
        }
    }
}
</style>