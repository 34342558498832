<template>
    <FeedHeader class="headerStyle" :qCountry="qCountry" :qName="qName" :qContents="qContents"/>
    <template v-if="posts && posts.length === 0">
        <div class="noScheduleStyle" :style="{ maxWidth: '54.375rem' }" >
            <h1>There is no feed.</h1>
        </div>
    </template>
    <template v-else>
        <div v-if="isLoading">
            <div class="postViewStyle" v-for="n in 5" :key="n">
                <feedSkeletonLoader />
            </div>
        </div>
        <div v-else>
            <div class="postViewStyle" v-for="post in posts" :key="post">
                <PostItem :postId="post?.id" :isDetail="false" 
                    :isComment="post.comment_count > 0 ? true : false" 
                    :refreshPost="refresh" :refreshNetwork="refreshNetwork"/>
            </div>
        </div>
        <bottomRefresh :callBack="onScrolling" v-if="isBottomRefresh" class="bottomRefresh"/>
    </template>
    <a class="linkStyle" @click="()=>this.$router.push('/feed/write')">
        <v-btn icon class="addStyle">
            <img :src="require('@/assets/plus.png')"/>
        </v-btn>
    </a>
</template>

<script>
import feedSkeletonLoader from "@/component/feedSkeletonLoader.vue"
import bottomRefresh from "@/component/bottomRefresh.vue"
import FeedHeader from "@/page/feed/feedHeader.vue"
import PostItem from "@/page/feed/post"
import { usePostStore } from "@/stores/post"
import { computed, onMounted, onUnmounted, ref, watch } from "vue"
import { storeToRefs } from "pinia"
import { useRoute } from "vue-router"
import { useNetworkStore } from '@/stores/network'

export default {
    components : {
        PostItem,
        FeedHeader,
        bottomRefresh,
        feedSkeletonLoader,
    },
    setup(props, ctx) {
        const postStore = usePostStore()
        const route = useRoute()
        const networkStore = useNetworkStore()

        const { posts, name, country, totalCount, isLoading } = storeToRefs(postStore)
        const { fetchPosts } = postStore
        
        const { networks } = storeToRefs(networkStore)

        const qCountry = ref("")
        const qName = ref([])
        const qContents = ref([])

        const refresh = async () => {
            await fetchPosts({
                name : name.value, 
                country : country.value,
                page : 1,
                per_page : posts.value?.length,
                noSave : true
            })
        }
        const refreshNetwork = async post => {
            await networkStore.fetchNetworks({email : post.writer_email})
            const newNetworkData = networks.value.filter(net => net.user_id == post.writer_id)
            posts.value.forEach(postItem => {
                if(postItem.writer_id == post.writer_id) {
                    if(newNetworkData?.length > 0) {
                        postItem.network_type = newNetworkData[0].type
                        if(!postItem.network_id) {
                            postItem.network_id = newNetworkData[0].id
                        }
                    }
                    else {
                        postItem.network_type = 0;
                        if(postItem.network_id) {
                            delete postItem.network_id
                        }
                    }
                }
            })
        }
        const onScrolling = async () => {
            await postStore.morePosts()
        }

        const isBottomRefresh = computed(() => {
            if(posts.value && totalCount.value && !isLoading.value) {
                return posts.value?.length < parseInt(totalCount.value)
            }
            return false;
        })

        const init = async () => {
            qName.value = ""
            qContents.value = ""
            qCountry.value = ""
            if (route.query.name) {
                qName.value = route.query.name
            }
            if(route.query.cont) {
                qContents.value = route.query.cont
            }
            if (route.query?.ctr) {
                qCountry.value = route.query.ctr
            }
            await fetchPosts({
                name : qName.value, 
                content : qContents.value, 
                country : qCountry.value,
                page : 1,
            })
        }

        onMounted(async () => {
            await init()
        })
        
        watch(() => route.query, async () => {
            console.log(route.path)
            if(!route.path.includes("feed/main")) {
                return;
            }
            await init()
        })

        return {
            posts,
            isBottomRefresh,
            qCountry,
            qName,
            qContents,
            isLoading,

            refresh,
            refreshNetwork,
            onScrolling,
        }
    },
}
</script>

<style scoped>
.headerStyle {
    margin-bottom: 30px;
}
.postViewStyle {
    max-width: 54.375rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
/* .bottomRefresh {
    width: 54.375rem;    
} */
.addStyle {
    position: fixed;
    bottom: 103px;
    right: 2rem;
    z-index: 10;
    background: rgb(145, 4, 87);
    width: 56px;
    height: 56px;
}
</style>
