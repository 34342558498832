export default {
    minStamp : 30,
    M_STATUS : {
        // 요청
        MEET_INVITATION : 0,
        // 협상
        MEET_NEGOTIATION : 1,
        // 수락
        MEET_AGREEMENT : 2,
        // 거절
        MEET_REFUSAL : 3,
        // 진행
        MEET_PROCEED : 4,
        // 취소
        MEET_CANCELLATION : 5,
        // 완료
        MEET_COMPLETION : 6,
        // 삭제
        MEET_DELETION : -1,
    },
    P_STATUS : {
        // 대기
        NEGOTIATION_NON : 0,
        // 수락
        NEGOTIATION_AGREEMENT : 1,
        // 거절
        NEGOTIATION_REFUSAL : 2,
    },
    getMStatusText(status) {
        switch(Number(status)) {
            // 요청
            case 0 : return {
                text : "scheduling",
                class : "scheduling",
            }
            // 협상
            case 1 : return {
                text : "scheduling",
                class : "scheduling",
            }
            // 수락
            case 2 : return {
                text : "confirmed",
                class : "confirmed",
            }
            // 거절
            case 3 : return {
                text : "rejected",
                class : "rejected",
            }
            // 진행
            case 4 : return {
                text : "confirmed",
                class : "confirmed",
            }
            // 취소
            case 5 : return {
                text : "cancel",
                class : "cancel",
            }
            // 완료
            case 6 : return {
                text : "we met",
                class : "we_met",
            }
            // 삭제
            case -1 : return {
                text : "delete",
                class : "delete",
            }
            default : return {}
        }
    },
    getMStatusTextInitial(status) {
        switch(Number(status)) {
            // 요청
            case 0 : return "SH"
            // 협상
            case 1 : return "SH"
            // 수락
            case 2 : return "CF"
            // 거절
            case 3 : return "RJ"
            // 진행
            case 4 : return "CF"
            // 취소
            case 5 : return "CC"
            // 완료
            case 6 : return "WM"
            // 삭제
            case -1 : return "DT"
            default : return ""
        }
    },
    getMStatusColor(status) {
        switch(Number(status)) {
            // 요청
            case 0 : return "59227c"
            // 협상
            case 1 : return "59227c"
            // 수락
            case 2 : return "ffcb6b"
            // 거절
            case 3 : return "ff8e7f"
            // 진행
            case 4 : return "ffcb6b"
            // 취소
            case 5 : return "ece75f"
            // 완료
            case 6 : return "a5ea89"
            // 삭제
            case -1 : return "5b3e31"
            default : return ""
        }
    },
    getPStatusText(status) {
        switch(Number(status)) {
            // 대기
            case 0 : return "waiting"
            // 수락
            case 1 : return "accept"
            // 거절
            case 2 : return "reject"
            default : return ""
        }
    },
    STATUS : {
        APPLY : 0,
        ACCEPT : 1,
        REJECT : 2,
        MEDIATE : 3,
        COMPLETE : 4,
    },
    RESULT_STATUS : {
        // 미완료
        0 : "",
        // 완료
        1 : "" 
    },
    noDateText : "The date has not been specified.",
    noLocationText : "The meeting place has not been specified.",
    noSpecified : "not specified"
}