import axios from "axios";
import { TokenService } from "@/services/storage.service"
import { useAuthStore } from "@/stores/auth";

class ApiError extends Error {
    constructor(code, message) {
        super(message)
        this.name = this.constructor.name
        this.message = message
        this.code = code
    }
}

const ApiV2Service = {
    _401interceptor: null,
    flush_queue : [],
    is_refreshing : false,

    init(baseURL) {
        axios.defaults.baseURL = baseURL
    },

    setHeader() {
        axios.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getAccessToken()}`
    },

    removeHeader() {
        axios.defaults.headers.common = {}
    },

    get(url, data) {
        // if (data) {
        //     const encodedParams = []
        //     Object.keys(data).forEach(key => {
        //         encodedParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
        //     })
        //     url = `${url}?${encodedParams.join("&")}`
        //     console.log(url)
        // }
        return axios.get(url, {params : data})
    },

    post(url, data) {
        return axios.post(url, data)
    },

    put(url, data) {
        return axios.put(url, data)
    },

    delete(url, data) {
        return axios.delete(url, {data : data})
    },

    /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
    **/
    customRequest(data) {
        return axios(data)
    },
    refresh_flush() {
        this.flush_queue.forEach((item, index) => {
            this.customRequest({
                method: item.method,
                url: item.url,
                data: item.data,
                params : item.params
            }).then(res => {
                this.flush_queue.splice(index , 1)
                item.resolve(res)
            })

        })
    },

    mount401Interceptor() {
        this._401interceptor = axios.interceptors.response.use(
            (response) => {
                return response
            },
            async (error) => {
                if (error.request.status == 401) {
                    const authStore = useAuthStore()

                    if (error.config.url.includes('/refresh')) {
                        // Refresh token has failed. Logout the user
                        console.log("Refresh token has failed")
                        authStore.logout()
                        throw error
                    } else {
                        // Refresh the access token
                        console.log("Refresh the access token")
                        try{
                            if(this.is_refreshing) {
                                return new Promise((resolve, reject) => {
                                    this.flush_queue.push({
                                        method: error.config.method,
                                        url: error.config.url,
                                        data: error.config.data,
                                        params : error.config.params,
                                        resolve : resolve,
                                    })
                                })
                            }
                            this.is_refreshing = true
                            if (!await authStore.refreshToken()) {
                                this.is_refreshing = false
                                authStore.logout()
                                throw error
                            }
                            this.is_refreshing = false
                            this.refresh_flush()
                            // Retry the original request
                            console.log("Retry the original request")
                            return this.customRequest({
                                method: error.config.method,
                                url: error.config.url,
                                data: error.config.data,
                                params : error.config.params,
                            })
                        } catch (e) {
                            // Refresh has failed - reject the original request
                            throw error
                        }
                    }
                }

                // If error was not 401 just reject as is
                throw error
            }
        )
    },

    unmount401Interceptor() {
        // Eject the interceptor
        axios.interceptors.response.eject(this._401interceptor)
    }
}

export default ApiV2Service

export { ApiV2Service, ApiError }