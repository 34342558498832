<template>
    <img v-bind="props" :src="safeSrc" @error="isError = true"/>
</template>

<script>
import { onMounted, ref, watch } from 'vue-demi'
import {useImageStore} from "@/stores/image"
import util from '@/data/util'
export default {
    props : {
        src : [String, Object],
        isLoading : {
            default : false,
            type : Boolean,
        },
        default_image : [String, Object],
    },
    setup(props, ctx) {
        const isError = ref(false)
        const safeSrc = ref(require("@/assets/loading.gif"))
        const imageStore = useImageStore()
        const isUrlImage = async (path) => {
            try {
                const response = await fetch(path)
                const data = await response.blob()
                if(!data.type.includes("image")) {
                    return false;
                }
                return true;
            }
            catch(error) {
                // url fetch 가 안되는 경우는 해당 src 그대로 사용.
                return false
            }
        }
        const setSrc = async () => {
            if(props.src) {
                if (isError.value) {
                    return props.src
                } else {
                    if(!imageStore.getImage(props.src) && imageStore.getImage(props.src) != false) {
                        
                        const setImage = async (thumbnailData) => {
                            if(thumbnailData) {
                                return util.getThumbnailPath(props.src)
                            }
                            return props.src
                        }
                        // 동일 이미지가 들어올 경우
                        if(imageStore.getLoadingImage(props.src)) {
                            // 해당 이미지의 로딩이 false 처리되는 경우 처리됨.
                            return imageStore.waitLoading(props.src, setImage)
                        }
                        imageStore.setLoadingImage(props.src, true)
                        const thumbnailData = await isUrlImage(util.getThumbnailPath(props.src))
                        imageStore.setImage(props.src, thumbnailData)
                        imageStore.setLoadingImage(props.src, false)
                        return await setImage(thumbnailData)
                    }
                    return props.src
                }
            }
            else {
                // default image
                if(props.isLoading) {
                    return require("@/assets/loading.gif")
                }
                if(props.default_image) {
                    return props.default_image
                }
                else {
                    return require("@/assets/no_image.png")
                }
            }
        }
        onMounted(async () => {
            safeSrc.value = await setSrc()
        })
        watch(() => props.src, async () => {
            safeSrc.value = await setSrc()
        })
        return {
            props,
            safeSrc,
            isError,
        }
    }
}
</script>

<style scoped lang="scss">

</style>