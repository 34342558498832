import { defineStore } from "pinia";
import { ApiError } from "@/services/api.v2.service";
import ConferenceService from "@/services/conference.service";
import storeData from "./storeData";

export const useConferenceStore = defineStore("conference", {
    state: () => ({
        conference: null,
        conference_attendees : null,
        conferences: null,
        order: null,
        totalCount: 0,
        page: 1,
        perPage: storeData.default_per_page,
        error: { code: 0, message: "" },
    }),

    actions: {
        async createConference(data) {
            try {
                return await ConferenceService.createConference(data)
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async fetchConferences(params) {
            try {
                this.order = params?.order
                this.page = 1
                this.perPage = storeData.default_per_page

                const response = await ConferenceService.fetchConferences(this.order, this.page, this.perPage, params?.xlsx)
                if(response) {
                    if(params?.xlsx) {
                        return response.data
                    }
                    this.conferences = response.data
                    this.totalCount = Number(response.count)
                    return this.conferences
                }

                return false
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async moreConferences() {
            try {
                this.page += 1
                const res = await ConferenceService.fetchConferences(this.page, this.perPage)
                
                if(res) {
                    this.conferences = this.conferences.concat(res)
                    return this.conferences
                }
                else {
                    return res;
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async fetchConference(conferenceId) {
            try {
                this.conference = await ConferenceService.fetchConference(conferenceId)

                return this.conference
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async attendConference(conferenceId) {
            try {
                const res = await ConferenceService.attendConference(conferenceId)

                return res
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },
        async absentConference(conferenceId) {
            try {
                const res = await ConferenceService.absentConference(conferenceId)

                return res
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },
        async fetchConferenceAttendees(conferenceId) {
            try {
                this.conference_attendees = await ConferenceService.fetchConferenceAttendees(conferenceId)

                return this.conference
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async updateConference(conferenceId, data) {
            try {
                return await ConferenceService.updateConference(conferenceId, data)
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async deleteConference(conferenceId) {
            try {
                return await ConferenceService.deleteConference(conferenceId)
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },
    },
})