<template>
    <router-view>
    </router-view>
</template>
<script>
export default {
}
</script>

<style scoped>

</style>