<template>
    <div>
        <v-btn icon v-for="menu in menuList" :key="menu" @click="onClick(menu)" 
            :class="{active : activeTab?.value == menu?.value}"
            class="imageStyle">
            <v-icon>{{menu.image}}</v-icon>
        </v-btn>
    </div>
</template>
<script>
import { onMounted, ref, watch } from 'vue';

// ex)
// menuList = [
//     {image : 'mdi icon path', value : 'menu value' , callback : 'call function if click tab'},
// ]

export default {
    props : {
        menuList : Object,
        activeValue : String,
    },
    setup(props, ctx) {
        const activeTab = ref({})

        const onClick = (menu) => {
            activeTab.value = menu;
            menu.callback();
        }
        onMounted(() => {
            if(!activeTab.value.value) {
                activeTab.value = props.menuList[0]
            }
        })
        watch(() => props.activeValue, () => {
            const activeValueTab = props.menuList.filter(item => item.value == props.activeValue)
            if(activeValueTab.length > 0) {
                activeTab.value = activeValueTab[0]
            }
        })
        return {
            activeTab,
            onClick,
        }
    }

}
</script>

<style scoped>
.active {
    color : #fff;
    background: #910457;
}
.imageStyle {
    margin-right: 10px;
}
</style>