<template>
    <div class="block">
        <div class="box">
            <div class="loader6"></div>
        </div>
    </div>
</template>
<script>
import { getCurrentInstance, inject, watch } from 'vue'
export default {
    props : {
        isLoading : Boolean,
    },
    setup(props, ctx) {
        const modalManager = inject("ModalManager")
        const currentInstance = getCurrentInstance()
        watch(() => props.isLoading, () => {
            if(!props.isLoading) {
                modalManager.close(currentInstance)
            }
        })
    }
}
</script>

<style scoped lang="scss">
.loader6{
  position: relative;
  width: 12px;
  height: 12px;

  top: 46%;
  top: -webkit-calc(50% - 6px);
  top: calc(50% - 6px);
  left: 46%;
  left: -webkit-calc(50% - 6px);
  left: calc(50% - 6px);
  
  border-radius: 12px;
  background-color: #910428;
  -webkit-transform-origin:  50% 50%;
      transform-origin:  50% 50% ;
  -webkit-animation: loader6 1s ease-in-out infinite;
      animation: loader6 1s ease-in-out infinite;
}

.loader6:before{
  content: "";
  position: absolute;
  background-color: #91042888;
  top: 0px;
  left: -25px;
  height: 12px;
  width: 12px;
  border-radius: 12px;
}

.loader6:after{
  content: "";
  position: absolute;
  background-color: #91042888;
  top: 0px;
  left: 25px;
  height: 12px;
  width: 12px;
  border-radius: 12px;
}


@-webkit-keyframes loader6{
    0%{-webkit-transform:rotate(0deg);}
    50%{-webkit-transform:rotate(180deg);}
    100%{-webkit-transform:rotate(180deg);}
}

@keyframes loader6{
    0%{transform:rotate(0deg);}
    50%{transform:rotate(180deg);}
    100%{transform:rotate(180deg);}
}
</style>