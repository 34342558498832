<template>
    <div class="footerStyle">
        <div class="footerSummary">
            <div class="item like">
                Like
                {{ postData?.like_count }}
            </div>
            <div class="item comment" @click.stop="toggleCommentShow">
                Comment
                {{postData?.comment_count ?? postData?.comments?.length}}
            </div>
        </div>
        <hr>
        <div class="buttonWrapper">
            <div @click.stop="onLike" class='item' v-if="!isMe">
                <img :src="heartImage"/>
                Like
            </div>
            <div v-if="!isDetail" @click.stop="onMoveToDetail" class='item'>
                <img :src="require('@/assets/comment.png')"/>
                Write Comment
            </div>
        </div>
        <div class="commentStyle" v-if="isCommentShow">
            <div>
                <PostCommentItem :isDetail="isDetail" inputMode="input" ref="inputCommentComponent"/>
            </div>
            <div v-for="item in sortedComments" :key="item.id" >
                <PostCommentItem :isDetail="isDetail" inputMode="view" :commentData="item"/>
            </div>
            <div v-if="postData?.comment_count && Number(postData?.comment_count) > sortedComments?.length" class="commentMore">
                ...
            </div>
        </div>
    </div>    
</template>

<script>
import PostCommentItem from "@/page/feed/post/postCommentItem.vue"
import { computed, inject, nextTick, onMounted, onUnmounted, ref, watch } from "vue"
import { useUserStore } from '@/stores/user'
import { usePostStore } from '@/stores/post'
import util from '@/data/util'

export default {
    components : {
        PostCommentItem
    },
    props : {
        isDetail : {
            type : Boolean,
            default : false,
        },
        isComment: Boolean
    },
    setup(props, ctx) {
        const userStore = useUserStore()
        const postStore = usePostStore()
        const postData = inject('postData');
        const modalManager = inject('ModalManager')
        const likeTimeout  = ref(null)
        const inputCommentComponent = ref(null)
        const isLike = ref(false)
        const isCommentShow = ref(false)

        const heartImage = computed(() => {
            return require(isLike.value ? "@/assets/heart.png" : "@/assets/no_heart.png")
        })
        const isMe = computed(() => {
            return userStore.user?.id == postData.value?.writer_id
        })
        const sortedComments = computed(() => {
            return util.sortObj(postData.value?.recent_comments ?? postData.value?.comments, false, item => Number(item.id))
        })

        const onLike = async () => {
            if(likeTimeout.value) {
                clearTimeout(likeTimeout.value)
                likeTimeout.value = null;
            }
            isLike.value = !isLike.value
            if (isLike.value) {
                postData.value.like_count = Number(postData.value.like_count) + 1
            } else {
                postData.value.like_count = Number(postData.value.like_count) - 1
            }
            likeTimeout.value = setTimeout(async () => {
                if(isLike.value == (parseInt(postData.value.is_like) ? true : false)) {
                    return;
                }
                const result = await postStore.setLike(postData.value.id, isLike.value)
                if (!result) {
                    // 실패시 원복
                    isLike.value = parseInt(postData.value?.is_like) ? true : false;
                    if (isLike.value) {
                        postData.value.like_count = Number(postData.value.like_count) + 1
                    } else {
                        postData.value.like_count = Number(postData.value.like_count) - 1
                    }
                    modalManager.showdialog("Failed")
                }
                else {
                    postData.value.is_like = isLike.value ? "1" : "0";
                }
                likeTimeout.value = null;
            }, 500)
        }

        const toggleCommentShow = () => {
            isCommentShow.value = !isCommentShow.value
            if(isCommentShow.value) {
                nextTick(() => {
                    if(inputCommentComponent.value) {
                        inputCommentComponent.value.focusInput()
                    }
                })
            }
        }
        const onMoveToDetail = () => {
            isCommentShow.value = true;
            if(isCommentShow.value) {
                nextTick(() => {
                    if(inputCommentComponent.value) {
                        inputCommentComponent.value.focusInput()
                    }
                })
            }
        }
        onMounted(() => {
            if(props.isComment || props.isDetail) {
                isCommentShow.value = true;
            }
            isLike.value = parseInt(postData.value?.is_like) ? true : false;
        })
        onUnmounted(async () => {
            if(likeTimeout.value) {
                clearTimeout(likeTimeout.value)
                await postStore.setLike(postData.value.id, Number(postData.value.is_like))
            }
        })
        watch(() => postData.value?.is_like, () => {
            isLike.value = parseInt(postData.value?.is_like) ? true : false;
        }, {deep : true})

        return {
            postData,
            heartImage,
            isMe,
            sortedComments,

            onLike,
            onMoveToDetail,
            isCommentShow,
            inputCommentComponent,

            toggleCommentShow,
        }
    },
}
</script>

<style scoped lang="scss">
.footerStyle {
    display: flex;
    flex-direction: column;
    margin: 0 1.875rem;
}
.footerSummary {
    user-select: none;
    display: flex;
    .item {
        font-size: 15px;
        margin:0 5px;
        &.like {
            margin-right: auto;
            margin-left: 0;
        }
        &.comment {
            cursor: pointer;
            margin-right: 0;
            margin-left: auto;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
.buttonWrapper {
    display: flex;
    margin: 1.25rem 0;
    user-select: none;

    .item {
        height: 40px;
        width : 100%;
        

        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 5px;
        color: #333333;
        margin-right: 10px;
        cursor: pointer;
        
        background: #fff;
        transition : background-color 0.2s linear;
        &:hover {
            background: #cccccc;
        }
        &:active {
            background: #AAAAAA;
        }
        img {
            width: 22px;
            height: 20px;
            margin-right: 5px;
        }
    }
}
.commentMore {
    margin: 0 20px 20px 20px;
    user-select: none;
    writing-mode: vertical-lr;
    width: 100%;
    display: flex;
    align-items: center;
}
</style>
