import { ApiV2Service, ApiError } from "./api.v2.service";

const CountryService = {
    fetchCountries: async function() {
        const response = await ApiV2Service.get('/countries')

        if (response.data.success) {
            return response.data.data
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    }
}

export default CountryService