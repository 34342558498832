import { defineStore } from "pinia";
import { ApiError } from "@/services/api.v2.service";
import NetworkService from "@/services/network.service";
import storeData from "./storeData";

export const useNetworkStore = defineStore("network", {
    state: () => ({
        networks: null,
        totalNetworks : 0,
        networksPage: 1,
        stim : 0,
        etim : 0,
        networksPerPage: storeData.default_per_page,

        users: null,
        totalUsers : 0,
        userType: 0,
        userCountry: '',
        userName: "",
        userField: '',
        usersPage: 1,
        usersPerPage: storeData.default_per_page,

        tempUser : null,

        error: { code: 0, message: "" },
    }),

    actions: {
        async createNetwork(data) {
            try {
                return await NetworkService.createNetwork(data)
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async fetchNetworks(params) {
            try {
                this.stim = params?.stim
                this.etim = params?.etim
                this.email = params?.email
                this.name = params?.name
                this.ctr = params?.ctr
                this.field = params?.field
                this.networksPage = 1
                this.networksPerPage = storeData.default_per_page

                const response = await NetworkService.fetchNetworks(this.networksPage, this.networksPerPage, params)
                if(response) {
                    this.networks = response.data
                    this.totalNetworks = Number(response.count)
                    return this.networks
                }
                return false
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async moreNetworks() {
            try {
                this.networksPage += 1
                const res = await NetworkService.fetchNetworks(this.networksPage, this.networksPerPage)
                
                if(res) {
                    this.networks = this.networks.concat(res.data)
                    return this.networks
                }
                else {
                    return res;
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async updateNetwork(networkId, data) {
            try {
                return await NetworkService.updateNetwork(networkId, data)
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async deleteNetwork(networkId, params) {
            try {
                return await NetworkService.deleteNetwork(networkId, params)
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async fetchUsers(params) {
            try {
                this.userType = params?.type != undefined ? params.type : this.userType
                this.userCountry = params?.ctr != undefined ? params.ctr : this.userCountry
                this.userName = params?.name != undefined ? params.name : this.userName
                this.userField = params?.field != undefined ? params.field : this.userField
                this.usersPage = 1
                this.usersPerPage = storeData.default_per_page

                const response = await NetworkService.fetchUsers(this.userType, this.userCountry, this.userName, this.userField, this.usersPage, this.usersPerPage)
                
                if(response) {
                    this.users = response.data
                    this.totalUsers = Number(response.count)
                    return this.users
                }
                return false
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async moreUsers() {
            try {
                this.usersPage += 1
                const res = await NetworkService.fetchUsers(this.userType, this.userCountry, this.userName, this.usersPage, this.usersPerPage)

                if(res) {
                    this.users = this.users.concat(res.data)
                    return this.users
                }
                else {
                    return res;
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    this.error = { code: error.code, message: error.message }
                }

                return false
            }
        },
        setTempUser(user) {
            this.tempUser = user
            return true
        },
        clearNetworks() {
            this.networks = null;
            this.totalNetworks = 0;
        }
    },
})