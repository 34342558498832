<template>
    <div class="bottom-refresh" ref="bottom_refresh">
        <Transition name="slide" >
            <div class="loaderContainerStyle">
                <div class="loader"></div>
            </div>
        </Transition>
    </div>
</template>
<script>
import { inject, onMounted, onUnmounted, ref } from 'vue';
export default {
    props : {
        callBack : Function,
    },
    setup(props, ctx) {
        const bottom_refresh = ref(null)
        const isScrolling = ref(false)
        const isLoading = ref(false)
        const layout = inject("layout")
        const scrollToBottomRefreshFunction = ref(null)

        const onScrollCheck = async () => {
            if(!bottom_refresh.value || isLoading.value) {
                return;
            }
            const topToBottomDiff = bottom_refresh.value.getBoundingClientRect().top - window.innerHeight
            const scrollOffsetTop = 20
            
            var acc = 1;
           
            const scrollToBottomRefresh = () => {
                scrollToBottomRefreshFunction.value = setTimeout(() => {
                    if(!bottom_refresh.value) {
                        return;
                    }
                    layout.value.scrollTo({top: layout.value.scrollTop - acc})
                    acc += .5;
                    if(!(layout.value.scrollTop < bottom_refresh.value.offsetTop - window.innerHeight + scrollOffsetTop)) {
                        scrollToBottomRefresh()
                    }
                    else {
                        isScrolling.value = false;
                    }
                }, 30)
            }

            const clearScroll = () => {
                clearTimeout(scrollToBottomRefreshFunction.value)
                isScrolling.value = false;
                scrollToBottomRefreshFunction.value = null;
            }


            if(window.innerHeight + layout.value.scrollTop > layout.value.scrollHeight - scrollOffsetTop && !isLoading.value) {
                isLoading.value = true;
                document.body.classList.add("no-scrolling");
                clearScroll();
                await props.callBack();
                isLoading.value = false;
                document.body.classList.remove("no-scrolling");
            }
            else if(topToBottomDiff < -scrollOffsetTop && !isScrolling.value) {
                acc = 1;
                isScrolling.value = true;
                scrollToBottomRefresh()
            }
        }
        onMounted(() => {
            setTimeout(() => {
                layout.value.addEventListener('scroll', onScrollCheck);
            }, 500)
        })
        onUnmounted(() => {
            layout.value.removeEventListener('scroll', onScrollCheck);
        })

        return {
            bottom_refresh,

        }
    }
}
</script>
<style scoped lang="scss">
.bottom-refresh {
    height: 100px;
}

// variables
$size: 50px !default;
$thickness: 6px !default;
$base-color: #fff !default;
$wheel-color: #c30 !default;
$speed: 800ms !default;

.loaderContainerStyle {
    transform-origin: bottom;
    transition: transform .4s ease-in-out;
}
.loader {
    margin: 5% auto;
    height: $size;
    width: $size;
    border: $thickness solid $base-color;
        border: {
        right-color: $wheel-color;
        top-color: $wheel-color;
        radius: 100%;
    }
    animation: spin $speed infinite linear;
}

@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(359deg); }
}
</style>
