<template>
    <div class="backdrop" v-if="isVisible" :class="{out : is_out}">
        <template v-for="(modal, modal_index) in current_modals" :key="modal.name" >
            <div class="modal-background" @click="close_to_background(modal)" :style="{'z-index' : (modal_index * 2) }" :tabindex="-1"></div>
            <keep-alive>
            <div class="modal-container" :style="{'z-index' : (modal_index * 2) + 1 }" @click="close_to_background(modal)">
                <component :is="modal.instance" :attr="modal.attr" v-bind="modal.paramEx?.bind ?? {}" v-on="modal.paramEx?.on ?? {}" 
                class="modal" @click.stop="close_to_background_in_modal()"/>
            </div>
            </keep-alive>
        </template>
    </div>
</template>

<script>
import ModalComponents from "@/modal/ModalContainer"
import {shallowRef} from "vue"

export default {
    name: "BaseModal",
    data() {
        return {
            current_modals : [],
            isVisible : false,
            is_out : false,
        }
    },
    components : ModalComponents,
    methods : {
        open_object(value, attr, paramEx, close_background_click = false) {
            // console.log(value);
            // const name = value.__file.split('/').reverse()[0];
            const name = value.__scopeId;
            // value를 직접 component에 전달할 경우, 깊은 복사로 인한 불필요 오버헤드 발생 가능성을 줄이기 위한 shallowRef 를 사용.

            let temp = shallowRef(value);
            
            this.current_modals.push({
                name: name,
                instance : temp,
                attr : attr,
                paramEx : paramEx,
                close_background_click : close_background_click,
                value : value,
            })
            if(this.isVisible !== true) {
                this.open()
            }
            this.open_focus()
            return name
        },
        open_object_with_key(key, value, attr, paramEx, close_background_click = false) {
            const name = key;
            this.current_modals.push({
                name: name,
                instance : shallowRef(value),
                attr : attr,
                paramEx : paramEx,
                close_background_click : close_background_click,
                value : value,
            })
            if(this.isVisible !== true) {
                this.open()
            }
            this.open_focus()
            return name
        },
        open_string(value, attr, paramEx, close_background_click = false) {
            let res = false
            // 등록된 컴포넌트 확인.
            Object.keys(ModalComponents).forEach((modal_name) => {
                if(modal_name === value) {
                    res = true
                }
            })
            // 아닐 경우 false
            if(!res) {
                return res;
            }
            this.current_modals.push({
                name: value,
                instance : value,
                attr : attr,
                paramEx : paramEx,
                close_background_click : close_background_click,
                value : value,
            })
            if(this.isVisible !== true) {
                this.open()
            }
            this.open_focus()
            return res;
        },

        open() {
            this.isVisible = true;
            this.is_in = true;
            document.body.classList.add("no-scrolling");
        },

        open_focus() {
            this.$nextTick(() => {
                document.querySelector('div.modal-background')?.focus();
            })
        },

        close(name) {
            let duplicate_name = ""

            for(let i = this.current_modals.length - 1 ; i >= 0 ; i--) {
                const modal = this.current_modals[i]
                if(modal.name === name && duplicate_name !== name) {
                    duplicate_name = name
                    this.current_modals.splice(i, 1)
                    break;
                }
            }
            
            if(this.current_modals.length <= 0) {
                this.isVisible = false;
                document.body.classList.remove("no-scrolling");
            }
        },
        close_to_background(modal) {
            if(modal.close_background_click) {
                this.$ModalManager.close(modal.value)
            }
        },
        close_to_background_in_modal() {
        }
    },
    beforeCreate() {
        this.$ModalManager.registerBaseModal(this);
    },
};
</script>

<style scoped lang="scss">
.backdrop {
    z-index: 999;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
}
.modal-background {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.1);
    position: fixed;
    
}
.modal-container {
    position: fixed;
    top : 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    justify-content : center;
    align-items : center;
    .modal{
        margin: auto;
        width: fit-content;
        height: fit-content;
        position: relative;
    }
}
</style>