<template>
    <div id="modalContainer">
        <div class="cropperButtons">
            <div class="cropperColorButtonsTitle">
                Background Color
            </div>
            <v-btn @click="onChangeBackground(`black`)" :class="{selected : backgroundClass == `black`}">
                Black
            </v-btn>
            <v-btn @click="onChangeBackground(`gray`)" :class="{selected : backgroundClass == `gray`}">
                 Gray
            </v-btn>
            <v-btn @click="onChangeBackground(`white`)" :class="{selected : backgroundClass == `white`}">
                 White
            </v-btn>
        </div>
        <div class="cropperContainer">
            <div class="cropperDiv">
                <Cropper 
                    :backgroundClass="backgroundClass"
                    :foregroundClass="backgroundClass"
                    :src="imgSrc" 
                    :stencil-component="isCircle ? CircleStencil : RectangleStencil"
                    :stencil-props="{
                        aspectRatio : ratio,
                        boundingBoxClass : stencilClass
                    }"
                    :defaultSize="defaultSize"
                    ref="cropper"
                    image-restriction="none"
                    class="cropper"
                    >
                </cropper>
            </div>
        </div>
        <div class="buttons">
            <v-btn @click="onSave" class="save">
                Select
            </v-btn>
            <v-btn @click="onClose">
                CLOSE
            </v-btn>
        </div>
    </div>
</template>
<script>
import { Cropper , CircleStencil, RectangleStencil} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import { computed, getCurrentInstance, inject, onMounted, ref } from 'vue-demi'
import util from '@/data/util';
export default { 
    components : {
        Cropper
    },
    props : {
        ratio : {
            type : Number,
            default : 5 / 4,
        },
        image : [String, Object],
        isCircle : {
            type : Boolean,
            default : false,
        }
    },
    setup(props, ctx) {
        const modalManager = inject("ModalManager")
        const currentInstance = getCurrentInstance()
        const p_image = ref(null)
        const cropper = ref(null)
        const imgSrc = ref(null)
        const backgroundClass = ref("gray")
        const stencilClass = ref("stencil-gray")

        const onChangeBackground = color => {
            backgroundClass.value = color
            stencilClass.value = `stencil-${color}`
        }
        const onClose = () => {
            modalManager.close(currentInstance)
        }
        const defaultSize =({ imageSize, visibleArea }) => {
            const size = Math.max((visibleArea || imageSize).height ,(visibleArea || imageSize).width) 
			return {
				width: size,
				height: size,
			};
		}
        const onSave = () => {
            const { canvas, visibleArea } = cropper.value.getResult()
            const fileData = util.base64ToFile(canvas.toDataURL(), `${Math.round(visibleArea.width)}X${Math.round(visibleArea.height)}_${p_image.value.name}`)
            modalManager.close(currentInstance, {
                image : fileData
            })
        }

        onMounted(() => {
            p_image.value = props.image
            
            if (p_image.value .type.indexOf('image/') === -1) {
                modalManager.showdialog('Please select an image file');
                modalManager.close(currentInstance)
                return;
            }
            if (typeof FileReader === 'function') {
                const reader = new FileReader();
                reader.onload = (event) => {
                    if(cropper.value) {
                        imgSrc.value = event.target.result;
                        // rebuild cropperjs with the updated source
                        // cropper.value.replace(event.target.result);
                    }
                };
                reader.readAsDataURL(p_image.value);
            } else {
                modalManager.showdialog('Failed');
            }
        })
        return {
            p_image,
            cropper,
            imgSrc,
            CircleStencil,
            RectangleStencil,
            backgroundClass,
            stencilClass,
            defaultSize,

            onChangeBackground,
            onClose,
            onSave
        }
    }
}
</script>

<style scoped lang="scss">
#modalContainer {
    padding: 10px;
    box-shadow: none;
    background: #fff;
    border-radius: 12px;
    display: flex;
    align-items: center;
    overflow: hidden;
    flex-direction: column;
    .cropperButtons {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        .cropperColorButtonsTitle {
            margin-right: 5px;
        }
        button {
            margin : 0 3px;
        }
        .selected { 
            background: #910457;
            color: #fff;
        }
    }
    .cropperContainer {
        width: fit-content;
        max-height: 400px;
        max-width: 500px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        .cropperDiv {
            .cropper {
                width: 400px;
                height: 400px;
                transition: all 1s ease-in;
                &::v-deep .black {
                    background: #000;
                }
                &::v-deep .gray {
                    background: #AAA;
                }
                &::v-deep .white {
                    background: #fff;
                }
                &::v-deep .stencil-black {
                    .vue-simple-line {
                        border-color: #ffffff33;
                    }
                    .vue-simple-handler {
                        background: #fff;
                    }
                }
                &::v-deep .stencil-gray {
                    .vue-simple-line {
                        border-color: #ffffff33;
                    }
                    .vue-simple-handler {
                        background: #fff;
                    }
                }
                &::v-deep .stencil-white {
                    .vue-simple-line {
                        border-color: #00000033;
                    }
                    .vue-simple-handler {
                        background: #000;
                    }
                }
            }
        }
    }
    .buttons {
        width: 100%;
        margin-top: 10px;
        display: flex;
        justify-content: center;
        button {
            width: 100%;
            flex : 1;
            margin: 0 3px;
            &.save {
                background: #910457;
                color : #fff;
            }
        }
    }
}
</style>