<template>
    <Header :menuList="menuList"> </Header>
    <router-view></router-view>
</template>
<script>
import Header from "@/component/subMenuBar.vue"
import { ref } from 'vue'
import { useRouter } from "vue-router"
export default {
    components : {
        Header,
    },
    setup(props, ctx) {
        const router = useRouter()

        const onLink = (path) => {
            return () => {router.push(path)};
        }
        
        const  menuList = ref([
            {title : "MY NETWORK", value : "network", callback : onLink("/network/my")},
            {title : "FOLLOW", value : "follow", callback : onLink("/network/follow")},
            {title : "ALL MEMBERS", value : "browse", callback : onLink("/network/browse")},
        ])

        return {
            menuList,
        }
    }
}
</script>

<style scoped lang="scss">

</style>