<template>
    <div class="wrapper">
        <div class='inner'>
            <div class="eventHeaderStyle">
                <span class="event-card-header">{{eventData?.title}}</span>
                <div class="toolbar">
                    <v-icon @click="onEdit()">mdi-pencil</v-icon>
                </div>
            </div>
            <div class="contentStyle"  @click="onDetail()">
                <!-- <span>
                    Invitor :
                    <profile-image :src="eventData?.owner_profile_image" :size="40"/>
                    {{`${eventData.owner_name}`}}
                    
                </span> -->
                <span v-if="eventData.country_name">
                    Country : 
                    <img :src="util.getCountryFlag(eventData.country_iso2)"
                        style="marginRight:10px;">
                    <b>{{eventData.country_name}}</b>
                </span>
                <span>
                    Period : <b>{{date?.start_date}} ~ {{date?.end_date}} </b>
                </span>
                <span>Schedule : {{eventData?.meeting_count}}</span>
            </div>
        </div>
        <div class="buttonStyle" @click="onCreateSchedule()" aria-disabled="true">
            <div class="text">+ New Schedule</div>
        </div>
    </div>
</template>
<script>
import eventCreateModal from "@/modal/eventCreateModal.vue"
import { useRouter } from 'vue-router'
import { computed, inject } from 'vue'
import { useEventStore } from '@/stores/event'
import util from '@/data/util'
export default {
    props : {
        eventData : Object,
    },
    setup(props, ctx) {
        const router = useRouter()
        const modalManager = inject("ModalManager")
        const eventStore = useEventStore()

        const onEdit = () => {
            modalManager.showEx(eventCreateModal,{
                bind : {
                    isEdit : true,
                    eventData : props.eventData
                }
            })
            .then(res => {
                if(res?.success) {
                    eventStore.fetchEvents()
                }
            })
        }
        const onDetail = () => {
            router.push({
                path : "/meeting/event/detail",
                query : {
                    eventno : props.eventData?.id
                }
            })
        }
        const onCreateSchedule = () => {
            router.push({
                path : "/meeting/schedule/create",
                query : {
                    eventno : props.eventData?.id
                }
            })
        }

        const date = computed(() => {
            let timezoneData = {}
            if(props.eventData.country_timezones) {
                timezoneData = util.convertTimezoneJsonToData(props.eventData.country_timezones)
            }

            return { 
                start_date : util.date_format(util.convertTimeStmapToDateTime(props.eventData.start_date), "YYYY-MM-DD"),
                end_date : util.date_format(util.convertTimeStmapToDateTime(props.eventData.end_date), "YYYY-MM-DD"),
                timezone : timezoneData?.gmtOffsetName ?? util.getTimeZoneText(),
            }
        })

        return {
            date,
            util,

            onEdit,
            onDetail,
            onCreateSchedule,
        }
    },
}
</script>

<style scoped lang="scss">
.wrapper {
    
    width: 37.5rem;
    min-width: 37.5rem;
    margin-bottom: 2.5rem;
    margin-right: 1.25rem;
    box-shadow: 2px 5px 11px #00000029;

    // height: 13.5625rem;
    // padding: 1.875rem;
    padding : 0 1.875rem;
    padding-top : 1.875rem;

    border-radius: 1rem;

    background: #fff;
    opacity: 0.7;

    .toolbar {
        visibility: hidden;
    }
    .eventHeaderStyle {
        
        display: flex;
        margin-bottom: 20px;
        justify-content: space-between;
        opacity: 1;

        .event-card-header {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font: normal normal 800 18px NanumSquareOTF;
            color: #333333;
            line-height: 1.166666667;
        }
    }
    &:hover {
        opacity: 1;
        box-shadow: none;

        .eventHeaderStyle {
            .event-card-header {
                color: #A1045A;
            }

            .toolbar {
                cursor: pointer;
                visibility: visible;
            }
        }
    }
    .contentStyle {
        
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        font: normal normal normal 18px NanumSquareOTF;
        color: #6c6c6c;

        b {
            font-weight: 800;
            color: #6c6c6c;
        }

        span {
            margin-bottom: 1.375rem;
        }
    }
    .buttonStyle {
    
        cursor: pointer;
        background : #A1045A;
        margin: 0.6875rem -1.875rem 0;

        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;

        .text {
            padding-top: 0.625rem;
            padding-bottom: 0.625rem;
            font: normal normal normal 16px NanumSquareOTF;
            line-height: 1.125;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
        }
    }
}
</style>