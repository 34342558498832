<template>
    <div class="wrapper">
        <ul class="tabStyle">
            <li v-for="item in menuList" :key="item">
                <a class="itemStyle" @click="item.callback" :class="{active :activePath == item.value}">
                    {{item.title}}
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props : {
        menuList : Object,
    },
    data() {
        return {
            activePath : ""
        }
    },
    methods : {
        getActivePath() {
            this.menuList.forEach(item => {
                const itemValue = item.value
                if(this.$route.path.includes(itemValue)) {
                    this.activePath = itemValue;
                }
            })
        }
    },
    watch : {
        "$route.path" : {
            handler() {
                this.getActivePath()
                console.log(this.activePath)
            }
        }
    },
    mounted() {
        this.getActivePath();
    }
}
</script>

<style scoped lang="scss">
.wrapper { 
    padding-right: 1rem;
    .tabStyle {
        list-style: none;
        padding: 0;
        margin: 0 0 25px 0;
        display: flex;
        user-select: none;
        li {
            z-index: 50;
            &:not(:last-of-type) {
                margin-right: 3.3125rem;
            }
            .itemStyle {
                text-decoration: none;
                color: #6c6c6c;
                font: normal normal 800 17px 'NanumSquareOTF';
                line-height: 1.117647059;
                cursor: pointer;
                &:hover {
                    color: #3c3c3c;
                }
                &.active {
                    color: #910457;
                }
            }
        }
    }
}
</style>