<template>
    <div class="block">
        <img :src="require('@/assets/wemet_logo.png')" alt='main-logo' />
        Your invitation is wrong. <br>
        Ask the person who sent the invitation to check the invitation.
    </div>
</template>
<script>
export default {

}
</script>

<style scoped lang="scss">
.block {
    user-select: none;
    background: #fff;
    padding : 30px;
    border-radius: 20px;
    top :50%;
    left: 50%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -50%);
    font-size: 20px;
    img {
        margin-bottom: 20px;
    }
}
</style>