import {createRouter, createWebHistory } from "vue-router";
import InitPage from "@/page/init/initPage.vue";
import LoginPage from "@/page/login/loginPage.vue";
import MainLayout from "@/page/main/mainLayout.vue";
import Layout from "@/page/App/AppLayout.vue"
import NotFound from "@/page/App/NotFound.vue";

import Auth from "@/router/auth"
import Email from "@/router/email"
import Feed from "@/router/feed"
import Meeting from "@/router/meeting";
import Network from "@/router/network";
import Conference from "@/router/conference";
import Profile from "@/router/profile"
import Admin from "@/router/admin"
import Invitation from "@/router/invitation"
import userData from "@/data/userData";

import { useAuthStore } from "@/stores/auth";
import { useUserStore } from "@/stores/user";

const routes = [
    {
        path : "/",
        component : Layout,
        children : [
            {
                path : "/",
                component : InitPage,
            },
            {
                path : "/login",
                component : LoginPage,
            },
            {
                path : "/main",
                component : MainLayout,
                meta: { requiresAuth: true },
                children : [
                    ...Feed,
                    ...Meeting,
                    ...Network,
                    ...Conference,
                    ...Profile,
                    ...Admin,
                ],
            },
            ...Email,
            ...Auth,
            ...Invitation,
        ]
    },
    {
        path: "/:pathMatch(.*)*",
        component: NotFound,
    },
]

const router = createRouter({
    history : createWebHistory(),
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        return {top : 0}
    }
})

router.beforeEach(async (to, from, next) => {
    let res = true;
    const authStore = useAuthStore()
    // 1. 토큰이 필요한 path로 이동시.
    if (to.matched.some(record => record.meta.requiresAuth)) {
        
        // 1-1. 토큰 확인. (없다면, 들어가면 안됨.)
        if (authStore.loggedIn) {
            // 1-2. admin 페이지 이동 확인.
            if (to.matched.some(record => record.meta.requiresAdmin)) {
                const userStore = useUserStore()
                
                if (!userStore.user) {
                    await userStore.fetchMe()
                }
                // 1-3. admin 확인. (아니라면, 들어가면 안됨.)
                if (userStore.user.type != userData.USER_TYPE.ADMIN) {
                    res = false;
                }
            }
        }
        else {
            res = false;
        }
    } 
    // 2. 이메일 인증이 필요한 path로 이동하는 경우
    else if(to.matched.some(record => record.meta.requiresEmailAuth) ) {
        // 2-1. 토큰 확인. (있다면, 들어가면 안됨.)
        // 2-2. 이메일 토큰 확인. (없다면, 들어가면 안됨.)
        if(authStore.loggedIn || !authStore.emailChecked) {
            res = false;
        }
    } 
    // 3. 아무 토큰도 필요하지 않는 path로 이동하는 경우
    else {
        // 3-1. 토큰 확인. (있다면, 들어가면 안됨.)
        if(authStore.loggedIn) {
            res = false;
        }
        // 3-1. 토큰 확인도 필요 없는 경우(무조건 들어감.)
        if(to.matched.some(record => record.meta.anywhere)) {
            res = true;
        }
    }

    if(!res) {
        if(authStore.loggedIn) {
            next({path : "/feed"})
        }
        else {
            next({path : "/"})
        }
        return;
    }
    next()
})

export default router;