import BaseModal from "@/modal/BaseModal.vue"
import ModalData from "@/modal/ModalContainer"
import loadingModal from "@/modal/loadingModal.vue"
import {ref} from "vue"

const UNSUPPORTED_MODAL_TYPE_ERROR =
    '[ModalManager] ' +
    '$ModalManager() received an unsupported argument as a modal argument.'

const NOT_REGISTER_MODAL_CALL =
    '[ModalManager] ' +
    '$ModalManager() received an non-register string argument as modal argument.'

const ModalManager = {
    base_modal : null,
    modal_event : [],

    loading : async function (func) {
        
        var isLoading = ref(true)
        this.showEx(loadingModal, {
            bind : {
                isLoading : isLoading,
            }
        })
        const res = await func()
        isLoading.value = false
        return res;
    },
    showdialog : async function(dialogdata, buttons)
    {
        if(typeof dialogdata == 'string')
        {
            dialogdata = 
                {
                    title : dialogdata,
                    buttons : {
                        "ok" : "OK",
                    }
                };
            if(buttons != undefined)
            dialogdata.buttons = buttons;
        }

        // 버튼 1개만 있을 경우만 백그라운드 닫기 기능 추가.
        let close_background_click = false
        if(Object.keys(dialogdata.buttons).length === 1) {
            close_background_click = true
        }
        return this.show(ModalData.CommonMessageBox, dialogdata, close_background_click);
    },

    show : async function(modal, attr, close_background_click = false){
        // console.log(modal);
        let modal_name;
        switch (typeof modal) {
        // 이미 등록된 컴포넌트의 String
        case 'string':
            if(!ModalManager.base_modal.open_string(modal, attr, null, close_background_click)) {
                console.warn(NOT_REGISTER_MODAL_CALL);
                return false
            }
            return new Promise((resolve, reject) => {
                ModalManager.modal_event.push({name : modal , resolve : resolve , reject : reject})
            })
        // 동적으로 등록하는 vue 컴포넌트
        case 'object':
            modal_name = ModalManager.base_modal.open_object(modal, attr, null, close_background_click)
            return new Promise((resolve, reject) => {
                ModalManager.modal_event.push({name : modal_name , resolve : resolve , reject : reject})
            })

        default:
            console.warn(UNSUPPORTED_MODAL_TYPE_ERROR, modal)
        }
    },

    showEx : async function(modal, paramEx, close_background_click = false) {
        //https://github.com/vuejs/rfcs/blob/master/active-rfcs/0003-dynamic-directive-arguments.md

        /*
        paramEx = {
            bind : {
                a : value
            },
            on : {
                b : value
            },
        }
        to
        <div v-bind="{ a : value }" v-on="{ b : value }" />
        */

        let modal_name;
        switch (typeof modal) {
        // 이미 등록된 컴포넌트의 String
        case 'string':
            if(!ModalManager.base_modal.open_string(modal, null, paramEx, close_background_click)) {
                console.warn(NOT_REGISTER_MODAL_CALL);
                return false
            }
            return new Promise((resolve, reject) => {
                ModalManager.modal_event.push({name : modal , resolve : resolve , reject : reject})
            })
        // 동적으로 등록하는 vue 컴포넌트
        case 'object':
            modal_name = ModalManager.base_modal.open_object(modal, null, paramEx, close_background_click)
            return new Promise((resolve, reject) => {
                ModalManager.modal_event.push({name : modal_name , resolve : resolve , reject : reject})
            })

        default:
            console.warn(UNSUPPORTED_MODAL_TYPE_ERROR, modal)
        }
    },

    showWithKey : async function(key, modal, attr, close_background_click = false) {
        if(typeof key === 'string' && typeof modal === 'object') {
            let modal_name = ModalManager.base_modal.open_object_with_key(key, modal, attr, null, close_background_click);
            return new Promise((resolve, reject) => {
                ModalManager.modal_event.push({name : modal_name , resolve : resolve , reject : reject})
            })
        } else {
            console.warn(UNSUPPORTED_MODAL_TYPE_ERROR, modal);
            return false;
        }
    },
    all_close() {
        for (let i = ModalManager.modal_event.length - 1 ; i >= 0  ; i--) {
            ModalManager.close(ModalManager.modal_event[i].name)
        }
    },

    do_cancel(modal, params)
    {
        this.emit_close(modal, "cancel", params);
    },


    do_ok(modal, params)
    {
        this.emit_close(modal, "ok", params);
    },

    emit_close(modal, event_name, event_params)
    {
        modal.$emit(event_name, event_params);
        this.close(modal);
    },

    close(modal, event_param)
    {
        let modal_name = ""
        let modal_value = ""
        if(typeof modal === "string") {
            modal_name = modal
            modal_value = ModalData[modal]
        }
        else if(typeof modal === "object") {
            // __scopeId 의 경우 css 가 없는 component에서는 적용되지 않으므로, css를 넣어주도록 한다.
            // vue instance 일 경우 name path (ex. this)
            if(modal?._?.type?.__scopeId) {
                modal_name = modal?._?.type?.__scopeId;
            }
            // composition API 대응 추가
            else if(modal?.type?.__scopeId) {
                modal_name = modal?.type?.__scopeId;
            }
            // vue object 일 경우 name path (ex. import된 모달 컴포넌트)
            else if(modal?.__scopeId) {
                modal_name = modal?.__scopeId;
            }
            modal_value = Object.keys(ModalData).find(key =>  {
                if(ModalData[key]?.__scopeId){
                    return ModalData[key]?.__scopeId === modal_name 
                }
            })
        }
        else {
            console.warn(UNSUPPORTED_MODAL_TYPE_ERROR, modal)
            return false;
        }

        let duplicate_name = ""
        for (let i = ModalManager.modal_event.length - 1 ; i >= 0  ; i--) {
            let info = ModalManager.modal_event[i];
            if((info.name === modal_name || info.name === modal_value) && duplicate_name != modal_name) {
                duplicate_name = modal_name
                if(info.resolve && info.reject){
                    if(info.name === modal_name) {
                        ModalManager.base_modal.close(modal_name)
                    } 
                    else if (info.name === modal_value) {
                        ModalManager.base_modal.close(modal_value)
                    }
                    if(event_param !== undefined && event_param !== null) {
                        info.resolve(event_param)
                    }
                    else {
                        // reject 사용안함.
                        info.resolve()
                    }
                    ModalManager.modal_event.splice(i, 1);
                    break;
                }
            } 
        }
    },
    registerBaseModal(base_modal) {
        if(!ModalManager.base_modal) {
            // console.log("base modal ready.")
            ModalManager.base_modal = base_modal
        }
    },
    install : (vue, options = {}) =>
    {
        ModalManager.modals = [];
        vue.component('BaseModal', BaseModal)
        vue.config.globalProperties.$ModalManager = {
            show : ModalManager.show,
            showEx : ModalManager.showEx,
            all_close : ModalManager.all_close,
            close : ModalManager.close,
            emit_close : ModalManager.emit_close,
            do_ok : ModalManager.do_ok,
            do_cancel : ModalManager.do_cancel,
            showdialog : ModalManager.showdialog,
            registerBaseModal : ModalManager.registerBaseModal,
            showWithKey : ModalManager.showWithKey,
            loading : ModalManager.loading,
        };
        vue.provide('ModalManager', vue.config.globalProperties.$ModalManager)
    },
}

export default ModalManager;

