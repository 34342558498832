<template>
    <div>
        <div class="searchBoxStyle">
            <div>
                <nationSelector
                    @updateCountry="onUpdateCountry" 
                    :selectedCountries="selectedCountries" 
                    variant="outlined"/>
            </div>
            <div class="typeSelector">
                <v-select v-model="searchType"
                    variant="outlined" hide-details @update:modelValue="onUpdateSearchType"
                    label="Search Field" :items="typeList" item-text="title"
                />
            </div>
            <div>
                <searchBox
                    ref="searchBox"
                    @onSearch="onSearch" 
                    :useSpecialChar="false" 
                    :input="searchText"
                    :searchType="selectedValue.boxtype"
                    :list="selectedValue.list"
                />
            </div>
        </div>

        <div v-if="filteredNetworks && filteredNetworks.length > 0">
            <template v-for="user in filteredNetworks" :key="user">
                <div class="networkCartStyle">
                    <networkUserCard :user="user" />
                </div> 
            </template>
            <bottomRefresh :callBack="onScrolling" v-if="isBottomRefresh" class="bottomRefresh"/>
        </div>
        <div class="noScheduleStyle" :style="{ maxWidth: '54.375rem' }" v-else>
            <h1>There is no network.</h1>
        </div>
    </div>
</template>

<script>
import networkUserCard from "@/page/network/networkUserCard.vue"
import nationSelector from "@/component/nationSelector.vue"
import searchBox from "@/component/searchBox.vue"
import bottomRefresh from "@/component/bottomRefresh.vue"
import { useNetworkStore } from '@/stores/network'
import { storeToRefs } from "pinia"
import { computed, onMounted, onUnmounted, ref, watch } from "vue"
import { useRoute, useRouter } from "vue-router"
import { useUserStore } from '@/stores/user'
import userData from '@/data/userData'
import profileData from '@/data/profileData'

export default {
    components : {
        networkUserCard,
        nationSelector,
        searchBox,
        bottomRefresh,
    },
    setup(props, ctx) {
        const userStore = useUserStore()
        const networkStore = useNetworkStore()
        const route = useRoute()
        const router = useRouter()

        const { networks, totalNetworks } = storeToRefs(networkStore)
        const { fetchNetworks, moreNetworks } = networkStore
        const searchBox = ref(null)
        const fieldList = ref(profileData.fieldData)
        const searchType = ref("name")
        const searchText = ref("")
        const selectedCountries = ref([])
        const typeList = ref([
            {title : "Name", value : "name", boxtype : "text"},
            {title : "Field", value : "field", boxtype : "select", list : fieldList.value}
        ])
        const selectedValue = ref(typeList.value[0])

        const filteredNetworks = computed(() => {
            if (networks.value) {
                return networks.value.filter(user => user.id != userStore.user.id)
            }
            return []
        })

        const updateUrl = () => {
            let params = getParams()
            delete params.type
            if(!params.ctr) {
                delete params.ctr;
            }
            if(!params.name) {
                delete params.name;
            }
            if(!params.field) {
                delete params.field;
            }
            router.push({
                query : params
            })
        }

        const onUpdateSearchType = type => {
            searchText.value = ""
            searchBox.value.setInput("")
            selectedValue.value = typeList.value.filter(item => item.value == type)[0]
        }

        const isBottomRefresh = computed(() => {
            if(totalNetworks.value > networks.value.length) {
                return true
            }
            return false
        })
        
        const onScrolling = async () => {
            await moreNetworks();
        }
        
        const getParams = () => {
            let params = {
                ctr : "",
                name : "",
                field : "",
                type : userData.NETWORK_TYPE.FOLLOW,
            }
            if(searchText.value && searchText.value.trim() && searchType.value) {
                params[searchType.value] = searchText.value
            }
            
            if(selectedCountries.value.length > 0) {
                params.ctr = selectedCountries.value.join(",")
            }
            return params
        }

        const getUsers = async () => {
            await fetchNetworks(getParams())
        }

        const onUpdateCountry = countries => {
            selectedCountries.value = countries;
            updateUrl()
        }

        const onSearch = search => {
            searchText.value = search;
            updateUrl()
        }

        const refresh = async () => {
            if(route.query.name) {
                searchText.value = route.query.name;   
                selectedValue.value = typeList.value.filter(item => item.title == "Name")[0]
                searchType.value = "name"
            } else if(route.query.field) {
                searchText.value = route.query.field;
                selectedValue.value = typeList.value.filter(item => item.title == "Field")[0]
                searchType.value = "field"
            }
            else {
                searchText.value = "";
            }
            
            if(route.query.ctr) {
                const countries = route.query.ctr.split(",")
                selectedCountries.value = countries;
            }
            else{
                selectedCountries.value = [];
            }
            await getUsers()
        }
        watch(() => route.query, async () => {
            if(route.path.includes("network/follow")) {
                await refresh()
            }
        })

        onMounted(async () => {
            await refresh()
        })

        onUnmounted(async () => {
            networkStore.clearNetworks()
        })

        return {
            networks,
            searchType,
            searchText,
            selectedCountries,
            typeList,
            isBottomRefresh,
            onScrolling,
            selectedValue,
            searchBox,

            onUpdateSearchType,
            filteredNetworks,
            onSearch,
            onUpdateCountry,
        }
    },
}
</script>

<style scoped lang="scss">
.searchBoxStyle {
    display: flex;
    max-width: 54.375rem;
    margin-bottom: 30px;
    > div {
        width: 100%;
        margin: 0 5px;
        height: fit-content;
        &.typeSelector {
            display: flex;
            width: 230px;
        }
    }
}
.networkCartStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    max-width: 54.375rem;
    margin-bottom: 20px;
}
</style>