<template>
    <div class="containerStyle">
        <div>
            <div class="note">
                Note: The current timeline is shown based on the local time of the schedule.
            </div>
            <dateSelector :date="currentDate" :isExpanded="true" :is_required="true" :useFromDate="false"
            @update:from-page="onUpdateMonth" :disabledDates="disabledDates"/>
        </div>
        <div v-if="timeData.length > 0" ref="timeline">
            <weekCalendar :dayCount="1" :startDay="currentDate.date" :eventList="timeData" @onEventClick="onScheduleClick">
                <template v-slot:mouseHoverContent="data">
                    <div class="mouseHoverContainer">
                        <div>
                            Title : {{data.title}}
                        </div>
                        <div>
                            Period : {{data.period}}
                        </div>
                        <div v-if="data.country_name">
                            Country :<img :src="util.getCountryFlag(data.country_iso2)" class="countryFlag"> {{data.country_name}}
                        </div>
                        <div>
                            Meeting Place : {{data.location}}
                        </div>
                        <div v-if="data.participants" class="partiStyle">
                            Participants : 
                            <span class="parti" :class="{'test': user.id == item.id}" 
                                v-for="(item, idx) in data?.participants" :key="idx">
                                {{item.name ?? "someone"}}
                            </span>
                        </div>
                        <div>
                            Status : <span class="status" :class="[data.statusText?.class]"> {{data.statusText?.text}} </span>
                        </div>
                    </div>
                </template>
            </weekCalendar>
        </div>
        <div v-else>
            <div class="noScheduleText">
                There is no schedule on the current date.
            </div>
        </div>
    </div>
</template>
<script>
import { useScheduleStore } from '@/stores/schedule'
import { storeToRefs } from 'pinia'
import { computed, inject, nextTick, onActivated, onMounted, ref, watch } from '@vue/runtime-core'
import { useRoute, useRouter } from 'vue-router'
import util from '@/data/util'
import dateSelector from "@/component/dateSelector.vue"
import ProfileImage from '@/component/profileImage.vue'
import { useUserStore } from '@/stores/user'
import scheduleData from '@/data/scheduleData'
import weekCalendar from "@/component/weekCalendar.vue"
export default {
    components : {
        // timeline,
        // ProfileImage,
        dateSelector,
        weekCalendar,
    },
    props : {
        date : String,
    },
    setup(props, ctx) {
        const scheduleStore = useScheduleStore()
        const {schedules} = storeToRefs(scheduleStore)
        const router = useRouter()
        const userStore = useUserStore()
        const {user} = storeToRefs(userStore)
        const currentDate = ref({})
        const modalManager = inject("ModalManager")
        const timeData = ref([])
        const selectedSchedule = ref([])
        const maxParticipantCount = ref(5)
        const disabledDates = ref([])
        const timeline = ref(null)

        const getMStatusText = status => {
            return scheduleData.getMStatusText(status)
        }

        const getMStatusTextInitial = status => {
            return scheduleData.getMStatusTextInitial(status)
        }
        
        const setDisabledDates = (sdate, edate) => {
            const start_time = util.getDate(sdate).getTime()
            const end_time = util.getDate(edate).getTime()
            const dateRange = util.range(0, Math.ceil((end_time - start_time) / (24 * 3600 * 1000)))

            disabledDates.value = dateRange.map(index => {
                const temp_date = util.getDate(sdate).getTime() + (index * 24 * 3600 * 1000)
                return {
                    start : util.date_format(temp_date, "YYYY-MM-DD"),
                    end : util.date_format(temp_date, "YYYY-MM-DD"),
                }
            })
            if(schedules.value) {
                schedules.value.forEach(schedule => {
                    const date = util.date_format(util.convertUTSTOJTS(schedule.date), "YYYY-MM-DD")
                    const index = disabledDates.value.findIndex(item => item.start == date)
                    if(index != -1) {
                        disabledDates.value.splice(index, 1)
                    }
                })
            }
        }

        const setTimeData = () => {
            timeData.value = selectedSchedule.value?.map(schedule => {
                let participants = {}
                if(schedule?.participants) {
                    participants = JSON.parse(schedule.participants)
                }
                let timezone = {}
                if(schedule?.country_timezones) {
                    timezone = util.convertTimezoneJsonToData(schedule?.country_timezones)
                }
                const date = util.date_format(util.convertTimeStmapToDateTime(schedule?.date), "YYYY-MM-DD")
                const start = util.date_format(util.convertTimeStmapToDateTime(schedule?.start_time), "HH:mm")
                const end = util.date_format(util.convertTimeStmapToDateTime(schedule?.end_time), "HH:mm")
                return {
                    ...schedule,
                    participants : util.sortObj(participants, false, parti => parti.id == user.value.id ? 1 : -1, parti => parti.name),
                    timezone : timezone,
                    period : `${date} ${start} ~ ${end}`,
                    color : scheduleData.getMStatusColor(schedule?.status),
                    statusText : scheduleData.getMStatusText(schedule?.status),
                };
            })
            timeData.value = util.sortObj(timeData.value, true, item => item.start_time, item => item.end_time, item => item.id)
        }

        const onUpdateMonth = async (data) => {
            if(Object.entries(data).length == 2 && data.year && data.month) {
                const convertDates = convertMonthStartandEnd(`${data.year}-${data.month}-01`)
                const res = await getScheduleData(convertDates.sdate, convertDates.edate)
                if(res) {
                    setDisabledDates(convertDates.sdate, convertDates.edate)
                }
            }
        }

        const selectDate = (date) => {
            const format_date = util.date_format(date, "YYYY-MM-DD")
            selectedSchedule.value = schedules.value?.filter(schedule => {
                return util.date_format(util.convertUTSTOJTS(schedule.date), 'YYYY-MM-DD') == format_date
            })
        }

        const getScheduleData = async (sdate, edate) => {
            const stim = util.convertJTSToUTS(util.getDate(sdate).getTime() + (util.getTimeZone() * 60 * 1000))
            const etim = util.convertJTSToUTS(util.getDate(edate).getTime() + (util.getTimeZone() * 60 * 1000))
            const res = await scheduleStore.fetchSchedules({
                stim : `-:${etim}`,
                etim : `+:${stim}`,
            })
            return res;
        }

        const onIconClick = (data) => {
            router.push({
                path : "/meeting/schedule/detail",
                query : {
                    scheduleno : data.id
                }
            })
        }

        const convertMonthStartandEnd = (date) => {
            // 앞뒤 1달 총 3달의 스케줄 데이터를 받아오도록 날짜 설정.
            const nDate = util.getDate(date)
            const sdate = new Date(nDate.getFullYear(), nDate.getMonth() - 1, 1, 0, 0, 0)
            const edate = new Date(nDate.getFullYear(), nDate.getMonth() + 2, 0, 23, 59, 59)
            return {
                sdate : sdate,
                edate : edate,
            }
        }
        const init = async () => {
            
            let date = util.date_format(util.getCurrentDate(),"YYYY-MM-DD")
            if(props.date) {
                date = props.date
            }
            currentDate.value = {
                date : date
            }
            const convertDates = convertMonthStartandEnd(currentDate.value.date)
            const res = await getScheduleData(convertDates.sdate, convertDates.edate)
            
            setDisabledDates(convertDates.sdate, convertDates.edate)
            if(res) {
                selectDate(currentDate.value.date)
                setTimeData()
            }
        }

        const onScheduleClick = (schedule) => {
            router.push({
                path : "/meeting/schedule/detail",
                query : {
                    scheduleno : schedule.id
                }
            })
        }

        watch(() => currentDate.value, () => {
            selectDate(currentDate.value.date)
            setTimeData()
        }, {deep : true})

        onActivated(async () => {
            await init()
        })

        return {
            util,
            user,
            currentDate,
            timeData,
            maxParticipantCount,
            // backgroundImage,
            disabledDates,
            timeline,

            onScheduleClick,
            getMStatusText,
            getMStatusTextInitial,
            onIconClick,
            onUpdateMonth,
        }
    }
}
</script>

<style scoped lang="scss">
.containerStyle {
    background: #fff;
    border-radius: 15px;
    padding : 20px;
    user-select: none;
    max-width: 600px;
    .icon {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        opacity: 0.7;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
            opacity: 1;
        }
    }
    .content {
        display: flex;
        align-items: center;
        width: 300px;
        
    .periodContent {
            flex: 1;
            display: flex;
            font: 14px NanumSquareOTF;
            justify-content: center;
            margin-right: 30px;
        }
        .scheduleContent {
            flex: 2;
            .title {
                font: 15px NanumSquareOTF;
                max-width: 200px;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: block;
                overflow: hidden;
            }
            .parcitipants {
                display: flex;
                .participantContainer {
                    display: flex;
                }
                .more {
                    margin-left: 5px;
                }
            }
        }
    }
    .note {
        font : 12px NanumSquareOTF;
    }
}
.noScheduleText {
    margin-top: 20px;
    font : 19px NanumSquareOTF;
    .no{
        color : #a50000;
    }
}

.mouseHoverContainer {
    user-select: none;
    position: absolute;
    right: 1px;
    bottom: 1px;
    font-size: 16px;
    background: #484848;
    color : #fff;
    border-radius: 5px;
    padding : 0 5px;
    width : max-content;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
    max-width: 350px;
    word-break: break-all;
    img {
        margin: 0 5px;
    }
    .status {
        padding: 0 5px;
        border-radius: 5px;
    }
    .partiStyle {
        display: flex;
        flex-flow: wrap;
        gap: 5px;
        .parti {
            border: 1px solid #fff;
            border-radius: 5px;
            padding: 0 5px;
        }
        .test {
            background: #a500006f;
        }
    }
    
}
</style>