import ConferenceMainPage from "@/page/conference/conferenceMainPage.vue"
import ConferenceWrite from "@/page/conference/conferenceWrite.vue"
import ConferenceDetail from "@/page/conference/conferenceDetail.vue"
import ConferenceLayout from "@/page/conference/conferenceLayout.vue"

export default [
    {
        path : "/conference",
        component : ConferenceLayout,
        redirect : "/conference/main",
        children : [
            {
                path : "/conference/main",
                component : ConferenceMainPage,
            },
            {
                path: "/conference/write",
                component: ConferenceWrite,
            },
            {
                path: "/conference/detail",
                component: ConferenceDetail,
            },
            {
                path: "/conference/edit",
                component: ConferenceWrite,
            }
        ]
    }
]