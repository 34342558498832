import util from "@/data/util"

export default {
    // uts(sec) data (not jts(millsec))

    // vue => server
    step_keys : {
        "negotiations" : null,
        "schedules" : null, 
        "meetings" : null,
        "participants" : {
            "meetings" : null,
        },
    },
    keys : [
        "start_date", "end_date", "start_time", "end_time", "date", "event_end_date", "event_start_date"
    ],
    timezoneEncode(data) {
        data = this.encodeData(data)
        data = this.deep_encodeData(data, this.step_keys)
        return data
    },
    encodeData(data) {
        const timezone_uts = util.getTimeZone() * 60
        this.keys.forEach(key => {
            if(data?.[key] && !isNaN(data[key])) {
                data[key] = Number(data[key]) + timezone_uts
            }
            if(data?.get && !isNaN(data.get(key))) {
                data.set(key, (Number(data.get(key)) + timezone_uts))
            }
        })
        return data
    },
    deep_encodeData(data, keys) {
        Object.entries(keys).forEach(item => {
            const key = item[0]
            const value = item[1]
            if(data?.[key]) {
                let is_string = false
                let keyData = data[key]
                if(typeof data[key] == "string") {
                    keyData = JSON.parse(data[key])
                    is_string = true
                }
                if(Array.isArray(keyData) && keyData.length > 0) {
                    keyData.forEach(keyDataItem => {
                        keyDataItem = this.decodeData(keyDataItem)
                        if(value) {
                            keyDataItem = this.deep_decodeData(keyDataItem, value)
                        }
                    })
                }
                else {
                    keyData = this.decodeData(keyData)
                    if(value) {
                        keyData = this.deep_decodeData(keyData, value)
                    }
                }

            }
        })
        return data;
    },
    // server => vue
    timezoneDecode(data) {
        data = this.decodeData(data)
        data = this.deep_decodeData(data, this.step_keys)
        return data
    },
    decodeData(data) {
        const timezone_uts = util.getTimeZone() * 60
        this.keys.forEach(key => {
            if(data?.[key] && !isNaN(data[key])) {
                data[key] = Number(data[key]) - timezone_uts
            }
            if(data?.get && data.get(key)) {
                data.set( key, (Number(data.get(key)) - timezone_uts))
            }
        })
        return data
    },
    deep_decodeData(data, keys) {
        Object.entries(keys).forEach(item => {
            const key = item[0]
            const value = item[1]
            if(data?.[key]) {
                let is_string = false
                let keyData = data[key]
                if(typeof data[key] == "string") {
                    keyData = JSON.parse(data[key])
                    is_string = true
                }
                if(Array.isArray(keyData) && keyData.length > 0) {
                    keyData.forEach(keyDataItem => {
                        keyDataItem = this.decodeData(keyDataItem)
                        if(value) {
                            keyDataItem = this.deep_decodeData(keyDataItem, value)
                        }
                    })
                }
                else {
                    keyData = this.decodeData(keyData)
                    if(value) {
                        keyData = this.deep_decodeData(keyData, value)
                    }
                }
                if(is_string) {
                    data[key] = JSON.stringify(keyData)
                }
            }
        })
        return data;
    }
}