import interceptor from "@/stores/interceptor";
import storeData from "@/stores/storeData";
import { ApiV2Service, ApiError } from "./api.v2.service";

const ScheduleService = {
    createSchedule: async function(data) {
        const param = interceptor.timezoneEncode(data)
        const response = await ApiV2Service.post('/meeting', param)
        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    fetchSchedules: async function(params, page = 1, perPage = storeData.default_per_page) {
        params =  {
            ...params,
            page : page, 
            per_page : perPage,
        }
        const response = await ApiV2Service.get('/meetings', params)
        
        response.data.data.map(sche => {
            return interceptor.timezoneDecode(sche)
        })
        if (response.data.success) {
            return {
                data : response.data.data,
                count : response.headers["x-total-count"],
                link : response.headers.link,
            }
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    fetchSchedule: async function(scheduleId) {
        const response = await ApiV2Service.get(`/meeting/${scheduleId}`)
        
        if (response.data.success) {
            return interceptor.timezoneDecode(response.data.data)
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    proceedSchedule: async function(scheduleId, data) {
        const response = await ApiV2Service.put(`/meeting/${scheduleId}/proceed`, data)
        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },


    cancelSchedule: async function(scheduleId) {
        const response = await ApiV2Service.put(`/meeting/${scheduleId}/cancel`)

        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    deleteSchedule: async function(scheduleId) {
        const response = await ApiV2Service.delete(`/meeting/${scheduleId}`)

        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    createScheduleResult : async function(scheduleId, data) {
        
        const response = await ApiV2Service.post(`/meeting/${scheduleId}/result`, data)
        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },


    mediateSchedule : async function(scheduleId, data) {
        const param = interceptor.timezoneEncode(data)
        
        const response = await ApiV2Service.post(`/meeting/${scheduleId}/negot`, param)

        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    mediateUpdateSchedule : async function(scheduleId, data) {
        
        const response = await ApiV2Service.put(`/meeting/${scheduleId}/negot`, data)

        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    }

}

export default ScheduleService