<template>
    <div id="videoSelectContainer">
        <div class="videoSelectContent" @drop.prevent="onDragVideo" @dragover.prevent="isOnMouse">
            <template v-if="!currentVideoFile">
                <div class="videoSelectTextContent">
                    <v-icon :size="50">
                        mdi-video
                    </v-icon>
                    Drag a video here or <span class="videoSelectText" @click="onClickVideo">upload a file</span>
                </div>
                <div class="splitContent">
                    <v-divider/>
                    OR
                    <v-divider/>
                </div>
                <div class="videoLinkConent">
                    <v-text-field hide-details v-model="currentVideoURL" class="textfield" variant="solo" placeholder="Enter video link" @keydown.enter="onSelect" />
                </div>
            </template>
            <template v-else>
                <div class="playVideoContainer">
                    <div class="closeVideo">
                        <v-icon @click="onCloseVideo">
                            mdi-close
                        </v-icon>
                    </div>
                    <video ref="playVideoTag" controls class="playVideo"></video>
                    <div class="videoInfoContainer">
                        Size : {{currentVideoFileSize}}
                    </div>
                </div>
            </template>
        </div>
        <!-- <div class="caution" v-if="!currentVideoFile">
            Only support YouTube links.
        </div> -->
        <div class="buttons">
            <v-btn class="button select" @click="onSelect">
                SELECT
            </v-btn>
            <v-btn class="button" @click="onClose">
                CLOSE
            </v-btn>
        </div>
    </div>
</template>
<script>
import { computed, getCurrentInstance, inject, nextTick, onMounted, onUnmounted, ref } from 'vue-demi'
import util from '@/data/util'
export default {
    setup(props, ctx) {
        const modalManager = inject("ModalManager")
        const currentInstance = getCurrentInstance()
        const currentVideoFile = ref(null)
        const currentVideoBase64 = ref(null)
        const currentVideoURL = ref("")
        const playVideoTag = ref(null)

        const currentVideoFileSize = computed(() => {
            if(currentVideoBase64.value) {
                const sizeWithUnit = util.convertByteToSizeWithUnit(util.getUnicodeTextSize(currentVideoBase64.value))
                return `${sizeWithUnit.size} ${sizeWithUnit.unit}`
            }
            return 0;
        })
        const onSelect = () => {
            if(currentVideoFile.value || (currentVideoURL.value && currentVideoURL.value.trim())) {
                let videoData = {
                    result : true
                }
                if(currentVideoFile.value) {
                    videoData.video = currentVideoFile.value
                }
                else {

                    let checkYoutubeUrl = checkYoutubeVideoUrl(currentVideoURL.value)
                    if(!checkYoutubeUrl) {
                        if(checkVideoUrl(currentVideoURL.value)) {
                            videoData.video = currentVideoURL.value
                        }
                        else {
                            modalManager.showdialog("This is not video link")
                            return;
                        }
                    }
                    else {
                        videoData.url = currentVideoURL.value
                    }
                }
                modalManager.close(currentInstance, videoData)
            }
            else {
                modalManager.showdialog({
                    title : "There is no video.<br>Are you sure you want to close it?",
                    buttons : {
                        yes : "yes",
                        no : "no"
                    }
                }).then(res => {
                    if(res.result == "yes") {
                        onClose()
                    }
                })
            }
        }
        const isOnMouse = e => {
            e.dataTransfer.dropEffect = 'link';
        }
        const onClose = () => {
            modalManager.close(currentInstance)
        }
        const onClickVideo = async () => {
            const input = document.createElement('input');

            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'video/*');
            input.click();

            input.onchange = () => {
                const file = input.files[0];
                selectVideo(file)
            }
        }
        const onDragVideo = e => {
            const data = e.dataTransfer.files[0]
            selectVideo(data)
        }
        const onCloseVideo = () => {
            currentVideoFile.value = null;
            currentVideoBase64.value = null;
            playVideoTag.value.setAttribute("src", null)
        }
        const selectVideo = async file => {
            currentVideoFile.value = file;
            currentVideoBase64.value = await util.fileToBase64(file);
            nextTick(async () => {
                const videoUrl = URL.createObjectURL(file)
                playVideoTag.value.setAttribute("src", videoUrl)
            })
        }
        const checkVideoUrl = url => {
            if(url && typeof url == 'string') {
                let match = url.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#()?&//=]*)/)
                if(match) {
                    const checkExtension = ["mp4", "3gp", "ogg", "webM"]
                    const urlObject = new URL(url)
                    const urlPathList = urlObject.pathname.split(".")
                    const extension = urlPathList[urlPathList.length - 1]
                    if(checkExtension.includes(extension)) {
                        return true;
                    }
                }
            }
            return false
        }
        const checkYoutubeVideoUrl = url => {
            let match = url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/) ||
                url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/) ||
                url.match(/^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#&?]*).*/);
            if (match && match[2].length === 11) {
                return ('https') + '://www.youtube.com/embed/' + match[2] + '?showinfo=0';
            }
            if (match = url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/)) { // eslint-disable-line no-cond-assign
                return (match[1] || 'https') + '://player.vimeo.com/video/' + match[2] + '/';
            }
            return null;
        }
        return {
            playVideoTag,
            currentVideoFile,
            currentVideoURL,
            currentVideoFileSize,

            isOnMouse,
            onCloseVideo,
            onClickVideo,
            onDragVideo,
            onClose,
            onSelect,
        }
    }
}
</script>

<style scoped lang="scss">
#videoSelectContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    box-shadow: none;
    background: #fff;
    border-radius: 12px;
    width: 600px;
    height: 500px;
    overflow: hidden;
    padding: 20px;
    .videoSelectText {
        margin-left: 5px;
        color: rgb(52, 82, 253);
        cursor: pointer;
        &:hover {
            text-decoration-line: underline;
        }
    }
    .videoSelectContent {
        display: flex;
        flex-direction: column;
        border: 1px dashed #c0c0c0;
        border-radius: 8px;
        width: 100%;
        height: 80%;
        padding : 10px;
        background: #EEE;
        .videoSelectTextContent {
            width: 100%;
            height: 80%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .splitContent {
            display: flex;
            align-items: center;
            hr {
                margin : 0 20px;
            }
        }
        .videoLinkConent {
            display: flex;
            padding: 10px;
            align-items: center;
            .textfield {
                ::v-deep .v-field--variant-solo {
                    border-radius: 20px;
                    height : 50px;
                    input{
                        padding : 10px;
                    }
                }
            }
        }
        
        .playVideoContainer {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .closeVideo {
                display: flex;
                width: 100%;
                justify-content: flex-end;
            }
            .playVideo {
                width: 90%;
                height: 90%;
            }
            .videoInfoContainer {
                display : flex
            }
        }
    }
}
.caution {    
    font : 12px NanumSquareOTF;
    margin-bottom: 5px;
}
.buttons {
    display: flex;
    width: 100%;
    margin-top: 30px;
    .button {
        display: block;
        flex-grow: 1;
        margin : 0 10px;
        &.select {
            background: #910457;
            color: #fff;
        }
    }
}
</style>