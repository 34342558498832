<template>
    <div class="recipientContainerStyle">
        <div v-if="isOwner" class="owner">
            {{isOwner ? "Invitor" : ""}}
        </div>
        <div class="FlexRow">
            <div class="profileImage">
                <profile-image :size="50" :src="recipient?.profile_image" />
            </div>
            <div class="profileData">
                <p>{{recipient?.name ?? "Non-member"}}</p>
                <p> {{recipient?.email}}</p>
                <p>
                    <v-tooltip location="top" >
                        <template v-slot:activator="{ props }">
                            <span v-bind="props">
                                <img :src="require('@/assets/company.png')" />
                                <div>
                                    {{recipient?.company ?? "-"}}
                                </div>
                            </span>
                        </template>
                        <span>Company</span>
                    </v-tooltip>
                    <v-tooltip  location="top">
                        <template v-slot:activator="{ props }">
                            <span v-bind="props">
                                <img :src="require('@/assets/country.png')" />
                                <div>
                                    {{recipient?.country_name ?? "-"}}
                                </div>
                            </span>
                        </template>
                        <span>Country</span>
                    </v-tooltip>
                </p>
            </div>
        </div>
        <div class="Field" v-if="field_list?.length > 0">
            <h4>Field</h4>
            <div class="Grid" >
                <template v-for="field in field_list" :key="field">
                    <div class="FieldItem">
                        {{field}}
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import { computed } from 'vue'
export default {
    props : {
        recipient : Object,
        isOwner : Boolean,
    },
    setup(props, ctx) {
        const field_list = computed(() => {
            if(props.recipient?.field) {
                return JSON.parse(props.recipient?.field)
            }
            return []
        })
        return {
            field_list,
        }
    }
}
</script>

<style scoped lang="scss"> 
.recipientContainerStyle {
    background: white;
    color: #333;
    padding: 1.25rem 0.9375rem;
    border-radius: 1rem;
    box-shadow: 2px 2px 8px #00000029;
    margin-top: 10px;
    max-width: 800px;
}
.owner {
    width: fit-content;
    height: fit-content;
    border-radius: 10px;
    background: #910457;
    padding : 2px 5px;
    color : #fff;
}
.FlexRow {
    
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .profileImage {
        margin-right: 15px;
    }
    .profileData p:nth-of-type(1) {
        font: normal normal 800 18px/21px NanumSquareOTF;
        margin-bottom: 0.25rem;
    }
    .profileData p:nth-of-type(2) {
        font: normal normal normal 1rem/26px NanumSquareOTF;
    }
    .profileData p:nth-of-type(3) {
        font: normal normal normal 1rem/26px NanumSquareOTF;
        color: #6c6c6c;
        display: flex;
        height: 100%;

        img {
            margin-right: 0.375rem;
            width: 1.375rem;
            height: 1.375rem;
            color: #A1045A;
        }

        span {
            max-width: 300px;
            line-height : 100%;
            display: flex;
            margin-right: 5px;
            align-items: center;
            font: normal normal normal 16px/26px NanumSquareOTF;
            >div{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        } 
    }
}

.Field {
    flex: 1;
    max-width: 100%;
    .Grid {
        display: flex;
        flex-wrap: wrap;
        .FieldItem {
            display: flex;
            align-items: center;
            margin-right: 0.5rem;
            margin-top: 0.25rem;
            color: #fff;
            padding: 0.25rem 0.5rem;
            border-radius: 50px;
            font-size: 0.875rem;
            background: #1E3560;
        }
    }
}
</style>