import { defineStore } from "pinia";
import { ApiError } from "@/services/api.v2.service";
import CountryService from "@/services/country.service";

export const useCountryStore = defineStore("country", {
    state: () => ({
        countries: null,
    }),

    getters: {
        getCountries: (state) => {
            return state.countries
        },
    },

    actions: {
        async fetchCountries() {
            try {
                this.countries = await CountryService.fetchCountries()

                return this.countries
            } catch (error) {
                if (error instanceof ApiError) {
                    this.authenticationError = { code: error.code, message: error.message }
                }

                return false
            }
        }
    },
})