import { defineStore } from "pinia";
import { ApiError } from "@/services/api.v2.service";
import UserService from "@/services/user.service";
import userData from "@/data/userData";

export const useUserStore = defineStore("user", {
    state: () => ({
        // me
        user: null,
        // other
        userFromId : null,

        authenticationError : {},
    }),

    getters: {
        getMe: (state) => {
            return state.user
        },
        isAdmin: (state) => {
            return state.user?.type == userData.USER_TYPE.ADMIN
        }
    },

    actions: {
        async fetchMe() {
            try {
                this.user = await UserService.fetchMe()

                return this.user
            } catch (error) {
                if (error instanceof ApiError) {
                    this.authenticationError = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async updateMe(data) {
            try {
                const result = await UserService.updateMe(data)
                if (result) {
                    this.fetchMe()
                }

                return true
            } catch (error) {
                if (error instanceof ApiError) {
                    this.authenticationError = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async withdraw() {
            try {
                return await UserService.withdraw()
            } catch (error) {
                if (error instanceof ApiError) {
                    this.authenticationError = { code: error.code, message: error.message }
                }

                return false
            }
        },

        async fetchUser(userId) {
            try {
                this.userFromId = await UserService.fetchUser(userId)

                return this.userFromId
            } catch (error) {
                if (error instanceof ApiError) {
                    this.authenticationError = { code: error.code, message: error.message }
                }

                return false
            }
        },
        async deleteUser(userId) {
            try {
                return await UserService.deleteUser(userId)
            } catch (error) {
                if (error instanceof ApiError) {
                    this.authenticationError = { code: error.code, message: error.message }
                }

                return false
            }
        },
        async updateUser(userId, data) {
            try {
                const result = await UserService.updateUser(userId, data)
                if (result) {
                    this.fetchMe()
                }

                return true
            } catch (error) {
                if (error instanceof ApiError) {
                    this.authenticationError = { code: error.code, message: error.message }
                }

                return false
            }
        },


        clearUser() {
            this.userFromId = null;
        },

        clearErrorData() {
            this.authenticationError = {};
        }
    },
})