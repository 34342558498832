<template>
    <div class="postStyle">
        <div class="headerStyle">
            <div class="iconStyle">
                <div />
            </div>
            <div class="titleStyle">
                <div class="titleContent">
                    <h4></h4>
                </div>
                <div class='time-date'></div>
            </div>
            <div class="moreStyle">
                <div class="moreDiv clickable"></div>
            </div>
        </div>
        <div class="bodyStyle">
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div class="footerStyle">
            <div class="footerSummary">
                <div class="item like"></div>
                <div class="item comment"></div>
            </div>
            <hr>
            <div class="buttonWrapper">
                <div class='item'></div>
                <div class='item'></div>
            </div>
            <div class="commentStyle"></div>
        </div>
    </div>
</template>

<script>
import { onMounted } from 'vue-demi'
export default {
    setup(props, ctx) {
       
        onMounted(() => {

        })

        return {

        }
    }
}
</script>

<style scoped lang="scss">
.postStyle {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.6rem;
    background: #fff;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 1rem;
    position: relative;
    opacity: 0.8;
    width: 100%;
    text-decoration: none;

    .headerStyle {
        display: flex;
        padding: 1.5625rem 1.875rem 1.25rem;
        align-items: flex-start;
        .iconStyle {
            margin-right: 1.25rem;
            padding: 5px;

            div {
                width: 70px; height: 70px;
                border-radius: 50%;
                animation: shimmer 1.5s infinite;
            }

        }
        .titleStyle {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            margin-top: -5px;
            margin-bottom: -5px;
            .titleContent {
                align-items: center;
                display: flex;

                h4 {
                    margin: 0.5625rem 0 0.3125rem;
                    width: 150px; height: 24px;
                    border-radius: 15px;

                    animation: shimmer 1.5s infinite;
                }
                .time-date {
                    width: 40px; height: 24px;
                    border-radius: 15px;

                    animation: shimmer 1.5s infinite;
                }
            }
        }
        .moreStyle {
            display: flex;
            .moreDiv {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 3rem;
                height: 3rem;
                padding: 0.5rem;
                border-radius: 999px;

                animation: shimmer 1.5s infinite;
            }
        }
    }
    .bodyStyle {
        padding: 0 1.875rem;
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        div {
            width: 90%; height: 24px;
            border-radius: 15px;

            animation: shimmer 1.5s infinite;
        }
    }
    .footerStyle {
        display: flex;
        flex-direction: column;
        margin: 0 1.875rem;
        .footerSummary {
            display: flex;
            .item {
                margin: 5px;
                &.like {
                    margin-right: auto;
                    margin-left: 0;
                    width: 40px; height: 22px;
                    border-radius: 15px;

                    animation: shimmer 1.5s infinite;
                }
                &.comment {
                    margin-right: 0;
                    margin-left: auto;
                    width: 60px; height: 22px;
                    border-radius: 15px;

                    animation: shimmer 1.5s infinite;
                }
            }
        }
        .buttonWrapper {
            display: flex;
            margin: 1.25rem 0;
            gap: 10px;

            .item {
                height: 40px;
                width : 100%;
                border-radius: 5px;
                
                animation: shimmer 1.5s infinite;
            }
        }
    }
    @keyframes shimmer {
        0% { background: #c9c9c9; }
        50% { background: #e7e5e5; }
        100% { background: #c9c9c9; }
    }
}
</style>