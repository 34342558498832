<template>
    <div class="eventSelectorStyle">
        <div class="header">
            <div >
                Create Schedule 
            </div>
        </div>
        <div class="content">
            <div>
                <div>
                    <h3>
                        Select Event
                    </h3>
                </div>
                <div class="searchDiv">
                    <searchBox :input="search" @onSearch="onSearch" :placeholder="'Search Title'"/>
                </div>
                <div class="contentContainerStyle">
                    <!-- <div v-for="event in eventSearchData" :key="event" class="eventContainerStyle" @click="onClickEvent(event)">
                                
                        <h4 class="title">
                            {{event?.title}}
                        </h4>
                        <div v-if="event.country_id" class="eventDataContent">
                            <img :src="require('@/assets/country.png')" class="imgStyle">
                            <img :src="util.getCountryFlag(event.country_iso2)" class="countryFlag">
                            {{event.country_name}}
                        </div>
                        <div class="eventDataContent">
                            <v-icon class="imgStyle">mdi-calendar</v-icon>
                            {{`${dateFormat(event?.start_date, "YYYY-MM-DD")} ~ ${dateFormat(event?.end_date, "YYYY-MM-DD")}`}}<br>
                            {{timezoneFormat(event?.country_timezones)?.gmtOffsetName ?
                             `(${timezoneFormat(event?.country_timezones)?.gmtOffsetName})` : ""}}
                        </div>
                    </div> -->
                    <calendarForEvent :eventList="eventSearchData" @clickDate="onClickDate" @updateMonth="onUpdateMonth" :maxEventCount="maxEventCount" :isClickDate="true"/>
                </div>
            </div>
        </div>
        <div class="footer">
            <v-btn @click="onClickEvent({id : 'without'})" class="buttonStyle"> CREATE EVENT </v-btn>
            <v-btn @click="onClose" class="buttonStyle close"> CLOSE </v-btn>
        </div>
    </div>
</template>
<script>
import { computed, getCurrentInstance, inject, onMounted, ref } from 'vue'
import { useEventStore } from '@/stores/event'
import { storeToRefs } from 'pinia'
import util from '@/data/util'
import searchBox from "@/component/searchBox.vue"
import eventCreateModal from "@/modal/eventCreateModal.vue"
import calendarForEvent from "@/component/calendarForEvent.vue"
import eventCalendarAttributesModal from './eventCalendarAttributesModal.vue'
export default {
    components : {
        searchBox,
        calendarForEvent,
    },
    setup(props, ctx) {
        const eventStore = useEventStore()
        const {events} = storeToRefs(eventStore)
        const modalManager = inject("ModalManager")
        const currentInstance = getCurrentInstance()
        const search = ref("")
        const p_eventList = ref([])
        const eventSearchData = ref([])
        const maxEventCount = ref(4)
        const sdat = ref(null)
        const edat = ref(null)

        const onClose = () => {
            modalManager.close(currentInstance)
        }

        const onSearch = (input) => {
            search.value = input.trim();
            eventSearchData.value = p_eventList.value.filter(event => {return event.title.includes(search.value)})
        }

        const dateFormat = (timestamp, format = "YYYY-MM-DD") => {
            return util.date_format(util.convertTimeStmapToDateTime(timestamp), format)
        }
        
        const timezoneFormat = (timezone) => {
            return util.convertTimezoneJsonToData(timezone)
        }
        const refresh = async () => {
            const currentDate = util.getCurrentDate()
            const current_date_query = util.convertJTSToUTS(currentDate.getTime())
            let start_query = util.convertJTSToUTS(util.getDate(edat.value).getTime() + (util.getTimeZone() * 60 * 1000))
            let end_query = util.convertJTSToUTS(util.getDate(sdat.value).getTime() + (util.getTimeZone() * 60 * 1000))

            start_query = Math.max(current_date_query, start_query)
            end_query = Math.max(current_date_query, end_query)

            await eventStore.fetchEvents({
                sdat : `-:${start_query}`,
                edat : `+:${end_query}`
            })
            p_eventList.value = events.value
            preprocessing()
            eventSearchData.value = p_eventList.value
        }

        const preprocessing = () => {
            let attrData = []
            p_eventList.value = util.sortObj(p_eventList.value, true, item => util.getDate(util.convertUTSTOJTS(item.start_date)), item => item.id)
            p_eventList.value.forEach(item => {
                let overlap_count = 0
                if(attrData.length != 0 ){
                    attrData.forEach(attr => {
                        const attr_start = util.convertTimeStmapToDateTime(attr.start_date)
                        const attr_start_date = util.getDate(util.date_format(attr_start, "YYYY-MM-DD"))
                        const attr_end = util.convertTimeStmapToDateTime(attr.end_date)
                        const attr_end_date = util.getDate(util.date_format(attr_end, "YYYY-MM-DD"))
                        const item_start = util.convertTimeStmapToDateTime(item.start_date)
                        const item_start_date = util.getDate(util.date_format(item_start, "YYYY-MM-DD"))
                        const item_end = util.convertTimeStmapToDateTime(item.end_date)
                        const item_end_date = util.getDate(util.date_format(item_end, "YYYY-MM-DD"))
                        if(!((attr_start_date > item_end_date) || (item_start_date > attr_end_date))) {
                            if(attr.order) {
                                overlap_count = overlap_count > (attr.order + 1) ? overlap_count : attr.order + 1;
                            }
                            else {
                                overlap_count++;
                            }
                        }
                    })
                }
                let timezone = {}
                if(item.country_timezones) {
                    timezone = util.convertTimezoneJsonToData(item.country_timezones)
                }
                attrData.push({
                    ...item,
                    order : overlap_count,
                    startDate : util.date_format(util.convertTimeStmapToDateTime(item.start_date), "YYYY-MM-DD"),
                    endDate : util.date_format(util.convertTimeStmapToDateTime(item.end_date), "YYYY-MM-DD"),
                    start_date_jts : util.convertUTSTOJTS(item.start_date),
                    end_date_jts : util.convertUTSTOJTS(item.end_date),
                    callback : () => {onClickEvent(item)},
                    timezone : timezone,
                })
            })
            p_eventList.value = attrData
        }

        const onUpdateMonth = async (e) => {
            if(Object.entries(e).length == 2 && e.year && e.month) {
                sdat.value = new Date(e.year, e.month - 1, 1, 0, 0, 0)
                edat.value = new Date(e.year, e.month , 0, 23 ,59, 59)
                await refresh()
            }
        }

        const onClickDate = (day, attr) => {
            if(Math.max(...attr.map(item => item.customData.data.order)) < maxEventCount.value) {
                return ;
            }
            modalManager.showEx(eventCalendarAttributesModal, {
                bind : {
                    day : day,
                    attribues : attr
                }
            }, true)
            .then(res => {
                if(res?.event) {
                    onClickEvent(res.event)
                }
            })
        }

        const onClickEvent = (event) => {
            if(event.id == "without") {
                modalManager.show(eventCreateModal)
                .then(async res => {
                    if(res?.success) {
                        await refresh()
                    }
                })
            }
            else {
                modalManager.close(currentInstance, event.id)
            }
        }

        onMounted(async () => {
            sdat.value = new Date(util.getCurrentDate().getFullYear(), util.getCurrentDate().getMonth(), 1, 0, 0, 0)
            edat.value = new Date(util.getCurrentDate().getFullYear(), util.getCurrentDate().getMonth() + 1 , 0, 23, 59, 59)
            await refresh()
        })

        return {
            eventSearchData,
            search,
            util,

            maxEventCount,
            onUpdateMonth,
            dateFormat,
            timezoneFormat,
            onClose,
            onSearch,
            onClickEvent,
            onClickDate,
        }
    }
}
</script>

<style scoped lang="scss">
.eventSelectorStyle {
    background: #fff;
    border-radius: 15px;
    padding : 20px;
    user-select: none;
    .header {
        background: #910457;
        color:#fff;
        border-radius: 15px;
        font-weight: bold;
        margin : 20px;
        display: flex;
        font-size: 18px;
        justify-content: center;
        text-align: center;
        padding: 10px 0;
    }
    .content {
        display: flex;
        flex-direction: column;
        .searchDiv {
            display: flex;
            width: 100%;
        }
        .contentContainerStyle {
            display: flex;
            width: 800px;
            overflow-x : auto;
            padding : 10px 0;
            max-height: 440px;

            .eventContainerStyle {
                padding : 10px;
                margin-right: 10px;
                min-width: fit-content;
                display: flex;
                background: #fff;
                opacity: 0.7;
                border-radius: 20px;
                flex-direction: column;
                border : 1px solid #00000029;
                &:hover {
                    font: 800;
                    background: rgba(255, 255, 255, 1);
                    box-shadow: 2px 5px 11px #00000029;
                    cursor: pointer;
                    opacity: 1;
                }
                .title {
                    display: block;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
                .eventDataContent {
                    display: flex;
                    align-items: center;
                    .imgStyle {
                        color : #910457;
                        width: 20px;
                        margin-right: 5px;
                    }
                    .countryFlag {
                        margin-right: 5px;
                    }
                }
            }
        }
    }
    .footer {
        display: flex;
        justify-content: center;
        margin : 20px;
        .buttonStyle {

            border: none;
            margin: 0 0.5rem;
            height: 43px; 
            width: 300px;
            font: 14px NanumSquareOTF;
            font-weight: 500;
            color: white;
                background: #910457;
            &.close { 
                background: #9c9c9c;
            }
        }
    }
}
</style>