<template>
    <Header :menuList="menuList"> </Header>
    <router-view></router-view>
</template>
<script>
import Header from "@/component/subMenuBar.vue"
export default {
    components : {
        Header,
    },
    data() {
        return {
            menuList : [
                {title : "USER", value : "user", callback : this.onLink("/admin/user")},
                {title : "FEED", value : "feed", callback : this.onLink("/admin/feed")},
                {title : "EVENT", value : "event", callback : this.onLink("/admin/event")},
                // {title : "SCHEDULE", value: 'schedule', callback : this.onLink("/admin/schedule")},
                // {title : "NETWORK", callback : this.onLink("/admin/network")},
                {title : "CONFERENCE", value : "conference", callback : this.onLink("/admin/conference")},
            ]
        }
    },
    methods : {
        onLink(path) {
            return () => {this.$router.push(path)};
        }
    }
}
</script>

<style scoped lang="scss">

</style>