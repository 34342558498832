import MailCheck from "@/page/email/emailCheckPage.vue";

export default [
    {
        path : "/email/check",
        component : MailCheck,
    },
    {
        path : "/email/forgot",
        component : MailCheck,
    }
]