import storeData from "@/stores/storeData";
import { ApiV2Service, ApiError } from "./api.v2.service";

const AdminService = {
    getUsers: async function(type = 9, country = '', name = "", field = "", order = null, page = 1, perPage = storeData.default_per_page, xlsx) {
        let params =  { 
            page : page, 
            per_page : perPage,
        }
        if(country) {
            params.ctr = country
        }
        if(name) {
            params.name = name
        }
        if(field) {
            params.field = field
        }
        if(xlsx) {
            params.per_page = 99999
        }
        if(order) {
            params["order"] = order
        }
        const response = await ApiV2Service.get('/admin/users', params)

        if (response.data.success) {
            return {
                data : response.data.data,
                count : response.headers["x-total-count"],
                link : response.headers.link,
            }
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    getPosts: async function(order = null, page = 1, perPage = storeData.default_per_page, xlsx) {
        let params =  { 
            page : page, 
            per_page : perPage,
        }
        if(xlsx) {
            params.per_page = 99999
        }
        if(order) {
            params["order"] = order
        }

        const response = await ApiV2Service.get('/admin/posts', params)

        if(response.data.success) {
            return {
                data : response.data.data,
                count : response.headers["x-total-count"],
                link : response.headers.link,
            }
        } else if(response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    getEvents: async function(order = null, page = 1, perPage = storeData.default_per_page, xlsx) {
        let params =  { 
            page : page, 
            per_page : perPage,
        }
        if(xlsx) {
            params.per_page = 99999
        }
        if(order) {
            params["order"] = order
        }

        const response = await ApiV2Service.get('/admin/events', params)

        if(response.data.success) {
            return {
                data : response.data.data,
                count : response.headers["x-total-count"],
                link : response.headers.link,
            }
        } else if(response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    getSchedules: async function(order = null, page = 1, perPage = storeData.default_per_page, xlsx, event) {
        let params = {
            page: page,
            per_page: perPage
        }
        if(xlsx) {
            params.per_page = 99999
        }
        if(order) {
            params['order'] = order
        }
        if(event) {
            params.per_page = 99999
        }

        const response = await ApiV2Service.get('/admin/meetings', params)

        if(response.data.success) {
            return {
                data: response.data.data,
                count: response.headers['x-total-count'],
                link: response.headers.link
            }
        } else if(response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    }
}

export default AdminService