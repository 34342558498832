<template>    
    <v-btn v-bind="innerAttrs">
        <slot></slot>
    </v-btn>
</template>

<script>
export default {
    inheritAttrs : false,
    data() {
        return {
            isLoading : false
        };
    },
    computed : {
        innerAttrs() {
            let attrs = {
                ...this.$attrs
            };

            if(attrs.onClick) {
                attrs.onClick = this.handleClick;
            }

            return attrs;
        },

        loadingState() {
            return this.isLoading;
        }
    },
    methods : {
        async handleClick(e) {
            if(!this.isLoading) {
                this.isLoading = true;
                await this.$attrs.onClick(e);
                this.isLoading = false;
            }
        }
    }
}
</script>

<style>

</style>