<template>
    <div class="imgBox" @click="this.$refs.inputForm.click()" @drop.prevent="onSelectImage" @dragover.prevent
        :style="{width: cssData.width + 'px', height: cssData.height + 'px'}"
    >
        <input 
            type="file" accept="image/*" name="image" id="img"
            @change="onSelectImage" ref="inputForm"
            style="display: none;"
        >
        <div>
            <img :src="inputImage ? inputImage : default_photo_image" 
                :class="[inputImage ? '' : 'defaultStyle']"
                :style="[inputImage ? {'maxWidth': cssData.width - 40 + 'px', 'height': cssData.height - 30 + 'px'} : '']" />
        </div>
    </div>
</template>

<script>
import { getCurrentInstance, onMounted, ref, watch } from "vue"
import util from "@/data/util"

export default {
    props: {
        cssData: Object,
        img : [Object , String],
    },
    setup(props, ctx) {
        const { emit } = getCurrentInstance()

        const default_photo_image = ref(require('@/assets/plus.png'))
        const inputImage = ref('')
        const inputForm = ref('')

        const resetImage = () => {
            inputImage.value = ''
        }

        const setImage = async img => {
            if(img) {
                const type = typeof img
                if(type == 'string') {
                    inputImage.value = img
                }
                else if(type == 'object') {
                    inputImage.value = await util.fileToBase64(img)
                }
            }
            else {
                inputImage.value = ''
            }
        }

        const onSelectImage = async (e) => {
            let data = ''

            if(e.target.files) {
                data = e.target.files[0]
            } else {
                data = e.dataTransfer.files[0]
            }

            // 같은 파일 선택시 동작.
            e.target.value = ''
            const last_image = inputImage.value
            inputImage.value = await util.fileToBase64(data)
            emit('onSelectImage', data, last_image)

        }
        const init = async () => {
            await setImage(props.img)
        }
        onMounted(() => {
            init()
        })
        watch(() => props.img, () => {
            init()
        })

        return {
            inputForm,
            inputImage,
            default_photo_image,

            setImage,
            onSelectImage,
            resetImage,
        }
    }
}
</script>

<style lang="scss" scoped>
.imgBox {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F2F2F2;
    cursor: pointer;

    .defaultStyle {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #91045766;
    }
}
    

</style>