<template>
    <div>
        <div class="circleMenuContainerStyle">
            <circleMenu :menuList="scheduleTab" class="circleMenuStyle" :activeValue="component" />
        </div>
        <keep-alive>
            <component :is="component" @changeComponent="changeComponent" v-bind="bindData" :isActive="isActive"/>
        </keep-alive>
    </div>
</template>
<script>
import circleMenu from "@/component/circleMenu.vue"
import ScheduleTable from "@/page/meeting/schedule/scheduleTable.vue"
import ScheduleCalendar from "@/page/meeting/schedule/scheduleCalendar.vue"
import ScheduleTimeline from "@/page/meeting/schedule/scheduleTimeLine.vue"
import emptyComponent from "@/component/emptyComponent.vue"
import { onMounted, onUnmounted, ref, watch } from 'vue'
import { useScheduleStore } from '@/stores/schedule'
import { useRoute, useRouter } from 'vue-router'
export default {
    components : {
        circleMenu,
        ScheduleTable,
        ScheduleCalendar,
        ScheduleTimeline,
        emptyComponent,
    },
    setup(props, ctx) {
        const route = useRoute()
        const router = useRouter()
        const scheduleStore = useScheduleStore()
        const query = ref({})
        const bindData = ref({})
        const isActive = ref(false);
        
        const scheduleTabCallback = (componentName) => {
            return () => {
                isActive.value = false;
                var params = {}
                // save query
                if(route.query) {
                    query.value[component.value] = route.query
                }
                // load query
                if(query.value[componentName]) {
                    params = query.value[componentName]
                }

                router.push({query : params}).then(res => {
                    isActive.value = true;
                    component.value = componentName
                })
            }
        }

        const scheduleTab = ref([
            {image : "mdi-calendar-text", value : "ScheduleCalendar", callback : scheduleTabCallback("ScheduleCalendar")},
            {image : "mdi-timeline-clock-outline", value : "ScheduleTimeline", callback : scheduleTabCallback("ScheduleTimeline")},
            {image : "mdi-view-list", value : "ScheduleTable", callback : scheduleTabCallback("ScheduleTable")},
        ])
        
        const component = ref("emptyComponent")

        const changeComponent = (component, params) => {
            if(component == "ScheduleTimeline" && params) {
                bindData.value = params
            }
            scheduleTabCallback(component)()
        }

        const pageInit =() => {
            isActive.value = false;
            if(route.query.page || route.query.order) {
                component.value = "ScheduleTable"
            }
            else {
                component.value = "ScheduleCalendar"
            }
            isActive.value = true;
        }
        onUnmounted(() => {            
            scheduleStore.clearSchedules()
        })

        onMounted(() => {
            pageInit()
        })
        
        // watch(() => route.query, () => {
        //     pageInit()
        // })
        return {
            scheduleTab,
            component,
            bindData,
            isActive,

            changeComponent,
        }
    },
}
</script>

<style scoped lang="scss">
.circleMenuContainerStyle { 
    position: absolute;
    transform: translate3d(0,0,0);
    left : 600px;
    right: 0px;
    top : -10px;
    // top : 78px;
    min-width: 300px;
    // width : calc(100% - 1000px);
    z-index: 10;
    height: 52px;
    overflow: hidden;
    .circleMenuStyle {
        right: 2rem;
        overflow: visible;
        position: absolute;
    }
}
</style>