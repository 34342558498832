<template>
    <section>
        <template v-if="isSendEmail">
            <div class="CheckEmailBlock">
                <div class="email_verift_text">
                    <h2>Check E-mail</h2>
                    <p>We sent a verification E-mail</p>
                    <p>Please check your e-mail and type the verification code sent to your E-mail</p>
                    <p>E-mail : {{email}} </p>
                </div>
                <v-form id="otp-input-form" @submit.prevent="onCheckCode">
                    <div class="otp-div">
                        <v-text-field
                            v-model="code" 
                            label="CODE" 
                            counter="6" 
                            ref="tfCode"
                            :error="authenticationError.code > 0"
                            :hint="authenticationError.message"/>
                        <!-- <optInput v-model:otp="otp" :length="6" @onEnter="onCheckCode" @onComplete="onComplete" ref="otpInput"/> -->
                    </div>
                    <div class="button-div">
                        <v-btn :loading="authenticationEmailCheck" @click="onCheckCode" :disabled="code.length < 6">
                            VERIFY CODE
                        </v-btn>
                    </div>
                </v-form>

                <div class="divider_div">
                    <v-divider></v-divider>
                    <div>OR</div>
                    <v-divider></v-divider>
                </div>
                <div class="button-div">
                    <v-btn :loading="authenticationEmailSend" @click="onResendEmail" >
                        RESEND
                    </v-btn>
                </div>
            </div>
        </template>

        <template v-else>
            <v-form id="email-input-form" @submit.prevent="onCheckEmail">
                <v-text-field
                    v-model="email"
                    label="Email"
                    class="inputStyle"
                    ref="tfEmail"
                    :error="authenticationError.code > 0"
                    :hint="authenticationError.message"
                />
                <div class="button-div">
                    <v-btn :loading="authenticationEmailSend" type="submit" form="email-input-form">
                        Send E-mail
                    </v-btn>
                </div>
            </v-form>
        </template>
    </section>
</template>

<script>
import util from "@/data/util"
import { useAuthStore } from "@/stores/auth"
import { storeToRefs } from "pinia"
import { inject, nextTick, onMounted, ref, watch, onUnmounted } from "vue"
// import optInput from "@/component/otpDigit.vue"

export default {
    props : {
        sendMail : Boolean,
        emailCheckType : Number,
    },
    setup(props, ctx) {
        const modalManager = inject("ModalManager")

        const isSendEmail = ref(false)

        const email = ref("")
        const code = ref("")

        const authStore = useAuthStore()

        const { authenticationEmailCheck, authenticationEmailSend, authenticationError } = storeToRefs(authStore)

        const tfEmail = ref(null)
        const tfCode = ref(null)

        const onCheckEmail = async () => {
            if(!util.emailCheck(email.value)) {
                authenticationError.value.code = 1
                authenticationError.value.message = "Invalid E-mail"
                tfEmail.value.focus()
                return;
            }

            authStore.checkEmail(email.value, props.emailCheckType)
                .then((result) => {
                    if (result) {
                        modalManager.showdialog("E-mail sent")
                        .then(() => {
                            tfCode.value.focus()                                                        
                        })
                        ctx.emit("onSendMail")
                        isSendEmail.value = true
                    } else {
                        tfEmail.value.focus()
                    }
                })
        }

        const onResendEmail = async () => {
            authStore.checkEmail(email.value, props.emailCheckType)
                .then((result) => {
                    if (result) {
                        modalManager.showdialog("E-mail resent")
                    }
                    tfCode.value.focus()
                })
        }

        const onCheckCode = async () => {
            authStore.verifyEmail(email.value, code.value)
                .then((result) => {
                    if (result) {
                        ctx.emit("onEmailChecked")
                    } else {
                        tfCode.value.focus()
                    }
                })
        }
        onMounted(() => {
            isSendEmail.value = props.sendMail

            if (isSendEmail.value) {
                tfCode.value.focus()
            } else {
                tfEmail.value.focus()
            }
        })

        onUnmounted(() => {
            authStore.clearAuthenticationError()
        })

        watch(() => code.value, (newValue, oldValue) => {
            if(newValue.length > 6) {
                nextTick(() => {
                    code.value = oldValue
                })
            }
        })

        return {
            isSendEmail,
            email,
            code,

            authenticationEmailCheck,
            authenticationEmailSend,
            authenticationError,

            tfEmail,
            tfCode,

            onCheckEmail,
            onResendEmail,
            onCheckCode,
        }
    },
}
</script>

<style scoped lang="scss">
.CheckEmailBlock {
    color: #6c6c6c;
    text-align: center;
    padding: 15px;
    .email_verift_text {
        margin-bottom: 20px;
        h2 {
            font: normal normal bold 16px/18px NanumSquareOTF;
            margin-bottom: 15px;
        }

        p {
            font: normal normal normal 16px/24px NanumSquareOTF;
            color: #6c6c6c;
        }
    }
}
.helpertextStyle {
    color: #6c6c6c;
    font: normal normal normal 14px/16px NanumSquareOTF;
    margin-top: 10px;
    letter-spacing: 0;
}

.otp-div {
    display: flex;
    justify-content: center;
    // margin-bottom: 2rem;
}
.inputStyle {
    
    @media all and (max-width : 768px) {
        width: 10.1875rem;
    }
    @media all and (min-width : 768px) and (max-width : 1023px) {
        width: 18.1875rem;
    }
    @media all and (min-width : 1023px) {
        width: 22.1875rem;
    }
}
</style>