<template>
    
    <div id="nationStyle" tabIndex="1" >
        <img
            :src="require('@/assets/country.png')"
            alt='country'
            :style="{ width: '1rem', height: '1rem', marginRight: '0.3125rem' }"
            v-if="isShowNationImg"
        />
        <div class="NationContainerStyle">
            <v-autocomplete
            v-model="p_selectedCountries"
            :items="countries_menu"
            clearable
            :chips="!oneSelect"
            :closable-chips="!oneSelect"
            hide-details
            filled
            :label="label"
            multiple
            item-title="name"
            item-value="id"
            :variant="variant"
            :menu-props="{ maxHeight: 400, closeOnContentClick : oneSelect }"
            >
                <template v-slot:chip="{ props, item }" v-if="!oneSelect">
                    <v-chip
                    v-bind="props"
                    :text="item.raw.name"
                    @click="onDeleteCountry(item.raw)"
                    >
                        <img :src="util.getCountryFlag(item.raw?.iso2)"
                            style="marginRight:10px;">
                        <div>
                            {{item.raw.name}}
                        </div>
                    </v-chip>
                </template>
                <template v-slot:item="{ props, item }">
                    <v-list-item
                        v-bind="props"
                        title=""
                    >
                        <img :src="util.getCountryFlag(item.raw?.iso2)">
                        {{item.raw.name}}
                    </v-list-item>
                </template>
            </v-autocomplete>
        </div>
    </div>
</template>
<script>
import { onMounted, ref, watch } from 'vue'
import { storeToRefs } from 'pinia';
import { useCountryStore } from '@/stores/country';
import util from '@/data/util';
export default {
    props : {
        selectedCountries : Array,
        oneSelect : {
            type : Boolean,
            default : false,
        },
        isShowNationImg : {
            type : Boolean,
            default : true,
        },
        variant : {
            type : String,
            default : "filled"
        },
        label : {
            type : String,
            default : "Country"
        }
    },
    setup(props, ctx) {
        
        const countryStore = useCountryStore()

        const { countries } = storeToRefs(countryStore)
        const p_selectedCountries = ref([])
        const countries_menu = ref([])


        const onDeleteCountry = (country) => {
            const index = p_selectedCountries.value.indexOf(country.id)
            if (index !== -1) {
                p_selectedCountries.value.splice(index, 1)
                ctx.emit("updateCountry", p_selectedCountries.value)
            }
        }
        onMounted(async () => {
            if(!countries.value) {
                await countryStore.fetchCountries()
            }
            countries_menu.value = countries.value
            if(props.selectedCountries) {
                p_selectedCountries.value = JSON.parse(JSON.stringify(props.selectedCountries))
            }
        })
        watch(() => props.selectedCountries, (newv, oldv) => {
            if(JSON.stringify(newv) == JSON.stringify(oldv)) {
                return;
            }
            p_selectedCountries.value = JSON.parse(JSON.stringify(props.selectedCountries))
        })
        watch(() => countries.value, () => {
            countries_menu.value = countries.value
        })
        watch(() => p_selectedCountries.value, (newv, oldv) => {
            if(JSON.stringify(newv) == JSON.stringify(oldv)) {
                return;
            }
            if(p_selectedCountries.value.length > 1 && props.oneSelect) {
                p_selectedCountries.value = newv.filter(i => i != oldv[0]);
            }
            ctx.emit("updateCountry", p_selectedCountries.value)
        })
        return {
            p_selectedCountries,
            countries_menu,
            util,

            onDeleteCountry,

        }
    }
}
</script>

<style scoped lang="scss">

.iconStyle {
    padding-left: 10px;
    color : #910457;
}
#nationStyle {
    align-items: center;
    display: flex;
    width: 100%;
    .NationContainerStyle {
        width : 100%;
        display: flex;
        align-items: center;
        .NationInputStyle {
            width : 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            .countryItemStyle {
                margin : 2.5px 5px 2.5px 5px;
                background: #910457;
                color : #fff;
                border-radius: 25px;
                padding : 0 5px;
                width: fit-content;
                cursor: pointer;
            }
            .NationInputStyle {
                width : 100%;
                padding: 5px;
                margin: 5px;
                background: #fff;
                input {
                    width: 100%;
                    height: 1.375rem;
                }
            }
        }
    }

    input::placeholder {
        color: #d9d9d9;
    }

    input:focus {
        outline: none;
    }
}
</style>