import invitationLayout from "@/page/invitation/invitationLayout"
import invitationMainPage from "@/page/invitation/invitationMainPage"
import invitationMediate from "@/page/invitation/invitationMediate"
import invitationError from "@/page/invitation/invitationError"

export default [
    {
        path : "/invitation",
        component : invitationLayout,
        meta : {anywhere : true},
        children : [
            {
                path : "/invitation",
                component : invitationMainPage
            },
            {
                path : "/invitation/mediate",
                component : invitationMediate
            },
            {
                path : "/invitation/error",
                component : invitationError
            }
        ]
    }
]