<template> 
    <div class="container">
        <div class="gg-battery">
            <div class="back"/>
            <div class="gauge" :style="{width : `${(percentage > 100 ? 100 : percentage) * 0.98}%`}" :class="{over : overSize}"/>
            <div class="text">
                {{`${percentage}%`}}
            </div>
        </div>
        <div class="textContainer">
            <div class="current">
                {{currentText}}
            </div>
            <div class="max">
                {{maxText}}
            </div>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue'
export default {
    props : {
        current : {
            type : Number,
            default : 0,
        },
        max : {
            type : Number,
            default : 0,
        },
        currentText : {
            type : String, 
            default : ""
        },
        maxText : {
            type : String,
            default : ""
        }
    },
    setup(props, ctx) {
        const percentage = computed(() => {
            if(props.max) {
                // if(props.max > props.current) {
                    return ((props.current / props.max) * 100).toFixed(2)
                // }
                // else {
                //     return 100;
                // }
            }
            else {
                return 0
            }
        })
        const overSize = computed(() => {
            return props.max < props.current
        })
        return {
            overSize,
            percentage,
        }
    }
}
</script>

<style scoped lang="scss">
.container {
    display: flex;
    flex-direction: column;
    user-select: none;
    .gg-battery {
        box-sizing: border-box;
        position: relative;
        display: block;
        width: 100px;
        height: 24px;
        transform: scale(var(--ggs,1));
        border: 2px solid;
        border-radius: 3px;
        margin-left: -3px;
        >div {
            position: absolute;
            top: 1px;
            left: 1%;
            height: 18px;
        }
        .back {
            z-index: 1;
            background: white;
            width: 98%;
        }
        .gauge {
            z-index: 2;
            background: black;
            &.over {
                background:#a00000;
            }
        }
        .text {
            z-index: 3;
            mix-blend-mode: difference;
            display: flex;
            justify-content: center;
            align-items: center;
            color : white;
            width: 98%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        &::before {
            content: "";
            display: block;
            box-sizing: border-box;
            position: absolute;
            height: 18px;
            background: currentColor;
            top: 1px;
            right: -4px;
            border-radius: 3px;
            width: 4px
        }
    }
    .textContainer {
        display: flex;
        font-size: 11px;
        justify-content: space-between;
    }
}
</style>