<template>
    <div class="scheduleMainCalendarStyle">
        <div class="note">
            Note: The current calendar is shown based on the local time of the schedule.
        </div>
        <calendarSchedule :eventList="p_eventList" @updateMonth="onUpdateMonth" :maxEventCount="maxEventCount" @clickDate="onClickDate"/>
    </div>
</template>
<script>
import calendarSchedule from "@/component/calendarForEvent.vue"
import util from '@/data/util'
import { useRouter } from 'vue-router'
import { onActivated, ref } from 'vue'
import { useScheduleStore } from '@/stores/schedule'
import scheduleData from '@/data/scheduleData'
export default {
    components : {
        calendarSchedule,
    },
    props : {
        scheduleData : Object
    },
    setup(props, ctx) {
        const router = useRouter()
        const p_eventList = ref([])
        const scheduleStore = useScheduleStore()
        const stim = ref("")
        const etim = ref("")
        const maxEventCount = ref(4)

        const onEventClick = (id) => {
            return () => {
                router.push({
                    path : "/meeting/schedule/detail",
                    query : {
                        scheduleno : id
                    }
                })
            }
        }
        const preprocessing = () => {
            let attrData = []
            if(!p_eventList.value || p_eventList.value?.length == 0) {
                p_eventList.value = []
                return
            }
            
            p_eventList.value = util.sortObj(p_eventList.value, true, item => util.getDate(util.convertUTSTOJTS(item.start_time)))
            p_eventList.value.forEach(item => {
                let overlap_count = 0
                if(attrData.length != 0 ){
                    attrData.forEach(attr => {
                        const attr_start_date = util.getDate(util.date_format(util.convertUTSTOJTS(attr.start_time),"YYYY-MM-DD"))
                        const attr_end_date = util.getDate(util.date_format(util.convertUTSTOJTS(attr.end_time),"YYYY-MM-DD"))
                        const item_start_date = util.getDate(util.date_format(util.convertUTSTOJTS(item.start_time),"YYYY-MM-DD"))
                        const item_end_date = util.getDate(util.date_format(util.convertUTSTOJTS(item.end_time),"YYYY-MM-DD"))
                        if(!((attr_start_date > item_end_date) || (item_start_date > attr_end_date))) {
                            if(attr.order) {
                                overlap_count = overlap_count > (attr.order + 1) ? overlap_count : attr.order + 1;
                            }
                            else {
                                overlap_count++;
                            }
                        }
                    })
                }
                let timezone = {}
                if(item.country_timezones) {
                    timezone = util.convertTimezoneJsonToData(item.country_timezones)
                }
                attrData.push({
                    ...item,
                    callback : onEventClick(item.id),
                    order : overlap_count,
                    startDate : util.date_format(util.convertTimeStmapToDateTime(item.start_time), "YYYY-MM-DD HH:mm"),
                    endDate : util.date_format(util.convertTimeStmapToDateTime(item.end_time), "YYYY-MM-DD HH:mm"),
                    start_date_jts : util.convertUTSTOJTS(item.start_time),
                    end_date_jts : util.convertUTSTOJTS(item.end_time),
                    color : `#${scheduleData.getMStatusColor(item.status)}`,
                    timezone : timezone,
                })
            })
            p_eventList.value = attrData
        }

        const getEventDataForDate = async () => {
            await scheduleStore.fetchSchedules(
                {
                    page : 1,
                    order : null,
                    stim : `-:${util.convertJTSToUTS(util.getDate(etim.value).getTime() + (util.getTimeZone() * 60 * 1000))}`,
                    etim : `+:${util.convertJTSToUTS(util.getDate(stim.value).getTime() + (util.getTimeZone() * 60 * 1000))}`
                }
            )
            p_eventList.value = scheduleStore.schedules
            preprocessing()
        }
 
        const onUpdateMonth = async (e) => {
            if(Object.entries(e).length == 2 && e.year && e.month) {
                stim.value = new Date(e.year, e.month - 1, 1, 0, 0 ,0)
                etim.value = new Date(e.year, e.month, 0, 23, 59, 59)
                await getEventDataForDate()
            }
        }

        const onClickDate = (day, attr) => {
            if(attr.length > 0) {
                ctx.emit("changeComponent", "ScheduleTimeline", {date : day.id})
            }
        }

        onActivated(async () => {
            stim.value = new Date(util.getCurrentDate().getFullYear(), util.getCurrentDate().getMonth(), 1, 0, 0, 0)
            etim.value = new Date(util.getCurrentDate().getFullYear(), util.getCurrentDate().getMonth() + 1 , 0, 23, 59 ,59)
            await getEventDataForDate()
        })
        return {
            p_eventList,
            maxEventCount,

            onClickDate,
            onUpdateMonth,
        }
    },
}
</script>
<style scoped lang="scss">
.scheduleMainCalendarStyle {
    max-width: 76.25rem;
    .note {
        font : 12px NanumSquareOTF;
    }
}
</style>