<template>
  <div class="detailMain">
    <div class="detailStyle">
        <div class="testStyle">
            <div class="imgStyle">
                <imageShow :src="conference?.image" :isLoading="isLoading" />
                <div>
                    <v-btn block @click="onShowAttendees" class="attendeesButton" :class="{active : conference_attendees?.length > 0}">
                        ATTENDEES List
                    </v-btn>
                </div>
                <div class="participatedDiv">
                    <div class="participatedTag" :class="{active : !isPaticipated}" @click="onAttend(false)">
                        Not Participated
                    </div>
                    /
                    <div class="participatedTag" :class="{active : isPaticipated}" @click="onAttend(true)">
                        Participated
                    </div>
                </div>
            </div>
            <div class="contentStyle">
                <h1>{{ conference?.title }}</h1>
                <div>
                    <b>Period</b> : &nbsp;{{ toFormatString(conference?.start_date, "YYYY-MM-DD (ddd)") }} ~ {{ toFormatString(conference?.end_date, "YYYY-MM-DD (ddd)") }}
                </div>
                <div><b>Country</b> : <img :src="util.getCountryFlag(conference?.country_iso2)">{{ conference?.country_name }} ({{ conference?.country_native }})</div>
                <div><b>Location</b> : &nbsp;{{ conference?.location }}</div>
            </div>
        </div>

        <div class="noteStyle">
            <b>note</b>
            <div class="preline">{{ conference?.note }}</div>
        </div>

        <img :src="conference?.image" alt="conference_img">
        
        <div class="toolStyle2" v-if="isMe">
            <v-btn class="buttonStyle" @click="onEdit"><v-icon large>mdi-pencil</v-icon>EDIT</v-btn>
            <v-btn class="buttonStyle delete" @click="onDelete"><v-icon large>mdi-delete</v-icon>DELETE</v-btn>
        </div>
    </div>
  </div>
    <a @click="onCreateEvent">
        <v-btn icon class="addStyle" :class="{disabled : isEnded}">
            <img :src="require('@/assets/plus.png')"/>
        </v-btn>
    </a>
</template>

<script>
import util from "@/data/util"
import { useConferenceStore } from '@/stores/conference'
import { computed, inject, onMounted, ref } from "vue"
import { useRoute, useRouter } from "vue-router"
import { useUserStore } from '@/stores/user'
import imageShow from "@/component/imageShow.vue"
import eventCreateModalVue from "@/modal/eventCreateModal.vue"
import conferenceAttendeesListModal from '@/modal/conferenceAttendeesListModal.vue'
import { storeToRefs } from 'pinia'

export default {
    components : {
        imageShow,
    },
    setup(props, ctx) {
        const modalManager = inject("ModalManager")
        
        const userStore = useUserStore()
        const conferenceStore = useConferenceStore()
        const router = useRouter()
        const route = useRoute()
        const isLoading = ref(true)

        const {conference, conference_attendees} = storeToRefs(conferenceStore)

        const isMe = computed(() => {
            return userStore.user?.id == conference.value?.owner_id
        })

        const timezoneData = computed(() => {
            return util.convertTimezoneJsonToData(conference.value?.country_timezones)
        })

        const isEnded = computed(() => {
            const current_date = util.getDate(util.date_format(util.getCurrentDate(timezoneData.value?.gmtOffset), "YYYY-MM-DD"))
            const conference_end_date = util.getDate(util.date_format(util.convertTimeStmapToDateTime(conference.value?.end_date), "YYYY-MM-DD"))
            return current_date > conference_end_date
        })

        const isPaticipated = computed(() => {
            return conference_attendees.value?.some(attendee => attendee.id == userStore.user?.id)
        })

        const toFormatString = (timestamp, format) => {
            return util.date_format(util.convertTimeStmapToDateTime(timestamp), format)
        }
        const onEdit = () => {
            router.push({ path: '/conference/edit', query: { conferenceno: route.query?.conferenceno} })
        }
        const onDelete = () => {
            modalManager.showdialog({
                title : "Do you want to delete this conference?",
                buttons : {
                    "yes" : "yes",
                    "no": "no"
                }
            })
            .then(res => {
                if(res.result == "yes") {
                    conferenceStore.deleteConference(route.query?.conferenceno)
                    .then((result) => {
                        if(result) {
                            modalManager.showdialog("Deleted")
                                .then(() => {
                                    router.back()
                                })
                        } else {
                            modalManager.showdialog("Failed")
                        }
                    })
                }
            })
        }
        const onCreateEvent = () => {
            if(isEnded.value) {
                modalManager.showdialog("The conference has ended.<br>Unable to create event.")
                return;
            }
            modalManager.showEx(eventCreateModalVue, { 
                bind : {
                    conference: conference.value,
                } 
            })
            .then(res => {
                if(res?.success) {
                    router.push('/meeting/event')
                }
            })
        }
        const onShowAttendees = () => {
            if(conference_attendees.value?.length == 0) {
                modalManager.showdialog("There is no attendee")
                return;
            }
            modalManager.showEx(conferenceAttendeesListModal, {
                bind : {
                    conference : conference.value,
                    attendees : conference_attendees.value,
                }
            })
        }
        
        const onAttend = async attend => {
            let res = false
            let succeesText = "Participated"
            if(attend) {
                if(!isPaticipated.value) {
                    res = await conferenceStore.attendConference(conference.value.id)
                }
                else {
                    return;
                }
            }
            else {
                succeesText = "Not Participated"
                if(isPaticipated.value) {
                    res = await conferenceStore.absentConference(conference.value.id)
                }
                else {
                    return;
                }
            }

            conferenceStore.fetchConferenceAttendees(route.query?.conferenceno)
            if(res) {
                modalManager.showdialog(`${succeesText}`)
            }
            else {
                modalManager.showdialog(`Failed`)
            }
        }

        onMounted(async () => {
            isLoading.value = true;
            if (route.query?.conferenceno) {
                await conferenceStore.fetchConference(route.query?.conferenceno)
                await conferenceStore.fetchConferenceAttendees(route.query?.conferenceno)
                isLoading.value = false;
            } else {
                router.back()
            }
        })

        return {
            conference,
            conference_attendees,
            isMe,
            isEnded,
            isLoading,
            isPaticipated,
            util,

            toFormatString,
            onEdit,
            onAttend,
            onDelete,
            onCreateEvent,
            onShowAttendees,
        }
    },
}
</script>

<style lang="scss" scoped>
.addStyle {
    position: fixed;
    bottom: 103px;
    right: 2rem;
    z-index: 10;
    background: rgb(145, 4, 87);
    width: 56px;
    height: 56px;
    &.disabled {
        background: #6c6c6c;
    }
}
.detailMain {
    max-width: 54rem;
    height: 100%;
    font: 16px NanumSquareOTF;

    .detailStyle {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.6rem;
        background: #fff;
        box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
        border-radius: 1rem;
        position: relative;
        opacity: 0.8;
        width: 100%;
        padding: 50px 20px;

        .testStyle {
            display: flex;
            padding-bottom: 50px;
            border-bottom: 1px solid #9c9c9c;
            .imgStyle {
                display: flex;
                flex-direction: column;
                align-self: center;
                margin-right: 2.5rem;

                img {
                    width: 250px;
                    border: 1px solid #9c9c9c;
                    border-radius: 1rem;
                }
            }
            .attendeesButton {
                margin: 10px 0;
                color: #fff;
                background: #6c6c6c;
                &.active {
                    background: #A1045A;
                }
            }
            .participatedDiv {
                display: flex;
                align-items: center;

                .participatedTag {
                    transition: 0.3s all;
                    user-select: none;
                    cursor: pointer;
                    border-radius: 10px;
                    padding: 2px 7px;
                    width: fit-content;
                    margin : 5px 2px;
                    &.active {
                        background: #A1045A;
                        color: #fff;
                    }
                    &:not(.active) {
                        &:hover {
                            background: #A1045A33;
                        }
                    }

                }
            }

            .contentStyle {
                display: flex;
                flex-direction: column;
                margin-right: 2.5rem;
                flex-grow: 1;
                word-break: break-all;
                h1 {
                    word-break: break-all;
                }
                div {
                    margin-top: 0.8rem;

                    &:first-of-type {
                        margin-top: 1.9rem;
                    }

                    &:nth-of-type(odd) > b {
                        color: #A1045A;
                    }
                }
                img{
                    margin : 0 5px;
                }
            }
        }

        .noteStyle {
            padding-top: 20px;
            padding-bottom: 20px;

            .preline {
                word-break: break-all;
                white-space: pre-line;
            }
        }

        .toolStyle2 {
            display: flex;
            justify-content: center;
            color: #fff;
            margin-top: 50px;

            .buttonStyle {
                flex : 1;
                border: none;
                margin: 0 0.5rem;
                width: 100%;
                height: 43px;
                font: 14px NanumSquareOTF;
                font-weight: 500;
                color: white;
                background: #A1045A;
                
                &.delete { background: #6c6c6c; }

                i { padding-right: 0.5rem; }
            }
        }
    }
}
</style>
