import { defineStore } from "pinia";

export const useImageStore = defineStore("image", {
    state: () => ({
        images : {},
        loadingImages : {},
        loadingQueue : {},
    }),

    actions: {
        getImage(key) {
            return this.images[key]
        },
        setImage(key, value) {
            this.images[key] = value
        },
        getLoadingImage(key) {
            return this.loadingImages[key]
        },
        setLoadingImage(key, value) {
            this.loadingImages[key] = value
            if(value == false && this.loadingQueue[key] && this.loadingQueue[key].length > 0) {
                this.loadingQueue[key].forEach(async functions => {
                    const resolve = functions[0]
                    const callback = functions[1]
                    const thumbnailData = this.getImage(key)
                    const res = await callback(thumbnailData)
                    resolve(res)
                })
            }
        },
        waitLoading(key, callback) {
            return new Promise((resolve, reject) => {
                if(!this.loadingQueue[key]) {
                    this.loadingQueue[key] = []
                }
                this.loadingQueue[key].push([resolve, callback])
            })
        }
    },
})