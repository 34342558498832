<template>
    <div class="Header">
        <div class="profileImageStyle">
            <profile-image :src="profile?.profile_image" />
        </div>
        <div class="InnerBlock">
            <div class="NameMailContainer">
                <h1>
                    {{profile?.name}} {{isMe ? "(Me)" : ""}}
                </h1>
                <span>{{profile?.email}}</span>
            </div>
            <div class="ButtonBlock" v-if="!isMe">
                <template v-if="!checkNetwork(networkTypes.BUDDY)">
                    <v-btn icon @click="onAddBuddy(true)">
                        <img :src="require('@/assets/follow.png')" />
                    </v-btn>
                </template>
                <template v-else>
                    <v-btn icon class="follow-1" @click="onAddBuddy(false)">
                        <img :src="require('@/assets/follow-1.png')" />
                    </v-btn>
                </template>
                <v-btn icon @click="onInvite()">
                    <img :src="require('@/assets/invite.png')" />
                </v-btn>
                <v-btn icon :href="'mailTo:' + profile?.email">
                    <img :src="require('@/assets/email.png')" />
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import eventSeletorModal from "@/modal/eventSelectorModal.vue"
import { computed, inject, onMounted, onUnmounted, ref } from 'vue'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import { useNetworkStore } from '@/stores/network'
import router from '@/router'
import userData from '@/data/userData'
export default {
    setup(props, ctx) {
        const userStore = useUserStore()
        const {userFromId, user} = storeToRefs(userStore)
        const profile = ref(userFromId)
        const networkStore = useNetworkStore()
        const {networks} = storeToRefs(networkStore)
        const modalManager = inject('ModalManager')
        const networkTypes = ref(userData.NETWORK_TYPE)

        const isMe = computed(() => {
            return profile.value?.id == user.value?.id
        })
        const networkData = computed(() => {
            if (networks.value?.length > 0) {
                return networks.value.find(network => network.user_id == profile.value?.id)
            }
            return null
        })
        const checkNetwork = type => {
            return (networkData.value?.type & type) == type
        }
        const onAddBuddy = async (add) => {
            let res = false;
            if(add) {
                let params = {
                    type : userData.NETWORK_TYPE.BUDDY,
                    data : {
                        id : Number(profile.value.id),
                        name : profile.value.name,
                        email : profile.value.email,
                        company : profile.value.company
                    },
                }
                res = await networkStore.createNetwork(params)
                if(res) {
                    modalManager.showdialog("Maked network")
                    refreshNetwork()
                }
                else {
                    modalManager.showdialog("Failed")
                }
            }
            else {
                if (networks.value?.length > 0) {
                    res = await networkStore.deleteNetwork(networkData.value.id, {type : userData.NETWORK_TYPE.BUDDY})
                    if(res) {
                        modalManager.showdialog("Deleted network")
                        refreshNetwork()
                    }
                    else {
                        modalManager.showdialog("Failed")
                    }
                }                
            }
        }
        const onInvite = () => {
            modalManager.show(eventSeletorModal).then(res => {
                if(res) {
                    networkStore.setTempUser(profile.value)
                    router.push({
                        path : "/meeting/schedule/create",
                        query : {
                            eventno : res
                        }
                    })
                }
            })
        }
        const refreshNetwork = async  () => {
            await networkStore.fetchNetworks()
        }
        onMounted(() => {
            refreshNetwork()
        })

        onUnmounted(() => {
            networkStore.clearNetworks()
        })

        return {
            profile,
            networkTypes,
            isMe,
            checkNetwork,
            networkData,

            onAddBuddy,
            onInvite,
        }
    },
}
</script>

<style scoped lang="scss">
.Header{
    
    display: flex;
    align-items: center;
    padding: 2rem 0;
    border-bottom: 1px solid #eee;
    box-shadow: inset 0 calc(-1 * 1px) 0 #9c9c9c;
    margin-bottom: 1.5625rem;
    margin-left: 1.875rem;
    margin-right: 1.875rem;
    .profileImageStyle {
        margin-right: 16px;
    }
    .InnerBlock {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .NameMailContainer {
                        
            display: flex;
            flex-direction: column;

            h1 {
                margin-bottom: 5px;
                color: #333;
                font: normal normal 800 18px/21px NanumSquareOTF;
            }

            span {
                font: normal normal normal 15px/17px NanumSquareOTF;
                color: #9c9c9c;
            }
        }
    }
    .ButtonBlock {
        display: flex;

        button, a {
            width: 36px;
            height: 36px;
            border: none;
            outline: none;
            background: none;
            padding: 0;
            cursor: pointer;
            border: 1px solid #910457;
            border-radius: 999px;
            cursor: pointer;

            &.follow-1 {
                background : #910457;
            }

            img {
            display: inline-block;
            max-width: 100%;
            width: 1.25rem;
            height: 1.25rem;
            object-fit: scale-down;
            border: none;
            margin: 0;
            border-radius: 0;
            }
        }

        button + button,
        button + a {
            margin-left: 0.625rem;
        }
    }
}
</style>