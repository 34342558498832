<template>
    <div class="itemStyle" @click.stop="onLink()" :class="{isMember : user.user_id && user.user_id > 0}">
      <div class="iconStyle">
        <profile-image :src="user?.profile_image"/>
      </div>
      <div class="userStyle">
            <div class="nameStyle"><span class="notUserStyle" v-if="!user.user_id">Non-member</span> {{user?.name ?? networkUser?.name}} </div>
            <div class="emailStyle">{{user?.email ?? networkUser?.email}}</div>
      </div>
      <div class="informStyle">
        <div class="companyBoxStyle">
            <v-tooltip location="top" >
                <template v-slot:activator="{ props }">
                    <span v-bind="props">
                        <img :src="require('@/assets/company.png')" />
                        {{user?.company ?? networkUser?.company}}
                    </span>
                </template>
                <span>Company</span>
            </v-tooltip>
            <v-tooltip  location="top">
                <template v-slot:activator="{ props }">
                    <span v-bind="props">
                        <img :src="require('@/assets/country.png')" />
                        {{user?.country_name}}
                    </span>
                </template>
                <span>Country</span>
            </v-tooltip>
        </div>
        <v-tooltip location="start">
            <template v-slot:activator="{ props }">
                <div :style="{ display: 'flex', flexDirection: 'row' }" v-bind="props">
                    <div class="Grid" >
                        <template v-for="field in fields" :key="field">
                            <div class="FieldItem">
                                {{field}}
                            </div>
                        </template>
                    </div>
                </div>
            </template>
            <span>Field</span>
        </v-tooltip>
      </div>
        <v-tooltip location="start">
            <template v-slot:activator="{ props }">
                <div class="stateContainer">
                    <div class="stateStyle" v-bind="props" @click.stop="onCreateSchedule">
                        <img :src="require('@/assets/invite.png')" />
                    </div>
                </div>
            </template>
            <span>Wemet</span>
        </v-tooltip>
    </div>
</template>

<script>
import { computed, inject } from 'vue'
import { useRouter } from 'vue-router'
import eventSeletorModal from '@/modal/eventSelectorModal.vue'
import { useNetworkStore } from '@/stores/network'
export default {
    props : {
        user : Object,
    },
    setup(props, ctx) {
        const modalManager = inject("ModalManager")
        const router = useRouter()
        const networkStore = useNetworkStore()

        const fields = computed(() => {
            if (props.user?.field) {
                try {
                    const fields = JSON.parse(props.user?.field)
                    if (Array.isArray(fields)) {
                        return fields
                    }
                } catch(e) {
                    return []
                }
            }
            return []
        })
        const networkUser = computed(() => {
            if(props.user?.data) {
                try {
                    const networkUser = JSON.parse(props.user?.data)
                    return networkUser
                }
                catch(e) {
                    return {}
                }
            }
            return {}
        })

        const onCreateSchedule = () => {
            modalManager.show(eventSeletorModal)
            .then(res => {
                if(res) {
                    networkStore.setTempUser({...networkUser.value})
                    if(props.user.user_id) {
                        networkStore.setTempUser({
                            ...props.user,
                            id : props.user.user_id
                        })
                    }
                    router.push({
                        path : "/meeting/schedule/create",
                        query : {
                            eventno : res
                        },
                    })
                }
            })
        }
        const onLink = () => {
            let userId = 0
            // if (props.user?.data) {
            //     if (networkUser.value.id) {
            //         userId = networkUser.value.id
            //     }
            // } else {
                userId = props.user.user_id
            // }

            if (userId && userId >= 0) {
                router.push({
                    path : "/profile/user",
                    query : {
                        userno : userId
                    }
                })
            } 
            // else {
            //     modalManager.showdialog("Non-member")
            // }
        }
        return {
            fields,
            networkUser,

            onLink,
            onCreateSchedule,
        }
    }
}    
</script>

<style scoped lang="scss">
.itemStyle {
    user-select: none;
    display: flex;
    width: 100%;
    align-items: center;
    background: #fff;
    opacity: 0.7;
    border-radius: 0.5rem;
    padding: 1.5625rem 1.875rem;
    overflow: hidden;
    &.isMember {
        cursor: pointer;
    }
    &:hover {
        color: #A1045A;
        background: rgba(255, 255, 255, 1);
        box-shadow: 2px 5px 11px #00000029;
        opacity: 1;
    }

    & + & {
        margin-top: 0.5rem;
    }
    .iconStyle {
        margin-right: 1.25rem;
    }
    .userStyle {
        flex: 2;
        .nameStyle {
            font-size: 1.25rem;
            font-weight: 600;
            margin-bottom: 0.3125rem;
            color: #333333;
            .notUserStyle {
                padding : 0 8px;
                background: #A1045A;
                color : #fff;
                border-radius: 15px;
            }
        }
        .emailStyle {
            color: #9c9c9c;
        }
    }
    .informStyle {
        display: flex;
        flex: 3;
        min-height: 3.6875rem;
        flex-direction: column;
        font-size: 0.875rem;
        color: #bdbdbd;

        svg {
            margin-right: 0.375rem;
            color: #A1045A;
            font-size: 1rem;
        }

        span {
            display: flex;
            align-items: center;
        }
        .companyBoxStyle {
                        
            display: flex;
            flex-direction: row;
            color: #6c6c6c;
            margin-bottom: 1.0625rem;

            span + span {
                margin-left: 1.6875rem;
            }

            span img {
                width: 1.375rem;
                margin-right: 6px;
            }
        }
    }
    .Grid {
        display: flex;
        width: 400px;
        flex-wrap: wrap;
        .FieldItem {
            display: flex;
            align-items: center;
            margin-right: 0.5rem;
            color: #fff;
            padding: 0.25rem 0.5rem;
            border-radius: 50px;
            font-size: 0.875rem;
            background: #1E3560;
        }
    }
    .stateContainer {
        .stateStyle {
            width: 3.75rem;
            height: 3.75rem;
            background: #f2f2f2;
            text-align: center;
            border-radius: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            span {
                color: #6c6c6c;
                font: normal normal normal 16px/18px NanumSquareOTF;
            }
            img {
                width: 1.5rem;
            }
        }
    }
}
</style>