<template>
    <div class="containerBlockStyle">
        <v-form @submit.prevent="onSubmit" id="schedule-form">
            <section class="meetingSectionStyle">
                <div class="resetHeader">
                    <h2>Result</h2>
                    <v-btn @click="onReset" class="reset" rounded v-if="result">
                        RESET
                        <v-icon>mdi-restart</v-icon>
                    </v-btn>
                </div>
                <v-text-field
                    v-model="form.title"
                    placeholder='Enter comment'
                    hide-details
                />
            </section>
            <section class="meetingSectionStyle">
                <h2>Photo</h2>
                <div v-for="(img, index) in form.image" :key="img">
                    <div class="close">
                        <v-icon @click="onImageDelete(index)" >mdi-close</v-icon>
                    </div>
                    <insertImage :cssData="{width: '450', height: '200'}" :img="form.image[index]"
                        @onSelectImage="data => onSelectImage(data, index)" />
                    <hr>
                </div>
                <div>
                    <insertImage :cssData="{width: '450', height: '200'}" ref="newInsertImage"
                        @onSelectImage="data => onSelectImage(data)" />
                </div>
            </section>
            <section class="meetingSectionStyle">
                <h2>Whether we met or not</h2>
                <v-radio-group v-model="form.is_met" row>
                    <v-radio label="we met"  color="red" :value="true"/>
                    <v-radio label="missed" color="red" :value="false"/>
                </v-radio-group>
            </section>
            <div class="buttons">
                <v-btn class="buttonStyle submit" type="submit" form="schedule-form">
                    SUBMIT
                </v-btn>
                <v-btn class="buttonStyle back" @click="onBack" v-if="result">
                    BACK
                </v-btn>
            </div>
        </v-form> 
    </div>
</template>
<script>
import { onMounted, ref } from 'vue'
import insertImage from '@/component/insertImage.vue'
export default {
    components: {
        insertImage
    },
    props : {
        schedule : Object,
        result : Object,
    },
    setup(props, ctx) {
        const newInsertImage = ref(null)
        
        const form = ref({
            title : "",
            is_met : false,
            image : [],
        })
        const image_base64 = ref("")
        const default_photo_image = ref(require('@/assets/plus.png'))

        const onSelectImage = (data, index = -1) => {
            if(index == -1) {
                form.value.image.push(data)
                if(newInsertImage.value) {
                    newInsertImage.value.resetImage()
                }
            }
            else {
                form.value.image[index] = data;
            }
        }

        const onImageDelete = index => {
            if(form.value.image.length > index) {
                form.value.image.splice(index, 1)
            }
        }
        
        const onSubmit = async () => {
            ctx.emit("onResultSubmit", form)
        }
        
        const onReset = () => {
            init()
        }
        
        const onBack = () => {
            ctx.emit("onBack")
        }

        const init = () => {
            form.value.title = props.result.result;
            form.value.is_met = Number(props.result.is_meet) ? true : false;
            if(props.result.image) {
                form.value.image = props.result.image.split(";");
            }
            else {
                form.value.image = [];
            }
        }
        onMounted(() => {
            if(props.result) {
                init()
            }
        })

        return {
            form,
            image_base64,
            default_photo_image,
            newInsertImage,

            onSubmit,
            onImageDelete,
            onReset,
            onBack,
            onSelectImage
        }
    },
}
</script>

<style scoped lang="scss">

.containerBlockStyle {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 32.0625rem;
    min-width: 32.0625rem;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 2px 5px 11px #00000029;
    border-radius: 1rem;
    padding: 1.875rem;
    margin-bottom: 1rem;
    height: fit-content;
    

    h1 {
        color: #333;
        margin: 0 0 1.875rem;
        font: normal normal 800 20px/23px NanumSquareOTF;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .meetingSectionStyle {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.3125rem;
        color: #333333;
        width: 100%;

        .resetHeader {
            display: flex;
            .reset { 
                margin-right: 0;
                margin-left: auto;
                background: rgba(238, 92, 92);
                color: #fff;
            }
        }
        &:last-of-type {
            margin-bottom: 0;
        }

        .multiline {
            white-space: pre-wrap;
            max-width: 100%;
            overflow-wrap: break-word;
            color: #6c6c6c;
            font: normal normal normal 16px/26px NanumSquareOTF;
        }

        .email {
            font: normal normal normal 16px/18px NanumSquareOTF;
            color: #9c9c9c;
        }

        .state-text {
            font: normal normal normal 16px/26px NanumSquareOTF;
            color: #6c6c6c;
            margin-left: 1.25rem;
        }

        h2 {
            font: normal normal 800 16px/36px NanumSquareOTF;
            line-height: 2.25;
            margin: 0 0 0.25rem;
            border-radius: 0.5rem;
            user-select: none;
        }

        hr {
            margin : 5px 0;
        }

        p {
            margin: 0;
            font: normal normal normal 16px/18px NanumSquareOTF;
        }

        .divider {
            background: #d9d9d9 0% 0% no-repeat padding-box;
            height: 18px;
            width: 1px;
        }
        
        .close {
            position: absolute;
            width: 26px;
            height: 26px;
            cursor: pointer;
            right: 5px;
            background: #F2F2F2;
            border-radius: 5px;
            &:after {
                content: '';
                position: absolute;
                border-style: solid;
                border-top : 0 solid #F2F2F2;
                border-bottom : 8px solid #F2F2F2;
                border-left : 8px solid #F2F2F2;
                border-right : 0 solid #F2F2F2;
                border-color: transparent #F2F2F2;
                display: block;
                width: 0;
                top: 0;
                height: 120%;
                z-index: 1;
                left: -2px;
            }
        }
    }
    
    .buttons {
        display: flex;
        width: 100%;
        .buttonStyle {
            display: block;
            flex-grow: 1;
            font: 14px NanumSquareOTF;
            color: #fff;
            margin: 0 5px;
            &.submit {
                background: #910457;
            }
            &.back {
                background: #6c6c6c;
            }
        }
    }
}
</style>