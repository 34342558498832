<template>
    <div class="layout">
        <div class="content">
            <div class="logoStyle">
                <img :src="require('@/assets/wemet_logo.png')" alt='main-logo'  />
            </div>
            <div class="textStyle">
                404 Not Found
            </div>
        </div>
    </div>
</template>
<script>
export default {

}
</script>

<style scoped lang="scss">
.layout {
    overflow-x: hidden;
    min-height: 100vh;
    height: 100vh;
    background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(@/assets/background.jpg);
    background-size: cover;
    .content {
        top :50%;
        left: 50%;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transform: translate(-50%, -50%);
        .logoStyle {
            width: 90px;
            height: 90px;
            display: flex;
            justify-content: flex-end;

            img {
                width: 4.375rem;
                height: 100%;
                margin-right: 0.78125rem;
            }
        }
        .textStyle {
            margin-top: 40px;
            text-align: center;
            font-size: 24px;
        }
    }
}
</style>