import interceptor from "@/stores/interceptor";
import storeData from "@/stores/storeData";
import { ApiV2Service, ApiError } from "./api.v2.service";

const EventService = {
    createEvent: async function(data) {
        const param = interceptor.timezoneEncode(data)
        const response = await ApiV2Service.post('/event', param)
        if (response.data.success) {
            return response.data.data
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    fetchEvents: async function(sdat = '', edat = '', order = null, page = 1, perPage = storeData.default_per_page) {
        let params = {
            page : page,
            per_page : perPage
        }
        if(sdat) {
            params.sdat = sdat
        }
        if(edat) {
            params.edat = edat
        }
        if(order) {
            params.order = order
        }
        
        const response = await ApiV2Service.get('/events', params)
        response.data.data.map(event => {
            return interceptor.timezoneDecode(event)
        })
        if (response.data.success) {
            return {
                data : response.data.data,
                count : response.headers["x-total-count"],
                link : response.headers.link,
            }
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    fetchEvent: async function(eventId) {
        const response = await ApiV2Service.get(`/event/${eventId}`)

        if (response.data.success) {
            return interceptor.timezoneDecode(response.data.data)
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    updateEvent: async function(eventId, data) {
        const param = interceptor.timezoneEncode(data)
        const response = await ApiV2Service.put(`/event/${eventId}`, param)

        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

    deleteEvent: async function(eventId) {
        const response = await ApiV2Service.delete(`/event/${eventId}`)

        if (response.data.success) {
            return true
        } else if (response.data.error) {
            throw new ApiError(response.data.error.code, response.data.error.message)
        } else {
            return false
        }
    },

}

export default EventService