<template>
    <div class="containerBlockStyle">
        <div class="headerStyle">
            <h1>
                Adjust Schedule
            </h1>
            <v-btn @click="onReset" class="buttonStyle reset" rounded>
                RESET
                <v-icon >
                    mdi-restart
                </v-icon>
            </v-btn>
        </div>
        <div>
            <section class="meetingSectionStyle">
                <h2>Requested by</h2>
                <div class="title-div">
                    <profile-image :src="myData?.data?.profile_image" :size="40"/>
                    <p>{{myData?.name ?? 'Non-member'}}</p>
                    <div class='divider'></div>
                    <p class='email'>{{myData?.email}}</p>
                </div>
                <h2>Country</h2>
                <div class="title-div">
                    <img :src="util.getCountryFlag(schedule?.country_iso2)" > <p>{{schedule?.country_name}}</p>
                </div>
                <!-- <h2>Schedule Information</h2> -->
                <div class="ScheduleInfoBlock dateSelectorBlack">
                    <h2>Calendar</h2>
                    <p class="caution" v-if="isDisabledScheduleTime">
                        <v-icon class="blink">mdi-alert-circle-outline</v-icon>
                        The selected period overlaps with the participant's schedule.
                    </p>
                    <v-tooltip location="start">
                        <template v-slot:activator="{ props }">
                            <div v-bind="props">
                                <v-icon>mdi-calendar</v-icon>
                                <div class="showSelectedDate">
                                    {{date.date && date.start && date.end ? `${date.date} ${date.start} ~ ${date.end}` : date.noDateText}} 
                                    {{date.timezone ? `(${date.timezone})` : ""}}
                                </div>
                            </div>
                        </template>
                        <span>Calendar</span>
                    </v-tooltip>
                    <div class="arrowStyle">
                        <v-icon>
                            mdi-arrow-down-bold
                        </v-icon>
                    </div>
                    <div class="showSelectedDate" v-if="form.date.start != 0 && form.date.end != 0">
                        {{`${scheduleDate.date} ${scheduleDate.start} ~ ${scheduleDate.end}`}} 
                        {{scheduleDate.timezone ? `(${scheduleDate.timezone})` : ""}}
                    </div>
                    <v-btn @click="onScheduleDateModal" class="selectButton">
                        SELECT SCHEDULE DATE
                    </v-btn>
                </div>
                <div class="ScheduleInfoBlock">
                    <h2>Meeting Place</h2>
                    <v-tooltip location="start">
                        <template v-slot:activator="{ props }">
                            <div v-bind="props">
                                <v-icon>mdi-map-marker</v-icon>{{schedule?.location ?? date.noLocationText}}
                            </div>
                        </template>
                        <span>Meeting Place</span>
                    </v-tooltip>
                    <div class="arrowStyle">
                        <v-icon>
                            mdi-arrow-down-bold
                        </v-icon>
                    </div>
                    <div class="textfieldStyle">
                        <v-text-field 
                            v-model="form.location" 
                            hide-details 
                            type='text'
                            placeholder='Enter meeting place'
                        />
                    </div>
                </div>
                <div class="ScheduleInfoBlock">
                    <h2>Comment</h2>
                    <v-tooltip location="start">
                        <template v-slot:activator="{ props }">
                            <div v-bind="props">
                                <v-icon>mdi-comment-text-outline</v-icon>{{schedule?.comment}}
                            </div>
                        </template>
                        <span>Comment</span>
                    </v-tooltip>
                    <div class="arrowStyle">
                        <v-icon>
                            mdi-arrow-down-bold
                        </v-icon>
                    </div>
                    <div>
                        <v-textarea
                            v-model="form.comment"
                            type='text'
                            placeholder="Enter comment"
                            no-resize
                            hide-details
                            class="commentInputStyle"
                        />
                    </div>
                </div>
            </section>
        </div>
        <div class="buttons">
            <v-btn @click="onApply" class="buttonStyle apply">
                Adjust
            </v-btn>
            <v-btn @click="onBack" class="buttonStyle back">
                BACK
            </v-btn>
        </div>
    </div>
</template>
<script>
import scheduleDateSeletorModal from "@/modal/scheduleDateSeletorModal.vue"
import { computed, inject, onMounted, ref, watch } from 'vue'
import util from '@/data/util'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import scheduleData from '@/data/scheduleData'
export default {
    props : {
        schedule : Object,
        event : Object,
        isInvitation : {
            type : Boolean,
            default : true,
        },
        tokenData : Object,
    },
    setup(props, ctx) {
        const modalManager = inject("ModalManager")
        const disabledDates = ref([])
        const userStore = useUserStore()
        const {user} = storeToRefs(userStore)
        const form = ref({
            date : {
                date : util.convertUTSTOJTS(props.schedule?.date),
                start : util.convertUTSTOJTS(props.schedule?.start_time),
                end : util.convertUTSTOJTS(props.schedule?.end_time),
            },
            location : props.schedule?.location,
            comment : props.schedule?.comment,
        })
        const selectedDateData = ref(null)
        const participants = computed(() => {
            if(props.schedule?.participants) {
                const participants = JSON.parse(props.schedule?.participants)
                // owner를 위로 올리기 위함.
                return util.sortObj(participants, false, item => item.id == props.schedule?.owner_id ? 1 : 0)
            }
            else {
                return []
            }
        })
        const timezoneData = computed(() => {
            return util.convertTimezoneJsonToData(props.schedule?.country_timezones)
        })
        const myData = computed(() => {
            if(participants.value) {
                let myId = user.value?.email
                if(props.tokenData) {
                    myId = props.tokenData?.email
                }
                const data = participants.value.filter(parti => parti.email == myId)
                if(data.length > 0) {
                    return data[0]
                }
            }
            return {}
        })
        const date = computed(() => {
            if(!props.schedule?.date || !props.schedule?.start_time || !props.schedule?.end_time) {
                return {
                    date : null,
                    start : null,
                    end : null,
                    noDateText : scheduleData.noDateText,
                    noLocationText : scheduleData.noLocationText
                }
            }
            var res = {
                date : util.date_format(util.convertTimeStmapToDateTime(props.schedule?.date), "YYYY-MM-DD"),
                start : util.date_format(util.convertTimeStmapToDateTime(props.schedule?.start_time), "HH:mm"),
                end : util.date_format(util.convertTimeStmapToDateTime(props.schedule?.end_time), "HH:mm"),
                noDateText : scheduleData.noDateText,
                noLocationText : scheduleData.noLocationText
            }
            if(res.end == "00:00") {
                res.end = util.date_format(util.getDate(props.schedule?.end_time), "YYYY-MM-DD HH:mm")
            }
            // if(props.schedule?.country_timezones) {
            //     res.timezone = timezoneData.value.gmtOffsetName
            // }
            return res;
        })
        const scheduleDate = computed(() => {
            var res = {
                date : util.date_format(util.getDate(form.value.date.date), "YYYY-MM-DD"),
                start : util.date_format(util.getDate(form.value.date.start), "HH:mm"),
                end : util.date_format(util.getDate(form.value.date.end), "HH:mm"),
            }
            if(res.end == "00:00") {
                res.end = util.date_format(util.getDate(form.value.date.end), "YYYY-MM-DD HH:mm")
            }
            // if(props.schedule?.country_timezones) {
            //     res.timezone = timezoneData.value.gmtOffsetName
            // }

            return res;
        })
        const selectedMeetings = computed(() => {
            if(participants.value.length > 0 ) {
                let result = []
                participants.value.forEach(reci => {
                    if(!reci.user_id && !reci.meetings) {
                        return;
                    }
                    reci.meetings.forEach(meeting => {
                        const m_timezoneData = util.convertTimezoneJsonToData(meeting.country_timezones)
                        let start_time = Number(meeting.start_time)
                        let end_time = Number(meeting.end_time)
                        if(m_timezoneData?.gmtOffset && timezoneData.value?.gmtOffset) {
                            start_time = start_time - Number(m_timezoneData.gmtOffset) + Number(timezoneData.value?.gmtOffset)
                            end_time = end_time - Number(m_timezoneData.gmtOffset) + Number(timezoneData.value?.gmtOffset)
                            meeting.start_time = start_time
                            meeting.end_time = end_time
                        }
                        result.push(meeting)
                    })
                })
                return result
            }
            return null
        })

        const isDisabledScheduleTime = computed(() => {
            let res = false
            if(selectedMeetings.value && form.value.date.date && form.value.date.start && form.value.date.end) {
                const schedule_start = Number(form.value.date.start)
                const schedule_end = Number(form.value.date.end)
                selectedMeetings.value.forEach(meeting => {
                    if(res) {
                        return;
                    }
                    const meeting_start = Number(util.convertUTSTOJTS(meeting.start_time))
                    const meeting_end = Number(util.convertUTSTOJTS(meeting.end_time))
                    if(!(schedule_start >= meeting_end || meeting_start >= schedule_end)) {
                        res = true;
                    }
                })
            }
            return res
        })
        const checkData = () => {
            let req = [
                {
                    value : !(form.value.date.date == util.convertUTSTOJTS(props.schedule?.date)
                        && form.value.date.start == util.convertUTSTOJTS(props.schedule?.start_time)
                        && form.value.date.end == util.convertUTSTOJTS(props.schedule?.end_time)
                        && form.value.location == props.schedule?.location
                        && form.value.comment == props.schedule?.comment
                    ), 
                    text : "This is same content"
                },
                {
                    value : form.value.date.date && form.value.date.start && form.value.date.end,
                    text : "calendar date"
                },
                {
                    value : form.value.location,
                    text : "location"
                },
                {
                    value : !isDisabledScheduleTime.value,
                    text : "period overlaps with this participant's schedule"
                }
            ]
            const res = util.dataCheck(req)
            if(res.length > 0) {
                return res.join("<br>")
            }
            return false
        }

        const onScheduleDateModal = () => {
            modalManager.showEx(scheduleDateSeletorModal, {
                bind : {
                    timezone : timezoneData.value,
                    selectedDateData : selectedDateData.value,
                    event : props.event,
                    form : form.value,
                    disabledDates : disabledDates.value,
                    disabledMeetings : selectedMeetings.value,
                }
            })
            .then(res => {
                if(res) {
                    selectedDateData.value = res
                    
                    if(selectedDateData.value.start) {
                        form.value.date.date = util.getDate(selectedDateData.value.start).getTime()
                    }
                    form.value.date.start = util.getDate(selectedDateData.value.start).getTime()
                    form.value.date.end = util.getDate(selectedDateData.value.end).getTime()
                }
            })
        }

        const setDisabledDates = () => {
            if(props.event?.start_date && props.event?.end_date) {

                const start_date = util.convertTimeStmapToDateTime(props.event.start_date)
                const end_date = util.convertTimeStmapToDateTime(props.event.end_date)
                disabledDates.value.push({
                    start : null,
                    end : start_date.setDate(start_date.getDate() - 1),
                })
                disabledDates.value.push({
                    start : end_date.setDate(end_date.getDate() + 1),
                    end : null,
                })
            }
        }

        const onBack = () => {
            ctx.emit("onBack")
        }
        const onApply = () => {
            const res = checkData()
            if(res) {
                modalManager.showdialog(`Please check your data:<br> ${res}`)
                return;
            } 
            ctx.emit("onApply", form.value)
        }
        const onReset = () => {
            initData()
        }
        const initData = () => {
            form.value ={
                date : {
                    date : util.convertUTSTOJTS(props.schedule?.date),
                    start : util.convertUTSTOJTS(props.schedule?.start_time),
                    end : util.convertUTSTOJTS(props.schedule?.end_time),
                },
                location : props.schedule?.location,
                comment : props.schedule?.comment,
            }
            selectedDateData.value = {
                start : util.date_format(util.getDate(form.value.date.start), "YYYY-MM-DD HH:mm"),
                end : util.date_format(util.getDate(form.value.date.end), "YYYY-MM-DD HH:mm"),
            }
        }
        onMounted(() => {
            onReset()
            initData()
            setDisabledDates()
        })
        watch(() => props.schedule, () => {
            onReset()
            initData()
        })
        watch (() => props.event, () => {
            setDisabledDates()
        })
        return {
            participants,
            date,
            form,
            myData,
            util,
            scheduleDate,
            isDisabledScheduleTime,
            
            onScheduleDateModal,
            onBack,
            onReset,
            onApply,
        }
    }
}
</script>

<style scoped lang="scss">

.containerBlockStyle {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 32.0625rem;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 2px 5px 11px #00000029;
    border-radius: 1rem;
    padding: 1.875rem;
    margin-bottom: 1rem;
    margin-right : 16px;
    height: fit-content;
    .headerStyle {
        display: flex;
        width: 100%;
        align-items: center;
        
        h1 { 
            font: normal normal 800 23px/23px NanumSquareOTF;
            color : #910457;
            user-select: none;
        }
        .buttonStyle {
            margin-left: auto;
            margin-right: 0;
        }
    }
    
    .meetingSectionStyle {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.3125rem;
        color: #333333;
        width: 100%;
        .email {
            font: normal normal normal 16px/18px NanumSquareOTF;
            color: #9c9c9c;
        }

        h2 {
            font: normal normal 800 16px/36px NanumSquareOTF;
            line-height: 2.25;
            margin: 0 0 0.25rem;
            border-radius: 0.5rem;
            user-select: none;
        }

        p {
            margin: 0;
            font: normal normal normal 16px/18px NanumSquareOTF;
        }

        .divider {
            background: #d9d9d9 0% 0% no-repeat padding-box;
            height: 18px;
            width: 1px;
        }
        .title-div {
            display: flex;
            align-items: center;
            * {
                margin-right: 5px;
            }
        }
        .arrowStyle {
            display: flex;
            justify-content: center;
            margin : 0px 20px;
            color : black;
        }
        .textfieldStyle {
            width: 100%;
        }
        .ScheduleInfoBlock {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            width: 100%;
            justify-content: space-between;
            &.dateSelectorBlack {
                flex-direction: column;
                .selectButton {
                    background: #910457;
                    font: 14px NanumSquareOTF;
                    color: #fff;
                }
                .caution {
                    font : 14px NanumSquareOTF;
                    margin-bottom: 5px;
                    i {
                        border-radius: 50%;
                        background: rgb(145, 0, 0);
                        color : #fff;
                    }
                }
            }
            >div {
                display: flex;
                align-items: center;
                text-align: center;
            }

            & + & {
                margin-top: 4px;
            }

            & :not(.arrowStyle) i {
                color: #910457;
                margin-right: 6px;
            }
            .imageStyle {
                width: 1.5rem;
            }
        }
    }
    .buttons {
        display: flex;
        align-items: center;
        width : 100%;
        .buttonStyle {
            width: 100%;
            flex : 1;
        }
    }
    .buttonStyle {
        border: none;
        margin: 0 0.5rem;
        font: 14px NanumSquareOTF;
        font-weight: 500;
        color: white;
        background: #A1045A;
        justify-self: center;
        
        &.back { background: #6c6c6c; }
        &.reset { background: rgba(238, 92, 92); }
    }
}
</style>