<template>
    <div class="containerBlockStyle">
        <section class="meetingSectionStyle">
            <div class="ResultHeader">
                <h2>Result </h2> <span>{{isAdmin ? `(Reporter Email : ${participant?.email})` : ''}}</span>
            </div>
            <div>
                {{result?.result}}
            </div>
        </section>
        <section class="meetingSectionStyle" v-if="result.image">
            <h2>Photo</h2>
            <template  v-for="(img, index) in images" :key="img">
                <hr v-if="index != 0"/>
                <div class="photo-div" @click="onShowImage(index)">
                    <img class="imgViewStyle" :src="img"/>
                </div>
            </template>
        </section>
        <section class="meetingSectionStyle">
            <h2>Whether we met or not</h2>
            <v-radio-group v-model="radioTrue">
                <v-radio :label="result?.is_meet &&  Number(result?.is_meet) ? 'met' : 'missed'" disabled :value="true" />
            </v-radio-group>
        </section>
        <div class="buttons">
            <v-btn @click="onUpdateResult" class="selectButton">
                Edit Result
            </v-btn>
        </div> 
    </div>
</template>
<script>
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { computed, inject, onMounted, ref } from 'vue';
import imageShowModal from "@/modal/imageShowModal.vue"
export default {
    props : {
        result : Object
    },
    setup(props, ctx) {
        const radioTrue = ref(true);
        const userStore = useUserStore()
        const {isAdmin} = storeToRefs(userStore)
        const modalManager = inject("ModalManager")

        const images = computed(() => {
            if(props.result?.image) {
                return props.result.image.split(";")
            }
            return []
        })
        const onUpdateResult = () => {
            modalManager.showdialog({
                title : "Do you want to edit this result?",
                buttons : {
                    yes : "yes",
                    no : "no",
                }
            }).then(res => {
                if(res.result == "yes") {
                    ctx.emit("onUpdateResult")
                }
            })
        }
        
        const participant = computed(() => {
            if(props.result?.participant) {
                const partiData = JSON.parse(props.result?.participant)
                return partiData
            }
            return {}
        })
        const onShowImage = index => {
            modalManager.showEx(imageShowModal, {
                bind : {
                    imgs : images.value.map(img => {
                        return {
                            src : img
                        }
                    }),
                    idx : index,
                }
            })
        }
        onMounted(() => {
            console.log(props.result)
        })
        return {
            participant,
            radioTrue,
            images,
            isAdmin,

            onShowImage,
            onUpdateResult,
        }
    },
}
</script>

<style scoped lang="scss">

.containerBlockStyle {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 32.0625rem;
    min-width: 32.0625rem;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 2px 5px 11px #00000029;
    border-radius: 1rem;
    padding: 1.875rem;
    margin-bottom: 1rem;
    margin-right : 16px;
    height: fit-content;
    

    h1 {
        color: #333;
        margin: 0 0 1.875rem;
        font: normal normal 800 20px/23px NanumSquareOTF;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .meetingSectionStyle {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.3125rem;
        color: #333333;
        width: 100%;
        
        .ResultHeader {
            display: flex;
            align-items: center;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
        h2 {
            font: normal normal 800 16px/36px NanumSquareOTF;
            line-height: 2.25;
            margin: 0 0 0.25rem;
            border-radius: 0.5rem;
            user-select: none;
            margin-right: 10px;
        }
        .photo-div {
            width: 450px;
            height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #F2F2F2;
            cursor: pointer;
            .imgViewStyle {
                max-width: 365px;
                height: 170px;
            }
            .defaultImgViewStyle {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background: #91045766;
            }
        }
        hr {
            margin : 5px 0;
        }
    }

    .selectButton {
        font: 14px NanumSquareOTF;
        color : #fff;
        background: #910457;
    }
}
</style>