<template>
    <div>
        <div class="titleContainer">
            <h1 class="titleEventStyle">{{event?.title}}</h1>
            <div class="eventHeaderStyle">
                <div class="toolbar">
                    <v-btn icon @click="onEdit()">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon @click="onDelete()">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </div>
            </div>
        </div>
        <section class="dateSectionStyle">
            <img :src="require('@/assets/calendar.png')"/>
            <span>
                {{date?.start_date}} - {{date?.end_date}}
            </span>
        </section>
        <section class="dateSectionStyle" v-if="event?.country_name">
            <img :src="require('@/assets/country_gray.png')" />
            <img v-if="event?.country_iso2" :src="util.getCountryFlag(event.country_iso2)"
                style="marginRight:10px;">
            <span>{{event?.country_name}}</span>
        </section>
        
        <div class="UnavailableTimeContainer">
            <div>
                <v-btn @click="onUnavailableTimeShow" class="unavailableTimeButton" :class="{disabled : !activeEvent}">
                    UNAVAILABLE TIME
                </v-btn>
            </div>
            <Transition mode="in-out" name="slide">
                <div v-show="isUATime" class="unavailableTimePicker">
                    <EventUnavailableTime :event="event" @onUnavailableSave="onUnavailableSave"/>
                </div>
            </Transition>
        </div>

        <h3 class="titleEventStyle">Schedule List</h3>
        <template v-if="event?.meetings?.length > 0">
            <div class="scheduleWrapStyle">
                <div v-for="schedule in event?.meetings" :key="schedule">
                    <EventDetailCard
                        :scheduleData="schedule"
                    />
                </div>
            </div>
        </template>
        <template v-else> 
            <div class="noScheduleStyle">
                <h1>There is no schedule.</h1>
            </div>
        </template>
        <a class="linkStyle" @click="onCreateSchedule">
            <v-btn icon class="addStyle" :class="{disabled : !activeEvent}">
                <img :src="require('@/assets/plus.png')"/> 
            </v-btn>
        </a>
    </div>
</template>
<script>
import EventUnavailableTime from "@/page/meeting/event/eventUnavailableTime.vue"
import EventDetailCard from "@/page/meeting/event/eventDetailCard.vue"
import { useRoute, useRouter } from 'vue-router'
import { useEventStore } from '@/stores/event'
import { computed, inject, onMounted, onUnmounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import util from '@/data/util'
import scheduleData from '@/data/scheduleData'
import eventCreateModal from '@/modal/eventCreateModal.vue'
export default { 
    data() {
        return {
            query : {},
            eventData : {},
            scheduleList : [],
            eventClassData : {},
            scheduleClassData : {},
        }
    },
    components : {
        EventUnavailableTime,
        EventDetailCard
    },
    setup(props, ctx) {
        const router = useRouter()
        const route = useRoute()
        const eventStore = useEventStore()
        const modalManager = inject("ModalManager")

        const {event} = storeToRefs(eventStore)
        const isUATime = ref(false)
        
        const timezoneData = computed(() => {
            return util.convertTimezoneJsonToData(event.value?.country_timezones)
        })
        const date = computed(() => {
            return {
                start_date : util.date_format(util.convertTimeStmapToDateTime(event.value?.start_date), "YYYY-MM-DD"),
                end_date : util.date_format(util.convertTimeStmapToDateTime(event.value?.end_date), "YYYY-MM-DD"),
                timezone : timezoneData.value?.gmtOffsetName
            }
        })
        const activeEvent = computed(() => {
            const currentDate = util.getCurrentDate(timezoneData.value?.gmtOffset)
            const eventEndDate = util.convertTimeStmapToDateTime(event.value?.end_date, 24 * 3600)
            if(currentDate < eventEndDate) {
                return true;
            }
            return false
        })
        const onUnavailableTimeShow =  () => {
            if(activeEvent.value) {
                isUATime.value = !isUATime.value
            }
            else {
                modalManager.showdialog("The event has ended. Unable to show the unavailable time.")
            }
        }
        const onUnavailableSave = async (unavailable_time) => {
            const requestData= JSON.stringify(unavailable_time)
            if(event.value.unavailable_time && requestData == event.value.unavailable_time) {
                modalManager.showdialog("This is same unavailable time")
                return;
            }
            const func = async () => {
                const res = await eventStore.updateEvent(event.value?.id, {
                    unavailable_time : requestData,
                })
                if(res) {
                    await refresh()
                }
                return res;
            }
            const res = await modalManager.loading(func)
            if(res) {
                modalManager.showdialog("Saved")
                
            }
            else {
                modalManager.showdialog("Failed")
            }
        }
        const onDelete = async () => {
            let res = await modalManager.showdialog({
                title : "Do you want to delete this event?",
                buttons : {
                    yes : "yes",
                    no : "no"
                }
            })
            if(res?.result == "yes") {
                if(event.value.meetings?.length > 0) {
                    res = await modalManager.showdialog({
                        title : "Your schedule remains for the event.<br>Are you sure you want to delete this event?",
                        buttons : {
                            yes : "yes",
                            no : "no"
                        }
                    })
                }
                if(res?.result == "yes") {
                    await eventStore.deleteEvent(event.value.id)
                    router.back()
                }
            }
        }

        const onEdit = () => {
            modalManager.showEx(eventCreateModal,{
                bind : {
                    isEdit : true,
                    eventData : event.value
                }
            })
            .then(res => {
                if(res?.success) {
                    refresh()
                }
            })
        }

        const refresh = async () => {
            return await eventStore.fetchEvent(route.query.eventno)
        }

        onMounted(async () => {
            const res = await refresh()
            if(!res) {
                modalManager.showdialog("No data").then(res => {
                    router.back()
                })
            }
            // reject status만 뒤로
            // 날짜 기준 오름차순 정렬
            util.sortObj(event.value.meetings, true,
                item => item.status == scheduleData.STATUS.REJECT ? 999 : Number(item.status),
                item => util.getDate(util.convertUTSTOJTS(item.date)),
            )
        })

        onUnmounted(() => {
            eventStore.clearEvent()
        })

        const onCreateSchedule = () => {
            if(!activeEvent.value) {
                modalManager.showdialog("The event has ended. Unable to create a new schedule.")
                return;
            }
            router.push({
                path : "/meeting/schedule/create",
                query : {
                    eventno : event.value?.id
                }
            })
        }

        return {
            event,
            date,
            isUATime,
            util,
            activeEvent,

            onEdit,
            onDelete,
            onUnavailableTimeShow,
            onUnavailableSave,
            onCreateSchedule,
        }
    },
}
</script>

<style scoped lang="scss">
.titleEventStyle {
    font: normal normal 800 20px/23px NanumSquareOTF;
    margin: 0 0 15px;
    color: #333333;
    word-break: break-all;
    max-width: 700px;
}
.titleContainer {
    display: flex;
    max-width: 76.25rem;
    .eventHeaderStyle {
        display: flex;
        margin-left: auto;
        margin-right: 0;
        opacity: 1;
        button {
            margin: 0 5px;
        }
    }
}
.UnavailableTimeContainer {
    margin-bottom: 15px;
    .unavailableTimePicker {
        margin: 15px 0 ;
        transform-origin: top;
        transition: transform .4s ease-in-out;
    }
    .unavailableTimeButton {
        color: #fff;
        font: 14px NanumSquareOTF;
        background: #910457;
        &.disabled {
            background: #6c6c6c;
        }
    }
}
.dateSectionStyle {
        
    display: flex;
    align-items: center;
    color: #6c6c6c;

    img {
        width: 1rem;
        margin-right: 0.625rem;
    }

    font: normal normal normal 16px/18px NanumSquareOTF;
    margin-bottom: 15px;
}
.scheduleWrapStyle{

    display: flex;
    width: 100%;
    flex-wrap: wrap;
}
.linkStyle {
        
    display: flex;
    align-items: center;
    color: #6c6c6c;
    text-decoration: none;
    width: 95px;
    justify-content: center;
    font: normal normal normal 17px/19px NanumSquareOTF;
    cursor: pointer;

    .addStyle {
        position: fixed;
        bottom: 103px;
        right: 2rem;
        z-index: 10;
        background: rgb(145, 4, 87);
        width: 56px;
        height: 56px;
        &.disabled {
            background: #6c6c6c;
        }
    }
    span {
        text-align: center;
        padding: 1.25rem 0 1.25rem;
    }

    &.active {
        color: #910457;
        font-weight: 800;
    }
    &:hover {
        font-weight: bold;
    }
}
</style>