<template>
    <div class="leftStyle" ref="leftMenu"> 
        <LeftLayout>
        </LeftLayout>
    </div>
    <div class="mainStyle" ref="mainLayout">
        <router-view>
        </router-view>
    </div>
    <!-- <div class="footerStyle">
        <Footer>
        </Footer>
    </div> -->
</template>
<script>
import LeftLayout from "@/page/main/mainLeft.vue"
import Footer from "@/page/main/mainFooter.vue"
import { inject, nextTick, onMounted, onUnmounted, ref, watch } from 'vue'

export default {
    components : {
        LeftLayout,
        // Footer,
    },
    setup(props, ctx) {
        const layout = inject("layout")
        const leftMenu = ref(null)
        const mainLayout = ref(null)
        const initLeftMenuTop = ref(0)
        const initLeftMenuLeft = ref(0)
        const onScroll = () => {
            const mainHeight = mainLayout.value.scrollHeight - 198 // padding 값 제거
            const leftHeight = leftMenu.value.scrollHeight
            const scrollTop = layout.value.scrollTop
            if(scrollTop >= initLeftMenuTop.value && mainHeight > leftHeight) {
                leftMenu.value.style.position = "fixed"
                const marginTop = initLeftMenuTop.value - scrollTop
                const deadlineTop = window.innerHeight - leftMenu.value.offsetHeight - 90 > 0 
                    ? 0 : window.innerHeight - leftMenu.value.offsetHeight - 90
                leftMenu.value.style.marginTop = (marginTop < deadlineTop ? deadlineTop : marginTop) + "px";
                leftMenu.value.style.marginLeft= initLeftMenuLeft.value - layout.value.scrollLeft + "px";
            } else {
                leftMenu.value.style.position = "relative"
                leftMenu.value.style.marginTop = initLeftMenuTop.value + "px";
                leftMenu.value.style.marginLeft= initLeftMenuLeft.value + "px";

            }
            const small = window.matchMedia("all and (max-width : 768px)")
            const middle = window.matchMedia("all and (min-width : 768px) and (max-width : 1023px)")
            const big = window.matchMedia("all and (min-width : 1023px)")
            
            if(small.matches) {
                leftMenu.value.style.marginLeft =  "2.875rem";
            }
            else if (middle.matches) {
                leftMenu.value.style.marginLeft =  "6.875rem";
            }
            else if (big.matches) {
                leftMenu.value.style.marginLeft =  "12.875rem";
            }
        }
        onMounted(() => {
            nextTick(() => {
                initLeftMenuTop.value = leftMenu.value.offsetTop;
                initLeftMenuLeft.value = leftMenu.value.offsetLeft;
            })
            // layout.value.addEventListener("scroll", onScroll)
            // window.addEventListener("resize", onScroll)
            // mainLayout.value.addEventListener("resize", onScroll)
        })
        onUnmounted(() => {
            if(layout.value) {
                // layout.value.removeEventListener("scroll", onScroll)
            }
            // window.removeEventListener("resize", onScroll)
            if(mainLayout.value) {
                // mainLayout.value.removeEventListener("resize", onScroll)
            }
        })
        return {
            leftMenu,
            mainLayout,
        }
    }
}
</script>

<style scoped lang="scss">
.mainStyle {
    @media all and (max-width : 768px) {
        margin-left: 8.8125rem;
        padding-left: 3.25rem;
        padding-top: 5.625rem;
        padding-bottom: 5.1875rem;
    }
    @media all and (min-width : 768px) and (max-width : 1023px) {
        margin-left: 12.8125rem;
        padding-left: 5.25rem;
        padding-top: 5.625rem;
        padding-bottom: 5.1875rem;
    }
    @media all and (min-width : 1023px) {
        margin-left: 18.8125rem;
        padding-left: 9.25rem;
        padding-top: 5.625rem;
        padding-bottom: 5.1875rem;
    }
}
.footerStyle {
    width: 100%;
    // width: calc(100% - 15px);
    overflow: hidden;
    height: 5.1875rem;
    background: #f2f2f2;
    z-index: 99;
    // position: fixed;
    bottom: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.leftStyle {
    @media all and (max-width : 768px) {
        margin-left: 2.875rem;
    }
    @media all and (min-width : 768px) and (max-width : 1023px) {
        margin-left: 6.875rem;
    }
    @media all and (min-width : 1023px) {
        margin-left: 12.875rem;
    }
    margin-top: 5.625rem;
    float: left;
    position: sticky;
    top : 0px;
    background-color: transparent;
    display: flex;
    margin-bottom: 90px;
}
</style>