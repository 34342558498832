<template>
    <div class="hoverItem" v-show="p_isShow" ref="hoverItem">
        <slot name="content"/>
    </div>
</template>
<script>
import { nextTick } from 'vue';
export default {
    props : {
        isShow : {
            type : Boolean,
            default : true,
        },
        hoverRefs : Object,
        overCallback : Function,
        outCallback : Function,
        offsetY : {
            type : Number,
            default : 0,
        },
        offsetX : {
            type : Number,
            default : 0,
        }
    },
    data() {
        return  {
            p_isShow : false,
            current_ref : null,
        }
    },
    methods : {
        moveItem(e) {
            this.$refs.hoverItem.style.top = this.offsetY + 1 + e.clientY + "px";
            this.$refs.hoverItem.style.left = this.offsetX + 1 + e.clientX + "px";
        },
        registerEvent(ref) {
            const _this = this
            ref.addEventListener("mousemove", function (e) {
                nextTick(() => {
                    _this.moveItem(e)
                })
            })
            ref.addEventListener("mouseenter", function (e) {
                nextTick(() => {
                    ref.appendChild(_this.$refs.hoverItem)
                })
                _this.p_isShow = true;
                if(_this.overCallback) {
                    this.current_ref = ref;
                    _this.overCallback(...arguments, ref);
                }
            })
            ref.addEventListener("mouseleave", function(e) {
                nextTick(() => {
                    if(ref.contains(_this.$refs.hoverItem)) {
                        ref.removeChild(_this.$refs.hoverItem)
                    }
                })
                _this.p_isShow = false;
                if(_this.outCallback) {
                    this.current_ref = ref;
                    _this.outCallback(...arguments, ref)
                }
            })
        },
        init() {
            if(this.hoverRefs) {
                const hoverItem = this.$refs.hoverItem
                const _this = this
                hoverItem.addEventListener("mousemove", function (e) {
                    _this.moveItem(e)
                })

                if(Array.isArray(this.hoverRefs) && this.hoverRefs.length > 0) {
                    this.hoverRefs.forEach(ref => {
                        this.registerEvent(ref)
                    })
                }
                else if(typeof this.hoverRefs === 'object' && Object.keys(this.hoverRefs).length > 0){
                    this.registerEvent(this.hoverRefs)
                }
            }
        }
    },
    mounted() {
        this.p_isShow = this.isShow
        if(typeof this.hoverRefs === 'object' || Array.isArray(this.hoverRefs)) {
            this.init()
        }
    },
    watch : {
        hoverRefs : {
            handler() {
                this.init()
            }
        }
    }
}
</script>

<style scoped>
.hoverItem {
    position: fixed;
    z-index : 99;
}
</style>