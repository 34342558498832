<template>
    <div class="scroll-container editorStyle">
        <QuillEditor :options="options" v-model:content="p_content.value" contentType="html" :toolbar="toolbar" ref="quill_edit" @update:content="updateContent"/>
    </div>
</template>
<script>
import util from "@/data/util"
import {Delta, QuillEditor} from "@vueup/vue-quill";
import Quill from "quill";
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import BlotFormatter from 'quill-blot-formatter'
import quillVideoSelectModal from "@/modal/quillVideoSelectModal.vue"
export default {
    props : {
        content : Object,
        isLoading : {
            type : Object,
            defalut : null,
        },
        size : Object,
    },
    components : {
        QuillEditor,
    },
    data() {
        return {
            pIsLoading : {
                image : 0,
            },
            options: {
                theme: 'snow',
                scrollingContainer: '.scroll-container'
            },
            p_size : {
                current : 0,
                max : false,
            },
            isOverSize : false,
            toolbar: {
                container : [
                    [{ header: [1, 2, false] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" }
                    ],
                    ["link", "image","video"],
                    ["clean"]
                    ],
                handlers : {
                    image : this.imageHandler,
                    video : this.videoHandler,
                }
            },
            p_content : {
                value : ""
            },
            modules : {
                name : "blotFormatter",
                module : BlotFormatter,
                options : {
                    
                }
            }
        }
    },
    methods : {
        updateContent () {
            const check = this.checkSize()
            if(!check) {
                this.isOverSize = true;
            }
            else {
                this.isOverSize = false;
            }
        },
        linkInit() {
            const formatLink = Quill.import('formats/link')
            formatLink.sanitize = function(url) {
                if(!url.startsWith('http://') && !url.startsWith('https://')) {
                    return `https://${url}`
                }
                return url
            }
        },
        videoInit () {
            const formatVideo = Quill.import("formats/video")
            class LocalVideo extends formatVideo {
                static create(value) {

                    const video = document.createElement('video')
                    video.setAttribute('controls', true);
                    video.setAttribute('style', "height: fit-content; width: 300px");
                    video.setAttribute('src', value);

                    return video;
                }
            }
            LocalVideo.tagName = "video"
            LocalVideo.blotName = "local-video"
            LocalVideo.className = "ql-video"
            Quill.register(LocalVideo)
        },
        checkSize () {
            let size = 0
            const quill = this.$refs.quill_edit;
            const content = quill.getHTML()

            const srcReg = /<[^>]*src\s*=\s*["']?([^>"']+)["']?[^>]*>/g
            let srcContent = ""
            while((srcContent = srcReg.exec(content))) {
                if(srcContent[1] && typeof srcContent[1] == "string") {
                    size += util.getUnicodeTextSize(srcContent[1])
                }
            }

            const tagReg = /(<([^>]+)>)/ig
            const contentWithoutTag = content.replace(tagReg, "")
            size += util.getUnicodeTextSize(contentWithoutTag)

            this.p_size.current = size;
            if(this.p_size.max) {
                if(size >= this.p_size.max) {
                    return false;
                }
            }
            return true;
        },
        async videoHandler () {
            const quill = this.$refs.quill_edit.editor.__quill;
            
            const modalRes = await this.$ModalManager.show(quillVideoSelectModal)
            if(!modalRes || !modalRes.result) {
                return;
            }
            if(modalRes.url) {
                const url = modalRes.url;
                let range = quill.getSelection();
                if (url != null) {
                    quill.insertEmbed(range, 'video', url);
                }
            }
            else if(modalRes.video) {
                let range = quill.getSelection();
                let base64Video = modalRes.video
                if(typeof modalRes.video == "object" ) {
                    base64Video = await util.fileToBase64(modalRes.video)
                }
                quill.insertEmbed(range, 'local-video', base64Video);
            }

        },
        imageHandler() {

            const quill = this.$refs.quill_edit.editor.__quill;
            const input = document.createElement('input');

            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');
            input.click();

            input.onchange = async () => {
                this.pIsLoading.image++;
            

                const file = input.files[0];

                // Save current cursor state
                const range = quill.getSelection(true);
                // Insert temporary loading placeholder image
                quill.insertEmbed(range.index, 'image', require('@/assets/loading.gif')); 

                // Move cursor to right side of image (easier to continue typing)
                quill.setSelection(range.index + 1);

                // THIS NEEDS TO BE HOOKED UP TO AN API
                // RESPONSE DATA WILL THEN BE USED TO EMBED THE IMAGE
                // const res = await apiPostNewsImage(formData);
            
                const base64Image = await util.fileToBase64(file)
                quill.deleteText(range.index, 1);
                quill.insertEmbed(range.index, 'image', base64Image);
                
                this.pIsLoading.image--;


                // Remove placeholder image
                // quill.deleteText(range.index, 1);

                // try {
                //     console.info('before');
                //     const res = await axios({
                //         method: 'POST',
                //         url: 'https://api.imgur.com/3/image',
                //         headers: {
                //             'Content-Type': 'multipart/form-data'
                //         },
                //         data: formData
                //     });
                //     console.info('res', res);
                // } catch (err) {
                //     console.error('error', err)
                // }
                // Insert uploaded image
                // quill.insertEmbed(range.index, 'image', base64Image);

            }
        },
        setHTML(html) {
            this.$refs.quill_edit.setHTML(html);
        }
    },
    mounted() {
        this.linkInit()
        this.videoInit()
        this.p_content = this.content
        this.pIsLoading = this.isLoading
        this.p_size = this.size;
        
        // const imageHandler = (dataUrl, type, imageData, callback) => {
        //     imageData
        //     .minify({
        //         maxWidth: 320,
        //         maxHeight: 320,
        //         quality: 0.7
        //     })
        //     .then(miniImageData => {
        //         console.log(typeof dataUrl);
        //         console.log(typeof miniImageData);
        //         console.log(typeof miniImageData.dataUrl);
        //         // const blob = miniImageData.toBlob();
        //         // const file = miniImageData.toFile("my_cool_image.png");
        //         // console.log(`type: ${type}`);
        //         // console.log(`dataUrl: ${dataUrl.length}`);
        //         // console.log(`blob: ${blob}`);
        //         // console.log(`miniImageData: ${miniImageData}`);
        //         // console.log(`file: ${file}`);

        //         // setImage({ type, dataUrl, blob, file });
        //         console.log("Calling...");
        //         // callback.call(this, miniImageData.dataUrl);
        //         callback.call(this, miniImageData.dataUrl);
        //     });
        // };
        // this.qmodules = {
        //     name: 'blotFormatter',  
        //     module: BlotFormatter, 
        //     options: {/* options */}
        // }
        this.qmodules = {
            // blotFormatter: {},
            // imageDropAndPaste: { handler: imageHandler },
            // imageDropAndPaste: { },
            toolbar: {container : [
                [{ header: [1, 2, false] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" }
                ],
                ["link", "image","video"],
                ["clean"]
                ]}
        };
        this.checkSize()
    },
}
</script>
<style scoped lang="scss">
.editorStyle {
    line-height: 1.142857143;
    color: #6c6c6c;
    ::v-deep .ql-snow {
        &.ql-toolbar {
            height: 40px;
            &:after {
                display: inline;
            }
        }
        &.ql-container {
            height: calc(100% - 40px);
        }
        .ql-editor {
            font: normal normal normal 14px NanumSquareOTF;
        }
    }
}
</style>
