<template>
    <div class="calendarMainStyle">
        <div class="note">
            Note: The current calendar is shown based on the local time of the event.
        </div>
        <calendar :eventList="p_eventList" @updateMonth="onUpdateMonth" @clickDate="onClickDate" 
             :isCreateEventDrag="true" :isClickDate="true"  :maxEventCount="maxEventCount"/>
    </div>
</template>

<script>
import calendar from "@/component/calendarForEvent.vue"
import util from '@/data/util';
import { useRouter } from 'vue-router';
import { computed, inject, onActivated, onDeactivated, ref } from 'vue';
import { useEventStore } from '@/stores/event';
import eventCalendarAttributesModal from '@/modal/eventCalendarAttributesModal.vue';
export default {
    props : {
        eventList : Object,
    },
    components : {
        calendar,
    },
    setup(props, ctx) {
        const router = useRouter()
        const p_eventList = ref([])
        const eventStore = useEventStore()
        const sdat = ref(null)
        const edat = ref(null)
        const maxEventCount = ref(4)
        const modalManager = inject("ModalManager")

        const UTCText = computed(() => {
            return util.getTimeZoneText()
        })

        const onDetail = (id) => {
            return () => {
                router.push({
                    path : "/meeting/event/detail",
                    query : {
                        eventno : id,
                    }
                })
            }
        }

        const preprocessing = () => {
            let attrData = []
            p_eventList.value = util.sortObj(p_eventList.value, true, item => util.getDate(util.convertUTSTOJTS(item.start_date)), item => item.id)
            p_eventList.value.forEach(item => {
                let overlap_count = 0
                if(attrData.length != 0 ){
                    attrData.forEach(attr => {
                        const attr_start = util.convertTimeStmapToDateTime(attr.start_date)
                        const attr_start_date = util.getDate(util.date_format(attr_start, "YYYY-MM-DD"))
                        const attr_end = util.convertTimeStmapToDateTime(attr.end_date)
                        const attr_end_date = util.getDate(util.date_format(attr_end, "YYYY-MM-DD"))
                        const item_start = util.convertTimeStmapToDateTime(item.start_date)
                        const item_start_date = util.getDate(util.date_format(item_start, "YYYY-MM-DD"))
                        const item_end = util.convertTimeStmapToDateTime(item.end_date)
                        const item_end_date = util.getDate(util.date_format(item_end, "YYYY-MM-DD"))
                        if(!((attr_start_date > item_end_date) || (item_start_date > attr_end_date))) {
                            if(attr.order) {
                                overlap_count = overlap_count > (attr.order + 1) ? overlap_count : attr.order + 1;
                            }
                            else {
                                overlap_count++;
                            }
                        }
                    })
                }
                let timezone = {}
                if(item.country_timezones) {
                    timezone = util.convertTimezoneJsonToData(item.country_timezones)
                }
                attrData.push({
                    ...item,
                    order : overlap_count,
                    startDate : util.date_format(util.convertTimeStmapToDateTime(item.start_date), "YYYY-MM-DD"),
                    endDate : util.date_format(util.convertTimeStmapToDateTime(item.end_date), "YYYY-MM-DD"),
                    start_date_jts : util.convertUTSTOJTS(item.start_date),
                    end_date_jts : util.convertUTSTOJTS(item.end_date),
                    callback : onDetail(item.id),
                    timezone : timezone,
                })
            })
            p_eventList.value = attrData
        }

        const getEventDataForDate = async () => {
            await eventStore.fetchEvents(
                {
                    page : 1,
                    order : null,
                    sdat : `-:${util.convertJTSToUTS(util.getDate(edat.value).getTime() + (util.getTimeZone() * 60 * 1000))}`,
                    edat : `+:${util.convertJTSToUTS(util.getDate(sdat.value).getTime() + (util.getTimeZone() * 60 * 1000))}`
                }
            )
            p_eventList.value = eventStore.events
            preprocessing()
        }

        const refresh = async () => {
            await getEventDataForDate()
        }
 
        const onUpdateMonth = async (e) => {
            if(Object.entries(e).length == 2 && e.year && e.month) {
                sdat.value = new Date(e.year, e.month - 1, 1, 0, 0, 0)
                edat.value = new Date(e.year, e.month , 0, 23 ,59, 59)
                await refresh()
            }
        }
        const onClickDate = async (day, attr) => {
            if(Math.max(...attr.map(item => item.customData.data.order)) < maxEventCount.value) {
                return ;
            }
            modalManager.showEx(eventCalendarAttributesModal, {
                bind : {
                    day : day,
                    attribues : attr
                }
            }, true)
            .then(res => {
                if(res?.event) {
                    onDetail(res.event.id)()
                }
            })
        }

        onActivated(async () => {
            sdat.value = new Date(util.getCurrentDate().getFullYear(), util.getCurrentDate().getMonth(), 1, 0, 0, 0)
            edat.value = new Date(util.getCurrentDate().getFullYear(), util.getCurrentDate().getMonth() + 1 , 0, 23, 59, 59)
            await refresh()
        })

        onDeactivated(() => {
            eventStore.clearEvents()
        })

        return {
            p_eventList,
            UTCText,
            maxEventCount,

            onUpdateMonth,
            onClickDate,
            refresh,
        }
    }
};
</script>

<style lang="scss" scoped>
.calendarMainStyle {
    max-width: 76.25rem;
    .note {
        font : 12px NanumSquareOTF;
    }
}
</style>