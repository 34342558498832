const ACCESS_TOKEN = "access_token"
const REFRESH_TOKEN_KEY = "refresh_token"
const INVITATION_TOKEN_KEY = "invitation_token"

const TokenService = {
    getAccessToken() {
        return localStorage.getItem(ACCESS_TOKEN)
    },

    saveAccessToken(accessToken) {
        localStorage.setItem(ACCESS_TOKEN, accessToken)
    },

    removeAccessToken() {
        localStorage.removeItem(ACCESS_TOKEN)
    },

    getRefreshToken() {
        return localStorage.getItem(REFRESH_TOKEN_KEY)
    },

    saveRefreshToken(refreshToken) {
        localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
    },

    removeRefreshToken() {
        localStorage.removeItem(REFRESH_TOKEN_KEY)
    },

    getInvitationToken() {
        return localStorage.getItem(INVITATION_TOKEN_KEY)
    },

    saveInvitationToken(invitationToken) {
        localStorage.setItem(INVITATION_TOKEN_KEY, invitationToken)
    },

    removeInvitationToken() {
        localStorage.removeItem(INVITATION_TOKEN_KEY)
    },
}

export { TokenService }