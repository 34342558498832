<template>
    <div class="mainStyle">
        <template  v-if="!isMediateMode">
            <scheduleDetailContent :schedule="schedule" @updateSchedule="onUpdateSchedule"/>
            <template v-if="schedule?.status == scheduleData.M_STATUS.MEET_COMPLETION ||
                schedule?.status == scheduleData.M_STATUS.MEET_PROCEED || 
                schedule?.status == scheduleData.M_STATUS.MEET_AGREEMENT">
                <template v-if="(schedule?.results?.length > 0 && schedule.results.some(item => item))">
                    <template v-for="result in schedule?.results" :key="result">
                        <scheduleResultView :result="result" @onUpdateResult="onUpdateResult(result, true)" v-if="!result.result_update"/>
                        <scheduleResultInput :schedule="schedule" :result="result" @onBack="onUpdateResult(result, false)" @onResultSubmit="onResultSubmit" v-else/>
                    </template>
                </template>
                <scheduleResultInput v-else :schedule="schedule" @onResultSubmit="onResultSubmit"/>
            </template>
        </template>
        <template  v-else>
            <invitationMediateContent :schedule="schedule" @onBack="onBack" @onApply="onApply" :event="event" />
        </template>
    </div>
</template>
<script>

import scheduleDetailContent from "@/page/meeting/schedule/scheduleDetailContent.vue"
import scheduleResultInput from "@/page/meeting/schedule/scheduleResultInput.vue"
import scheduleResultView from "@/page/meeting/schedule/scheduleResultView.vue"
import invitationMediateContent from "@/page/invitation/invitationMediateContent.vue"
import { useScheduleStore } from '@/stores/schedule'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/user'
import { computed, inject, onMounted, onUnmounted, ref } from 'vue'
import scheduleData from '@/data/scheduleData'
import util from '@/data/util'
export default { 
    components : {
        scheduleResultInput,
        scheduleResultView,
        scheduleDetailContent,
        invitationMediateContent,
    },
    setup(props, ctx) {
        const scheduleStore = useScheduleStore()
        const userStore = useUserStore()
        const route = useRoute()
        const router = useRouter()
        const {schedule} = storeToRefs(scheduleStore)
        const {isAdmin} = storeToRefs(userStore)
        const modalManager = inject("ModalManager")
        const isMediateMode = ref(false)
        const isUpdateResult = ref(false)

        const isMe = computed(() => {
            return schedule.value?.owner_id == userStore.user?.id
        })

        const refresh = async () => {
            return await scheduleStore.fetchSchedule(route.query.scheduleno)
        }

        const onUpdateSchedule = async (type, param) => {
            const func = async() => {
                if(type == "accept") {
                    const res = await modalManager.showdialog({
                        title : "Do you want to accept this schedule?",
                        buttons : {
                            "yes" : "yes",
                            "no" : "no"
                        }})
                    if(res.result == "yes") {
                        await scheduleStore.mediateUpdateSchedule(route.query.scheduleno, {
                            negotiation : param, 
                            status : scheduleData.P_STATUS.NEGOTIATION_AGREEMENT
                        })
                    }
                        
                    await refresh()
                }
                else if(type == "reject") {
                    const res = await modalManager.showdialog({
                        title : "Do you want to reject this schedule?",
                        buttons : {
                            "yes" : "yes",
                            "no" : "no"
                        }})
                    if(res.result == "yes") {
                        await scheduleStore.mediateUpdateSchedule(route.query.scheduleno, {
                            negotiation : param,
                            status : scheduleData.P_STATUS.NEGOTIATION_REFUSAL
                        })
                    }
                    await refresh()
                }
                else if(type == "proceed") {
                    const res = await modalManager.showdialog({
                        title : "Do you want to proceed for all particpants?",
                        buttons : {
                            "yes" : "yes",
                            "no" : "no"
                        }})
                    if(res.result == "yes") {
                        await scheduleStore.proceedSchedule(route.query.scheduleno, {
                            negotiation : param
                        })
                    }
                    await refresh()
                }
                else if(type == "mediate") {
                    isMediateMode.value = true;
                }
                else if(type == "cancel") {
                    const res = await modalManager.showdialog({
                        title : "Do you want to cancel this schedule?",
                        buttons : {
                            "yes" : "yes",
                            "no" : "no"
                        }
                    })
                    if(res.result == "yes") {
                        await scheduleStore.deleteSchedule(route.query.scheduleno)
                        router.back()
                    }
                    return;
                }
            }
            await modalManager.loading(func)
        }
        const onUpdateMediate = async (id, type, text) => {
            const func = async () => {
                if(type == "mediationAccept") {
                    const params = {
                        status : scheduleData.STATUS.ACCEPT,
                        comment : text,
                    }
                    await scheduleStore.mediateUpdateSchedule(route.query.scheduleno, id, params)
                }
                else if(type == "mediationReject") {
                    const params = {
                        status : scheduleData.STATUS.REJECT,
                        comment : text,
                    }
                    await scheduleStore.mediateUpdateSchedule(route.query.scheduleno, id, params)
                }
                await refresh()
            }
            await modalManager.loading(func)
        }

        const onBack = () => {
            isMediateMode.value = false;
        }

        const onUpdateResult = (result, value) => {
            result.result_update = value;
        }

        const onResultSubmit = async form => {
            const checkData = () => {
                const req = [
                    {value : form.value.title, text : "Comment"}
                ]
                const res = util.dataCheck(req)
                if(res.length > 0) {
                    return res.join("<br>")
                }
                return false
            }
            const func = async() => {
                let res = checkData()
                if(res) {
                    modalManager.showdialog(`Please check your data: <br>${res}`)
                    return "nothing";
                }
                var params = new FormData()
                params.append('result', form.value.title)
                if(form.value.image.length > 0) {
                    form.value.image.forEach(image => {
                        params.append('image[]', image)
                    })
                }
                params.append('is_meet', form.value.is_met ? 1 : 0)

                res = scheduleStore.createScheduleResult(schedule.value.id, params)
                return res;
            }
            const res = await modalManager.loading(func);
            if(res == 'nothing') {
                return;
            }
            if(res) {
                modalManager.showdialog("Submitted").then(async res => {
                    await refresh()
                })
            }
            else {
                modalManager.showdialog("Failed")
            }
        }
        const onApply = async (form) => {
            const func = async () => {
                const params = {
                    date : util.convertJTSToUTS(form.date.date),
                    start_time : util.convertJTSToUTS(form.date.start),
                    end_time : util.convertJTSToUTS(form.date.end),
                    location : form.location,
                    comment : form.comment,
                }
                const res = await scheduleStore.mediateSchedule(route.query.scheduleno, params)
                if(res) {
                    await refresh()
                    modalManager.showdialog("Adjusted").then(res => {
                        onBack()
                    })
                }
                else {
                    modalManager.showdialog("Failed")
                }
            }
            await modalManager.loading(func)
        }
        
        const event = computed(() => {
            let event = {}
            Object.entries(schedule.value).forEach(data => {
                const k = data[0]
                const v = data[1]
                let index = k.indexOf("event_")
                if(index > -1 ) {
                    index += "event_".length
                    event[k.slice(index)] = v
                }
            })
            return event;
        })

        onMounted(async () => {
            const res = await refresh()
            // 실패시 Back
            if(!res) {
                modalManager.showdialog("No Permission").then(res => {
                    router.back()
                })
            }
        })

        onUnmounted(() => {
            scheduleStore.clearSchedule()
        })

        return {
            schedule,
            isAdmin,
            scheduleData,
            isMediateMode,
            isUpdateResult,
            isMe,
            event,

            refresh,
            onUpdateSchedule,
            onUpdateMediate,
            onBack,
            onApply,
            onResultSubmit,
            onUpdateResult,
        }
    }
}
</script>

<style scoped lang="scss">
.mainStyle {
    display : flex;
    flex-flow: row wrap;
    box-sizing: border-box;
    width: 100%;
}
</style>