<template>
    <div class="wrapper" @click="onLink()" :class="{rejectScheduleStyle : isReject}">
        <h1>{{scheduleData.title}}</h1>
        <div class="avatarGroupStyle">
            <!-- <profile-image :src="scheduleData.owner_profile_image" :size="40" /> -->
            <template v-if="Array.isArray(recipient)">
                <template v-for="user in recipient" :key="user">
                    <profile-image :src="user.data?.profile_image" :size="40"/>
                </template>
            </template>
        </div>
        <v-divider class="dividerStyle" />
        <section class="ScheduleDetailContents">
            <p class='schedule-td' :class="{noData : !date.date}">
                {{date.date ?? date.noDateText}}
            </p>
            <p class='schedule-td' :class="{noData : !(date.start_time && date.end_time)}">
                {{date.start_time && date.end_time ? `${date.start_time} ~ ${date.end_time}` : ""}}
            </p>
            <p class='schedule-place' :class="{noData : !scheduleData.location}">
                {{scheduleData.location ? `Meeting Place : ${scheduleData.location}`: date.noLocationText}}
            </p>
            <div class='schedule-state' :class="[getMStatusText(scheduleData.status)?.class]">
                {{getMStatusText(scheduleData.status)?.text}}
            </div>
        </section>
    </div>
</template>
<script>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import util from '@/data/util'
import scheduleData from '@/data/scheduleData'
export default {
    props : {
        scheduleData : Object,
    },
    setup(props, ctx) {
        const router = useRouter()
        const recipient = JSON.parse(props.scheduleData.participants)
        const timezoneData = computed(() => {
            return util.convertTimezoneJsonToData(props.scheduleData.country_timezones)
        })
        const date = computed(() => {
            if(!props.scheduleData.date || !props.scheduleData.end_time || !props.scheduleData.start_time) {
                return {
                    start_time : null,
                    end_time : null,
                    date : null,
                    noDateText : scheduleData.noDateText,
                    noLocationText : scheduleData.noLocationText,
                }
            }
            var res = {
                start_time : util.date_format(util.convertTimeStmapToDateTime(props.scheduleData.start_time), "HH:mm"),
                end_time : util.date_format(util.convertTimeStmapToDateTime(props.scheduleData.end_time), "HH:mm"),
                noDateText : scheduleData.noDateText,
                noLocationText : scheduleData.noLocationText,
            }
            const start_date = util.date_format(util.convertTimeStmapToDateTime(props.scheduleData.start_time), "YYYY-MM-DD")
            const end_date = util.date_format(util.convertTimeStmapToDateTime(props.scheduleData.end_time), "YYYY-MM-DD")
            if(start_date == end_date) {
                res.date = start_date
            }
            else {
                res.start_date = start_date
                res.end_date = end_date
            }
            return res
        })
        const onLink = () => {
            router.push({
                path : "/meeting/schedule/detail",
                query : {
                    scheduleno : props.scheduleData.id
                }
            })
        }
        const isReject = computed(() => {
            return props.scheduleData?.status == scheduleData.M_STATUS.MEET_REFUSAL
        })
        const getMStatusText = (status) => {
            return scheduleData.getMStatusText(status)
        }
        return {
            date,
            recipient,
            isReject,

            onLink,
            getMStatusText,
        }
    }
    // mounted() {
    //     console.log(this.scheduleData)
    // }
}
</script>

<style scoped lang="scss">
.wrapper {
    cursor: pointer;
    width: 15.125rem;
    margin-right: 20px;
    margin-bottom: 20px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 1rem;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    &.rejectScheduleStyle {
    background: rgba(200, 200, 200, 0.9);
        filter: grayscale(0.5);
    }
    &:hover {
        box-shadow: 0 3px 6px #00000029;

        h1 {
            color: #A1045A;
        }
    }

    h1 {
        width: 202px;
        padding: 0 0 30px;
        font: normal normal 800 18px/21px NanumSquareOTF;
        color: #333333;
        height: 21px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
    }
    .avatarGroupStyle {
        .MuiAvatarGroup-avatar {
            background: #A1045A;
            width: 60px;
            height: 60px;
        }

        margin-bottom: 30px;
    }
    .dividerStyle {
        border: 0.5px solid #d9d9d9;
        width: 100%;
        margin: 0 0 10px;
    }
    .ScheduleDetailContents{
        
    position: relative;
    width: 100%;

    p {
        margin: 0;
        color: #6c6c6c;
        text-align: center;
    }

    .noData {
        color: #A1045A;
    }

    .schedule-td {
        font: normal normal normal 16px/18px NanumSquareOTF;
        margin-bottom: 10px;
    }

    .schedule-place {
            font: normal normal normal 16px/21px NanumSquareOTF;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-bottom: 4px;
        }

        .schedule-state {
            
            display: flex;
            justify-content: center;
            align-items: center;

            text-align: center;
            height: 37px;

            font: normal normal normal 16px/18px NanumSquareOTF;
            border-radius: 0.25rem;
            box-shadow: 0 8px 6px #00000029;
            user-select: none;
        }
    }
}
</style>