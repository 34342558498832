<template>
    <div class="headerStyle">
        <div class="headerBorder">
            <div class="checkStyle">
                <input type="checkbox" v-model="checked" value="event" checked id="event">
                <label for="event"> Events</label>
                <input type="checkbox" v-model="checked" value="schedule" id="schedule">
                <label for="schedule"> Schedules</label>
            </div>
            <v-btn class="downloadStyle" @click="downloadExcel" 
                :disabled="tableDatas?.content?.length == 0 || checked.length == 0"
            >
                Download<v-icon>mdi-file-excel</v-icon>
            </v-btn>
        </div>
    </div>
    <div>
        <dataTable :tableData="tableDatas" 
            :init_page="init_page"
            :totalCount="eventTotalCount"
            @onPageUpdate="onPageUpdate"
            @sorting="sorting">
            <template v-slot:click="data">
                <div @click.stop="onClick(data)" class="scheduleShowArrowDiv">
                    <v-icon v-if="data.meeting_count > 0"
                        :class="[selectedData[0]?.event_id == data.id ? 'active': '', 'btnStyle']"
                        size="30"
                    >mdi-menu-down</v-icon>
                </div>
            </template>
            <template v-slot:trContent="item" >
                <Transition mode="in-out" name="slide" >
                    <tr v-if="selectedData[0]?.event_id == item.id" class="trContentStyle">
                        <td colspan="100%">
                            <h3>Schedules</h3>
                            <table>
                                <thead>
                                    <th>Title</th>
                                    <th>Invitor name</th>
                                    <th>Participants</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Status</th>
                                    <th>Created</th>
                                </thead>
                                <tbody>
                                    <tr v-for="schedule in selectedData" :key="schedule" @click="onScheduleDetail(schedule)">
                                        <td class="fixed">{{schedule.title}}</td>
                                        <td>{{schedule.owner_name}}</td>
                                        <td>{{schedule.string_participant}}</td>
                                        <td>{{schedule.date ? toFormatString(schedule.date, 'YYYY-MM-DD') : scheduleData.noSpecified}}</td>
                                        <td>{{(schedule.start_time && schedule.end_time) ? `${toFormatString(schedule.start_time, 'hh:mm') } ~ ${toFormatString(schedule.end_time, 'hh:mm')}` : scheduleData.noSpecified}}</td>
                                        <td>{{scheduleData.getMStatusText(schedule.status)?.text}}</td>
                                        <td>{{toFormatString(schedule.created_at, 'YYYY-MM-DD hh:mm')}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </Transition>
            </template>
        </dataTable>
    </div>
</template>

<script>
import dataTable from "@/component/dataTable"
import adminTableModal from "@/modal/adminTableModal.vue"
import eventCreateModal from "@/modal/eventCreateModal.vue"
import { useAdminStore } from '@/stores/admin'
import { useEventStore } from '@/stores/event'
import { storeToRefs } from "pinia"
import { computed, onMounted, inject, ref, onActivated, watch } from "vue"
import { useRouter, useRoute } from "vue-router"
import util from "@/data/util"
import scheduleData from '@/data/scheduleData'
import * as XLSX from 'xlsx'

export default {
    components : {
        dataTable,
    },
    setup(props, ctx) {
        const modalManager = inject('ModalManager')

        const adminStore = useAdminStore()
        const eventStore = useEventStore()
        const router = useRouter()
        const route = useRoute()

        const { adminEvents, eventTotalCount } = storeToRefs(adminStore)
        const { event } = storeToRefs(eventStore)
        const init_page = ref(1)
        const reverse = ref(false)
        const orders = ref([])
        const selectedData = ref([])
        const checked = ref(['event'])

        const downloadExcel = async() => {
            const workBook = XLSX.utils.book_new()
            let eventsData
            let schedulesData
            let data_name = ''
            let date = new Date()
            date = util.date_format(date, 'yy-MM-DD')

            if(checked.value?.find(i => i == 'event')) {
                eventsData = tableData(await adminStore.getEvents({xlsx: true, order: ['id']}))
                console.log(eventsData)
                let jsonData = []
                eventsData?.content.forEach(elem => {
                    let a = {}
                    eventsData?.header.forEach(item => {
                        a[item.name] = elem?.[item.value]
                    })
                    jsonData.push(a)
                })
                const tbData = XLSX.utils.json_to_sheet(jsonData)
                XLSX.utils.book_append_sheet(workBook, tbData, 'EventData')
                data_name = 'Event'
            }

            if(checked.value?.find(i => i == 'schedule')) {
                let jsonData = []
                schedulesData = await adminStore.getSchedules({xlsx: true, order: ['event_id']})
                schedulesData?.forEach(item => {
                    let b = {
                        Event: item.event_id,
                        Title: item.title,
                        'Invitor name': item.owner_name,
                        Participants : participants(item),
                        Date: toFormatString(item.date, 'YYYY-MM-DD'),
                        Time: `${toFormatString(item.start_time, 'hh:mm')} ~ ${toFormatString(item.end_time, 'hh:mm')}`,
                        Status: scheduleData.getMStatusText(item.status)?.text,
                        Created: toFormatString(item.created_at, 'YYYY-MM-DD hh:mm')
                    }
                    jsonData.push(b)
                })
                const tbData = XLSX.utils.json_to_sheet(jsonData)
                XLSX.utils.book_append_sheet(workBook, tbData, 'ScheduleData')
                data_name += 'Schedule'
            }

            XLSX.writeFile(workBook, `${data_name}Data_${date}.xlsx`)
        }

        const onScheduleDetail = (item) => {
            router.push({ path : "/meeting/schedule/detail", query : { scheduleno : item.id } })
        }
        const onDetail = (item) => {
            router.push({ path : "/meeting/event/detail", query : { eventno : item.id } })
        }
        const onDelete = (item) => {
            eventStore.deleteEvent(item.id)
        }
        const onUpdate = (item) => {
            modalManager.showEx(eventCreateModal, {
                bind: {
                    eventData: item,
                    isEdit: true
                }
            }).then(res => {
                if(res?.success) {
                    adminStore.getEvents()
                }
            })
            // modalManager.showdialog("Not Working....")
            console.log(item)
        }
        const onCallback = (item) => {
            return () => {
                modalManager.showEx(adminTableModal,
                {
                    bind : {
                        title : `Management Event<br> No : ${item.id}`,
                        data : item,
                        value : "event"
                    },
                    on : {
                        read : onDetail,
                        delete : onDelete,
                        update : onUpdate,
                    }
                }, true)
            }
        }
        const onClick = async(item) => {
            await eventStore.fetchEvent(item.id)
            if(selectedData.value[0]?.event_id != item?.id) {
                selectedData.value = []
                event.value?.meetings.forEach(elem => {
                    elem['string_participant'] = participants(elem)
                    selectedData.value?.push(elem)
                })
            } else {
                selectedData.value = []
            }
        }

        const participants = (schedule) => {
            const recipient = JSON.parse(schedule.participants)
            let str_name = ''
            recipient?.forEach((item, idx) => {
                if(item.name == undefined) {
                    item.name = 'Non-member'
                }
                if(item.id != schedule.owner_id) {
                    if(idx > 0) {
                        str_name += ', '
                    }
                    str_name += item.name
                }
            })
            
            return str_name
        }

        const tableData = (data) => {
            return {
                header : [
                        {name : "No", value : "id", order: ['id']},
                        {name : "Title", value : "title", order: ['title'], fixed : true},
                        {name : "Invitor Name", value : "owner_name", order: ['owner_name']},
                        {name : "Invitor Email", value : "owner_email", order: ['owner_email']},
                        {name : "Schedule Count", value : "meeting_count", order: ['meeting_count']},
                        {name : "Date", value : "datetime", order: ['start_date', 'end_date']},
                        {name : "Created", value : "created_at", order: ['created_at']},
                        {name : "Schedules", value : "click", slot : true},
                ],
                content :
                    data?.map(item => {
                        return {
                            ...item,
                            callback : onCallback(item),
                            datetime : `${toFormatString(item.start_date, 'YYYY-MM-DD')} ~ ${toFormatString(item.end_date, 'YYYY-MM-DD')}`,
                            created_at: toFormatString(item.created_at, 'YYYY-MM-DD hh:mm'),
                            click: onClick
                        }
                    }),
                column : orders.value,
                reverse: reverse.value
            }
        }

        const tableDatas = computed(() => {
            return tableData(adminEvents.value)
        })
        
        const sorting = (order) => {
            orders.value = order
            adminStore.getEvents({ page: route.query.page, order: order })
            router.push({
                path: route.path,
                query: {
                    ...route.query,
                    order: order
                }
            })
        }

        const toFormatString = (timestamp, format) => {
            return util.date_format(util.convertTimeStmapToDateTime(timestamp), format)
        }
        
        const onPageUpdate = (page) => {
            adminStore.getEvents({
                page : page,
                order: orders.value
            })
            router.push({
                path : route.path,
                query : {
                    ...route.query,
                    page: page,
                }
            })
        }

        const init = async() => {
            selectedData.value = []
            if(route.query.page) {
                init_page.value = Number(route.query.page)
            } else {
                init_page.value = 1
            }
            if(route.query.order) {
                if(typeof route.query.order == 'string') {
                    orders.value = [route.query.order]
                } else {
                    orders.value = route.query.order
                }
            } else {
                orders.value = []
            }
            await adminStore.getEvents({ page: route.query.page, order: orders.value })
        }

        onMounted(async () => {
            await init()
        })

        watch(() => route.query, async() => {
            if(route.path.includes("event")) {
                await init()
            }
        })

        return {
            tableDatas,
            util : util,
            init_page,
            eventTotalCount,
            selectedData,
            checked,

            sorting,
            onPageUpdate,
            downloadExcel,
            onClick,
            onScheduleDetail,
            toFormatString,
            scheduleData,
        }
    },
}
</script>

<style scoped lang="scss">
.contentStyle {
    max-width: 300px;
    max-height: 80px;
    text-align:center;
    overflow: hidden;
    &:deep(*) {
        width: 100%;
        height: 100%;
    }
}
.headerStyle {
    max-width: 76.25rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 8px;
    .headerBorder {
        display: flex;
        padding: 10px;
        align-items: center;
        border: 1px solid #9c9c9c;
    }
    .checkStyle {
        input[type='checkbox'] {
            position: absolute;
            width: 1px; height: 1px;
            padding: 0; margin: -1px;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            border: 0;
        }
        input[type='checkbox'] + label {
            display: inline-block;
            position: relative;
            padding-left: 25px;
            padding-right: 15px;
            cursor: pointer;
        }
        input[type='checkbox'] + label::before {
            content: '';
            position: absolute;
            left: 0; top: 2px;
            width: 20px; height: 20px;
            text-align: center;
            background-color: #ffffff;
            border: 1px solid #9C9C9C;
            border-radius: 3px;
        }
        input[type='checkbox']:checked + label::after {
            content: '✔';
            position: absolute;
            left: 0; top: 2px;
            text-align: center;
            width: 20px; height: 20px;
            background-color: #910456;
            color: #ffffff;
            border-radius: 3px;
        }
    }
    .downloadStyle {
        margin-left: 25px;
        background-color: #9C9C9C;
        color: white;
        border-radius: 50px;
        &:hover {
            background-color: #1E3560;
        }
        i {
            padding-left: 10px;
        }
    }
}
.btnStyle {
    background-color: #1e3560a0;
    color: #ffffff;
    border-radius: 50%;
    &:hover {
        background-color: #1E3560;
    }
}
.active {
    background-color: #1E3560;
}
.scheduleShowArrowDiv {
    display: flex;
    width : 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.trContentStyle {
    background: #EEEEEE;
    transform-origin: top;
    transition: transform .4s ease-in-out;
    text-align: center;
    h3 { 
        padding-top: 10px;
    }
    table {
        width: 100%;;
        border-collapse: collapse;
        border-spacing:  0px;
        table-layout:auto;
        thead {
            th {
                font: 800 16px / 18px NanumSquareOTF;
                padding: 10px;
            }
        }
        tbody {
            border-top: 1px solid #9C9C9C;
            tr {
                cursor: pointer;
                &:not(:last-of-type) {
                    border-bottom: 1px solid #9C9C9C;
                }
            }
            td {
                padding: 10px;
                color: rgb(108, 108, 108);
                font: 15px / 26px NanumSquareOTF;
                &.fixed {
                    max-width: calc(1220px / 7);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
}

</style>