<template>
    <v-form @submit.prevent="onLogin" id="login-form">
        <v-text-field
            clearable
            label="E-mail or Account"
            v-model="id"
            class="login_input"
        />
        <v-text-field
            clearable
            label="Password"
            v-model="password"
            class="login_input"
            :type="isShowPassword ? 'text' : 'password'"
            :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="isShowPassword = !isShowPassword"
        />
        <div id="helper-text-login" :class="{show : authenticationError.code}">
            {{ authenticationError.message }}
        </div>
        <div class="button-div">
            <v-btn type="submit" form="login-form" :loading="authentication">
                LOGIN
            </v-btn>
        </div>
    </v-form>    
</template>

<script>
import { onUnmounted, ref } from "vue";
import { useAuthStore } from "@/stores/auth"
import { storeToRefs } from "pinia"
import util from '@/data/util';

export default {
    setup(props, ctx) {
        const id = ref("")
        const password = ref("")
        const isShowPassword = ref(false)

        const authStore = useAuthStore()

        const { authentication, authenticationError} = storeToRefs(authStore)

        const checkData = () => {
            if(!(id.value && id.value.trim())) {
                authenticationError.value.code = true;
                authenticationError.value.message = "No e-mail or account"
                return false;
            }
            if(!(password.value && password.value.trim())) {
                authenticationError.value.code = true;
                authenticationError.value.message = "No password"
                return false;
            }
            return true;
        }
        const onLogin = () => {
            if(!checkData()) {
                return;
            }
            authStore.login(id.value, password.value)
        }
        onUnmounted(() => {
            authStore.clearAuthenticationError()
        })

        return {
            id, password,
            isShowPassword,

            authentication,
            authenticationError,

            onLogin,
        }
    },
}

</script>

<style scoped lang="scss">
.login_input { 
    @media all and (max-width : 768px) {
        width: 13.1875rem;
    }
    @media all and (min-width : 768px) and (max-width : 1023px) {
        width: 20.1875rem;
    }
    @media all and (min-width : 1023px) {
        width: 22.1875rem;
    }
    // background: #fff;
}
#helper-text-login {
    color : #910457;
    font-size: 14px;
    opacity: 0;
    transition: all 0.2s linear 0s;
    &.show {
        opacity: 1;
    }
}
</style>