<template>

    <div class="NegotiationContainerBlockStyle" :class="{detailShow : detailShow}">
        <div class="disabledNego" v-if="disable" />
        <div>
            <div class="NegotiationInfoContainer"  v-if="detailShow">
                <h2 class="NegotiationInfoHeader">
                    Proposer
                </h2>
                <div class="NegotiationInfoContent">
                    <div class="participantContainer" :class="{myData : isMe(negotiation?.proposer.email)}">
                        <profile-image :src="negotiation?.proposer.data?.profile_image" :size="20"/>
                        <p>{{negotiation?.proposer?.name}}</p>
                        <div class="divider"/>
                        <p class="email"> {{negotiation?.proposer?.email}}</p>
                        <div class="myDataContent" v-if="isMe(negotiation?.proposer?.email)">
                            Me
                        </div>
                        <div class="accept participantStatus" v-if="!isNoData">accept</div>
                    </div>
                </div>
            </div>
            <div class="NegotiationInfoContainer"  v-if="detailShow && negotiation?.date && negotiation?.location">
                <h2 class="NegotiationInfoHeader">
                    <span class="acceptText"> Status</span>
                </h2>
                <div class="NegotiationInfoContent">
                    <div class="participantsFlexDiv">
                        <template v-for="parti in negotiation?.participants" :key="parti">
                            <div class="participantContainer" v-if="!isPrososer(parti.no)" :class="{myData : isMe(parti.data?.email)}">
                                <profile-image :src="parti.data?.data?.profile_image" :size="20"/>
                                <p>{{parti.data?.name ?? "Non-member"}}</p>
                                <div class="divider"/>
                                <p class="email">{{parti.data?.email}}</p>
                                <div v-if="!isNoData" :class="[getPStatusText(parti.status)]" class="participantStatus">{{getPStatusText(parti.status)}}</div>
                                <div class="myDataContent" v-if="isMe(parti.data?.email)">
                                    Me
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="NegotiationInfoContainer">
                <h2 class="NegotiationInfoHeader" v-if="detailShow">
                    Scheduling Information
                </h2>
                <div class="NegotiationInfoContent">
                    <div>
                        <div class="negoData" v-if="!detailShow">
                            <v-icon>mdi-account</v-icon>
                            <div class="negoDateData">
                                <div class="name">{{negotiation?.proposer?.name}}</div>
                                <div class="divider"/>
                                <p class="email"> {{negotiation?.proposer?.email}}</p>
                            </div>
                        </div>
                        <div class="negoData">
                            <v-icon>mdi-calendar</v-icon>
                            <div class="negoDateData">
                                {{negotiation.date && negotiation.start_time && negotiation.end_time ? 
                                `${negotiation.date_text} ${negotiation.start_time_text} ~ ${negotiation.end_time_text}` :
                                scheduleData.noDateText }}
                            </div>
                        </div>
                        <div class="negoData">
                            <v-icon>mdi-map-marker</v-icon>
                            <div class="negoContent">
                                {{negotiation.location ? negotiation.location : scheduleData.noLocationText}}
                            </div>
                        </div>
                        <div class="negoData" v-if="detailShow">
                            <v-icon>mdi-comment-text-outline</v-icon>
                            <div class="negoContent">
                                {{negotiation.comment}}
                            </div>
                        </div>
                        
                    </div>
                    <div :class="[getPStatusText(getMyAcceptanceData(negotiation.participants)?.status)]" class="negoStatus" v-if="!detailShow && !isNoData">
                        {{getPStatusText(getMyAcceptanceData(negotiation.participants)?.status)}}
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
import scheduleData from '@/data/scheduleData'
import { computed } from 'vue-demi'
export default {
    props : {
        negotiation : Object,
        detailShow : {
            type : Boolean,
            default : false,
        },
        myData : Object,
        disable : {
            type : Boolean,
            default : false
        }
    },
    setup(props, ctx) {
        const isPrososer = no => {
            return props.negotiation?.proposer.no == no
        }
        const getPStatusText = status => {
            return scheduleData.getPStatusText(status)
        }
        const isMe = email => {
            return email == props.myData?.email
        }
        const isNoData = computed(() => {
            return !(props.negotiation.date && props.negotiation.start_time && props.negotiation.end_time) || !props.negotiation.location
        })

        const getMyAcceptanceData = participants => {
            if(participants) {
                const myData = participants.filter(parti => parti.data?.email == props.myData?.email)
                if(myData.length > 0) {
                    return myData[0]
                }
            }
            return {}
        }
    
        return {
            scheduleData,
            isNoData,

            isMe,
            getPStatusText,
            getMyAcceptanceData,
            isPrososer,
        }
    }
}
</script>

<style scoped lang="scss">

.NegotiationContainerBlockStyle {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255);
    border-radius: 1rem;
    padding: 8px;
    margin-bottom: 1rem;
    margin-right : 16px;
    transition: 0.2s all;
    box-shadow: 2px 5px 11px #00000029;
    user-select: none;
    &.detailShow {
        padding : 20px;
    }
    .NegotiationInfoContainer{
        transform-origin: top;
        transition: transform .4s ease-in-out;
        .NegotiationInfoHeader {
            color: #333;
            margin: 10px 0;
            font: normal normal 800 16px/23px NanumSquareOTF; 
            // .acceptText {
            //     color : #89a5ea; 
            // }
            // .rejectText {
            //     color : #ff8e7f;
            // }
        }
        .NegotiationInfoContent {
            display: flex;
            align-items: center;
            .participantsFlexDiv {
                display: flex;
                flex-direction: column;
            }
            .divider {
                background: #d9d9d9 0% 0% no-repeat padding-box;
                height: 18px;
                width: 1px;
            }
            p {
                margin: 0 5px;
                font-size: 13px;
            }
            .email {
                font: normal normal normal 13px/18px NanumSquareOTF;
                color: #9c9c9c;
            }
            .participantContainer {
                display: flex;
                align-items: center;
                padding: 5px;
                &.myData {
                    border: 1px solid #999;
                    border-radius: 10px;
                    position: relative;
                    background: #EEE;
                    .myDataContent {
                        position: absolute;
                        right : -35px;
                        font-size: 12px;
                        justify-content: flex-end;
                        background: #484848;
                        color: white;
                        border-radius: 5px;
                        padding : 0 5px;
                        width: fit-content;

                        &:after {
                            border-top: 5px solid transparent;
                            border-left: 0px solid transparent;
                            border-right: 5px solid #484848;
                            border-bottom: 5px solid transparent;
                            content: "";
                            position: absolute;
                            left : -5px;
                            top : 4px;
                        }
                    }
                }
                .participantStatus {
                    padding: 2px 5px;
                    border-radius: 10px;
                    font-size: 14px;
                }
            }
                
            .negoStatus {
                display: flex;
                border-radius: 10px;
                padding: 2px 5px;
                margin-left: auto;
                margin-right: 10px;
            }
            
            .negoData {
                display: flex;
                align-items: center;
                font-size: 17px;
                padding: 6px 0;
                .negoDateData {
                    display: flex;
                    // flex-direction: column;
                    align-items: center;
                    .name {
                        margin-right: 5px;
                    }
                }
                i {
                    margin-right: 5px;
                    color : #910457;
                }
            }
        }
    }
}

.disabledNego {
    top : 0px;
    left: 0px;
    border-radius: 1rem;
    background: #00000033;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
}
</style>