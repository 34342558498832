<template>
    <div class="timeLineContainer">
        <div class="timePeriodTable">
            <timePeriodSelectorTable :dateData="dateData" :minStamp="minStamp" :isVertical="true"/>
            <!-- <weekCalendar /> -->
        </div>
        <div class="buttons">
            <v-btn class="vbutton save" @click="onSave">
                SAVE
            </v-btn>
            <v-btn class="vbutton reset" @click="onReset">
                RESET
            </v-btn>
        </div>
    </div>
</template>
<script>
import { computed, onMounted, ref, watch } from 'vue'
import timePeriodSelectorTable, { dateDataClass } from "@/component/timePeriodSelectorTable.vue"
import util from '@/data/util'
import weekCalendar from "@/component/weekCalendar.vue"
import scheduleData from '@/data/scheduleData'
export default {
    props : {
        event : Object,
        selectedDate : Number,
    },
    components : {
        timePeriodSelectorTable,
        // weekCalendar,
    },
    setup(props, ctx) {
        const minStamp = ref(scheduleData.minStamp)
        
        const timezoneData = computed(() => {
            return util.convertTimezoneJsonToData(props.event.country_timezones);
        })

        const dateData = ref([])
        const dateDataClassData = ref({})
        const initDateData = () => {
            dateData.value = []
            if(props.event) {
                const disableMeeting = props.event.meetings.filter(meeting => 
                    meeting.status == scheduleData.M_STATUS.MEET_AGREEMENT ||
                    meeting.status == scheduleData.M_STATUS.MEET_PROCEED
                )
                dateDataClassData.value = new dateDataClass(props.event, null, timezoneData.value, disableMeeting, true)
                // dateDataClassData.value.setIsUnavailableTime(true)
                dateData.value = dateDataClassData.value.dateData
            }
        }
        const setSelectedUnavailableTime = () => {
            const UAT = convertUnavailableTime()
            if(!UAT) {
                return;
            }
            dateData.value.forEach(date => {
                date.hours.forEach(hour => {
                    hour.mins.forEach(min => {
                        min.selected = false;
                        min.saved_selected = false;
                        if(UAT?.[date.date]?.[hour.hour] && UAT[date.date][hour.hour].includes(min.min)) {
                            min.selected = true;
                            min.saved_selected = true;
                        }
                    })
                })
            })
        }

        const setRequesetData = () => {
            let requsetObject = {}
            dateData.value.forEach(date => {
                date.hours.forEach(hour => {
                    hour.mins.forEach(min => {
                        if(!min.disabled && min.selected) {
                            const time = util.getDate(`${date.date} ${hour.hour}:${min.min}`)
                            const key1 = util.date_format(time, "YYYY-MM-DD")
                            const key2 = parseInt(util.date_format(time, "HH"))
                            const value = parseInt(util.date_format(time, "mm"))
                            if(!requsetObject?.[key1]) {
                                requsetObject[key1] = {}
                            }
                            if(!requsetObject[key1][key2]) {
                                requsetObject[key1][key2] = []
                            }
                            requsetObject[key1][key2].push(value)
                        }
                    })
                })
            })
            return requsetObject
        }

        const convertUnavailableTime = () => {
            if(!props.event?.unavailable_time) {
                return false;
            }
            const UAT = JSON.parse(props.event.unavailable_time)
            let convertingObject = {}

            Object.entries(UAT).forEach(date_hours => {
                const date = date_hours[0]
                const hours = date_hours[1]
                Object.entries(hours).forEach(hour_mins => {
                    const hour = hour_mins[0]
                    const mins = hour_mins[1]
                    mins.forEach(min => {
                        const time = util.getDate(`${date} ${hour}:${min}`)
                        const k1 = util.date_format(time, "YYYY-MM-DD")
                        const k2 = parseInt(util.date_format(time, "HH"))
                        const v = parseInt(util.date_format(time, "mm"))
                        if(!convertingObject?.[k1]) {
                            convertingObject[k1] = {}
                        }
                        if(!convertingObject[k1]?.[k2]) {
                            convertingObject[k1][k2] = []
                        }
                        convertingObject[k1][k2].push(v);
                    })
                })
            })
            return convertingObject
        }
        
        const onReset = () => {
            setSelectedUnavailableTime()
        }
        const onSave = () => {
            const requestData = setRequesetData()
            ctx.emit("onUnavailableSave", requestData)
        }

        onMounted(() => {
            initDateData();
            if(dateData.value) {
                setSelectedUnavailableTime()
            }
        })
        watch(() => props.event, () => {
            initDateData();
            if(dateData.value) {
                setSelectedUnavailableTime()
            }
        }, {deep : true})
        return {
            minStamp,
            dateData,
            onSave,
            onReset,
        }
    }
}
</script>

<style scoped lang="scss">
.timeLineContainer {
    display: flex;
    flex-direction: column;
    // overflow: auto;
    background: #fff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    user-select: none;
    max-width: 1220px;
    width: fit-content;
    .timePeriodTable {

    }
    .buttons {
        margin-top: 20px;
        display: flex;
        .vbutton {
            border: none;
            font: 14px NanumSquareOTF;
            font-weight: 500;
            color: white;
            justify-self: center;
            margin-right: 20px;
            
            &.save { background: #910457;}
            &.reset { background: rgba(238, 92, 92); }
        }
    }
}
</style>