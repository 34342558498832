<template>
    <div class="calendarContainer" :style="{height : height ? `${height}px` : 'auto'}">
        <div class="dateContainer">
            <div class="dateHeaders">
                <div class="dateHeader">
                </div>
                <div v-for="date in dates" :key="date" class="dateHeader" 
                :style="{width : `calc(100% / ${dayCount})`, height : `${headerHeight}px`}">
                    <div>
                        <div>
                            {{util.date_format(date, "YYYY-MM-DD")}} 
                        </div>
                        <div class="day" :class="[util.date_format(date, 'ddd').toLowerCase()]">
                            {{`${util.date_format(date, "ddd")}`}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="dataContainer">
                <div class="hourHeaders" :style="{width : headerWidth + 'px'}">
                    <div v-for="hour in hours" :key="hour" class="hourHeader" :style="{
                    height : `${minStampHeight *  Math.floor(60 / default_minStamp) + (hour == 0 ? 3 : 1)}px`}">
                        {{`${util.num_format(hour)}:00`}}
                    </div>
                </div>
                <div v-for="date in dates" :key="date" class="dateData" :style="{width : `calc(100% / ${dayCount})`}">
                    <div v-for="hour in hours" :key="hour" class="hourData">
                        <template v-for="min in mins" :key="min">
                            <div class="minData" ref="minDataDiv" :style="{height : minStampHeight + 'px'}" @click="onClick">
                                <!-- <div class="event">
                                </div> -->
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div ref="eventDiv">
                <template v-for="event in eventData" :key="event">
                    <div class="eventBlockContainer"
                    :class="{clickable : isEventClickable}"
                        @click="onEventClick(event)"
                        v-if="event.height && event.top && event.left"
                        :id="event.id"
                        :style="{
                            height : event.height + 'px',
                            top : event.top + 'px',
                            left : event.left + 'px',
                            width : event.width + 'px',
                            background : event.color ? `#${event.color}55` : ''
                        }">
                        <div class="eventContainer" :class="[getColumnClass(event)]">
                            <div class="eventTitle">
                                Title : 
                                {{event.title}} 
                            </div>
                            <div class="eventLocation">
                                Meeting Place : 
                                {{`${event.location ?? scheduleData.noSpecified}`}} {{event.country_iso2 ? `(${event.country_iso2})` : ""}}
                            </div>
                            <div class="participant_div">
                                Participants : 
                                <div v-for="parti in getParticipants(event.participants)" :key="parti" class="participant">
                                    {{parti.name ?? parti.email ?? `Non-member`}}
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <mouseHoverItem :hoverRefs="hoverRefs" :overCallback="overCallback">
            <template v-slot:content>
                <slot name="mouseHoverContent" :="hoverEventData" />
            </template>
        </mouseHoverItem>
    </div>
</template>

<script>
import util from '@/data/util'
import { ref } from '@vue/reactivity'
import { computed, nextTick, onMounted, watch } from '@vue/runtime-core'
import scheduleData from '@/data/scheduleData'
import mouseHoverItem from "@/component/mouseHoverItem.vue"
export default {
    props : {
        dayCount : {
            type : Number,
            default : 7,
        },
        startDay : {
            type : [String, Number, Date],
        },
        disabledHour : {
            type : Object,
            default : null,
        },
        mode : {
            type : String,
            default : "view",
        },
        timezone : {
            type : String,
            default : util.getTimeZoneText()  
        },
        eventList : [Array, Object],
        height : Number,
        isEventClickable : {
            type : Boolean,
            default : true,
        }
    },
    components : {
        mouseHoverItem,  
    },
    setup(props, ctx) {
        const minStampHeight = ref(20);
        const headerHeight = ref(50);
        const headerWidth = ref(50);
        const minDataDiv = ref(null);
        const eventDiv = ref(null);
        const eventData = ref ([]);

        const default_minStamp = ref(scheduleData.minStamp)
        const mins = ref(util.range(0, Math.ceil(60 / default_minStamp.value)).map(item => item * default_minStamp.value))
        const hours = ref(util.range(0,24))

        const hoverRefs = ref([])
        const hoverEventData = ref({})

        const minDataDivWidth = computed(() => {
            let res = 0;
            if(minDataDiv.value) {

                minDataDiv.value.forEach(item =>  {
                    if(res) {
                        return;
                    }
                    const width = item.offsetWidth
                    if(width) {
                        res = width;
                    }
                })
            }
            return res;
        })

        const getParticipants = parti => {
            if(typeof parti == 'object') {
                return parti
            }
            return parti && typeof parti == 'string' ? JSON.parse(parti) : []
        }

        const dates = computed(() => {
            const default_start_date = util.getDate(props.startDay ?? util.getCurrentDate())
            return util.range(0, props.dayCount).map(item => 
                util.getDate(default_start_date.setDate(default_start_date.getDate() + (item >= 1 ? 1 : item) )))
        })
        const getColumnClass = event => {
            return ((event.height / minStampHeight.value) < 1.5) ? "one" : ((event.height / minStampHeight.value) < 2.5) ? "two" : "more"
        }

        const getTopAndHeight = (event) => {

            const start_date_time = util.convertTimeStmapToDateTime(event.start_time)
            const end_date_time = util.convertTimeStmapToDateTime(event.end_time)
            
            let start_minStamp = (start_date_time.getHours() * 60 ) + (Math.round(start_date_time.getMinutes() / default_minStamp.value) * default_minStamp.value)
            let end_minStamp = (end_date_time.getHours() * 60 ) + (Math.round(end_date_time.getMinutes() / default_minStamp.value) * default_minStamp.value)
            
            if(start_minStamp == end_minStamp) {
                end_minStamp += default_minStamp.value
            }

            // top : border_padding + headerHeight + time_border + offset
            // height : time_border - offset * 2
            const offsetTop = 10 + headerHeight.value + start_date_time.getHours() + 4
            const offsetHeight = (end_date_time.getHours() - start_date_time.getHours()) - 6

            return {
                top : offsetTop + (start_minStamp * minStampHeight.value / default_minStamp.value),
                height : offsetHeight + (end_minStamp - start_minStamp) * minStampHeight.value / default_minStamp.value,
            }
        }
        const getLeftAndWidth = (event, date_width) => {
            const default_result = {
                left : 0,
                width : 0,
            }
            const event_date = util.getDate(util.date_format(util.convertTimeStmapToDateTime(event.date), "YYYY-MM-DD"))
            const start_date = util.getDate(util.date_format(props.startDay, "YYYY-MM-DD"))

            const date_index = Math.floor((event_date.getTime() - start_date.getTime()) / (24 * 3600 * 1000))
            
            if(date_index >= props.dayCount || event_date < start_date) {
                return default_result
            }

            const offsetLeft = headerWidth.value + 5
            const offsetWidth = -2;

            return {
                left : offsetLeft + (date_index * date_width) + (date_width * (event.overlap_order / event.overlap_count)),
                width : offsetWidth + (date_width / event.overlap_count),
            }
        }

        const preprocessing = () => {
            // 겹치는 스케쥴 및 겹치는 스케쥴 중에서 몇번째에 들어가야 하는 스케쥴 계산식.

            // 1. 전체 스케쥴상에서 시작 시간을 기준으로 오름차순 Sort
            const eventList = util.sortObj(props.eventList, true, item => item.start_time, item => item.id)
                                // .map(item => {return {...item, overlap_order : -1, overlap_count : 0}})

            // 2. 모든 스케쥴의 시작, 끝 시간을 가져옴.
            const event_time_list = [
                ...eventList.map(item => item.start_time),
                ...eventList.map(item => item.end_time)
            ]
            
            // 부) order 계산
            // 현 스케쥴이 들어가야 하는 시간대에 겹치는 갯수로 1을 나눈뒤 ,
            // 현재 차지하고 있는 칸 (0에서 1 사이의 칸으로 소수점으로 표기됨. ex) [[0, 0.3333], [0.666, 1]])
            // 들 중에서 비어있는 값을 찾아서 넣음.
            // 빈 값이 없는 경우, null 반환.
            const get_order = (count, order_list) => {
                let res = false;
                let res_order = -1;
                util.range(0, count).forEach(order => {
                    if(res) {
                        return;
                    }
                    res_order = order;
                    const check = order_list.some(orders => {
                        const start_line = orders[0]
                        const end_line = orders[1]
                        if(start_line >= ((order + 1) / count) || end_line <= (order / count)) {
                            return false;
                        }
                        return true;
                    })
                    if(!check) {
                        res = true
                    }
                })
                return res ? res_order : null;
            }

            // 3. (2번에서 정의한) 모든 시간을 기준으로 -
            event_time_list.forEach(time => {
                // 3. - 해당 시간에 들어가는 모든 스케쥴 확인.
                const current_time_event = eventList.filter(event => event.start_time <= time && event.end_time > time)
                // 4. 해당 시간 스케쥴을 for문 동작.
                current_time_event.forEach(event => {
                    const current_time_overlap_data = current_time_event
                        .filter(c_event => c_event.id != event.id && c_event.overlap_count && (c_event.overlap_order || c_event.overlap_order == 0))
                    // A. 해당 시간 스케쥴 중에서 count와 order를 통해 현재 차지하고 있는 width를 0에서 1사이의 값(ex. [0, 0.3333])으로 가지고 있는 Array(order를 구할때 사용.)
                    const current_time_order = current_time_overlap_data.map(c_event => [
                            c_event.overlap_order / c_event.overlap_count ,
                            (c_event.overlap_order + 1) / c_event.overlap_count
                        ])
                    // B. 현 시간 기준 가장 큰 count 값.
                    const current_time_max_count = Math.max(...current_time_overlap_data.map(c_event => c_event.overlap_count))

                    // 5-1. 만약 현재 스케쥴에 count와 order 가 있는 경우, 
                    if(event.overlap_count) {
                        // 5-1. 현 시간을 기준으로한 모든 스케쥴의 count보다 큰지 확인.
                        if(event.overlap_count < current_time_event.length) {
                            // 5-1-1. 큰 경우는 다시 count를 변경하고, order 계산.
                            event.overlap_count = current_time_event.length
                            const order = get_order(event.overlap_count, current_time_order);
                            // 5-1-1-1. order가 계산되면 그대로 사용.
                            if(order || order == 0) {
                                event.overlap_order = order;
                            }
                            // 5-1-1-2. 만약 order가 제대로 나오지 않는 경우,
                            else {
                                // 5-1-1-2. 현재 겹치는 시간대의 스케쥴중에서 가장큰 count 값(B)으로 다시 계산.
                                event.overlap_count = current_time_max_count
                                event.overlap_order = get_order(current_time_max_count, current_time_order)
                            }
                            // 5-1-2. 작은 경우는 그대로 사용.
                        }
                    }
                    // 5-2. 현재 스케쥴에 count와 order 가 없는 경우,
                    else {
                        // 5-2. 해당 시간대의 스케쥴 갯수를 count로 넣고, order 계산.
                        event.overlap_count = current_time_event.length
                        const order = get_order(event.overlap_count, current_time_order);
                        // 5-2-1. orde가 계산되면 그대로 사용.
                        if(order || order == 0) {
                            event.overlap_order = order;
                        }
                        // 5-2-2. 만약 order가 제대로 나오지 않는 경우,
                        else {
                            // 5-2-2. 현재 겹치는 시간대의 스케쥴중에서 가장큰 count 값(B)으로 다시 계산.
                            event.overlap_count = current_time_max_count
                            event.overlap_order = get_order(current_time_max_count, current_time_order)
                        }
                    }
                })
            })
            eventList.forEach(event => {
                // style data
                const left_width = getLeftAndWidth(event, minDataDivWidth.value)
                event.left = left_width.left
                event.width = left_width.width

                const top_height = getTopAndHeight(event)
                event.top = top_height.top
                event.height = top_height.height
            })
            eventData.value = eventList
        }

        const overCallback = (e) => {
            const eventId = e.target.getAttribute("id")
            let event = eventData.value.filter(event => eventId == event.id)
            hoverEventData.value = {}
            if(event.length > 0 ) {
                hoverEventData.value = event[0]
            }
        }
        const setHoverRefs = () => {
            nextTick(() => {
                if(eventDiv.value) {
                    hoverRefs.value = [
                        ...eventDiv.value.querySelectorAll(".eventBlockContainer")
                    ]
                }
            })
        }

        const onEventClick = (event) => {
            ctx.emit("onEventClick", event)
        }
        onMounted(() => {
            setTimeout(() =>{
                preprocessing()
                setHoverRefs()
            }, 100)
        })
        watch(() => props.startDay, () => {
            setTimeout(() => {
                preprocessing()
                setHoverRefs()
            },100)
        })

        return {
            minStampHeight,
            headerHeight,
            headerWidth,
            scheduleData,
            minDataDiv,
            default_minStamp,
            mins,
            hours,
            dates,
            util,
            eventDiv,
            eventData,
            hoverRefs,
            hoverEventData,
            
            getColumnClass,
            getParticipants,
            onEventClick,
            overCallback,
        }
    }
}
</script>

<style scoped lang="scss">
.calendarContainer {
    margin: 0 auto;
    padding : 10px;
    position: relative;
    // box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.2) ,0px 3px 7px rgba(0, 0, 0, 0.1);
    // border-radius: 8px;
    overflow-y: auto;
    .dateContainer {
        display: flex;
        flex-direction: column;
        .dateHeaders {
            display: flex;
            .dateHeader{
                text-align: center;
                justify-content: center;
                display: flex;
                position: sticky;
                font: 14px NanumSquareOTF;
                .day {
                    font-weight: 500;
                    &.sun {
                        color : #e76464;
                    }
                    &.sat {
                        color : rgb(94, 96, 207)
                    }
                }
                &:not(:first-of-type) {
                    padding: 3px;
                    // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                }
                &:first-of-type {
                    width: 10%;
                }
            }
        }
        .hourHeaders {
            .hourHeader {
                justify-content: center;
                font-size: 0.875rem;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.5);
                padding: 0.25rem 0;
                text-align: center;
                border: 0;
                position: relative;
                display: flex;
                align-items: center;
                border-bottom: 2px solid rgba(0, 0, 0, 0.1);
                &:first-of-type {
                    border-top: 2px solid rgba(0, 0, 0, 0.1);
                }
            }
        }
        .dataContainer {
            display: flex;
            .dateData {
                &:not(:last-of-type) {
                    border-right: 1px solid rgba(0, 0, 0, 0.1);
                }
                .hourData {
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    vertical-align: top;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    &:first-of-type {
                        border-top: 2px solid rgba(0, 0, 0, 0.1);
                    }
                    .minData {
                        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                        width: 100%;
                        position: relative;
                    }
                }
            }
        }
    }
}
.eventBlockContainer {
    position: absolute;
    background: #46464633;
    border: 1px solid #995252;
    .eventContainer {
        width: 100%;
        height: 100%;
        word-break: break-all;
        color : black;
        font: 12px NanumSquareOTF;
        &.one {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }
        &.two {
            display: grid;
            grid-template-columns:  1fr 1fr;
            >div:last-of-type {
                width: 200%;
            }
        }
        &.more {
            display: flex;
            flex-direction: column;
        }
        >div {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        .participant_div {
            display: flex;
            .participant {
                margin: 0 2px;
                width: fit-content;
                background: #DDD;
                padding: 0 1px;
                border-radius: 2px;
                border : 1px solid #222;
            }
        }
    }
}
</style>