<template>
    <div class="postStyle">
        <div @click.stop="onDetailLink" :class="{clickable : !isDetail}">
            <PostHeader :isDetail="isDetail"/>
        </div>
        <div @click="onDetailLink" :class="{clickable : !isDetail}">
            <div class="bodyStyle" v-html="post?.contents" ref="postContent"/>
        </div>
        <div>
            <PostFooter :isDetail="isDetail" :isComment="isComment" />
        </div>
    </div>
</template>
<script>
import PostHeader from "@/page/feed/post/postHeader.vue"
import PostFooter from "@/page/feed/post/postFooter.vue"

import {usePostStore} from "@/stores/post"
import { inject, nextTick, onMounted, provide, ref, watch} from "vue"
import {storeToRefs} from "pinia"
import { useRouter } from "vue-router"
import imageShowModalVue from '@/modal/imageShowModal.vue'
import { useNetworkStore } from '@/stores/network'
import util from '@/data/util'

export default {
    name : "PostItem",
    props : {
        postId : String,
        isDetail : {
            type : Boolean,
            default : false,
        },
        isLoading : {
            type : Boolean,
            default : false,
        },
        isComment: Boolean,
        refreshPost : Function,
        refreshNetwork : Function
    },
    components : {
        PostHeader,
        PostFooter,
    },
    setup(props, ctx) {
        const recentCommentCount = ref(5)
        const router = useRouter()
        const postStore = usePostStore()
        const networkStore = useNetworkStore()
        const {post} = !props.isDetail ? {post : postStore.getPost(props.postId)} : storeToRefs(postStore)
        const {networks} = storeToRefs(networkStore)
        const postContent = ref(null)
        const modalManager = inject("ModalManager")
        const onDetailLink = () => {
            if(!props.isDetail && props?.postId) {
                router.push({
                    path : "/feed/detail",
                    query : {
                        postno : props?.postId
                    }
                })
            }   
        }
        const refresh = async () => {
            // 모든 포스트 내용을 다시 가져오기에 느림.
            await props.refreshPost();
        }
        const refreshNetwork = async () => {
            // 한개의 포스트 밖에 없는 경우는 본인만 변경.
            if(props.isDetail) {
                await networkStore.fetchNetworks({email : post.value?.writer_email})
                const networkData = networks.value.filter(net => net.user_id == post.value?.writer_id)
                if(networkData?.length > 0) {
                    post.value.network_type = networkData[0].type
                    if(!post.value.network_id) {
                        post.value.network_id = networkData[0].id
                    }
                }
                else {
                    post.value.network_type = 0;
                    if(post.value.network_id) {
                        delete post.value.network_id
                    }
                }
            }
            // 여러 포스트 일 경우는 밖에서 전체 포스트 변경 필요.
            else {
                await props.refreshNetwork(post.value);
            }
        }
        const refreshComments = async () => {
            const comments = await postStore.fetchComments(post.value.id)
            if(comments) {
                const sortedComments = util.sortObj(comments, false, item => Number(item.id))
                if(post.value.recent_comments) {
                    post.value.comment_count = sortedComments.length
                    post.value.recent_comments = sortedComments.slice(0, recentCommentCount.value)
                }
                else if(post.value.comments) {
                    post.value.comments = sortedComments
                }
            }
        }

        const onLink = event => {
            event.stopPropagation();
        }

        const onShowImage = (src, imgs, idx) => {
            return () => {
                modalManager.showEx(imageShowModalVue, {
                    bind : {
                        src : src,
                        imgs: imgs,
                        idx: idx
                    }
                })
            }
        }

        const setTagEvent = async () => {
            if(postContent.value) {
                if(props.isDetail) {
                    const imgs = postContent.value.querySelectorAll("img")
                    if(imgs?.length > 0) {
                        imgs.forEach((imgTag, idx) => {
                            imgTag.style.cursor = "pointer";
                            const src = imgTag.src
                            imgTag.addEventListener("click", onShowImage(src, imgs, idx))
                        })
                    }
                }
                const links = postContent.value.querySelectorAll("a")
                if(links?.length > 0) {
                    links.forEach(link => {
                        link.addEventListener("click", onLink)
                    })
                }
            }
        }

        watch(() => post.value?.contents, () => {
            nextTick(() => {
                setTagEvent()
            })
        })
        
        onMounted(() => {
            nextTick(() => {
                setTagEvent()
            })
        })

        provide('refreshPost', refresh)
        provide('refreshNetwork', refreshNetwork)
        provide('postData', ref(post))
        provide('refreshComments', refreshComments)

        return {
            post,
            postContent,
            refresh,

            onDetailLink
        }
    },
}
</script>

<style scoped lang="scss">
.postStyle {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.6rem;
    background: #fff;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 1rem;
    position: relative;
    opacity: 0.8;
    width: 100%;
    text-decoration: none;
}
.bodyStyle {
    padding: 0 1.875rem;
    line-height: 1.142857143;
    margin-bottom: 50px;
    color: #6c6c6c;
    overflow: auto;
    &:deep(img) {
        max-width: 100%;
        cursor: pointer !important;
    }
}
</style>
