<template>
    <router-view>
    </router-view>
    <a class="linkStyle" @click="onLinkHome">
        <v-btn icon class="addStyle">
            <v-icon>
                mdi-home
            </v-icon>
        </v-btn>
    </a>
</template>
<script>
import { useRouter } from 'vue-router'
export default {
    setup(props, ctx) {
        const router = useRouter()
        const onLinkHome = () => {
            router.push("/")
        }
        return {
            onLinkHome,
        }
    }
}
</script>

<style scoped lang="scss">

.linkStyle {
        
    display: flex;
    align-items: center;
    color: #6c6c6c;
    text-decoration: none;
    width: 95px;
    justify-content: center;
    font: normal normal normal 17px/19px NanumSquareOTF;
    cursor: pointer;

    .addStyle {
        position: fixed;
        bottom: 53px;
        right: 30px;
        z-index: 10;
        background: rgb(145, 4, 87);
        width: 56px;
        height: 56px;
    }
    i {
        color : #fff;
    }
}
</style>