<template>
    <router-view></router-view>
</template>

<script>

</script>

<style scoped lang="scss">

</style>