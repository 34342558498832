<template>
    <div class="ContainerBlock">
        <form class="formStyle" enctype="multipart/form-data" @submit.prevent="onSubmit">
            <section>
                <h2>Title</h2>
                <v-text-field name="title" type='text' v-model="form.title"
                    placeholder="Enter Conference Title here" hide-details
                >
                </v-text-field>
            </section>

            <section>
                <h2>Photo</h2>
                <insertImage :cssData="{width: '540', height: '250'}"
                    v-model:img="inputImage" ref="insertImage"
                    @onSelectImage="onSelectImage" />
            </section>
            <section>
                <h2>Country</h2>
                <nationSelector :selectedCountries="[form.country_id]" :oneSelect="true" 
                    :isShowNationImg="false" @updateCountry="onSelectCountry"/>
            </section>
            <section class="daypicker">
                <div>
                    <h2>Start Date</h2>
                    <dayPicker v-model:date="form.start_date" class="day-picker" :mode="'date'"/>
                </div>
                <div>
                    <h2>End Date</h2>
                    <dayPicker v-model:date="form.end_date" class="day-picker" :mode="'date'"/>
                </div>
            </section>

            <section>
                <h2>Location</h2>
                <v-text-field name="location" type='text' v-model="form.location"
                    placeholder="Enter Conference Location here" hide-details
                >
                </v-text-field>
            </section>
            <section>
                <h2>Note</h2>
                <v-textarea name="note" type='text' v-model="form.note" 
                    placeholder="Enter Conference Note here" hide-details
                >
                </v-textarea>
            </section>
            <div class="buttons-div">
                <v-btn class="buttonStyle" type="submit">
                    {{ title }}
                </v-btn>
                <v-btn class="buttonStyle close" @click="onCancel">
                    CANCEL
                </v-btn>
            </div>
        </form>
    </div>
</template>

<script>
import dayPicker from "@/component/dayPicker.vue"
import util from "@/data/util"
import { useCountryStore } from '@/stores/country'
import { useConferenceStore } from '@/stores/conference'
import { useRoute, useRouter } from "vue-router"
import { computed, inject, onMounted, ref } from "vue"
import { storeToRefs } from "pinia"
import insertImage from '@/component/insertImage.vue'
import nationSelector from "@/component/nationSelector.vue"
import ImageResizeModalVue from '@/modal/ImageResizeModal.vue'

export default {
    components: {
        dayPicker,
        insertImage,
        nationSelector,
    },
    setup(props, ctx) {
        const modalManager = inject("ModalManager")

        const countryStore = useCountryStore()
        const conferenceStore = useConferenceStore()
        const router = useRouter()
        const route = useRoute()

        const { countries } = storeToRefs(countryStore)

        const pageType = ref("new")
        const insertImage = ref(null)
        const selected_country = ref(null)

        const timezoneData = computed(() => {
            return util.convertTimezoneJsonToData(selected_country.value?.timezones)
        })
        let conference = null
        const form = ref({
            title: "",
            image: "",
            start_date: { datestring: "" },
            end_date: { datestring: "" },
            country_id: "",
            country_name: "",
            location: "",
            note: "",
        })
        const inputImage = ref("")

        const title = computed(() => {
            return pageType.value == "new" ? "WRITE" : "EDIT"
        })

        const onSelectImage = (data, last_image) => {
            if(data) {
                modalManager.showEx(ImageResizeModalVue, {
                    bind : {
                        image : data,
                        isCircle : false,
                        ratio : 1,
                    }
                }).then(res => {
                    if(res?.image) {
                        form.value.image = res.image
                        inputImage.value = res.image
                    }
                    else {
                        form.value.image = last_image
                        if(last_image) {
                            // edit 일 경우 inputImage가 변경되지 않아 watch되지 않음 => 함수 호출로 변경.
                            insertImage.value.setImage(last_image)
                        }
                        else {
                            insertImage.value.resetImage()
                        }
                    }
                })
            }
        }

        const onSelectCountry = (select_country) => {
            form.value.country_id = null
            form.value.country_name = null
            if(select_country.length > 0 && countries.value) {
                const countryData = countries.value.filter(country => country.id == select_country[0])
                if(countryData.length > 0 ) {
                    selected_country.value = countryData[0]
                    form.value.country_id = countryData[0].id;
                    form.value.country_name = countryData[0].name;
                }
            }
        }

        const getOnlyDate = date => {
            return util.getDate(util.date_format(date, "YYYY-MM-DD"))
        }

        const checkContent = () => {
            let req = [
                { value: form.value.title, check: form.value.title.trim(), text: 'title' },
                { value: form.value.image, text: 'image' },
                { value: form.value.start_date.datestring, text: 'start date and time' },
                { value: form.value.end_date.datestring, text: 'end date and time' },
                { value: form.value.start_date.datestring && form.value.end_date.datestring,
                  check: getOnlyDate(form.value.start_date.datestring) <= getOnlyDate(util.convertUTSTOJTS(form.value.end_date.datestring)),
                  text: 'end time is less than or equal to start time' },
                { value: form.value.country_id, text: 'country' },
                { value: form.value.location, check: form.value.location.trim(), text: 'location' }
            ]

            if(pageType.value == 'edit') {
                req.push({
                    value: form.value.title && form.value.image &&
                           form.value.start_date.datestring && 
                           form.value.end_date.datestring &&
                           form.value.country_id && form.value.location &&
                           form.value.note,
                    check: 
                        !(form.value.title == conference.title &&
                          form.value.image == conference.image &&
                          getOnlyDate(form.value.start_date.datestring).getTime() == getOnlyDate(util.convertUTSTOJTS(conference.start_date)).getTime() &&
                          getOnlyDate(form.value.end_date.datestring).getTime() == getOnlyDate(util.convertUTSTOJTS(conference.end_date)).getTime() &&
                          form.value.country_id == conference.country_id &&
                          form.value.location == conference.location &&
                          form.value.note == conference.note),
                    text: 'This is same content'
                })
            }

            const res = util.dataCheck(req)
            if(res.length > 0) {
                return res.join("<br>")
            }
            return false
        }
        const onSubmit = async () => {
            const res = checkContent()
            if(res) {
                modalManager.showdialog(`Please check your data: <br> ${res}`)
                return
            }

            const data = new FormData()
            data.append('title', form.value.title)
            if (form.value.image) {
                data.append('image', form.value.image)
            }
            data.append('start_date', util.convertJTSToUTS(form.value.start_date.datestring, 0, false))
            data.append('end_date', util.convertJTSToUTS(form.value.end_date.datestring, 0, false))
            data.append('country', form.value.country_id)
            data.append('location', form.value.location)
            data.append('note', form.value.note)

            let result = false
            let successText = "Writed"
            if (conference) {
                successText = "Editted"
                result = await conferenceStore.updateConference(conference.id, data)
            } else {
                result = await conferenceStore.createConference(data)
            }

            if (result) {
                modalManager.showdialog(`${successText}`)
                    .then(() => {
                        router.back()
                    })
            } else {
                modalManager.showdialog(`Failed`)
            }
        }
        const onCancel = () => {
            router.back();
        }

        onMounted(async () => {
            if(!countries.value) {
                await countryStore.fetchCountries()
            }
            if(route.path.includes('edit') && route.query?.conferenceno) {
                pageType.value = 'edit'
                conference = await conferenceStore.fetchConference(route.query?.conferenceno)

                form.value.title = conference.title
                form.value.image = conference.image
                inputImage.value = conference.image
                form.value.country_id = conference.country_id
                if(conference.country_id) {
                    const countryData = countries.value.filter(country => country.id == conference.country_id)
                    if(countryData.length > 0) {
                        selected_country.value = countryData[0]
                    }
                }
                form.value.country_name = `${conference.country_name} (${conference.country_native})`
                form.value.start_date.datestring = util.convertUTSTOJTS(conference.start_date)
                form.value.end_date.datestring = util.convertUTSTOJTS(conference.end_date)
                form.value.location = conference.location
                form.value.note = conference.note

            } else {
                pageType.value = 'new'
            }
        })

        return {
            countries,
            pageType,
            conference,
            form,
            inputImage,
            title,
            util,
            timezoneData,
            insertImage,

            onSelectImage,
            onSelectCountry,
            onSubmit,
            onCancel,
        }
    },
}
</script>

<style lang="scss" scoped>
.ContainerBlock {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 37.5rem;
    min-width: 37.5rem;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: rgb(0 0 0 / 16%) 2px 5px 11px;
    border-radius: 1rem;
    padding: 1.875rem;
    margin-bottom: 1rem;
    color: #6c6c6c;

    .formStyle {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        section {
            margin-bottom: 1.5rem;
        }

        .daypicker {
            display: flex;
            justify-content: space-between;
        }
        .day-picker:deep(.date_content) {
            width: 250px;
            height: 43px;
        }
        .day-picker:deep(.date_content input) {
            width: 250px;
            height: 43px;
        }

        .searchBoxStyle {
            position: relative;
            display: flex;
            width: 540px;
            height: 43px;
            flex-direction: row;
        }
        .nationStyle {
            // padding: 0.5rem 0.75rem;
            border: 1px solid #9c9c9c;
            border-radius: 50px;
            width: 600px;

            .outlineStyle {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                outline: none;
                padding: 5px 10px;
                height: 40px;

                .countrySelect {
                    width: 495px; 
                    overflow: hidden; 
                    text-overflow: ellipsis; 
                    white-space: nowrap;
                }
            }
            
            .iconStyle {
                color : #910457;
            }
        }

        h2 {
            font: 800 16px / 2.25 NanumSquareOTF;
        }
    }

    .buttons-div {
        display: flex;
        .buttonStyle {
            border: none;
            width: 48%;
            margin: 0 5px;
            height: 43px;
            font: 14px NanumSquareOTF;
            font-weight: 500;
            color: white;
            cursor: pointer;
            background: #910457;
            &.close {
                background: #6c6c6c;
            }
        }
    }
}
</style>