
import {unified} from "unified";
import markdown from "remark-parse";
import remark2rehype from "remark-rehype";
import html from "rehype-stringify";

export default {
    
    removeTag(text) {
        const reg = /<[^>]*>?/g
        
        return text.replace(reg, '');
    },
    convertMarkDownToHTML(text) {
        const innerHtmlText = unified()
            .use(markdown)
            .use(remark2rehype)
            .use(html)
            .processSync(text);
        return innerHtmlText;
    },
    convertTextToHTML(text) {
        return text.replaceAll("\n", "<br>")
    },
    base64TokenParse(token) {
        return window.atob(token)
    },
    getDate(time, timezone_uts = null, reverse = false) {
        // timezone 처리용
        if(timezone_uts != null) {
            if(reverse) {
                return new Date(new Date(time).getTime() 
                    + (this.getTimeZone() * 60 * 1000) - (timezone_uts * 1000))
            }
            else {
                return new Date(new Date(time).getTime() 
                    - (this.getTimeZone() * 60 * 1000) + (timezone_uts * 1000))
            }
        }
        return new Date(time)
    },
    convertTimezoneJsonToData(tzData) {
        var tz_data = {
            gmtOffset : this.getTimeZone() * 60,
            gmtOffsetName : `${this.getTimeZoneText()}`
        }
        if(tzData) {
            tz_data = JSON.parse(tzData)[0]
        }
        return tz_data;
    },
    getThumbnailPath(url) {
        const dotIndex = url.lastIndexOf(".")
        return `${url.slice(0, dotIndex)}_thumb${url.slice(dotIndex)}`
    },
    getCountryFlag(country_iso2) {
        if(!country_iso2) {
            return ""
        }
        return `https://flagcdn.com/w20/${country_iso2.toLowerCase()}.png`
    },
    getCurrentDate(timezone_uts = null, reverse = false) {
        return this.getDate(new Date(), timezone_uts, reverse)
    },
    getTimeDiifForCurrent(time) {
        const p_time = new Date(time)
        if(!this.is_vaild_date(p_time)) {
            return 0;
        }
        const mic_sec_diff = 1000;
        const sec_diff = mic_sec_diff * 60; // 60초 내
        const min_diff = sec_diff * 60; // 60분 내
        const hour_diff = min_diff * 24; // 24시간 내
        const day_diff = hour_diff * 30; // 30일 내
        const month_diff = day_diff * 12; // 12달 내

        const time_diff = new Date().getTime() - p_time.getTime()

        if(time_diff < sec_diff) {
            return {
                data : Math.floor(time_diff / mic_sec_diff),
                type : "second"
            }
        }
        else if(time_diff < min_diff) {
            return {
                data : Math.floor(time_diff / sec_diff),
                type : "minute"
            }
        }
        else if(time_diff < hour_diff) {
            return {
                data : Math.floor(time_diff / min_diff),
                type : "hour"
            }
        }
        else if(time_diff < day_diff) {
            return {
                data : Math.floor(time_diff / hour_diff),
                type : "day"
            }
        }
        else if(time_diff < month_diff) {
            return {
                data : Math.floor(time_diff / day_diff),
                type : "month"
            }
        }
        else {
            return {
                data : Math.floor(time_diff / month_diff),
                type : "year"
            }
        }
        
    },
    getTimeZone() {
        const date = new Date();
        return -date.getTimezoneOffset();
    },
    getTimeZoneText() {
        const timezone = this.getTimeZone()
        const displayTime = `${this.num_format(Math.floor(timezone / 60))}:${this.num_format(timezone % 60)}`;
        return `UTC ${timezone >= 0 ? "+" + displayTime : "-" + displayTime}`
    },
    // DB use unix time stamp
    convertTimeStmapToDateTime(timeStamp, timezone_uts = null) {
        let time = this.convertUTSTOJTS(timeStamp)
        return this.getDate(time, timezone_uts)
    },
    convertJTSToUTS(JTS, timezone_uts = null, reverse = true) {
        return Math.floor(this.getDate(JTS, timezone_uts, reverse).getTime() / 1000);
    },
    convertUTSTOJTS(UTS, timezone_uts = null, reverse = false) {
        return this.getDate(UTS * 1000, timezone_uts, reverse).getTime();
    },
    convertDateTimeToTimeStamp(dateTime, timezone_uts = null, reverse = true) {
        const dt_dateTime = new Date(dateTime)
        return this.convertJTSToUTS(dt_dateTime.getTime(), timezone_uts, reverse)
    },
    checkProfileImage(image) {
        return image && image != "null" ? image : require("@/assets/default_user.png")
    },
    emailCheck(email) {
        // 이메일 검증 스크립트 작성
        var emailVal = email;

        var regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
        // 검증에 사용할 정규식 변수 regExp에 저장
        if (emailVal.match(regExp) != null) {
            return true
        }
        return false
    },
    is_vaild_date(date)  {
        if(date) {
            date = new Date(date)
        }
        return date.toString() !== "Invalid Date" && !isNaN(date)
    },
    num_format(value, length = 2) {
        const num_length = value.toString().length
        var r = "";
        for(var i = 0; i < length - num_length ; i++) {
            r += "0";
        }
        r += value.toString()
        return r;
    },

    dataCheck(check_data) {
        let res = []

        // value 만 있는 경우 value를 체크
        // value 와 chekc가 있는 경우 value가 true 일때만 check를 체크.
        check_data.forEach(item => {
            if(item.check == undefined) {
                if(!item.value) {
                    res.push(item.text)
                }
            }
            else if(item.value && !item.check) {
                res.push(item.text)
            }
        })
        return res;
    },
    fileToBase64(file) {          
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                resolve(reader.result);
            }
            reader.onerror = function () {
                reject();
            }
        })  
    },
    base64ToFile(base64, filename = "default") {
        var arr = base64.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
    
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    },
    getRandomColor(length) {
        let seed = -120;
        function random() {
            let x = Math.sin(seed++) * 10000;
            return x - Math.floor(x);
        }
        let series_array = Array(length).fill(0)
        series_array = series_array.map(() => {
            return {color : "#" + this.num_format(Math.floor(random()*16777215).toString(16), 6)}
        })
        return series_array
    },
    convertByteToSizeWithUnit(byte, pointFormat = 2) {
        const byteUnits = ["KB", "MB", "GB", "TB"];
        let unit = null
        let size = byte
        byteUnits.forEach(bUnit => {
            if(unit) {
                return;
            }
            size = Math.round(size * (10 ** pointFormat) / 1024) / (10 ** pointFormat)
            if(size < 1024) {
                unit = bUnit
                return;
            } 
        })
        return {
            size : size,
            unit : unit
        }
    },
    getUnicodeTextSize(string, b, i, c) {
        if(!string || typeof string != "string") {
            return 0;
        }
        for(b=i=0; (c=string.charCodeAt(i++)); b+=c>>11?3:c>>7?2:1);
        return b;
    },
    date_format(date, format = "YYYY/MM/DD", timezone_uts = null, reverse = false, lang = "en") {
        var p_date = new Date(date)
        if(timezone_uts) {
            p_date = this.getDate(p_date, timezone_uts, reverse)
        }
        if (!this.is_vaild_date(p_date) || !date) {
            return false;
        }
        let day = ["일", "월", "화", "수", "목" , "금" , "토"]
        if(lang == "en") {
            day = ["Sun", "Mon", "Tue", "Wed", "Thu" , "Fri" , "Sat"]
        }
        let date_array = [
            p_date.getFullYear(), 
            p_date.getMonth() + 1, 
            p_date.getDate(), 
            p_date.getHours(), 
            p_date.getMinutes(), 
            p_date.getSeconds(), 
            day[p_date.getDay()]
        ]
        
        date_array = date_array.map(item => { return {value : String(item).split(""), index : String(item).length -1} })

        let string_date = "", is_not_format = false

        format.split("").reverse().forEach(item => {
            if(item === '}') {
                is_not_format = true
            }
            else if(item === '{') {
                is_not_format = false
            }
            if(!is_not_format) {
                let date_array_index = null
                //년 0
                if(item === 'y' || item === 'Y') {
                    date_array_index = 0
                }
                //월 1
                else if(item === 'M') {
                    date_array_index = 1
                }
                //일 2
                else if(item === 'D') {
                    date_array_index = 2
                }
                //시 3
                else if(item === 'h' || item === 'H') {
                    date_array_index = 3
                } 
                //분 4
                else if(item === 'm') {
                    date_array_index = 4
                }
                //초 5
                else if(item === 's' || item === 'S') {
                    date_array_index = 5
                }
                // 요일 6
                else if(item === 'd') {
                    date_array_index = 6
                }
                if(date_array_index !== null) {
                    if(date_array[date_array_index].index < 0) {
                        string_date += "0"
                    }
                    else {
                        string_date += date_array[date_array_index].value[date_array[date_array_index].index]
                        date_array[date_array_index].index -= 1
                    }
                }
                else {
                    if(!(item === "{" || item === "}")) {
                        string_date += item
                    }
                }
            }
            else {
                if(!(item === "{" || item === "}")) {
                    string_date += item
                }
            }
        })
        return string_date.split("").reverse().join("")
    },
    sortObj(list = [], asc = true) {
        if(arguments.length > 2) {
            return list.sort((a,b) => {
                return this.compare_in_sort(a, b, asc, ...[...arguments].filter((arg, index) => {if(index > 1) return true}))
            })
        }
    },
    compare_in_sort(a, b, asc) {
        if(arguments.length > 3) {
            let result = 0
            if(asc) {
                if(arguments[3](a) > arguments[3](b)) result = 1;
                else if(arguments[3](a) < arguments[3](b)) result = -1;
            }
            else {
                if(arguments[3](a) < arguments[3](b)) result = 1;
                else if(arguments[3](a) > arguments[3](b)) result = -1;
            }
            if(result != 0) {
                return result
            }
            else if(arguments.length > 4){
                return this.compare_in_sort(a, b, asc, ...[...arguments].filter((arg, index) => {if(index > 3) return true}))
            }
        }
        return 0;
    },
    range(start, end) {
        const range = Math.floor(end - start);
        const arr = Array(range);
        const arrFill = arr.fill()
        arrFill.forEach((item, index) => {
            arrFill[index] = index + start
        })
        return arrFill;
    }
}